'use strict';

class liberacaoDaCaucaoService {

	/* @ngInject */
	constructor($q, visualForceApi, $sce, resgateFormService) {

		this.$q = $q;
		this.visualForceApi = visualForceApi;
		this.resgateFormService = resgateFormService;

		this.deferred = {

			saveCaseResgate: null
		};

		this.setPristine();
	}

	setPristine() {

		this.errorMessage = '';
		this.warningMessage = '';
		this.successMessage = '';
		this.propostaFromResgate = false;
		this.proposta = null;
		this.resgateAccount = null;
		this.isEdit = false;
		this.popUpCaseNumber = '';
		this.buttonControl = {
			cancel: {
				show: true,
				disabled: true
			},
			previous: {
				show: false,
				disabled: true
			},
			next: {
				show: true,
				disabled: true
			},
			save: {
				show: true,
				disabled: true
			},
			finish: {
				show: false,
				disabled: true
			}
		};
	}

	cleanMessages() {
		this.setErrorMessage('');
		this.setWarningMessage('');
		this.setSuccessMessage('');
	}

	setWarningMessage(warningMessage) {
		this.warningMessage = warningMessage;
	}

	getWarningMessage() {
		return this.warningMessage;
	}

	refuseCaseResgate(caseId) {
		const request = {
			resgate: {
				id: caseId
			}
		};
		console.log('refuseCaseResgate', request);

		const cap_access_token = localStorage.getItem("cap_access_token");
		request.accessToken = cap_access_token;

		return this.visualForceApi.invoke(
			'CapCommunityCaseControllerExt.refuseCaseResgate', request);
	}

	sendCaseLiberacao(caseId) {

		const cap_access_token = localStorage.getItem("cap_access_token");

		const request = {
			resgate: {
				id: caseId
			}
		};
		console.log('sendCaseResgate', request);

		request.accessToken = cap_access_token;

		return this.visualForceApi.invoke(
			'CapCommunityCaseControllerExt.sendCaseResgate', request);
	}

	reset() {

		this.setPristine();
	}

	isApiInUse(apiName) {

		return !!this.deferred[apiName];
	}

	getValueIfNotNull(obj) {
		return (obj != null) ? obj.value : null;
	}

	cleanUpFileArray(object) {
		const properties = Object.keys(object);

		for (let i = properties.length - 1; i >= 0; i--) {
			const propertyName = properties[i];

			if (propertyName === 'files') {
				object[propertyName] = [];
			} else if (angular.isObject(object[propertyName])) {
				this.cleanUpFileArray(object[propertyName]);
			}
		}
	}

	saveCaseResgate() {

		const apiController = 'CapCommunityCaseControllerExt.saveCaseResgate';
		const editCaseData = JSON.parse(JSON.stringify(this.resgateFormService.formField));
		this.cleanUpFileArray(editCaseData.file);

		if (!this.isApiInUse('saveCaseResgate')) {

			this.deferred.saveCaseResgate = this.$q.defer();

			const payload = this.createSaveRequest(editCaseData);
			const cap_access_token = localStorage.getItem("cap_access_token");
			payload.accessToken = cap_access_token;

			this.visualForceApi.invoke(apiController, payload)
				.then((response) => {

					this.deferred.saveCaseResgate.resolve(response);
				})
				.catch((response) => {

					this.deferred.saveCaseResgate.reject(response);
				})
				.finally((response) => {

					this.deferred.saveCaseResgate = null;
				});
		}

		return this.deferred.saveCaseResgate.promise;
	}

	sendCaseResgate() {
		File.prototype.toJSON = function () {
			return {
				// "index": this.index,
				// "$$hashKey": this.$$hashKey,
				"name": this.name,
				"result": this.result,
				"salesForceId": this.salesForceId
			};
		};

		//@todo gambiarra: o objeto File se perde ao fazer angular.copy()
		const editCaseData = JSON.parse(JSON.stringify(this.resgateFormService.formField));

		const cap_access_token = localStorage.getItem("cap_access_token");

		const payload = this.createSaveRequest(editCaseData);

		payload.accessToken = cap_access_token;

		return this.visualForceApi.invoke(
			'CapCommunityCaseControllerExt.sendCaseResgate', payload);
	}

	cancelResgate(Id) {

		const cap_access_token = localStorage.getItem("cap_access_token");

		return this.visualForceApi.invoke(
			'CapCommunityCaseControllerExt.cancelResgate', Id, 'resgate', cap_access_token);
	}

	pushRefactoredDebitDeclarationList(oldDebitDeclarationList) {

		const newDebitDeclaration = [];

		for (const item of oldDebitDeclarationList) {

			const newItem = {

				description: item.description,
				vencimento: item.venc,
				competencia: item.comp,
				debitValue: item.value,
				multaValue: item.multa,
				othersValue: item.others
			};

			newDebitDeclaration.push(newItem);
		}

		return newDebitDeclaration;
	}

	createSaveRequest(pEditCaseData) {
		// File.prototype.toJSON = function () {
		// 	return {
		// 		// "index": this.index,
		// 		// "$$hashKey": this.$$hashKey,
		// 		"name": this.name,
		// 		"result": this.result,
		// 		"salesForceId": this.salesForceId
		// 	};
		// };
		const request = {
			resgate: {
				id: this.resgateFormService.formField.id,
				accountId: (this.resgateFormService.formField.information.mainAccount.value !== null) ? this.resgateFormService.formField.information.mainAccount.value.Id : null,
				propostaId: (this.resgateFormService.formField.information.proposta.value !== null) ? this.resgateFormService.formField.information.proposta.value.objectId : null,
				titulos: [],
				legalRepresentative: (this.resgateFormService.formField.information.legalRepresentative.value === 'true') ? true : false,
				status: this.resgateFormService.formField.status,
				caucionado: this.resgateFormService.formField.type.caucionado,
				canceling: false,
				resgateType: ((this.resgateFormService.formField.type.resgateType.value !== null) ? ((this.resgateFormService.formField.type.resgateType.value.includes('antecipado')) ? 'Antecipado' : 'Final') : null),
				declaration: (this.resgateFormService.formField.type.customerAgreement.value !== null) ? this.resgateFormService.formField.type.customerAgreement.value : false,
				resgateResult: (this.resgateFormService.formField.liberarCaucao === true) ? 'liberar-caucao' : this.resgateFormService.formField.decisionType,
				debitValue: this.resgateFormService.formField.data.debitValue.value || 0,
				jobName: (this.resgateFormService.formField.data.customerData.professionalActivity.value !== null) ? this.resgateFormService.formField.data.customerData.professionalActivity.value.Name : null,
				income: this.resgateFormService.formField.data.customerData.income.value,
				phone: this.resgateFormService.formField.data.customerData.phone.value,
				email: this.resgateFormService.formField.data.customerData.email.value,
				observations: this.resgateFormService.formField.data.customerData.observations,
				propostaId2: (this.resgateFormService.formField.type.proposta.value !== null) ? this.resgateFormService.formField.type.proposta.value.Id : null,
				debitDeclaration: [],
				debitTotalValue: this.resgateFormService.formField.data.debitDeclaration.totalValue.value,
				multaTotalValue: this.resgateFormService.formField.data.debitDeclaration.totalMulta,
				acrescimoTotalValue: this.resgateFormService.formField.data.debitDeclaration.totalAcrescimo,
				legalRepresentativeName: this.resgateFormService.formField.data.representantData.name.value,
				legalRepresentativeCpfcnpj: this.resgateFormService.formField.data.representantData.cpfcnpj.value,
				legalRepresentativeObservations: this.resgateFormService.formField.data.representantData.observations,
				cpfcnpj: this.resgateFormService.formField.data.customerData.cpfcnpj.value,
				bankAccountType: this.resgateFormService.formField.bank.bankData.accountType.value,
				debitAccountType: this.resgateFormService.formField.bank.bankBills.accountType.value,
				bankBankName: this.resgateFormService.formField.bank.bankData.bank.value,
				debitBankName: this.resgateFormService.formField.bank.bankBills.bank.value,
				bankAgency: this.resgateFormService.formField.bank.bankData.agency.value,
				bankEmail: this.getValueIfNotNull(this.resgateFormService.formField.bank.bankData.bankEmail),
				debitEmail: this.getValueIfNotNull(this.resgateFormService.formField.bank.bankData.debitEmail),
				debitAgency: this.resgateFormService.formField.bank.bankBills.agency.value,
				bankAccountNumber: this.resgateFormService.formField.bank.bankData.accountNumber.value,
				debitAccountNumber: this.resgateFormService.formField.bank.bankBills.accountNumber.value,
				bankAccountOwnerName: this.resgateFormService.formField.bank.bankData.accountOwnerName.value,
				debitAccountOwnerName: this.resgateFormService.formField.bank.bankBills.accountOwnerName.value,
				bankCPFCNPJ: this.resgateFormService.formField.bank.bankData.cpfcnpj.value,
				debitCPFCNPJ: this.resgateFormService.formField.bank.bankBills.cpfcnpj.value,
				debitJobName: (this.resgateFormService.formField.bank.bankBills.professionalActivity.value !== null) ? this.resgateFormService.formField.bank.bankBills.professionalActivity.value.Name : null,
				bankJobName: (this.resgateFormService.formField.bank.bankData.professionalActivity.value !== null) ? this.resgateFormService.formField.bank.bankData.professionalActivity.value.Name : null,
				bankIncome: (this.resgateFormService.formField.bank.bankData.income.value !== null) ? this.resgateFormService.formField.bank.bankData.income.value : null,
				bankPhone: this.resgateFormService.formField.bank.bankData.phone || null,
				debitIncome: this.resgateFormService.formField.bank.bankBills.income.value,
				//phoneNumber: this.resgateFormService.formField.
				// debitObservations: this.resgateFormService.formField.bank.bankBills.observations,
				debitPhone: this.resgateFormService.formField.bank.bankBills.phone || null,
				tabResgateInfo: this.resgateFormService.formField.tabResgateInfo,
				tabResgateType: this.resgateFormService.formField.tabResgateType,
				tabResgateData: this.resgateFormService.formField.tabResgateData,
				tabResgateBank: this.resgateFormService.formField.tabResgateBank,
				tabResgateAttach: this.resgateFormService.formField.tabResgateAttach,
				valueToRecover: this.resgateFormService.formField.type.titleAquisition.value ? this.resgateFormService.formField.data.propostaValue.value : null,
				remainValue: this.resgateFormService.formField.data.valueRemain.value,
				contractDate: (this.resgateFormService.formField.data.realtyInformation.contractDate.value !== '') ? this.resgateFormService.formField.data.realtyInformation.contractDate.value : null,
				editCaseData: JSON.stringify(pEditCaseData)
				//editCaseData: JSON.stringify(this.resgateFormService.formField)
			}
		}

		const debitDeclarationList = this.resgateFormService.formField.data.debitDeclaration.debitsSelected.value;

		if (debitDeclarationList.length) {

			request.resgate.debitDeclaration = this.pushRefactoredDebitDeclarationList(debitDeclarationList);
		}

		if (this.resgateFormService.formField.information.garantias.value !== null) {

			for (let i = 0; i < this.resgateFormService.formField.information.garantias.value.length; i++) {

				if (this.resgateFormService.formField.information.garantias.value[i].selected === true) {

					request.resgate.titulos.push({
						id: this.resgateFormService.formField.information.garantias.value[i].objectId,
						value: this.resgateFormService.formField.information.garantias.value[i].value,
						antecipatedValue: this.resgateFormService.formField.information.garantias.value[i].antecipatedValue
					});
				}
			}
		}

		console.log('REQUEST: ', request);
		return request;
	}

	setErrorMessage(errorMessage) {
		this.errorMessage = errorMessage;
		console.log(errorMessage);
	}

	getErrorMessage() {
		return this.errorMessage;
	}

	setIsEdit(isEdit) {
		this.isEdit = isEdit;
	}

	getIsEdit() {
		return this.isEdit;
	}

	setSuccessMessage(successMessage) {
		this.successMessage = successMessage;
	}

	getSuccessMessage() {
		return this.successMessage;
	}

	setCancelShow(show) {
		this.buttonControl.cancel.show = show;
	}

	getCancelShow() {
		return this.buttonControl.cancel.show;
	}

	setCancelDisabled(disabled) {
		this.buttonControl.cancel.disabled = disabled;
	}

	getCancelDisabled() {
		return this.buttonControl.cancel.disabled;
	}

	setPreviousShow(show) {
		this.buttonControl.previous.show = show;
	}

	getPreviousShow() {
		return this.buttonControl.previous.show;
	}

	setPreviousDisabled(disabled) {
		this.buttonControl.previous.disabled = disabled;
	}

	getPreviousDisabled() {
		return this.buttonControl.previous.disabled;
	}

	setNextShow(show) {
		this.buttonControl.next.show = show;
	}

	getNextShow() {
		return this.buttonControl.next.show;
	}

	setNextDisabled(disabled) {
		this.buttonControl.next.disabled = disabled;
	}

	getNextDisabled() {
		return this.buttonControl.next.disabled;
	}

	setSaveShow(show) {
		this.buttonControl.save.show = show;
	}

	getSaveShow() {
		return this.buttonControl.save.show;
	}

	setSaveDisabled(disabled) {
		this.buttonControl.save.disabled = disabled;
	}

	getSaveDisabled() {
		return this.buttonControl.save.disabled;
	}

	setFinishShow(show) {
		this.buttonControl.finish.show = show;
	}

	getFinishShow() {
		return this.buttonControl.finish.show;
	}

	setFinishDisabled(disabled) {
		this.buttonControl.finish.disabled = disabled;
	}

	getFinishDisabled() {
		return this.buttonControl.finish.disabled;
	}

	setPropostaFromResgate(resgate) {
		this.propostaFromResgate = resgate;
	}

	getPropostaFromResgate() {
		return this.propostaFromResgate;
	}

	setObjectProposta(proposta) {
		this.proposta = proposta;
	}

	getObjectProposta() {
		return this.proposta;
	}

	setResgateAccount(account) {
		this.resgateAccount = account;
	}

	getResgateAccount() {
		return this.resgateAccount;
	}

	isItemSelected(item) {

		return item.selected === true;
	}

	getResgateValue() {

		let resgateValue = 0;
		const garantias = this.resgateFormService.formField.information.garantias.value;
		// const garantiaValueType = this.isResgateAntecipado() ? 'antecipatedValue' : 'value';
		const garantiaValueType = this.isCancelamento() ? 'value' : 'antecipatedValue';

		for (let i = garantias.length - 1; i >= 0; i--) {

			const garantiaItem = garantias[i];

			if (this.isItemSelected(garantiaItem)) {

				resgateValue += garantiaItem[garantiaValueType];
				// resgateValue += garantiaItem.antecipatedValue;
			}
		}
		console.log('getResgateValue', resgateValue, garantias, garantiaValueType);
		// console.log('getResgateValue', resgateValue, garantias);

		return resgateValue;
	}

	isCancelamento() {

		return !this.resgateFormService.formField.type.vigencia;
	}

	isResgateAntecipado() {

		return this.resgateFormService.formField.type.resgateType.value === 'Resgate antecipado';
	}
}

module.exports = liberacaoDaCaucaoService;
