'use strict';

import VisualForceApi from '../core/sfdc/visual-force-api.js';

class contactUsApi extends VisualForceApi {

	/* @ngInject */
	constructor($q, $timeout) {

		super();
		this.$q = $q;
		this.$timeout = $timeout;
	}

	sendForm(form) {
		// console.log('sendForm', form);

		const cap_access_token = localStorage.getItem("cap_access_token");
		form.accessToken = cap_access_token;

		return super.invoke(
			'CapCommunityCaseControllerExt.createCaseFaleConosco', form);
	}
}

module.exports = contactUsApi;
