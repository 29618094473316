'use strict';

class comissoesPagasDetailApi {

	/* @ngInject */
	constructor(visualForceApi) {

		this.visualForceApi = visualForceApi;
	}

	getDetails(comissaoId) {
		return this.visualForceApi.invoke(
			'CapCommunityComissaoPagaControllerExt.getComissaoPagaDetail', comissaoId);
	}
}

module.exports = comissoesPagasDetailApi;
