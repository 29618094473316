'use strict';

const annexModule = require('./annex/component.module');

const helperModule = angular.module('cap.secured.core.helper', [

	annexModule.name
]);

module.exports = helperModule;
