"use strict";

class propostaDetailController {
	// @ngInject
	constructor(
		$scope,
		$window,
		$stateParams,
		$state,
		sfConfig,
		propostaApi,
		propostaDetailService,
		formControlService,
		breadcrumbHelper
	) {
		this.$stateParams = $stateParams;
		this.propostaId = this.$stateParams.id;
		this.$state = $state;
		this.$scope = $scope;
		this.$window = $window;
		this.propostaApi = propostaApi;
		this.propostaDetailService = propostaDetailService;
		this.formControl = formControlService;
		this.formField = this.formControl.field;
		this.$scope.$emit("changeMainShowLoading", true);
		this.imageAdd = `${sfConfig.resource}/images/ico-add.png`;
		this.proposta = {
			details: null,
			related: {
				cases: null,
				rentGarantee: null,
				contacts: null
			}
		};
		this.errorMessage = "";
		this.getUserDetails();
		this.$scope.breadcrumb = breadcrumbHelper;
		this.configureBreadCrumb();
	}

	configureBreadCrumb() {
		this.$scope.breadcrumb.reset();
		this.$scope.breadcrumb.addMenuItem(
			"Home",
			"/caponline/secured#!/home",
			true,
			false
		);
		this.$scope.breadcrumb.addMenuItem(
			"Proposta",
			"/caponline/secured#!/proposta/lista",
			true,
			false
		);
		this.$scope.breadcrumb.addMenuItem("Detalhes da Proposta", "#", true, true);
	}

	getStatusDaDocumentacao() {
		this.proposta.details.propostaInfo.statusDaDocumentacao =
			this.proposta.details.propostaInfo.status.toLowerCase() ===
			"em andamento" ||
			this.proposta.details.propostaInfo.status.toLowerCase() === "cancelada" ?
			null :
			this.proposta.details.propostaInfo.statusDaDocumentacao;

		return this.proposta.details.propostaInfo.statusDaDocumentacao;
	}

	hasProposta() {
		return !!this.proposta.details;
	}

	fixPaymentType(paymentType, isOrigemResgate) {
		if (paymentType != null && paymentType != "") return paymentType;

		if (isOrigemResgate) {
			return "Recuperação de Crédito";
		} else {
			return "Boleto";
		}
	}

	hasGenerateBillet() {
		let habilitaBotao =
			this.hasProposta() && this.proposta.actions.hasGenerateBillet;
		// CAP-2909 Resgate com recuperação de crédito para Proposta não precisa gerar Boleto
		if (this.proposta.details.propostaInfo.isOrigemResgate)
			habilitaBotao = false;
		// COC-273 Emissão de boleto no Caponline para proposta reaplicada (série 81)
		if (this.proposta.details.propostaInfo.propostaNumber.indexOf(".81-") > 0)
			habilitaBotao = false;

		return habilitaBotao;
	}

	isPropostaDuplaGarantia() {
		const propostaType = sessionStorage.getItem("tipo_lista");
		if (propostaType == "D") {
			return true;
		} else {
			return false;
		}
	}

	hasGenerateDocument() {
		return this.hasProposta() && this.proposta.actions.hasGenerateDocument;
	}

	setPropostaActions(state, actions) {
		if (state === "proposta.boleto") {
			this.formControl.setActions(actions);
		}
	}

	goToPropostaStep(state, origem, propostaId) {
		this.propostaId = propostaId || this.propostaId;

		this.$scope.$emit("changeMainShowLoading", true);
		this.propostaApi
			.getPropostaOrigem(this.propostaId, origem)
			.then((response) => {
				console.log("getProposta response", response);
				if (response.propostaEdit.proposalType == "D") {
					sessionStorage.setItem("tipo_lista", "D");
				}

				//this.formField = this.proposta.details.paymentInfo;

				this.$scope.$emit("changeMainShowLoading", false);

				if (!response.isSuccessful) {
					this.apiUnsuccessful(response);
					return;
				}

				this.formControl.feedNewData(response.propostaEdit);
				this.setPropostaActions(state, response.actions);
				this.$window.scrollTo(0, 0);
				this.$state.go(state);
			});
	}

	isDebito() {
		const paymentType = this.proposta.details.paymentInfo.paymentType;

		if (paymentType != null) {
			return paymentType == "Débito em Conta Corrente";
		} else {
			return false;
		}
	}

	isCartaoCredito() {
		const paymentType = this.proposta.details.paymentInfo.paymentType;

		if (paymentType != null) {
			return paymentType == "CartaoCredito";
		} else {
			return false;
		}
	}

	apiUnsuccessful(response) {
		this.errorMessage = angular.toJson(response.errorMessages);
		this.$window.scrollTo(0, 0);
	}

	hasValidUrlParams() {
		return this.$stateParams && this.$stateParams.id ? true : false;
	}

	getUserDetails() {
		if (!this.hasValidUrlParams()) {
			this.$scope.$emit("changeMainShowLoading", false);
			this.$state.go("proposta.list");
		}

		this.propostaDetailService
			.getPropostaDetails(this.$stateParams.id)
			.then((response) => {
				console.log(response);
				if (!response.isSuccessful) {
					this.errorMessage = response.errorMessages[0];
				} else {
					console.log("this.proposta.details: ", response.proposta);
					this.proposta.details = response.proposta;
					this.proposta.actions = response.actions;
					this.proposta.related.cases = response.cases;
					this.proposta.related.rentGarantee = response.garantias;
					this.proposta.related.contacts = response.contacts;

					const paymentType = this.proposta.details.paymentInfo.paymentType;
					const isOrigemResgate =
						this.proposta.details.propostaInfo.isOrigemResgate;

					this.formField = this.proposta.details.paymentInfo;

					this.formField.parcelaValor =
						this.proposta.details.paymentInfo.parcelaValor;
					this.formField.cartaoValorLiquido =
						this.proposta.details.paymentInfo.cartaoValorLiquido;
					this.formField.parcelaSelecionada =
						this.proposta.details.paymentInfo.parcelaSelecionada;
					this.formField.cartaoValorCobradoTotal =
						this.proposta.details.paymentInfo.cartaoValorCobradoTotal;
					this.formField.cpfTitularCartao =
						this.proposta.details.paymentInfo.cpfTitularCartao;
					this.formField.nomeTitularCartao =
						this.proposta.details.paymentInfo.nomeTitularCartao;

					if (response.proposta.propostaInfo.DG.proposalType == "D") {
						sessionStorage.setItem("tipo_lista", "D");
					}

					this.proposta.details.paymentInfo.paymentType = this.fixPaymentType(
						paymentType,
						isOrigemResgate
					);
					console.log("formField ---->", this.formField);
				}

				this.$scope.$emit("changeMainShowLoading", false);
			});
	}

	cloneProposta() {
		this.$scope.$emit("changeMainShowLoading", true);
		this.propostaDetailService
			.cloneProposta(this.propostaId)
			.then((response) => {
				if (!response.isSuccessful) {
					this.$scope.$emit("changeMainShowLoading", false);
					this.errorMessage = response.errorMessages[0];
					console.log(response);
				} else {
					console.log("clonedProposta ", response);
					this.goToPropostaStep("proposta.new", '', response.propostaId);
				}
			});
	}

	customerHasPatrimony() {
		return (
			this.hasProposta() &&
			this.proposta.details.propostaInfo.customer.patrimony
		);
	}
}

module.exports = propostaDetailController;
