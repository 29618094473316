"use strict";

const mainController = require("./main-controller");
const routesConfiguration = require("./main-routes");
const autocompleteApiService = require("./autocomplete/autocomplete-api.service");
const autocompleteDirective = require("./autocomplete/autocomplete.directive");
const autocompleteController = require("./autocomplete/autocomplete.controller");
const capInputFilterDirective = require("./capinputfilter.directive");

const mainModule = angular
	.module("cap.secured.main", [
		"ui.router",
		"ui.bootstrap",
		"ui.utils.masks",
		"ngFileUpload"
	])
	.controller("mainController", mainController)
	.service("autocompleteApiService", autocompleteApiService)
	.directive("autocompleteDirective", () => new autocompleteDirective())
	.controller("autocompleteController", autocompleteController)
	.directive("capInputFilter", () => new capInputFilterDirective())
	.config(routesConfiguration);

module.exports = mainModule;
