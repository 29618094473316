'use strict';

const componentController = require('./sorteio.controller');
const componentApi = require('./sorteio.service');
const routesConfiguration = require('./sorteio.routes');

const formControlService = require('./sorteio.form.service');

const detailController = require('./sorteio-detail/detail.controller');
const detailApiService = require('./sorteio-detail/detail.service');

const newController = require('./sorteio-new/new.controller');
const newApiService = require('./sorteio-new/api.service');

const uploadController = require('./sorteio-annex/upload.controller');
const uploadApiService = require('./sorteio-annex/api.service');

const sorteioBankController = require('./sorteio-bank/bank.controller');
const sorteioBankService = require('./sorteio-bank/api.service');

const formValidationService = require('./sorteio.form-validation.service');

const componentModule = angular.module('cap.secured.case.sorteio', [
		'ui.router'
	])
	.controller('sorteioController', componentController)
	.service('sorteioApi', componentApi)

	.service('sorteioFormValidationService', formValidationService)

	.service('sorteioFormControlService', formControlService)

	.controller('sorteioDetailController', detailController)
	.service('sorteioDetailApi', detailApiService)

	.controller('sorteioNewController', newController)
	.service('sorteioNewApi', newApiService)

	.controller('sorteioBankController', sorteioBankController)
	.service('sorteioBankService', sorteioBankService)

	.controller('sorteioAnnexController', uploadController)
	.service('sorteioAnnexApi', uploadApiService)

	.config(routesConfiguration);

module.exports = componentModule;
