'use strict';

class rentGuaranteeListController {

	// @ngInject
	constructor($scope, rentGuaranteeListApiService, paginationHelper, ordenationHelper, breadcrumbHelper, feedbackMessages) {

		this.rentGuaranteeListApiService = rentGuaranteeListApiService;
		this.pages = paginationHelper;
		this.order = ordenationHelper;
		this.order.setPristine();
		this.recordsList = [];
		this.$scope = $scope;
		this.$scope.breadcrumb = breadcrumbHelper;
		this.messages = feedbackMessages;
		this.configureBreadCrumb();
		this.getListViewOptions(true);
	}

	configureBreadCrumb() {
		this.$scope.breadcrumb.reset();
		this.$scope.breadcrumb.addMenuItem('Home', '/caponline/secured#!/home', true, false);
		this.$scope.breadcrumb.addMenuItem('Garantia de Aluguel', '/caponline/secured#!/garantia-de-aluguel/lista', true, true);
	}

	getListViewOptions(getFirstList) {

		// this.showLoading = true;

		this.rentGuaranteeListApiService.getListViewOptions().then((response) => {
			console.log(response);
			// this.showLoading = false;

			if (!response.isSuccessful) {

				// vixi
			} else {

				this.listViewOptions = response.options;

				this.listViewOptionSelected = this.listViewOptions[0];

				getFirstList && this.getList(true);
			}
		});
	}

	changeOrder(field) {
		this.order.changeOrder(field);
		this.pages.setPagesPristine();
		this.getList(true);
	}

	changeViewOption() {
		this.order.setPristine();
		this.pages.setPagesPristine();
		this.getList(true);
	}

	getList(isCount) {

		this.showLoading = true;

		const queryModel = {

			queryType: this.listViewOptionSelected.queryType,
			queryLimit: this.pages.limitOfRecords,
			queryOffset: this.queryOffset,
			queryOrderField: this.order.data.field,
			queryOrderDirection: this.order.data.direction,
			isQueryCount: true
		};

		this.rentGuaranteeListApiService.getList(queryModel).then((response) => {

			this.showLoading = false;
			console.log(response);
			if (!response.isSuccessful) {
				if (response.errorMessages) {
					//this.$window.scrollTo(0, 0);
					const errorMessage = angular.toJson(response.errorMessages);
					this.messages.showErrorMessage(this.$scope, errorMessage);
					setTimeout(this.messages.cleanMessages(this.$scope), 3000);
				}
				this.recordsList = [];
				this.pages.totalOfRecords = 0;
			} else {

				this.recordsList = response.garantiasAluguel;

				this.pages.totalOfRecords = response.queryCount;

				if (isCount) {

					this.pages.setPaginationParams();
				}
			}
		});
	}

	get queryOffset() {

		return ((this.pages.actualPage - 1) * this.pages.limitOfRecords);
	}

	doPrevious() {

		this.pages.doPrevious(this.getList, this);
	}

	doNext() {

		this.pages.doNext(this.getList, this);
	}

}

module.exports = rentGuaranteeListController;
