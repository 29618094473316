'use strict'

class sorteioApi {

	/* @ngInject */
	constructor($q, visualForceApi, sorteioFormControlService) {

		this.$q = $q;
		this.visualForceApi = visualForceApi;
		this.formField = sorteioFormControlService;

		this.setPristine();

		File.prototype.toJSON = function () {
			return {
				// "index": this.index,
				// "$$hashKey": this.$$hashKey,
				"name": this.name,
				"result": this.result,
				"salesForceId": this.salesForceId
			};
		};
	}

	setPristine() {
		return true;
	}

	pristineAnnexObj() {
		return {
			files: [],
			hasError: false,
			loading: false,
			show: false,
			uploaded: false,
			uploadHasFailure: false
		};
	}

	//@todo gambiarra. refatorar pra deixar dinâmico em vez de marretar os anexos
	//Esse método serve pra remover os anexos que deram erro na hora de salvar o editCaseData
	removeFilesWithError(editCaseDataFiles) {
		const files = this.getAnnexes(editCaseDataFiles);

		for (let i = files.length - 1; i >= 0; i--) {
			for (let j = files[i].length - 1; j >= 0; j--) {
				if (!files[i][j].result) {
					files[i].splice(j, 1);
				}
			}
		}
	}

	getAnnexes(editCaseDataFiles) {
		const annexes = [
			editCaseDataFiles.documents.files
		];
		return annexes;
	}

	saveCaseSorteio() {
		let editCaseData = angular.copy(this.formField.extractEditCaseData());
		editCaseData.annex.form.files = editCaseData.annex.form.filesParsed;
		editCaseData.annex.auth.files = editCaseData.annex.auth.filesParsed;
		editCaseData.annex.subscritor.identityRenter.files = editCaseData.annex.subscritor.identityRenter.filesParsed;
		editCaseData.annex.subscritor.cpf.files = editCaseData.annex.subscritor.cpf.filesParsed;
		editCaseData.annex.subscritor.adressCheck.files = editCaseData.annex.subscritor.adressCheck.filesParsed;
		editCaseData.annex.subscritor.otherPf.files = editCaseData.annex.subscritor.otherPf.filesParsed;
		editCaseData.annex.subscritor.cnpj.files = editCaseData.annex.subscritor.cnpj.filesParsed;
		editCaseData.annex.subscritor.constitutionIdentity.files = editCaseData.annex.subscritor.constitutionIdentity.filesParsed;
		editCaseData.annex.subscritor.otherPj.files = editCaseData.annex.subscritor.otherPj.filesParsed;

		this.cleanupGambiFiles(editCaseData);

		editCaseData = JSON.stringify(editCaseData);
		console.log('EditCaseData: ====> ', editCaseData);
		//this.cleanUpFileArray(editCaseData.file);

		const cap_access_token = localStorage.getItem("cap_access_token");

		const payload = this.createSaveRequest(editCaseData);
		payload.accessToken = cap_access_token;

		return this.visualForceApi.invoke(
			'CapCommunityCaseControllerExt.saveCaseSorteio', payload);
	}

	sendCaseSorteio() {
		let editCaseData = angular.copy(this.formField.extractEditCaseData());
		console.log('Extract EditCaseData ===>', editCaseData);
		editCaseData.annex.form.files = editCaseData.annex.form.filesParsed;
		editCaseData.annex.auth.files = editCaseData.annex.auth.filesParsed;
		editCaseData.annex.subscritor.identityRenter.files = editCaseData.annex.subscritor.identityRenter.filesParsed;
		editCaseData.annex.subscritor.cpf.files = editCaseData.annex.subscritor.cpf.filesParsed;
		editCaseData.annex.subscritor.adressCheck.files = editCaseData.annex.subscritor.adressCheck.filesParsed;
		editCaseData.annex.subscritor.otherPf.files = editCaseData.annex.subscritor.otherPf.filesParsed;
		editCaseData.annex.subscritor.cnpj.files = editCaseData.annex.subscritor.cnpj.filesParsed;
		editCaseData.annex.subscritor.constitutionIdentity.files = editCaseData.annex.subscritor.constitutionIdentity.filesParsed;
		editCaseData.annex.subscritor.otherPj.files = editCaseData.annex.subscritor.otherPj.filesParsed;

		this.cleanupGambiFiles(editCaseData);

		editCaseData = JSON.stringify(editCaseData);
		//const editCaseData = JSON.parse(JSON.stringify(this.formField.extractEditCaseData()));
		console.log('Enviar CASE editcasedata==>: ', editCaseData);

		const cap_access_token = localStorage.getItem("cap_access_token");

		const payload = this.createSaveRequest(editCaseData);
		payload.accessToken = cap_access_token;

		return this.visualForceApi.invoke(
			'CapCommunityCaseControllerExt.sendCaseSorteio', payload);
	}

	cancelCase(id) {

		const cap_access_token = localStorage.getItem("cap_access_token");

		return this.visualForceApi.invoke(
			'CapCommunityCaseControllerExt.cancelResgate', id, 'pagamento de sorteio', cap_access_token);
	}

	getFormattedDate(date) {
		const month = `0${date.getMonth() + 1}`.slice(0, 2);
		const day = `0${date.getDate() + 1}`.slice(0, 2);
		const year = date.getFullYear();
		return `${month}/${day}/${year}`;
	}

	createSaveRequest(pEditCaseData) {
		const request = {
			solicitacao: {
				id: this.formField.id,
				cpfCnpj: this.formField.information.fields.mainAccount.value.cpf,
				accountId: (this.formField.information.fields.mainAccount.value.Id != null) ? this.formField.information.fields.mainAccount.value.Id : null,
				propostaId: (this.formField.information.fields.proposta.value.objectId != null) ? this.formField.information.fields.proposta.value.objectId : null,
				status: this.formField.information.status,
				isPersonAccount: this.formField.information.fields.mainAccount.value.isPersonAccount,
				titulos: [],
				combinacaoSorteada: this.formField.information.fields.garantias.value[0].combinacaoSorteada,
				//editCaseData: this.formField.information.fields,
				enderecoSorteado: {
					cep: (this.formField.information.fields.cep.value != null) ? this.formField.information.fields.cep.value : null,
					logradouro: (this.formField.information.fields.logradouro.value != null) ? this.formField.information.fields.logradouro.value : null,
					numero: (this.formField.information.fields.numero.value != null) ? this.formField.information.fields.numero.value : null,
					complemento: (this.formField.information.fields.complemento.value != null) ? this.formField.information.fields.complemento.value : null,
					bairro: (this.formField.information.fields.bairro.value != null) ? this.formField.information.fields.bairro.value : null,
					cidade: (this.formField.information.fields.cidade.value != null) ? this.formField.information.fields.cidade.value : null,
					uf: (this.formField.information.fields.uf.value != null) ? this.formField.information.fields.uf.value : null
				},
				dadosBancarios: {
					//clienteTitular: (this.formField.bank.fields.clienteTitular.value != null) ? this.formField.bank.fields.clienteTitular.value : null,
					tipoConta: (this.formField.bank.fields.tipoConta.value != null) ? this.formField.bank.fields.tipoConta.value : null,
					banco: (this.formField.bank.fields.banco.value != null) ? this.formField.bank.fields.banco.value : null,
					agencia: this.getBankInfoDV('agencia'),
					conta: this.getBankInfoDV('conta'),
					titularConta: (this.formField.bank.fields.titular.value != null) ? this.formField.bank.fields.titular.value : null,
					cpfCnpj: (this.formField.bank.fields.cpfCnpj.value != null) ? this.formField.bank.fields.cpfCnpj.value : null,
					profissao: (this.formField.bank.fields.profissao.value != null) ? this.formField.bank.fields.profissao.value.Name : null,
					rendaPatrimonio: (this.formField.bank.fields.renda.value != null) ? this.formField.bank.fields.renda.value : null,
					telefone: (this.formField.bank.fields.telefone.value != null) ? this.formField.bank.fields.telefone.value : null,
					email: (this.formField.bank.fields.email.value != null) ? this.formField.bank.fields.email.value : null
				},
				editCaseData: pEditCaseData

			}
		}

		if (this.formField.information.fields.garantias.value !== null) {

			for (let i = 0; i < this.formField.information.fields.garantias.value.length; i++) {

				request.solicitacao.titulos.push({
					id: this.formField.information.fields.garantias.value[i].objectId,
					value: this.formField.information.fields.garantias.value[i].value,
					antecipatedValue: this.formField.information.fields.garantias.value[i].antecipatedValue

				});
			}
		}

		console.log('REQUEST: ', request);
		return request;
	}

	getBankInfoDV(type) {
		let retVal = "";
		if (type === "agencia") {
			if (this.formField.bank.fields.agencia.value !== "") {
				retVal = this.formField.bank.fields.agencia.value;
				if (this.formField.bank.fields.agenciaDV.value !== "") {
					retVal += "-";
					retVal += this.formField.bank.fields.agenciaDV.value;
				}
			}
		} else if (type === "conta") {
			if (this.formField.bank.fields.conta.value !== "") {
				retVal = this.formField.bank.fields.conta.value;
				if (this.formField.bank.fields.contaDV.value !== "") {
					retVal += "-";
					retVal += this.formField.bank.fields.contaDV.value;
				}
			}
		}
		return retVal;
	}

	cleanUpFileArray(object) {
		const properties = Object.keys(object);

		for (let i = properties.length - 1; i >= 0; i--) {
			const propertyName = properties[i];

			if (propertyName === 'files') {
				object[propertyName] = [];
			} else if (angular.isObject(object[propertyName])) {
				this.cleanUpFileArray(object[propertyName]);
			}
		}
	}

	//@todo gambiarra
	cleanupGambiFiles(editCaseData) {
		editCaseData.annex.form.gambiFiles = null;
		editCaseData.annex.auth.gambiFiles = null;
		editCaseData.annex.subscritor.identityRenter.gambiFiles = null;
		editCaseData.annex.subscritor.cpf.gambiFiles = null;
		editCaseData.annex.subscritor.adressCheck.gambiFiles = null;
		editCaseData.annex.subscritor.otherPf.gambiFiles = null;
		editCaseData.annex.subscritor.cnpj.gambiFiles = null;
		editCaseData.annex.subscritor.constitutionIdentity.gambiFiles = null;
		editCaseData.annex.subscritor.otherPj.gambiFiles = null;
	}
}

module.exports = sorteioApi
