'use strict';

class remuneracoesPagasDetailApi {

	/* @ngInject */
	constructor(visualForceApi) {

		this.visualForceApi = visualForceApi;
	}

	getDetails(remuneracaoId) {
		return this.visualForceApi.invoke(
			'CapCommunityRemuneracaoControllerExt.getRemuneracoesPagasDetail', remuneracaoId);
	}
}

module.exports = remuneracoesPagasDetailApi;
