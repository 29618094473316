'use strict';

/* @ngInject */
function appConfig($compileProvider) {

	const hrefWhiteList = /^\s*(https?|ftp|mailto|tel|file):|data:application\//;

	$compileProvider.aHrefSanitizationWhitelist(hrefWhiteList);
};

module.exports = appConfig;
