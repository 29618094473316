'use strict'

class sorteioNewController {
	// @ngInject
	constructor($scope, $window, $state, $timeout, feedbackMessages, sorteioFormControlService, sorteioNewApi, autoCompleteService, sobjectQueryService, cepHelper) {
		console.log('entrou no sorteioNewController constructor()');
		this.$scope = $scope;
		this.$window = $window;
		this.$state = $state;
		this.$timeout = $timeout;
		this.messages = feedbackMessages;
		this.api = sorteioNewApi;
		this.formField = sorteioFormControlService;
		this.autoComplete = autoCompleteService;
		this.cepHelper = cepHelper;

		this.propostas = this.api.propostas;
		this.sobjectQueryService = sobjectQueryService;
		this.formField.information.status = 'Em Andamento';

		this.isEdit();
		this.formField.formStep = 'case.sorteio.new';
		this.$scope.breadcrumb = this.$scope.$parent.breadcrumb;
		this.configureBreadCrumb();

		console.log('Form Field:', this.formField);
	}

	isEdit() {
		console.log('entrou no isEdit()');
		console.log('this.formField.edit=', this.formField.edit);

		if (this.formField.edit) {
			this.formField.information.fields.mainAccount.hasError = this.formField.requireProperty(this.formField.information.fields, 'mainAccount');

			this.formField.buttonControl.save.show = true;
			this.formField.buttonControl.save.disabled = false;
			this.formField.buttonControl.cancel.show = true;
			this.formField.buttonControl.cancel.disabled = false;
			this.formField.buttonControl.next.show = true;
			this.formField.buttonControl.next.disabled = false;

			this.propostas = [];
			this.propostas.push(this.formField.information.fields.proposta.value)
		} else {
			this.propostas = (this.formField.information.fields.proposta.value !== null) ? this.api.getPropostas() : [];
		}
	}

	configureBreadCrumb() {
		console.log('entrou no configureBreadCrumb()');
		this.$scope.breadcrumb.reset();
		this.$scope.breadcrumb.addMenuItem('Home', '/caponline/secured#!/home', true, false);
		this.$scope.breadcrumb.addMenuItem('Solicitação', '/caponline/secured#!/solicitacoes/lista', true, false);
		this.$scope.breadcrumb.addMenuItem(' Novo pagamento de sorteio', '/caponline/secured#!/solicitacoes/sorteio/nova', false, true);
	}

	searchAccount(searchString) {
		console.log('entrou no searchString()');
		const sorteioNew = this.$parent.sorteioNew;
		return sorteioNew.api.searchAccount(searchString);
	}

	searchAccountSelect(item) {
		console.log('entrou no searchAccountSelect()');
		const sorteioNew = this.$parent.sorteioNew;
		if (sorteioNew.formField.information.fields.mainAccount.value === null) {
			if (!item) { return; }

			const hasAction = !!item.originalObject.action;

			if (hasAction) {

				const action = item.originalObject.action;

				sorteioNew[action](item);

				return;
			}

			sorteioNew.formField.information.fields.mainAccount.value = item.originalObject;
			sorteioNew.formField.information.fields.mainAccount.hasError = !sorteioNew.hasAccount();
			sorteioNew.formField.buttonControl.save.show = true;
			sorteioNew.formField.buttonControl.save.disabled = false;
			sorteioNew.formField.buttonControl.cancel.show = true;
			sorteioNew.formField.buttonControl.cancel.disabled = false;
			sorteioNew.formField.buttonControl.next.show = true;
			sorteioNew.formField.buttonControl.next.disabled = false;
			sorteioNew.formField.information.fields.mainAccount.loading = true;
			sorteioNew.getPropostasByAccountId(sorteioNew.formField.information.fields.mainAccount.value.Id);
		}
	}

	getPropostasByAccountId(accountId) {
		console.log('entrou no getPropostasByAccountId():', accountId);
		this.propostas = [];
		this.api.setPropostas(this.propostas);
		this.sobjectQueryService.getPropostasByAccountIdSorteio(accountId).then((response) => {
			this.formField.information.fields.mainAccount.loading = false;
			if (response.isSuccessful) {
				if (response.result.length > 0) {
					this.propostas = response.result;
					this.api.setPropostas(response.result);
				} else {
					this.messages.showErrorMessage(this.$scope, 'Cliente não possui nenhum título sorteado');
				}
			} else {
				console.log('error ', response);
			}
		});
	}

	searchAccountChanged() {
		console.log('entrou no searchAccountChanged()');

		const sorteioNew = this.$parent.sorteioNew; //CAP-2706
		console.log('entrou no searchAccountChanged');
		sorteioNew.messages.cleanMessages(sorteioNew.$scope);
		sorteioNew.autoComplete.resetSelected(sorteioNew.formField.information.fields.mainAccount, 'value');
		sorteioNew.propostas = [];
		sorteioNew.api.setPropostas(sorteioNew.propostas);
		if (sorteioNew.formField.information.fields.mainAccount.value == '' || sorteioNew.formField.information.fields.mainAccount.value == null) {
			sorteioNew.formField.buttonControl.save.disabled = true;
		}
		console.log('reset');
		//resetar todos os dados
		sorteioNew.resetForm();

	}

	hasAccount() {
		console.log('entrou no hasAccount()');
		return !!this.formField.information.fields.mainAccount.value;
	}

	getGarantiasByPropostaId(propostaId) {
		console.log('entrou no getGarantiasByPropostaId():', propostaId);

		this.formField.information.fields.proposta.loading = true;

		const cpfcnpj = this.formField.information.fields.mainAccount.value.cpf;

		this.sobjectQueryService.getGarantiasByPropostaId(propostaId, 'pagamento de sorteio', cpfcnpj).then((response) => {
			this.formField.information.fields.proposta.loading = false;
			if (response.isSuccessful) {
				if (response.result.length > 0) {
					this.messages.cleanMessages(this.$scope);
					this.formField.information.fields.garantias.value = [];
					for (let i = 0; i < response.result.length; i++) {

						this.formField.information.fields.garantias.value.push({
							objectId: response.result[i].objectId,
							objectName: response.result[i].objectName,
							identidade: response.result[i].vigencia,
							combinacao: response.result[i].finalVigencia,
							telefone: response.result[i].telefone,
							selected: false,
							disabled: false,
							value: response.result[i].value,
							antecipatedValue: response.result[i].antecipatedValue,
							combinacaoSorteada: response.result[i].combinacaoSorteada
						});
					}
					console.log('saiu no getGarantiasByPropostaId', this.formField.information.fields.garantias.value);
				} else {
					this.messages.setWarningMessage(this.$scope, 'Essa proposta não possui títulos contemplados ou já existe solicitação de pagamento de sorteio para o(s) título(s) contemplado(s)');
				}
			} else {
				this.messages.showErrorMessage(this.$scope, response.errorMessages[0]);
			}
		});
	}

	changePropostaField() {
		console.log('entrou no changePropostaField()');
		if (this.formField.information.fields.mainAccount.value != null) {
			this.formField.resetInformationAdressFields();
			this.messages.cleanMessages();
			if (this.formField.information.fields.proposta.value !== null)
				this.getGarantiasByPropostaId(this.formField.information.fields.proposta.value.objectId);
		}
	}

	resetForm() {
		console.log('entrou no resetForm()');
		this.formField.information.fields.garantias.value = null;
		this.formField.information.fields.garantias.hasError = false;
		//continuar daqui . Resetar proposta lá em cima
		this.formField.resetInformationAdressFields();
	}

	requireProperty(field) {
		this.formField.requireProperty(this.formField.information.fields, field)
	}

	getAddressFromCEP() {
		console.log('entrou no getAddressFromCEP()');
		//const me = this;
		const cep = this.formField.information.fields.cep;

		if ((cep.value.length == 9) && (cep.value.indexOf("-") == 5)) {

			this.$scope.$emit('changeMainShowLoading', true);
			this.cepHelper.getAddressFromCEP(cep.value, (response) => {

				this.messages.cleanMessages(this.$scope);

				this.formField.information.fields.logradouro.value = response.logradouro;
				this.formField.information.fields.bairro.value = response.bairro;
				this.formField.information.fields.cidade.value = response.cidade;
				this.formField.information.fields.uf.value = response.uf;

				if (!response.isSuccessful) {

					if (this.cepHelper.remoteCallErrorLimitWasExceded()) {
						cep.hasError = false;
						cep.isValid = true;
						//cep.value = null;
						this.messages.showErrorMessage(this.$scope, this.cepHelper.remoteCallErrorMessage);
					} else {
						cep.isValid = false;
						this.messages.showErrorMessage(this.$scope, response.errorMessages[0]);
						this.$window.scrollTo(0, 0);
						//cep.message = response.errorMessages[0];
					}
					this.cepHelper.setPristine();
				} else {
					this.requireProperty('logradouro');
					this.requireProperty('bairro');
					this.requireProperty('cidade');
					this.requireProperty('uf');
					//this.validated.clientCepError = false;
					cep.isValid = true;
					cep.message = null;
					this.messages.showErrorMessage(this.$scope, '');
				}

				this.$scope.$emit('changeMainShowLoading', false);
			});
		} else {
			this.cepHelper.setPristine();
			this.formField.information.fields.logradouro.value = null;
			this.formField.information.fields.bairro.value = null;
			this.formField.information.fields.cidade.value = null;
			this.formField.information.fields.uf.value = null;
		}
	}
}

module.exports = sorteioNewController;
