'use strict';

const caseController = require('./case-controller');
const caseCacheService = require('./case-cache.service');

const routesConfiguration = require('./case-routes');

const caseListController = require('./case-list/case-list.controller');
const caseListApiService = require('./case-list/case-list-api.service');

const liberacaoDaCaucaoModule = require('./case-liberacao-da-caucao/component.module');
const documentacaoPropostaModule = require('./case-documentacao-proposta/case-documentacao-proposta.module');
const bloqReaplicacaoModule = require('./bloq-reaplicacao/bloq-reaplicacao.module');
const reaplicacaoTituloModule = require('./reaplicacao-titulo/reaplicacao-titulo.module');
const revisaoComissaoModule = require('./revisao-comissao/revisao-comissao.module');
const alteracaoDadosModule = require('./alteracao-dados/alteracao-dados.module');
const sorteio = require('./sorteio/sorteio.module');

const resgateController = require('./resgate/resgate.controller');
const resgateService = require('./resgate/resgate.service');

const resgateNewController = require('./resgate/resgate-new/resgate-new.controller');
const resgateNewService = require('./resgate/resgate-new/resgate-new.service');

const resgateTypeController = require('./resgate/resgate-type/resgate-type.controller');
const resgateTypeService = require('./resgate/resgate-type/resgate-type.service');

const resgateDataController = require('./resgate/resgate-data/resgate-data.controller');
const resgateDataService = require('./resgate/resgate-data/resgate-data.service');

const resgateBankController = require('./resgate/resgate-bank/resgate-bank.controller');
const resgateBankService = require('./resgate/resgate-bank/resgate-bank.service');

const resgateFileController = require('./resgate/resgate-file/resgate-file.controller');
const resgateFileService = require('./resgate/resgate-file/resgate-file.service');

const resgateDetailController = require('./resgate/resgate-detail/resgate-detail.controller');
const resgateDetailService = require('./resgate/resgate-detail/resgate-detail.service');

const resgateFormService = require('./resgate/resgate-form.service');

const resgateGetFormService = require('./resgate/get-form.service');

const caseModule = angular.module('cap.secured.case', [
		liberacaoDaCaucaoModule.name,
		documentacaoPropostaModule.name,
		bloqReaplicacaoModule.name,
		reaplicacaoTituloModule.name,
		revisaoComissaoModule.name,
		alteracaoDadosModule.name,
		sorteio.name,
		'ui.router'
	])
	.controller('caseController', caseController)
	.service('caseCache', caseCacheService)

	.controller('caseListController', caseListController)
	.service('caseListApi', caseListApiService)

	.controller('resgateController', resgateController)
	.service('resgateService', resgateService)

	.controller('resgateNewController', resgateNewController)
	.service('resgateNewService', resgateNewService)

	.controller('resgateTypeController', resgateTypeController)
	.service('resgateTypeService', resgateTypeService)

	.controller('resgateDataController', resgateDataController)
	.service('resgateDataService', resgateDataService)

	.controller('resgateBankController', resgateBankController)
	.service('resgateBankService', resgateBankService)

	.controller('resgateFileController', resgateFileController)
	.service('resgateFileService', resgateFileService)

	.controller('resgateDetailController', resgateDetailController)
	.service('resgateDetailService', resgateDetailService)

	.service('resgateFormService', resgateFormService)
	.service('resgateGetForm', resgateGetFormService)

	.config(routesConfiguration);

module.exports = caseModule;
