'use strict';

class liberacaoDaCaucaoFormControlService {

	// @ngInject
	constructor() {
		// console.log('liberacaoDaCaucaoFormControlService');

		this.setPristine();
	}

	setPristine() {
		//console.log('setPristine;')

		this.status = null;
		this.formStep = null;
		this.id = null;
		this.edit = false;
		this.lastFormAction = '';
		this.popupStategoControl = {
			origem: '',
			destino: ''
		};

		this.setInformationPristine();
		this.setAnnexPristine();
	}

	setInformationPristine() {
		//console.log('setInformationPristine;')

		this.information = {

			active: false,
			status: 'Não Iniciado',
			mainAccount: {
				value: null,
				hasError: false,
				loading: false,
				show: false
			},
			proposta: {
				value: null,
				hasError: false,
				loading: false,
				show: false
			},
			legalRepresentative: {
				value: null,
				hasError: false,
				loading: false,
				show: false
			},
			garantias: {
				value: null,
				hasError: false,
				loading: false,
				show: false
			},
			observacoes: {
				value: null,
				hasError: false,
				loading: false,
				show: false
			}
		};
	}

	setFormField(json) {
		let self = this;
		self = json;
	}

	getFormField() {
		return this;
	}

	resetPopupStateGoControl() {
		this.popupStategoControl = {
			origem: '',
			destino: ''
		};
	}

	resetInformation() {

		this.setInformationPristine();
	}

	setAnnexPristine() {

		this.annex = {

			active: false,
			status: "Não Iniciado",
			form: this.pristineAnnexObj(),
			legalRepresentative: {
				show: false,
				document: this.pristineAnnexObj(),
				identityRenter: this.pristineAnnexObj(),
				other: this.pristineAnnexObj()
			},
			subscritor: {
				show: true,
				identity: this.pristineAnnexObj(),
				identityAdmin: this.pristineAnnexObj(),
				cnpj: this.pristineAnnexObj(),
				constituitionDocumentation: this.pristineAnnexObj(),
				other: this.pristineAnnexObj()
			}
		}

		this.annex.form.type = 'form';
		this.annex.legalRepresentative.document.type = 'legalRepresentative-document';
		this.annex.legalRepresentative.identityRenter.type = 'legalRepresentative-identityRenter';
		this.annex.legalRepresentative.other.type = 'legal-representative-other';

		this.annex.subscritor.identity.type = 'subscritor-identity';
		this.annex.subscritor.identityAdmin.type = 'subscritor-identity-admin';
		this.annex.subscritor.cnpj.type = 'subscritor-cnpj';
		this.annex.subscritor.constituitionDocumentation.type = 'subscritor-constituition';
		this.annex.subscritor.other.type = 'subscritor-other';
	}

	resetAnnex() {

		this.setAnnexPristine();
	}

	pristineAnnexObj() {

		return {
			files: [],
			hasError: false,
			loading: false,
			show: false,
			uploaded: false,
			uploadHasFailure: false
		};
	}

	resetField(type, fieldName) {

		const field = this[type][fieldName];

		switch (true) {

		case angular.isArray(field):

			this[type][fieldName] = [];
			break;

		case angular.isObject(field):

			this[type][fieldName] = {
				value: null,
				hasError: false,
				loading: false,
				show: false
			};
			break;

		case typeof field === 'boolean':

			this[type][fieldName] = false;
			break;

		default:

			this[type][fieldName] = null;
		}
	}

	reset() {
		// console.log('reset');

		this.setPristine();
	}

	extractFormControl() {
		return {
			status: this.status,
			formStep: this.formStep,
			id: this.id,
			information: this.information,
			annex: this.annex
		}
	}

	feedNewData(newData) {

		this.setPristine();

		this.mergeData(newData);
	}

	mergeData(data) {

		angular.extend(this.field, data);
	}

	resetErrorHandlerObject(errorHandlerObject) {

		for (const property in errorHandlerObject) {

			if (!errorHandlerObject.hasOwnProperty(property)) continue;

			// errorHandlerObject[property] = false;
			errorHandlerObject[property].hasError = false;
		}
	}

	requireProperty(propertyName, errorHandlerObject) {
		// console.log('requireProperty', propertyName, errorHandlerObject);

		const hasProperty = this.hasProperty(propertyName);
		// console.log('hasProperty', hasProperty);

		// errorHandlerObject[`${propertyName}Error`] = !hasProperty;
		errorHandlerObject[propertyName].hasError = !hasProperty;
		// console.log('propertyError', errorHandlerObject[`${propertyName}Error`]);
		return hasProperty;
	}

	hasProperty(propertyName) {
		// console.log('hasProperty', propertyName);

		// return !!this.field[propertyName];
		return !!this.field[propertyName].value;
	}

	setPopupStategoControl(paOrigem, paDestino) {
		this.popupStategoControl = {
			origem: paOrigem,
			destino: paDestino
		};
	}
}

module.exports = liberacaoDaCaucaoFormControlService;
