'use strict';

class profilePermissions {

	/* @ngInject */
	constructor(sfConfig) {

		this.sfConfig = sfConfig;
	}

	get() {

		const permissions = {};
		const properties = Object.keys(this.sfConfig);

		for (let i = properties.length - 1; i >= 0; i--) {

			const propertyName = properties[i];

			if (angular.isObject(this.sfConfig[propertyName])) {

				const permission = angular.copy(this.sfConfig[propertyName]);

				permissions[propertyName] = this.parsePermission(permission);
			}
		}

		return permissions;
	}

	parsePermission(permission) {

		const properties = Object.keys(permission);

		for (let i = properties.length - 1; i >= 0; i--) {

			const propertyName = properties[i];

			if (angular.isObject(permission[propertyName])) {

				const innerPermission = angular.copy(permission[propertyName]);

				permission[propertyName] = this.parsePermission(innerPermission);
			} else {

				permission[propertyName] = permission[propertyName] === 'true';
			}
		}

		return permission;
	}
}

module.exports = profilePermissions;
