'use strict';

class termoDeAdesaoController {

	// @ngInject
	constructor($scope, $window, $stateParams, $state, termoDeAdesaoApi, feedbackMessages, checkTerm) {

		this.$stateParams = $stateParams;
		this.$state = $state;
		this.$scope = $scope;
		this.$window = $window;

		this.messages = feedbackMessages;
		this.api = termoDeAdesaoApi;
		this.checkTerm = checkTerm;

		this.userOriginalRoute = this.$stateParams.originalRoute;
		this.userOriginalRoute.state = this.userOriginalRoute.state || 'home';
		this.userOriginalRoute.params = this.userOriginalRoute.params || {};

		$('.menu-bar').hide();

		this.userAccept = null;

		this.acceptTerm();
	}

	hasAcceptedTerm() {

		return !!this.userAccept;
	}

	acceptTerm(type) {
		if (!this.hasAcceptedTerm()) {

			return;
		} else {
			$('.menu-bar').show();
		}

		this.$scope.$emit('changeMainShowLoading', true);

		this.api.acceptTerm(type).then((response) => {
			// console.log('acceptTerm response', response);

			this.$scope.$emit('changeMainShowLoading', false);

			if (!response.isSuccessful) {
				this.$window.scrollTo(0, 0);

				const errorMessage = angular.toJson(response.errorMessages);

				this.messages.showErrorMessage(this.$scope, errorMessage);
			} else {
				// console.log(this.userOriginalRoute);

				this.checkTerm.reset('adhesion');

				this.$state.go(this.userOriginalRoute.state, this.userOriginalRoute.params);
			}
		});
	}

	// remove() {

	// 	this.api.remove().then((response) => {

	// 	// console.log('remove', response);
	// 	});
	// }
}

module.exports = termoDeAdesaoController;
