"use strict";

class propostaListController {
	// @ngInject
	constructor(
		$scope,
		$state,
		propostaListService,
		formControlService,
		propostaSetupAccountService,
		resgateService,
		ordenationHelper,
		propostaApi,
		feedbackMessages
	) {
		this.propostaListService = propostaListService;
		this.resgateService = resgateService;

		this.listViews = [{
				label: "Exibidas recentemente",
				api: "exibidas-recentemente",
				selected: true
			},
			{ label: "Minhas propostas", api: "minhas-propostas", selected: false },
			{
				label: "Todas as propostas",
				api: "todas-as-propostas",
				selected: false
			},
			{
				label: "Propostas em elaboração",
				api: "propostas-em-andamento",
				selected: false
			},
			// { label: 'Propostas Pendentes', api: 'propostas-pendentes', selected: false },
			{
				label: "Propostas enviadas",
				api: "propostas-enviadas",
				selected: false
			},
			// { label: 'Propostas Fechadas', api: 'propostas-fechadas', selected: false },
			{
				label: "Propostas canceladas",
				api: "propostas-canceladas",
				selected: false
			},
			{
				label: "Propostas transmitidas",
				api: "propostas-transmitidas",
				selected: false
			},
			{
				label: "Propostas com pendência",
				api: "propostas-com-pendencia",
				selected: false
			},
			// { label: 'Propostas com pendência de débito em conta corrente', api: 'propostas-com-pendencia-dcc', selected: false },
			{
				label: "Propostas com títulos emitidos",
				api: "propostas-com-titulos-emitidos",
				selected: false
			},
			{
				label: "Propostas pendentes de documentação",
				api: "propostas-pendentes-de-documentacao",
				selected: false
			},
			{
				label: "Propostas sem confirmação pagamento cartão",
				api: "propostas-sem-confirmacao-pgto-cartao",
				selected: false
			}
		];

		this.listViewsDG = [{
				label: "Exibidas recentemente",
				api: "exibidas-recentemente",
				selected: true
			},
			{ label: "Minhas propostas", api: "minhas-propostas", selected: false },
			{
				label: "Todas as propostas",
				api: "todas-as-propostas",
				selected: false
			},
			{
				label: "Propostas em elaboração",
				api: "propostas-em-andamento",
				selected: false
			},
			// { label: 'Propostas Pendentes', api: 'propostas-pendentes', selected: false },
			{
				label: "Propostas enviadas",
				api: "propostas-enviadas",
				selected: false
			},
			// { label: 'Propostas Fechadas', api: 'propostas-fechadas', selected: false },
			{
				label: "Propostas canceladas",
				api: "propostas-canceladas",
				selected: false
			},
			{
				label: "Propostas transmitidas",
				api: "propostas-transmitidas",
				selected: false
			},
			{
				label: "Propostas com pendência",
				api: "propostas-com-pendencia",
				selected: false
			},
			// { label: 'Propostas com pendência de débito em conta corrente', api: 'propostas-com-pendencia-dcc', selected: false },
			{
				label: "Propostas com títulos emitidos",
				api: "propostas-com-titulos-emitidos",
				selected: false
			},
			{
				label: "Propostas pendentes de documentação",
				api: "propostas-pendentes-de-documentacao",
				selected: false
			},
			{
				label: "Propostas sem confirmação pagamento cartão",
				api: "propostas-sem-confirmacao-pgto-cartao",
				selected: false
			},

			{
				label: "Propostas com assinatura no status: Criado",
				api: "propostas-assinatura-criado",
				selected: false
			},
			{
				label: "Propostas com assinatura no status: Enviado",
				api: "propostas-assinatura-enviado",
				selected: false
			},
			{
				label: "Propostas com assinatura no status: Invalidado",
				api: "propostas-assinatura-invalidado",
				selected: false
			},
			{
				label: "Propostas com assinatura no status: Concluído",
				api: "propostas-assinatura-concluido",
				selected: false
			},
			{
				label: "Propostas com assinatura no status: Expirado",
				api: "propostas-assinatura-expirado",
				selected: false
			},
			{
				label: "Propostas com assinatura no status: Cancelado",
				api: "propostas-assinatura-cancelado",
				selected: false
			}
		];

		this.listViewSelected = this.listViews[0];
		this.listViewDGSelected = this.listViewsDG[0];
		this.showLoading = true;
		this.propostas = {};
		this.setPristine();
		this.$scope = $scope;
		this.$state = $state;
		this.order = ordenationHelper;
		this.order.setPristine();
		this.getPropostaList(true);
		this.formControlService = formControlService;
		this.mensagemCartao = "";
		this.propostaCartao = "";
		this.emailCartaoEnviadoSucesso = false;
		this.generateEmailEnviadoMsgSuccess = false;
		this.$scope.breadcrumb = this.$scope.$parent.breadcrumb;
		this.configureBreadCrumb();

		formControlService.reset();
		propostaSetupAccountService.reset();

		this.$scope.$emit("clearMessages");

		this.messages = feedbackMessages;
		this.propostaApi = propostaApi;
		this.checkIfCanCreateProposta();

		this.tipoLista = null;
	}

	checkIfCanCreateProposta(paIdentificadorDaConta) {
		this.propostaApi.getSusepInformation().then((response) => {
			//console.log('>>>checkIfCanCreateProposta response', response);

			if (response.InativadoNaSusep == false) {
				this.$scope.$emit("checkIfCanCreateProposta", true);
			} else {
				this.$scope.$emit("checkIfCanCreateProposta", false);
			}
		});
	}

	configureBreadCrumb() {
		this.$scope.breadcrumb.reset();
		this.$scope.breadcrumb.addMenuItem(
			"Home",
			"/caponline/secured#!/home",
			true,
			false
		);
		this.$scope.breadcrumb.addMenuItem(
			"Proposta",
			"/caponline/secured#!/proposta/lista",
			true,
			true
		);
	}

	setPristine() {
		this.totalOfRecords = 0;
		this.actualPage = 1;
		this.totalOfPages = 1;
		this.limitOfRecords = 10;
	}

	changeOrder(field) {
		this.order.changeOrder(field);
		this.setPristine();
		this.getPropostaList(true);
	}

	changeListView() {
		this.order.setPristine();
		this.setPristine();
		this.getPropostaList(true);
	}

	getPropostaList(isCount) {
		this.showLoading = true;

		this.propostaListService
			.getPropostaList({
				queryPropostaType: this.tipoLista,
				queryType: this.tipoLista == "D" ?
					this.listViewDGSelected.api : this.listViewSelected.api,
				queryLimit: this.limitOfRecords,
				queryOffset: this.queryOffset,
				queryOrderField: this.order.data.field,
				queryOrderDirection: this.order.data.direction,
				isQueryCount: !!isCount
			})
			.then((response) => {
				console.log(response);
				this.showLoading = false;
				if (!response.isSuccessful) {
					this.proposta = {};
					this.totalOfRecords = 0;
				} else {
					this.propostas = response.propostas;
					if (isCount) {
						this.totalOfRecords = response.queryCount;
						this.setPaginationParams();
					}
				}
			});
	}

	get queryOffset() {
		return (this.actualPage - 1) * this.limitOfRecords;
	}

	hasPrevious() {
		return this.actualPage != 1 && this.actualPage <= this.totalOfPages;
	}

	hasNext() {
		return this.actualPage < this.totalOfPages && this.totalOfPages > 1;
	}

	doPrevious() {
		this.actualPage--;
		if (this.isPageValid()) {
			this.showLoading = true;
			this.getPropostaList(false);
		} else {
			this.actualPage++;
		}
		console.log("Previous: ", this.actualPage);
	}

	doNext() {
		this.actualPage++;
		if (this.isPageValid()) {
			this.showLoading = true;
			this.getPropostaList(false);
		} else {
			this.actualPage--;
		}
		console.log("Next: ", this.actualPage);
	}

	goToPage() {
		if (this.ifPageValid()) {
			this.showLoading = true;
			this.getPropostaList(false);
		}
	}

	isPageValid() {
		return this.actualPage > 0 && this.actualPage <= this.totalOfPages;
	}

	setPaginationParams() {
		this.actualPage = 1;
		this.totalOfPages =
			this.totalOfRecords > this.limitOfRecords ?
			parseInt(Math.ceil(this.totalOfRecords / this.limitOfRecords)) :
			1;
	}

	resetResgateService() {
		this.resgateService.setPropostaFromResgate(false);
	}

	tooltipNovaProposta() {
		//return 'Impossibilidade de transmissão de novas propostas por necessidade de atualização cadastral na SUSEP.';
	}

	showListProposta(listSelected) {
		console.log("listSelected: ", listSelected);
		console.log("tipoLista: ", this.tipoLista);
		sessionStorage.setItem("tipo_lista", this.tipoLista);
		this.getPropostaList(true);
	}

	showPagamentoStatus(idProposta) {
		this.emailCartaoEnviadoSucesso = false;
		console.log("id da proposta: ", idProposta);
		this.$scope.$emit("changeMainShowLoading", true);

		this.propostaListService
			.getStatusPagamentoCartao(idProposta)
			.then((data) => {
				console.log("resultado do status do pagamento: ", data);
				this.$scope.$emit("changeMainShowLoading", false);
				if (!data.isSuccessful) {
					this.$window.scrollTo(0, 0);
					const errorMessage = angular.toJson(data.errorMessages);
					this.messages.showErrorMessage(this.$scope, errorMessage);
					return;
				} else {
					//Ao clicar no botão, será aberto modal box com nome do subscritor, indicação do titular do cartão, valor, parcelas, status do pagamento e botão para reenvio do link de pagamento por e-mail.

					if (data.proposta.IDC_STATUS__C == "Aguardando Pagamento") {
						this.mensagemCartao =
							"O Pagamento ainda não foi confirmado pela operadora";
					} else if (data.response == "Enviada") {
						this.mensagemCartao = "Pagamento confirmado";
					}

					this.propostaCartao = data.proposta;

					const botaoCartaoCredito = document.getElementById(
						"cartaoCreditoStatus"
					);
					console.log("botaoCartaoCredito: ", botaoCartaoCredito);
					botaoCartaoCredito.click();
					//window.open(data.response, '_blank').focus();
				}
			});
	}

	operarCartaoCreditoEmail() {
		this.emailCartaoEnviadoSucesso = false;
		console.log("enviando pagamento de cartao de credito por e-mail");
		this.$scope.$emit("changeMainShowLoading", true);
		console.log("id da proposta: ", this.propostaCartao.Id);

		const objEmailCartao = {
			idProposta: this.propostaCartao.Id,
			emailCartaoDestinatario: null
		};

		this.propostaListService
			.enviarURLPagamentoCreditoEmail(objEmailCartao)
			.then((data) => {
				console.log("resultado do envio: ", data);
				this.$scope.$emit("changeMainShowLoading", false);
				this.emailCartaoEnviadoSucesso = false;
				if (!data.isSuccessful) {
					this.$window.scrollTo(0, 0);
					const errorMessage = angular.toJson(data.errorMessages);
					this.messages.showErrorMessage(this.$scope, errorMessage);
					return;
				} else {
					this.generateEmailEnviadoMsgSuccess = true;
					this.urlCartao = data.response;
					const botaoCartaoCredito = document.getElementById(
						"buttonModalFecharSituacaoCartao"
					);
					console.log("botaoCartaoCredito: ", botaoCartaoCredito);
					botaoCartaoCredito.click();
					//window.open(data.response, '_blank').focus();
					this.emailCartaoEnviadoSucesso = true;
				}
			});
	}
}

module.exports = propostaListController;
