'use strict'

class sorteioFormValidationService {

	/* @ngInject */
	constructor() {
		this.a = '';
	}

	/*@todo refactory: Os métodos isRequired e is Valid podem ser apenas 1
	  apenas passando qual a página que vai ser validada. As páginas podem estar num objeto de configuração. 
	*/
	setFormField(data) {
		this.formField = data;
	}

	hasRequiredFieldsInformationForm(setFieldErrors) {
		const information = this.formField.information.fields
		let noErrors = true;
		for (const field in information) {
			if (typeof information[field].required == 'function') {
				if ((information[field].required(this.formField) != false) && (information[field].show != false) && (!this.hasRequiredField(information[field], setFieldErrors))) {
					noErrors = false;
				}
			} else {
				if ((information[field].required != false) && (information[field].show != false) && (!this.hasRequiredField(information[field], setFieldErrors))) {
					noErrors = false;
				}
			}
		}

		return noErrors;
	}

	isValidFieldsInformationForm() {
		const information = this.formField.information.fields
		let noErrors = true;
		for (const field in information) {
			if ((information[field].show != false) && (information[field].isValid === false)) {
				noErrors = false;
			}
		}

		return noErrors;
	}

	hasRequiredFieldsBankForm(setFieldErrors) {
		const bank = this.formField.bank.fields
		let noErrors = true;

		console.log('---> bank hasRequiredFieldsBankForm: ', bank)

		for (const field in bank) {

			if ((!!bank[field].required) && (bank[field].show != false) && (!this.hasRequiredField(bank[field], setFieldErrors)) ||
				(setFieldErrors && bank[field].hasError)) {
				noErrors = false;
			}
		}

		console.log('noErrors: ', noErrors)
		return noErrors;
	}

	isValidFieldsBankForm() {
		const bank = this.formField.bank.fields
		let noErrors = true;
		for (const field in bank) {
			if ((bank[field].show != false) && (bank[field].isValid === false)) {
				noErrors = false;
			}
		}

		return noErrors;
	}

	hasRequiredFieldsAnnexForm() {
		return true; //@todo implementar
	}

	isValidFieldsAnnexForm() {
		return true; //@todo implementar
	}

	hasRequiredField(field, setFieldErrors) {

		console.log('#### field: ', field)
		console.log('#### setFieldErrors: ', setFieldErrors)

		const hasRequirement = ((field.value !== null) && (field.value !== ""));

		if (setFieldErrors) {
			field.hasError = !hasRequirement;
			console.log('#### field.hasError: ', field.hasError)
		}
		return hasRequirement;
	}
}

module.exports = sorteioFormValidationService;
