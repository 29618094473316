'use strict';

class formControlService {

	// @ngInject
	constructor() {
		// console.log('formControlService');

		this.setPristine();
	}

	setPristine() {

		this.field = {

			account: null,
			profession: null,
			declarationCode: null,
			incomeValue: null,
			resourceOrigin: null,
			resourceOriginOutros: null,
			listApjs: [],
			Id: null,
			formStep: null,
			saleStructure: null,
			product: null,
			susepRegistry: null,
			corretora: null,
			title1: null,
			title2: null,
			title3: null,
			rentValue: null,
			titlesCharges: null,
			titleMultiplier: null,
			locator: null,
			estateAdminIsNotLocator: false,
			estateAdmin: null,
			locatorIsNotClient: false,
			locatorCpfCnpj: null,
			locatorName: null,
			percentualDireito: null,
			useClientAddress: false,
			clientCep: null,
			clientStreetAddress: null,
			clientStreetNumber: null,
			clientAddressComplement: null,
			clientDistrict: null,
			clientCity: null,
			clientUf: null,
			estatePurpose: null,
			estateType: null,
			stepStatusPropostaNew: 'Não Iniciado',
			stepStatusPropostaInformation: 'Não Iniciado',
			stepStatusPropostaCaucao: 'Não Iniciado',
			stepStatusPropostaPayment: 'Não Iniciado',
			stepStatusPropostaGenerate: 'Não Iniciado',
			stepStatusPropostaBoleto: 'Não Iniciado',
			paymentType: null,
			checkAuthorizationDebit: true,
			bankDebit: null,
			agencyDebit: null,
			agencyDVDebit: null,
			accountNumberDebit: null,
			accountNumberDVDebit: null,
			accountOwnerNameDebit: null,
			accountOwnerCpfCnpjDebit: null,
			bestDayDebit: null,
			dateVencDebit: null,
			checkAuthorizationOwnerDebit: null,
			isAccountOwnerDebitSameProspect: null,
			status: null,
			numberProposta: null,
			cpfTitularCartao: null,
			nomeTitularCartao: null,
			parcelaSelecionada: null,
			parcelaValor: null,
			cartaoValorLiquido: null,
			cartaoValorCobradoTotal: null,
			cartaoCreditoChaveCobranca: null,
			cartaoCreditoChaveCheckout: null,
			cartaoCreditoURLCheckout: null,
			parcelaSeguroSemJuros: null,
			parcelaCapComJuros: null,
			showDocumentoProprietarioCartao: false,
			retirementType: null,
			proposalType: null,
			APIerrorMessage: null,
			DG: {
				product: null,
				brokerAccount: null,
				rentValue: null,
				insuranceTerm: null,
				lifeTermValue: null,
				rentLossValue: null,
				anyCauseValue: null,
				totalByAccidentValue: null,
				rentLossValueSISVIDA: null,
				anyCauseValueSISVIDA: null,
				totalByAccidentValueSISVIDA: null,
				numberProposta: null,
				productInfo: null,
				productList: null
			}

		};

		this.actions = {
			hasGenerateBillet: true,
			hasGenerateDocument: true,
			hasClone: true,
			hasCancel: true
		}

		this.data = {

			resgateValue: null
		}
	}

	reset() {

		this.setPristine();
	}

	feedNewData(newData) {

		this.setPristine();

		this.mergeData(newData);
	}

	setActions(data) {
		this.actions = data;
	}

	validateApj() {
		//ok
		let isOkApj = true;
		const IsPessoaJuridica = !this.field.account.isPersonAccount;
		const isHaveApjsInsList = this.field.listApjs.length;

		if (IsPessoaJuridica) {
			isOkApj = (isHaveApjsInsList > 0) ? true : false;
		} else {
			isOkApj = true;
		}

		return isOkApj;
	}

	mergeData(data) {
		angular.extend(this.field, data);
	}

	resetErrorHandlerObject(errorHandlerObject) {

		for (const property in errorHandlerObject) {

			if (!errorHandlerObject.hasOwnProperty(property)) continue;

			errorHandlerObject[property] = false;
		}
	}

	requireProperty(propertyName, errorHandlerObject) {
		// console.log('requireProperty', propertyName, errorHandlerObject);

		const hasProperty = this.hasProperty(propertyName);
		// console.log('hasProperty', hasProperty);

		errorHandlerObject[`${propertyName}Error`] = !hasProperty;
		// console.log('propertyError', errorHandlerObject[`${propertyName}Error`]);
		return hasProperty;
	}

	hasProperty(propertyName) {
		// console.log('hasProperty', propertyName, this.field[propertyName]);

		// if (propertyName === 'clientStreetAddress' && this.field[propertyName] && this.field[propertyName].length < 5) {
		// 	return false;
		// }

		if (propertyName === 'corretora' && this.isEmpty(this.field[propertyName])) {
			return false;
		}

		return !!this.field[propertyName];
	}
	valorDeRenda(propertyName, errorHandlerObject) {
		if (this.field.incomeValue <= 0.99) {
			errorHandlerObject[`${propertyName}Error`] = true;
		}
	}

	isEmpty(obj) {
		for (const key in obj) {
			if (obj.hasOwnProperty(key))
				return false;
		}
		return true;
	}
}

module.exports = formControlService;
