"use strict";

class ResgateNewService {
	/* @ngInject */
	constructor(visualForceApi, $sce, $q) {
		this.visualForceApi = visualForceApi;
		this.$q = $q;
		this.propostas = [];
	}

	searchAccount(searchKeyWord) {
		const deferred = this.$q.defer();

		const searchModel = {
			dataFetch: {
				searchKeyWord,
				searchTypeObject: "Account"
			}
		};

		const cap_access_token = localStorage.getItem("cap_access_token");
		searchModel.accessToken = cap_access_token;

		this.visualForceApi
			.invoke("CapCommunityPropostaControllerExt.solFetchRecords", searchModel)
			.then((data) => {
				//console.log(data.obtainedRecords);
				if (!data.obtainedRecords) data.obtainedRecords = [];
				if (data.obtainedRecords.length === 0) {
					data.obtainedRecords.push({
						action: "searchAccountFocusOut",
						Name: "Nenhum cadastro encontrado"
					});
				}
				deferred.resolve(data.obtainedRecords);
			});

		return deferred.promise;
	}

	getPropostas() {
		return this.propostas;
	}

	setPropostas(propostas) {
		this.propostas = propostas;
	}
}

module.exports = ResgateNewService;
