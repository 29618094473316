'use strict'

class sorteioFormControlService {
	// @ngInject
	constructor(sorteioFormValidationService, validationFormFields) {
		console.log('construtor da formservice')
		console.log('--> validationFormFields: ', validationFormFields)
		this.formValidationService = sorteioFormValidationService;
		this.valid = validationFormFields;
		this.setPristine();
		this.initRequiredFunctions();
		this.initIsValidFunctions();
		this.initWizardSteps();
		this.formValidationService.setFormField(this);
		console.log(' final construtor da formservice')
	}

	setPristine() {
		this.status = null;
		this.formStep = null;
		this.id = null;
		this.edit = false;
		this.lastFormAction = ''; //tem uma referência num arquivo setando essa propriedade

		this.setButtonControlPristine();
		this.setInformationPristine();
		this.setBankPristine();
		this.setAnnexPristine();
		console.log('final setPristine da form service')
	}

	initWizardSteps() {
		this.wizardSteps = {
			first: 'information',
			second: 'bank',
			last: 'annex'
		};
	}

	initRequiredFunctions() {
		this.hasRequiredFunctions = {
			information: this.hasRequiredFieldsInformationForm,
			bank: this.hasRequiredFieldsBankForm,
			annex: this.hasRequiredFieldsAnnexForm
		}
	}

	initIsValidFunctions() {
		this.isValidFunctions = {
			information: this.isValidFieldsInformationForm,
			bank: this.isValidFieldsBankForm,
			annex: this.isValidFieldsAnnexForm
		}
	}

	requireProperty(pageFields, propertyName) {
		const field = pageFields[propertyName];
		field.hasError = !field.value;
	}

	setButtonControlPristine() {
		this.buttonControl = {
			cancel: {
				show: true,
				disabled: true
			},
			previous: {
				show: false,
				disabled: true
			},
			next: {
				show: true,
				disabled: false
			},
			save: {
				show: true,
				disabled: true
			},
			finish: {
				show: false,
				disabled: true
			}
		}
	}

	setInformationPristine() {

		console.log('setInformationPristine;')

		this.information = {
			active: false,
			status: 'Não Iniciado',
			fields: {
				mainAccount: {
					value: null,
					hasError: false,
					isValid: true,
					loading: false,
					show: true,
					isPersonAccount: false
				},
				proposta: { value: null, hasError: false, loading: false, show: true },
				garantias: { value: null, hasError: false, loading: false, show: false },
				cep: { value: null, hasError: false, isValid: true, required: this.informationAdressFieldsRequired },
				logradouro: { value: null, hasError: false, isValid: true, required: this.informationAdressFieldsRequired },
				numero: { value: null, hasError: false, isValid: true, required: this.informationAdressFieldsRequired },
				complemento: { value: null, hasError: false, isValid: true, required: false },
				bairro: { value: null, hasError: false, isValid: true, required: this.informationAdressFieldsRequired },
				cidade: { value: null, hasError: false, isValid: true, required: this.informationAdressFieldsRequired },
				uf: { value: null, hasError: false, isValid: true, required: this.informationAdressFieldsRequired }
			}
		};
	}

	pristineFormObj() {
		return {
			value: null,
			hasError: false,
			show: false
		};
	}

	resetInformationAdressFields() {
		this.information.cep = { value: null, hasError: false, isValid: true, required: this.informationAdressFieldsRequired };
		this.information.logradouro = { value: null, hasError: false, isValid: true, required: this.informationAdressFieldsRequired };
		this.information.numero = { value: null, hasError: false, isValid: true, required: this.informationAdressFieldsRequired };
		this.information.complemento = { value: null, hasError: false, isValid: true, required: false };
		this.information.bairro = { value: null, hasError: false, isValid: true, required: this.informationAdressFieldsRequired };
		this.information.cidade = { value: null, hasError: false, isValid: true, required: this.informationAdressFieldsRequired };
		this.information.uf = { value: null, hasError: false, isValid: true, required: this.informationAdressFieldsRequired };
	}

	informationAdressFieldsRequired(context) {
		return context.information.fields.garantias.value !== null;
	}

	setBankPristine() {
		this.bank = {
			active: false,
			status: 'Não Iniciado',
			fields: {
				clienteTitular: { value: null, hasError: false, required: true },
				tipoConta: { value: null, hasError: false, required: true },
				banco: { value: null, hasError: false, isValid: true, required: true },
				agencia: { value: null, hasError: false, isValid: true, required: true },
				agenciaDV: { value: null, hasError: false, isValid: true, hasAgenciaDVPeopleError: false, required: false },
				conta: { value: null, hasError: false, isValid: true, required: true },
				contaDV: { value: null, hasError: false, isValid: true, hasContaDVPeopleError: false, required: false },
				titular: { value: null, hasError: false, isValid: true, required: true },
				cpfCnpj: { value: null, hasError: false, isValid: true, validateFunction: this.valid.isValidCpfCnpj, required: true },
				profissao: { value: null, hasError: false, required: true },
				renda: { value: null, hasError: false, required: true },
				telefone: { value: null, hasError: false, required: true },
				email: { value: null, hasError: false, isValid: true, validateFunction: this.valid.isValidEmail, required: true }
			}
		}
	}

	setAnnexPristine() {

		this.annex = {

			active: false,
			status: "Não Iniciado",
			form: this.pristineAnnexObj(),
			auth: this.pristineAnnexObj(),
			subscritor: {
				show: true, //todo dinamico
				identityRenter: this.pristineAnnexObj(),
				cpf: this.pristineAnnexObj(),
				adressCheck: this.pristineAnnexObj(),
				otherPf: this.pristineAnnexObj(),
				cnpj: this.pristineAnnexObj(),
				constitutionIdentity: this.pristineAnnexObj(),
				otherPj: this.pristineAnnexObj()
			}
		}

		this.annex.form.type = 'form';
		this.annex.auth.type = 'auth';
		this.annex.subscritor.identityRenter.type = 'subscritor-identityRenter';
		this.annex.subscritor.cpf.type = 'subscritor-cpf';
		this.annex.subscritor.adressCheck.type = 'subscritor-adressCheck';
		this.annex.subscritor.otherPf.type = 'subscritor-otherPf';
		this.annex.subscritor.cnpj.type = 'subscritor-cnpj';
		this.annex.subscritor.constitutionIdentity.type = 'subscritor-constitutionIdentity';
		this.annex.subscritor.otherPj.type = 'subscritor-otherPj';

		this.annex.subscritor.otherPf.required = false;
		this.annex.subscritor.otherPj.required = false;
		this.annex.auth.required = false;
	}

	resetAnnex() {

		this.setAnnexPristine();
	}

	pristineAnnexObj() {

		return {
			files: [],
			gambiFiles: [],
			hasError: false,
			loading: false,
			show: false,
			uploaded: false,
			uploadHasFailure: false
		};
	}

	hasRequiredFields(page, setFieldErrors) {
		if (!page) return;
		return this.hasRequiredFunctions[page](this, setFieldErrors);
	}

	isValidFields(page) {
		if (!page) return;
		return this.isValidFunctions[page](this);
	}

	hasRequiredFieldsInformationForm(formControl, setFieldErrors) {
		//parametro formControl é por causa do contexto
		if (formControl == null) formControl = this;
		return formControl.formValidationService.hasRequiredFieldsInformationForm(setFieldErrors);
	}

	isValidFieldsInformationForm(formControl) {
		return formControl.formValidationService.isValidFieldsInformationForm();
	}

	hasRequiredFieldsBankForm(formControl, setFieldErrors) {
		//parametro formControl é por causa do contexto
		if (formControl == null) formControl = this;
		return formControl.formValidationService.hasRequiredFieldsBankForm(setFieldErrors);
	}

	isValidFieldsBankForm(formControl) {
		return formControl.formValidationService.isValidFieldsBankForm();
	}

	hasRequiredFieldsAnnexForm(formControl, setFieldErrors) {
		if (formControl == null) formControl = this;
		return formControl.formValidationService.hasRequiredFieldsAnnexForm(setFieldErrors);
	}

	isValidFieldsAnnexForm(formControl) {
		return formControl.formValidationService.isValidFieldsAnnexForm();
	}

	activateTab(tab) {
		const tabs = {
			information: this.information,
			bank: this.bank,
			annex: this.annex
		}

		for (const t in tabs) {
			tabs[t].active = (t == tab);
		}
	}

	setStepButtons(step) {
		if (step == this.wizardSteps.first) {
			this.buttonControl.previous.show = false;
			this.buttonControl.previous.disabled = true;
			this.buttonControl.next.show = true;
			this.buttonControl.next.disabled = false;
			this.buttonControl.finish.show = false;
			this.buttonControl.finish.disabled = true;
		} else if (step == this.wizardSteps.last) {
			this.buttonControl.previous.show = true;
			this.buttonControl.previous.disabled = false;
			this.buttonControl.next.show = false;
			this.buttonControl.next.disabled = true;
			this.buttonControl.finish.show = true;
			this.buttonControl.finish.disabled = false;
		} else {
			this.buttonControl.previous.show = true;
			this.buttonControl.previous.disabled = false;
			this.buttonControl.next.show = true;
			this.buttonControl.next.disabled = false;
			this.buttonControl.finish.show = false;
			this.buttonControl.finish.disabled = true;
		}
	}

	validateField(formField, field) {
		const fieldObj = formField[field];
		if (this.nullOrEmpty(formField, field)) {
			fieldObj.isValid = true;
		} else {
			if (!fieldObj.validateFunction) return;
			fieldObj.isValid = fieldObj.validateFunction.call(this.valid, fieldObj.value);
		}
	}

	nullOrEmpty(formField, field) {
		return (formField[field].value === null || formField[field].value === '');
	}

	extractEditCaseData() {
		return {
			status: this.status,
			formStep: this.formStep,
			id: this.id,

			information: this.information,
			bank: this.bank,
			annex: this.annex,
			buttonControl: this.buttonControl
		};
	}

}

module.exports = sorteioFormControlService
