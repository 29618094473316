'use strict';

class reaplicacaoTituloDetailController {

	// @ngInject

	constructor($stateParams, $state, sfConfig, reaplicacaoTituloDetailService, $scope, reaplicacaoTituloFormService, reaplicacaoTituloService, breadcrumbHelper) {

		this.$stateParams = $stateParams;
		this.$state = $state;
		this.$scope = $scope;
		this.reaplicacaoTituloDetailService = reaplicacaoTituloDetailService;
		this.reaplicacaoTituloFormService = reaplicacaoTituloFormService;
		this.reaplicacaoTituloService = reaplicacaoTituloService;
		this.$scope.breadcrumb = breadcrumbHelper;

		this.showLoading = true;
		this.case = {
			details: null,
			related: {
				propostas: null,
				rentGarantee: null,
				history: null,
				parsedEditCaseData: null
			}
		}

		this.errorMessage = '';
		this.getCaseDetails();
		this.configureBreadCrumb();

		this.reaplicacaoTituloService.setFinishShow(false);
		this.reaplicacaoTituloService.setCancelShow(false);

	}

	configureBreadCrumb() {
		this.$scope.breadcrumb.reset();
		this.$scope.breadcrumb.addMenuItem('Home', '/caponline/secured#!/home', true, false);
		this.$scope.breadcrumb.addMenuItem('Solicitação', '/caponline/secured#!/solicitacoes/lista', true, false);
		this.$scope.breadcrumb.addMenuItem('Detalhes da Reaplicação do Título', '#', false, true);
	}

	getCaseDetails() {

		if (!this.hasValidUrlParams()) {
			this.showLoading = false;
			this.$state.go('case.list');
		}
		this.$scope.$emit('changeMainShowLoading', true);
		this.reaplicacaoTituloDetailService.getSolicitacaoDetails(this.$stateParams.id).then((response) => {
			this.$scope.$emit('changeMainShowLoading', false);
			console.log(response);
			if (!response.isSuccessful) {
				this.errorMessage = response.errorMessages[0];
			} else {
				this.case.details = response.resgate;
				this.case.related.propostas = response.resgate.propostas;
				this.case.related.rentGarantee = response.resgate.titulos;
				this.case.related.history = response.resgate.history;
				this.case.related.parsedEditCaseData = this.parseEditCaseData(this.case.details);
				console.log(this.case);
			}
			this.showLoading = false;
		});

		this.showLoading = false;
	}

	hasValidUrlParams() {
		return (this.$stateParams && this.$stateParams.id) ? true : false;
	}

	parseEditCaseData() {

		// monstruosidade requisitada pelo Diogo
		const div = document.createElement('div');
		div.innerHTML = this.case.details.editCaseData;

		return JSON.parse(div.innerHTML);
	}

}

module.exports = reaplicacaoTituloDetailController;
