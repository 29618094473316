'use strict';

class ResgateDetailService {

	/* @ngInject */
	constructor(visualForceApi, $sce) {
		this.visualForceApi = visualForceApi;
	}

	getResgateDetails(resgateId) {

		const cap_access_token = localStorage.getItem("cap_access_token");

		return this.visualForceApi.invoke(
			'CapCommunityCaseControllerExt.getCaseDetails', resgateId, cap_access_token);
	}

	updateEditCaseData(request) {

		console.log('Request updateEditCaseData', request);

		const cap_access_token = localStorage.getItem("cap_access_token");
		request.accessToken = cap_access_token;

		return this.visualForceApi.invoke(
			'CapCommunityCaseControllerExt.updateEditCaseData', request);
	}

}

module.exports = ResgateDetailService;
