'use strict';

class propostaPaymentController {
	/* @ngInject */
	constructor($scope, $rootScope, $uibModal, $window, $timeout, propostaPaymentService, formControlService, validationFormFields, autoCompleteService, propostaSetupAccountService, sfConfig, dadosBancariosHelper, accountUtilService, crivoHelper, feedbackMessages) {
		console.log('propostaPaymentController');

		this.propostaPaymentService = propostaPaymentService;
		this.formControl = formControlService;
		this.formField = this.formControl.field;
		this.valid = validationFormFields;
		this.autoComplete = autoCompleteService;
		this.messages = feedbackMessages;
		this.$scope = $scope;
		this.$rootScope = $rootScope;
		this.$timeout = $timeout;
		this.$window = $window;
		this.propostaSetupAccountService = propostaSetupAccountService;
		this.$uibModal = $uibModal;
		this.sfConfig = sfConfig;
		this.dadosBancariosHelper = dadosBancariosHelper;
		this.accountUtilService = accountUtilService;
		this.crivoHelper = crivoHelper;
		this.parametrosValidados = null;
		this.dataClienteOK = false;
		this.showDocumentoProprietarioCartao = false;
		this.erroParcelas = null;
		this.responseBestDateService = null;

		this.required = {};
		this.validated = {};
		this.parcelaSelecionada = null;

		this.bankListDebit = this.propostaPaymentService.getBankList();
		this.bestDayListDebit = this.propostaPaymentService.getBestDayList();
		this.formField.formStep = 'proposta.payment';

		this.debito = 'Débito em Conta Corrente';

		if (this.isOrigemResgate() || this.formField.paymentType == 'Recuperação de Crédito') {
			this.choosePayment('Recuperação de Crédito');
			this.formField.stepStatusPropostaPayment = 'Concluído';
			this.cleanDebitFields();
		}

		// Forma de pagamento padrão DEBITO EM CONTA
		// desativado para subida de DCC
		//if (this.formField.Id == null && this.formField.paymentType == null) {
		//	this.choosePayment(this.debito);
		//}

		// Forma de pagamento padrão BOLETO
		// ativado para subida de DCC
		if (this.formField.paymentType == null || this.formField.paymentType == this.debito) {
			this.choosePayment('Boleto');
			this.cleanDebitFields();
		}

		this.listenRequiredRequest();
		this.listenValidationRequest();
		this.listenEditBestDayDebit();
		this.checkOnLoadRequiredFields();
		this.getParcelas();

		console.log('TODOS OS CAMPOS:', this.formField);

		//NUM_QTD_TITULOS_1__c*NUM_VAL_UNITARIO_1__c+NUM_QTD_TITULOS_2__c*NUM_VAL_UNITARIO_2__c+NUM_QTD_TITULOS_3__c*NUM_VAL_UNITARIO_3__c

		/*
		objProposta.NUM_QTD_TITULOS_1__c                = request.propostaSave.title1 != null ? request.propostaSave.title1.titleSQuantity  : null;           
		objProposta.NUM_QTD_TITULOS_2__c                = request.propostaSave.title2 != null ? request.propostaSave.title2.titleSQuantity  : null;  
		objProposta.NUM_QTD_TITULOS_3__c                = request.propostaSave.title3 != null ? request.propostaSave.title3.titleSQuantity  : null;    
		objProposta.NUM_VAL_UNITARIO_1__c               = request.propostaSave.title1 != null ? request.propostaSave.title1.value           : null;          
		objProposta.NUM_VAL_UNITARIO_2__c               = request.propostaSave.title2 != null ? request.propostaSave.title2.value           : null;         
		objProposta.NUM_VAL_UNITARIO_3__c               = request.propostaSave.title3 != null ? request.propostaSave.title3.value           : null;   
		*/

	}

	listenValidationRequest() {
		this.$scope.$on('stepValidate', () => {
			this.$scope.$emit('stepValidateResponse', this.formValidation());
		});
	}

	getParametersForValidBank() {
		const parameter = {};
		parameter.tipoConta = 'Conta Corrente';
		parameter.banco = this.formField.bankDebit;
		parameter.agencia = this.formField.agencyDebit;
		parameter.agenciaDV = this.formField.agencyDVDebit;
		parameter.conta = this.formField.accountNumberDebit;
		parameter.contaDV = this.formField.accountNumberDVDebit;
		parameter.cpfCnpj = this.formField.accountOwnerCpfCnpjDebit;
		return parameter;
	}

	clearFieldsDebit() {
		this.formField.agencyDebit = null;
		this.formField.agencyDVDebit = null;
		this.formField.accountNumberDebit = null;
		this.formField.accountNumberDVDebit = null;
	}

	choosePayment(paymentType) {

		this.formField.paymentType = paymentType;

		if (paymentType != 'CartaoCredito') {
			this.formField.showDocumentoProprietarioCartao = false;
			this.formField.cpfTitularCartao = null;
			this.formField.nomeTitularCartao = null;
			this.formField.cartaoValorCobradoTotal = null;
		}
	}

	callForValidDataBank() {

		if (angular.equals(this.parametrosValidados, this.getParametersForValidBank())) {
			return;
		}

		this.parametrosValidados = this.getParametersForValidBank();

		const { tipoConta, cpfCnpj, banco, agencia, agenciaDV, conta, contaDV } = this.parametrosValidados;

		const isValid = this.hasField(tipoConta) && this.hasField(banco) && this.hasField(agencia) && this.hasField(conta) && this.hasField(contaDV);

		if (!isValid) {
			return;
		}
		this.$scope.$emit('changeMainShowLoading', true);
		this.dadosBancariosHelper.consultaDadosBancariosSeValidosPromisse(tipoConta, cpfCnpj, banco, agencia, agenciaDV, conta, contaDV).then((response) => {
			this.applyErrorBankFields(response);
			this.$scope.$emit('changeMainShowLoading', false);
		});
	}

	defineMessage(message) {
		this.messages.showErrorMessage(this.$scope, message);
	}

	isValidNotBankSuccess() {

		const hasLimitExceeded = this.dadosBancariosHelper.remoteCallErrorLimitWasExceded();
		const message = hasLimitExceeded ? '' : this.dadosBancariosHelper.remoteCallErrorMessage;
		const hasError = hasLimitExceeded ? false : true;

		this.defineMessage(message);
		this.setErrorBankField(hasError);

	}

	isValidBankSuccess(response) {

		if (response.isValid) {
			this.defineMessage('');
			this.setErrorBankField(false);
		} else {
			this.setErrorBankField(true);
		}
	}

	applyErrorBankFields(response) {

		const isSuccessful = response.isSuccessful;

		if (isSuccessful) {
			this.isValidBankSuccess(response);
		} else {
			this.isValidNotBankSuccess();
		}

		this.dadosBancariosHelper.setPristine();
	}

	setErrorBankField(value) {
		this.validated.hasAgencyPeopleError = value;
		this.validated.agencyDebitError = value;
		this.validated.hasAgencyDVPeopleError = value;
		//this.validated.agencyDVDebitError = value;
		this.validated.hasAccountNumberPeopleError = value;
		this.validated.accountNumberDebitError = value;
		this.validated.hasAccountNumberDVPeopleError = value;
		this.validated.accountNumberDVDebitError = value;
	}

	listenRequiredRequest() {
		this.$scope.$on('stepRequired', () => {
			this.$scope.$emit('stepRequiredResponse', this.formRequired());
		});
	}

	listenEditBestDayDebit() {
		this.$scope.$on('bestDayDebit', () => {
			this.formField.bestDayDebit = this.responseBestDateService.bestDayDebit;
			this.formField.dateVencDebit = this.responseBestDateService.bestDateDebit;
		});
	}

	isOrigemResgate() {
		if (this.formField.isOrigemResgate != null) {
			return this.formField.isOrigemResgate;
		} else {
			return false;
		}
	}

	isFormPristine() {
		return this.formField.stepStatusPropostaPayment === 'Não Iniciado';
	}

	checkOnLoadRequiredFields() {

		// console.log('checkOnLoadRequiredFields');

		/*if (this.checkIfHasUserInfo()) {
			this.formField.stepStatusPropostaPayment = 'Não Iniciado';
		}*/

		if (!this.isFormPristine() && this.formRequired()) {

			return;
		}

		this.formField.stepStatusPropostaPayment = 'Incompleto';
	}

	resetRequiredErrors() {
		const required = this.required;
		required.paymentError = false;
		required.checkAuthorizationDebitError = false;
		required.bankDebitError = false;
		required.agencyDebitError = false;
		//required.agencyDVDebitError = false;
		required.accountNumberDebitError = false;
		required.accountNumberDVDebitError = false;
		required.accountOwnerNameDebitError = false;
		required.accountOwnerCpfCnpjDebitError = false;
		required.bestDayDebitError = false;
		required.checkAuthorizationOwnerDebitError = false;
		required.cartaoParcelaError = false;
		required.cartaoOwnerError = false;
	}

	resetValidErrors() {
		this.validated.checkAuthorizationDebitError = false;
		this.validated.checkAuthorizationOwnerDebitError = false;
		this.validated.agencyDebitError = false;
		//this.validated.agencyDVDebitError = false;
		this.validated.accountNumberDebitError = false;
		this.validated.accountNumberDVDebitError = false;
		this.validated.cartaoParcelaError = false;
		this.validated.cartaoOwnerError = false;
	}

	changeBestDay() {
		this.$scope.$emit('changeMainShowLoading', true);
		this.propostaPaymentService.getBestDayService(this.formField.bestDayDebit).then((response) => {
			this.responseBestDateService = response;

			const showPopup = response.showPopup;
			this.formField.dateVencDebit = response.bestDateDebitClient;

			if (showPopup) {
				this.dataClienteOK = (response.bestDateDebitClient === response.bestDateDebit);
				let message = `Seu pagamento foi programado para ${response.bestDateDebitClient}.`;
				if (!this.dataClienteOK) message = `${message} Deseja manter esta data ou alterar para a data mais próxima disponível, ${response.bestDateDebit}? Caso o vencimento seja em um final de semana ou feriado, o débito ocorrerá no próximo dia útil.`;
				$('#propostaBestDateDebitMessage').html(message);
				$('#keepBetterDay').modal({ backdrop: 'static', keyboard: false });
			}

			this.$scope.$emit('changeMainShowLoading', false);
		});
	}

	isPropostaDuplaGarantia() {
		const propostaType = sessionStorage.getItem("tipo_lista");
		if (propostaType == "D") {
			return true;
		} else {
			return false;
		}
	}

	getParcelas() {

		console.log('----> iniciando busca de parcelas');

		console.log('----> this.formField completo:', this.formField);

		let valorTotal = 0;
		let valorTitulo = 0;

		console.log('----> this.formField.title1:', this.formField.title1);
		console.log('----> this.formField.title12:', this.formField.title2);
		console.log('----> this.formField.title3:', this.formField.title3);

		if (this.formField.title1 != null) {
			valorTotal = valorTotal + (this.formField.title1.titleSQuantity * this.formField.title1.value)
			valorTitulo = valorTitulo + (this.formField.title1.titleSQuantity * this.formField.title1.value)
		}

		console.log('----> valorTotal:', valorTotal);

		if (this.formField.title2 != null) {
			valorTotal = valorTotal + (this.formField.title2.titleSQuantity * this.formField.title2.value)
			valorTitulo = valorTitulo + (this.formField.title1.titleSQuantity * this.formField.title2.value)
		}

		console.log('----> valorTotal:', valorTotal);

		if (this.formField.title3 != null) {
			valorTotal = valorTotal + (this.formField.title3.titleSQuantity * this.formField.title3.value)
			valorTitulo = valorTitulo + (this.formField.title1.titleSQuantity * this.formField.title3.value)
		}

		console.log('----> valorTotal:', valorTotal);

		if (this.formField.DG != null && this.formField.DG.lifeTermValue != null && this.formField.DG.insuranceTerm != null) {
			valorTotal = valorTotal + (this.formField.DG.lifeTermValue)
		}

		console.log('----> valorTotal com dupla garantia:', valorTotal);
		console.log('----> valorTitulo: ', valorTitulo);

		console.log('valor total das parcelas: ', valorTotal);

		this.erroParcelas = null;

		if (!this.isPropostaDuplaGarantia()) {

			this.propostaPaymentService.getParcelas(valorTotal).then((response) => {

				console.log('resultado da busca de parcelas: ', response)

				if (response.status && response.status == 'error') {

					console.log('identificado erro na busca de parcelas')
					this.erroParcelas = response.message;

				} else {

					this.parcelasResultado = response.resultado;
					this.parcelas = [];

					for (const p of response.resultado) {
						//p.label = 'R$ ' + p.valorCobrado + ' - ' + p.numeroQtdParcelas + 'x de R$ ' + p.valorParcela;
						if (p.numeroQtdParcelas > 1)
							p.label = `${p.numeroQtdParcelas}x R$ ${p.valorParcela}  (R$ ${p.valorCobrado})`;
						else
							p.label = `à vista R$ ${p.valorParcela}  (R$ ${p.valorCobrado})`;

						this.parcelas.push(p);
					}

					console.log('this.parcelas: ', this.parcelas);
				}

			});
		} else {
			this.propostaPaymentService.getParcelasDG(valorTitulo, this.formField.DG.lifeTermValue).then((response) => {

				if (response.code != 200) {

					console.log('identificado erro na busca de parcelas');
					this.erroParcelas = response.mensagem;

				} else {

					this.parcelasResultado = response.parcelas;
					this.parcelas = [];

					for (const p of response.parcelas) {
						//p.label = 'R$ ' + p.valorCobrado + ' - ' + p.numeroQtdParcelas + 'x de R$ ' + p.valorParcela;
						if (p.numeroParcela > 1)
							p.label = `${p.numeroParcela}x     R$ ${p.somaValorUnitario}     R$ ${p.somaValorTotal}`;
						else
							p.label = `à vista R$ ${p.somaValorUnitario}     R$ ${p.somaValorTotal}`;

						this.parcelas.push(p);
					}

				}

			});
		}
	}

	chooseOwnerSubscriber(ownerSubscriber) {

		console.log('ownerSubscriber: ', ownerSubscriber);
		if (ownerSubscriber == 'S') {
			this.formField.showDocumentoProprietarioCartao = true;
		} else {
			this.formField.showDocumentoProprietarioCartao = false;
		}

	}

	isDebito() {
		return (this.formField.paymentType == this.debito);
	}

	setAccountOwnerSameProspect() {
		const formField = this.formField;
		const isSame = formField.isAccountOwnerDebitSameProspect;
		formField.accountOwnerNameDebit = (isSame) ? formField.account.Name : null;
		formField.accountOwnerCpfCnpjDebit = (isSame) ? formField.account.cpf : null;

		if (formField.accountOwnerNameDebit != null) {
			this.required.accountOwnerNameDebitError = false;
		}

		if (formField.accountOwnerCpfCnpjDebit != null) {
			this.required.accountOwnerCpfCnpjDebitError = false;
		}
	}

	cleanDebitFields() {

		const formField = this.formField;

		formField.checkAuthorizationDebit = null;
		formField.bankDebit = null;
		formField.agencyDebit = null;
		formField.agencyDVDebit = null;
		formField.accountNumberDebit = null;
		formField.accountNumberDVDebit = null;
		formField.accountOwnerNameDebit = null;
		formField.accountOwnerCpfCnpjDebit = null;
		formField.bestDayDebit = null;
		formField.dateVencDebit = null;
		formField.checkAuthorizationOwnerDebit = null;
		formField.isAccountOwnerDebitSameProspect = null;

		this.resetRequiredErrors();
	}

	formValidation() {

		let validFieldsOk = true;
		const formField = this.formField;

		if (this.formField.stepStatusPropostaPayment != 'Incompleto') {
			this.resetValidErrors();
		}

		const isValidCheckAuthorizationDebit = this.isValidCheckAuthorizationDebit();
		const isValidCheckAuthorizationOwnerDebit = this.isValidCheckAuthorizationOwnerDebit();

		const isDebito = (this.formField.paymentType == this.debito);
		const isCredito = (this.formField.paymentType == 'CartaoCredito');

		//Validation bank block

		const isValidAgencyPeople = (isDebito ? !this.validated.hasAgencyPeopleError : true);
		const isValidAgencyDVPeople = (isDebito ? !this.validated.hasAgencyDVPeopleError : true);
		const isValidAccountNumberPeople = (isDebito ? !this.validated.hasAccountNumberPeopleError : true);
		const isValidAccountNumberDVPeople = (isDebito ? !this.validated.hasAccountNumberDVPeopleError : true);

		//const isOwnerCartaoOk = (isCredito == true && !formField.showDocumentoProprietarioCartao) || (isCredito == true && formField.showDocumentoProprietarioCartao && formField.cpfTitularCartao != null && formField.nomeTitularCartao != null);
		//const isCartaoParcelaOk = (isCredito == true && formField.parcelaSelecionada != null && formField.parcelaSelecionada > 0);

		validFieldsOk = (validFieldsOk ? isValidCheckAuthorizationDebit : validFieldsOk);
		validFieldsOk = (validFieldsOk ? isValidCheckAuthorizationOwnerDebit : validFieldsOk);
		//validFieldsOk = (validFieldsOk ? isOwnerCartaoOk : validFieldsOk);
		//validFieldsOk = (validFieldsOk ? isCartaoParcelaOk : validFieldsOk);
		console.log('isValidCheckAuthorizationDebit: ', isValidCheckAuthorizationDebit);
		console.log('isValidCheckAuthorizationOwnerDebit: ', isValidCheckAuthorizationOwnerDebit);
		console.log('isOwnerCartaoOk: ', isOwnerCartaoOk);
		console.log('isCartaoParcelaOk: ', isCartaoParcelaOk);
		console.log('isCredito? ', isCredito);
		//validFieldsOk = (validFieldsOk ? isValidAgencyPeople : validFieldsOk);
		//validFieldsOk = (validFieldsOk ? isValidAgencyDVPeople : validFieldsOk);
		//validFieldsOk = (validFieldsOk ? isValidAccountNumberPeople : validFieldsOk);
		//validFieldsOk = (validFieldsOk ? isValidAccountNumberDVPeople : validFieldsOk);

		console.log('Valid Fields: ', validFieldsOk);
		console.log('FormField: ', this.formField);

		return validFieldsOk;
	}

	isValidCheckAuthorizationDebit() {
		let isOk = true;
		if (this.hasField(this.formField.checkAuthorizationDebit)) {
			const formField = this.formField;
			const isCheckTrue = formField.checkAuthorizationDebit;
			const isDebitTrue = (formField.paymentType == this.debito);
			isOk = isCheckTrue && isDebitTrue;
			this.validated.checkAuthorizationDebitError = !(isOk);
		}
		return isOk;
	}

	isValidCheckAuthorizationOwnerDebit() {
		let isOk = true;
		if (this.hasField(this.formField.checkAuthorizationOwnerDebit)) {
			const formField = this.formField;
			const isCheckTrue = this.formField.checkAuthorizationOwnerDebit;
			const isDebitTrue = (formField.paymentType == this.debito);
			isOk = isCheckTrue && isDebitTrue;
			this.validated.checkAuthorizationOwnerDebitError = !(isOk);
		}
		return isOk;
	}

	isPayment(payment) {
		return (this.formField.paymentType == payment);
	}

	formRequired() {

		this.resetRequiredErrors();

		let formOk = true;

		const formField = this.formField;

		const isPaymentOk = this.hasField(formField.paymentType);
		const isBoleto = (formField.paymentType == 'Boleto');
		const isRecuperacaoCredito = formField.paymentType == 'Recuperação de Crédito';
		const isCheckAuthorizationDebitOk = this.hasField(formField.checkAuthorizationDebit) || isBoleto || isRecuperacaoCredito;
		const isBankDebitOk = this.hasField(formField.bankDebit) || isBoleto || isRecuperacaoCredito;
		const isAgencyDebitOk = this.hasField(formField.agencyDebit) || isBoleto || isRecuperacaoCredito;
		//const isAgencyDVDebitOk = this.hasField(formField.agencyDVDebit) || isBoleto || isRecuperacaoCredito;
		const isAccountNumberDebitOk = this.hasField(formField.accountNumberDebit) || isBoleto || isRecuperacaoCredito;
		const isAccountNumberDVDebitOk = this.hasField(formField.accountNumberDVDebit) || isBoleto || isRecuperacaoCredito;
		const isAccountOwnerNameDebitOk = this.hasField(formField.accountOwnerNameDebit) || isBoleto || isRecuperacaoCredito;
		const isAccountOwnerCpfCnpjDebitOk = this.hasField(formField.accountOwnerCpfCnpjDebit) || isBoleto || isRecuperacaoCredito;
		const isBestDayDebitOk = this.hasField(formField.bestDayDebit) || isBoleto || isRecuperacaoCredito;
		const isCheckAuthorizationOwnerOk = this.hasField(formField.checkAuthorizationOwnerDebit) || isBoleto || isRecuperacaoCredito;

		const isOwnerCartaoOk = !formField.showDocumentoProprietarioCartao || (formField.showDocumentoProprietarioCartao && formField.cpfTitularCartao != null && formField.nomeTitularCartao != null);
		const isCartaoParcelaOk = formField.paymentType == 'CartaoCredito' && formField.parcelaSelecionada != null && formField.parcelaSelecionada > 0;

		const req = this.required;

		req.paymentError = this.applyError(isPaymentOk);
		req.checkAuthorizationDebitError = this.applyError(isCheckAuthorizationDebitOk);
		req.bankDebitError = this.applyError(isBankDebitOk);
		req.agencyDebitError = this.applyError(isAgencyDebitOk);
		//req.agencyDVDebitError = this.applyError(isAgencyDVDebitOk);
		req.accountNumberDebitError = this.applyError(isAccountNumberDebitOk);
		req.accountNumberDVDebitError = this.applyError(isAccountNumberDVDebitOk);
		req.accountOwnerNameDebitError = this.applyError(isAccountOwnerNameDebitOk);
		req.accountOwnerCpfCnpjDebitError = this.applyError(isAccountOwnerCpfCnpjDebitOk);
		req.bestDayDebitError = this.applyError(isBestDayDebitOk);
		req.checkAuthorizationOwnerDebitError = this.applyError(isCheckAuthorizationOwnerOk);
		req.ownerCartaoError = this.applyError(isOwnerCartaoOk);
		req.ownerCartaoParcelaError = this.applyError(isCartaoParcelaOk);

		formOk = (formOk ? isPaymentOk : formOk);

		if (!isBoleto) {
			//formOk = (formOk ? isCheckAuthorizationDebitOk : formOk);
			//formOk = (formOk ? isBankDebitOk : formOk);
			//formOk = (formOk ? isAgencyDebitOk : formOk);
			//formOk = (formOk ? isAgencyDVDebitOk : formOk);
			//formOk = (formOk ? isAccountNumberDebitOk : formOk);
			//formOk = (formOk ? isAccountNumberDVDebitOk : formOk);
			//formOk = (formOk ? isAccountOwnerNameDebitOk : formOk);
			//formOk = (formOk ? isAccountOwnerCpfCnpjDebitOk : formOk);
			//formOk = (formOk ? isBestDayDebitOk : formOk);
			//formOk = (formOk ? isCheckAuthorizationOwnerOk : formOk);
			formOk = (formOk ? isOwnerCartaoOk : formOk);
			formOk = (formOk ? isCartaoParcelaOk : formOk);
		}

		this.formField.stepStatusPropostaPayment = (formOk ? 'Concluido' : 'Incompleto');

		return formOk;
	}

	hasField(field) {
		return (typeof field !== 'undefined' && field != null && field != '');
	}

	hasErrorParcelCartaoNaoInformado() {
		return this.formField.paymentType == 'CartaoCredito' && (this.formField.parcelaSelecionada == null || this.formField.parcelaSelecionada == '');
	}

	hasErrorOwnerCartao() {
		return this.formField.showDocumentoProprietarioCartao && (this.formField.cpfTitularCartao == null || this.formField.cpfTitularCartao == '' || this.formField.nomeTitularCartao == null || this.formField.nomeTitularCartao == '');
	}

	hasErrorPayment() {
		return this.required.paymentError;
	}

	hasErrorCheckAuthorizationDebit() {
		return this.required.checkAuthorizationDebitError || this.validated.checkAuthorizationDebitError;
	}

	hasErrorBankDebit() {
		return this.required.bankDebitError;
	}

	hasErrorAgencyDebit() {
		return this.required.agencyDebitError || this.validated.agencyDebitError;
	}

	/*
	hasErrorAgencyDVDebit() {
		return this.required.agencyDVDebitError || this.validated.agencyDVDebitError;
	}
	*/

	hasErrorAccountNumberDebit() {
		return this.required.accountNumberDebitError || this.validated.accountNumberDebitError;
	}

	hasErrorAccountNumberDVDebit() {
		return this.required.accountNumberDVDebitError || this.validated.accountNumberDVDebitError;
	}

	hasErrorAccountOwnerNamedDebit() {
		return this.required.accountOwnerNameDebitError;
	}

	hasErrorAccountOwnerCpfCnpjDebit() {
		return this.required.accountOwnerCpfCnpjDebitError;
	}

	hasErrorBestDayDebit() {
		return this.required.bestDayDebitError;
	}

	hasErrorCheckAuthorizationOwnerDebit() {
		return this.required.checkAuthorizationOwnerDebitError || this.validated.checkAuthorizationOwnerDebitError;
	}

	applyError(isValid) {
		return (isValid) ? false : true;
	}

	consultarCrivo() {

		const me = this;

		console.log('entrou na consulta crivo: ', me.formField.cpfTitularCartao);
		console.log('this.accountUtilService: ', this.accountUtilService);

		const cpf = me.formField.cpfTitularCartao;

		if (this.accountUtilService.isValidCpf(cpf)) {
			me.$scope.$emit('changeMainShowLoading', true);

			grecaptcha.ready(function () {
				grecaptcha.execute('6Ld7HnMaAAAAALsutf2GlIhn9Qb-reY-1ir-38uE', { action: 'submit' }).then(function (token) {

					console.log('---- 2. GTOKEN: ', token);

					me.crivoHelper.consultarCrivo(cpf, token, (response) => {

						if (!response.isSuccessful) {
							me.messages.showErrorMessage(me.$scope, me.crivoHelper.remoteCallErrorMessage);
							me.cepHelper.setPristine();
						} else {

							console.log('--> consultaCrivo resultado: ', response)
							console.log('--> data de nascimento: ', new Date(response.req_dataNascimento))
							console.log('--> sexo: ', response.req_sexo);

							me.formField.nomeTitularCartao = response.req_nome.slice(0, 42);

							//me.newAccountObj.personal.name.value = response.req_nome.slice(0, 42);
							//me.newAccountObj.personal.birthDate.value = new Date(response.req_dataNascimento);
							//me.newAccountObj.personal.gender.value = response.req_sexo;

							me.messages.showErrorMessage(me.$scope, '');
						}

						me.$scope.$emit('changeMainShowLoading', false);

					});

				});
			});

		} else {
			me.crivoHelper.setPristine();
			me.formField.nomeTitularCartao = null
			me.messages.showErrorMessage(me.$scope, '');
		}
	}

	searchProprietarioCartaoFocusOut() {

		console.log('focus out!!!');

	}

	searchProprietarioCartaoChanged() {

		console.log('searchProprietarioCartaoChanged!!!');

	}

	selecionarParcela() {

		if (!this.isPropostaDuplaGarantia()) {
			console.log('numeroParcela: ', this.parcelaSelecionada.numeroQtdParcelas);
			console.log('this.parcelasResultado: ', this.parcelasResultado);
			//console.log('this.Id: ', this.Id);

			for (const p of this.parcelasResultado) {
				if (p.numeroQtdParcelas == this.parcelaSelecionada.numeroQtdParcelas) {
					console.log('parcela selecionada encontrada: ', p);
					this.formField.cartaoValorLiquido = p.valorTitulo;
					this.formField.parcelaValor = p.valorParcela;
					this.formField.parcelaSelecionada = p.numeroQtdParcelas;
					this.formField.cartaoValorCobradoTotal = p.valorCobrado;
				}
			}
		} else {

			let valorTitulo = 0;

			if (this.formField.title1 != null) {
				valorTitulo = valorTitulo + (this.formField.title1.titleSQuantity * this.formField.title1.value)
			}

			if (this.formField.title2 != null) {
				valorTitulo = valorTitulo + (this.formField.title1.titleSQuantity * this.formField.title2.value)
			}

			if (this.formField.title3 != null) {
				valorTitulo = valorTitulo + (this.formField.title1.titleSQuantity * this.formField.title3.value)
			}

			console.log('Parcela selecionada: ', this.parcelaSelecionada.numeroParcela);
			console.log('Resultado da parcela: ', this.parcelasResultado);
			//console.log('this.Id: ', this.Id);

			for (const p of this.parcelasResultado) {
				if (p.numeroParcela == this.parcelaSelecionada.numeroParcela) {
					this.formField.cartaoValorLiquido = valorTitulo;
					this.formField.parcelaValor = p.somaValorUnitario;
					this.formField.parcelaSelecionada = p.numeroParcela;
					this.formField.cartaoValorCobradoTotal = p.somaValorTotal;
					this.formField.parcelaSeguroSemJuros = p.detalheParcelaSemJuros.valorParcela;
					this.formField.parcelaCapComJuros = p.detalheParcelaComJuros.valorParcela;
				}
			}
			console.log('Forms atualizado: ', this.formField);
		}

	}

	operarCartaoCreditoEmail() {

		console.log('enviando pagamento de cartao de credito por e-mail');

	}

	operarCartaoCreditoOnline() {

		console.log('processando cartao de crédito online');

		console.log('parcela selecionada: ', this.formField.parcelaSelecionada);
		console.log('parcela valor: ', this.formField.parcelaValor);
		console.log('valor liquido: ', this.formField.cartaoValorLiquido);
		console.log('atributos correntes: ', this.formField);

		this.propostaPaymentService.getURLPagamentoCredito(this.formField).then((response) => {

			console.log('resultado da busca de url: ', response)

		});

		this.propostaId

	}
}

module.exports = propostaPaymentController;
