'use strict';

class revisaoComissaoDetailController {

	// @ngInject

	constructor($stateParams, $state, sfConfig, revisaoComissaoDetailService, $scope, revisaoComissaoFormService, revisaoComissaoService, breadcrumbHelper) {

		this.$stateParams = $stateParams;
		this.$state = $state;
		this.$scope = $scope;
		this.revisaoComissaoDetailService = revisaoComissaoDetailService;
		this.revisaoComissaoFormService = revisaoComissaoFormService;
		this.revisaoComissaoService = revisaoComissaoService;
		this.$scope.breadcrumb = breadcrumbHelper;

		this.showLoading = true;
		this.case = {
			details: null,
			related: {
				propostas: null,
				rentGarantee: null,
				history: null,
				parsedEditCaseData: null
			}
		}

		this.errorMessage = '';
		this.getCaseDetails();
		this.configureBreadCrumb();

		this.revisaoComissaoService.setFinishShow(false);
		this.revisaoComissaoService.setCancelShow(false);

	}

	hasEdit() {

		let hasEdit = false;
		const currentStatus = this.case.details ? this.case.details.status.toLowerCase() : null;

		if (currentStatus === 'em andamento') {

			hasEdit = true;
		}

		if (currentStatus === 'reprovado') {

			hasEdit = true;
		}

		return hasEdit;
	}

	editButtonHandler(status, id) {
		const x = document.createElement('div');
		x.innerHTML = this.case.details.editCaseData;
		this.revisaoComissaoFormService.resetForm();
		const json = JSON.parse(x.innerHTML);
		json.id = this.case.details.id;
		json.status = 'revisaoComissao.new';
		json.information.active = true;
		json.file.active = false;
		json.formStep = 'case.revisao-comissao.new';
		this.revisaoComissaoFormService.setFormField(json);
		const form = this.revisaoComissaoFormService.getFormField();
		this.revisaoComissaoService.setIsEdit(true);
		this.revisaoComissaoService.setSaveDisabled(false);
		this.revisaoComissaoService.setPreviousShow(false);
		this.revisaoComissaoService.setPreviousDisabled(true);
		this.revisaoComissaoService.setNextShow(true);
		this.revisaoComissaoService.setNextDisabled(false);
		this.revisaoComissaoService.getFinishDisabled(true);
		this.revisaoComissaoService.setFinishShow(false);
		this.$state.go('case.revisao-comissao.new');
	}

	configureBreadCrumb() {
		this.$scope.breadcrumb.reset();
		this.$scope.breadcrumb.addMenuItem('Home', '/caponline/secured#!/home', true, false);
		this.$scope.breadcrumb.addMenuItem('Solicitação', '/caponline/secured#!/solicitacoes/lista', true, false);
		this.$scope.breadcrumb.addMenuItem('Detalhes da Revisão de Comissão', '#', false, true);
	}

	getCaseDetails() {

		if (!this.hasValidUrlParams()) {
			this.showLoading = false;
			this.$state.go('case.list');
		}
		this.$scope.$emit('changeMainShowLoading', true);
		this.revisaoComissaoDetailService.getSolicitacaoDetails(this.$stateParams.id).then((response) => {
			this.$scope.$emit('changeMainShowLoading', false);
			console.log(response);
			if (!response.isSuccessful) {
				this.errorMessage = response.errorMessages[0];
			} else {
				this.case.details = response.resgate;
				this.case.related.anexos = response.resgate.anexos;
				this.case.related.history = response.resgate.history;
				this.case.related.parsedEditCaseData = this.parseEditCaseData(this.case.details);
				this.case.related.status = response.resgate.status;
				console.log(this.case);
			}
			this.showLoading = false;
		});

		this.showLoading = false;
	}

	hasValidUrlParams() {
		return (this.$stateParams && this.$stateParams.id) ? true : false;
	}

	parseEditCaseData() {

		// monstruosidade requisitada pelo Diogo
		const div = document.createElement('div');
		div.innerHTML = this.case.details.editCaseData;

		return JSON.parse(div.innerHTML);
	}

}

module.exports = revisaoComissaoDetailController;
