'use strict';

class homeController {

	// @ngInject
	constructor($scope, sfConfig, $stateParams, homeBannerApiService) {

		this.sfConfig = sfConfig;
		this.homeBannerApi = homeBannerApiService;

		this.myInterval = 5000;
		this.noWrapSlides = false;
		this.active = 0;

		this.bannersHome = [];
		this.getHomeBanners();

		this.$scope = $scope;

		this.stateCaseType = $stateParams.stateCaseType || "todos";

		angular.forEach(this.$scope.$parent.main.tabCommunity, (tab) => {
			tab.active = false;
		});

		this.$scope.$parent.main.tabCommunity.home.active = true;

		this.homeIcoProposta = `${sfConfig.resource}/images/ico_fold.png`;
		this.homeIcoGarantia = `${sfConfig.resource}/images/ico_lock.png`;
		this.homeIcoUser = `${sfConfig.resource}/images/ico_user_orange.png`;
	}

	changeTab(tabName) {
		this.$scope.$emit('mainChangeTab', tabName);
	}

	clickCaseList(stateCaseType) {
		this.$state.go('case.list', { stateCaseType });
	}

	getHomeBanners() {

		this.homeBannerApi.getBannerList().then((response) => {

			// this.showLoading = false;

			if (!response.isSuccessful) {

				// vixi
			} else {

				this.bannersHome = response.banners;
			}
		});
	}

}

module.exports = homeController;
