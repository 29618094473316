'use strict';

class accountController {

	// @ngInject
	constructor() {
		this.teste = "asda";
	}

	teste() {
		console.log("teste fprado");
	}

}

module.exports = accountController;
