'use strict';

class administrarUsuarioDetailController {

	// @ngInject
	constructor($scope, $window, $stateParams, $state, administrarUsuarioDetailApi, feedbackMessages, breadcrumbHelper) {

		this.$stateParams = $stateParams;
		this.$state = $state;
		this.$scope = $scope;
		this.$window = $window;
		this.messages = feedbackMessages;
		this.api = administrarUsuarioDetailApi;

		this.userId = this.$stateParams.id;
		this.data = null;
		this.$scope.breadcrumb = breadcrumbHelper;

		this.configureBreadCrumb();

		this.getDetails();
	}

	configureBreadCrumb() {
		this.$scope.breadcrumb.reset();
		this.$scope.breadcrumb.addMenuItem('Home', '/caponline/secured#!/home', true, false);
		this.$scope.breadcrumb.addMenuItem('Administrar usuário', '/caponline/secured#!/administrar-usuario/lista', true, false);
		this.$scope.breadcrumb.addMenuItem('Detalhes do Usuário', '#', false, true);
	}

	hasId() {

		return !!this.userId;
	}

	editUser(userId) {

		this.$state.go('administrar-usuario.edit', { id: userId });
	}

	getDetails() {
		if (!this.hasId()) {

			this.$state.go('administrar-usuario.list');
		}

		this.$scope.$emit('changeMainShowLoading', true);

		this.api.getDetails(this.userId).then((response) => {
			console.log('getDetails', response);

			this.$scope.$emit('changeMainShowLoading', false);

			if (!response.isSuccessful) {

				this.$window.scrollTo(0, 0);

				const errorMessage = angular.toJson(response.errorMessages);

				this.messages.showErrorMessage(this.$scope, errorMessage);
			} else {

				this.data = response.details;
			}
		});
	}
}

module.exports = administrarUsuarioDetailController;
