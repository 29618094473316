'use strict';

const sobjectQueryService = require('./sobject-query/sobject-query.service');
const checkTermService = require('./check-term/check-term.service');

const helperModule = require('./helper/component.module');
const profileModule = require('./profile/component.module');

const coreModule = angular.module('cap.secured.core', [
		'ui.bootstrap',
		'ui.mask',
		'angucomplete-alt',
		profileModule.name,
		helperModule.name
	])
	.service('sobjectQueryService', sobjectQueryService)
	.service('checkTerm', checkTermService);

module.exports = coreModule;
