'use strict';

class alteracaoDadosDetailController {

	// @ngInject
	constructor($stateParams, $state, sfConfig, alteracaoDadosDetailApi, alteracaoDadosFormControlService, feedbackMessages, $scope, breadcrumbHelper) {
		this.alteracaoDadosDetailApi = alteracaoDadosDetailApi;
		this.messages = feedbackMessages;
		this.formField = alteracaoDadosFormControlService;
		this.api = alteracaoDadosDetailApi;
		this.$stateParams = $stateParams;
		this.$state = $state;
		this.$scope = $scope;
		this.showLoading = true;
		this.case = {
			details: null,
			related: {
				propostas: null,
				rentGarantee: null,
				contacts: null,
				attachments: null,
				history: null
			}
		}
		this.tipoAlteracaoMap = {
			'Mudança de Imóvel / Locador': 'mudancaImovel',
			'Mudança de Locador': 'mudancaLocador',
			'Alteração de Dados do Cliente': 'mudancaCliente',
			'Inclusão da Caução': 'inclusaoCaucao'
		}

		this.errorMessage = '';
		this.getCaseDetails();
		this.$scope.breadcrumb = breadcrumbHelper;
		this.configureBreadCrumb();
		//this.alteracaoDadosService.setWarningMessage('');
	}

	configureBreadCrumb() {
		this.$scope.breadcrumb.reset();
		this.$scope.breadcrumb.addMenuItem('Home', '/caponline/secured#!/home', true, false);
		this.$scope.breadcrumb.addMenuItem('Solicitação', '/caponline/secured#!/solicitacoes/lista', true, false);
		this.$scope.breadcrumb.addMenuItem('Detalhes da Alteração de dados', '#', false, true);
	}

	hasValidUrlParams() {
		return (this.$stateParams && this.$stateParams.id) ? true : false;
	}

	getCaseDetails() {
		if (!this.hasValidUrlParams()) {
			this.showLoading = false;
			this.$state.go('case.list');
		}
		this.$scope.$emit('changeMainShowLoading', true);
		this.api.getDetails(this.$stateParams.id).then((response) => {
			this.$scope.$emit('changeMainShowLoading', false);
			// console.log(response);
			if (!response.isSuccessful) {
				this.errorMessage = response.errorMessages[0];
			} else {
				this.case.details = response.data;
				this.case.related.propostas = response.data.propostas;
				this.case.related.rentGarantee = response.data.titulos;
				this.case.related.attachments = response.data.anexos;
				this.case.related.history = response.data.history;
				this.case.editCaseData = this.case.editCaseData = this.decodeAndParseJson(response.data.editCaseData);
				this.case.editCaseData.id = this.$stateParams.id;
				this.case.editCaseData.status = response.data.status;

				console.log('RESPONSE EDITCASE DATA ==>', response.data.editCaseData);
				console.log("GET DETAIL CASE: ", this.case);
			}
			this.showLoading = false;
		});
	}

	editButtonHandler() {
		console.log('editButtonHandler()', this.case.editCaseData);

		this.case.editCaseData.data.alteracaoDadosCliente.fields.pf.dataNascimento.value = new Date(this.case.editCaseData.data.alteracaoDadosCliente.fields.pf.dataNascimento.value)
		this.case.editCaseData.data.alteracaoDadosCliente.fields.pf.dataExpedicao.value = new Date(this.case.editCaseData.data.alteracaoDadosCliente.fields.pf.dataExpedicao.value)

		this.formField.setPristine();

		this.formField.status = this.case.editCaseData.status;
		this.formField.formStep = this.case.editCaseData.formStep;
		this.formField.id = this.case.editCaseData.id;
		//angular.merge(this.formField.annex, this.formField.annex, this.case.editCaseData.annex);
		this.formField.annex = this.case.editCaseData.annex;
		angular.merge(this.formField.information, this.formField.information, this.case.editCaseData.information);
		angular.merge(this.formField.data, this.formField.data, this.case.editCaseData.data);
		this.formField.edit = true;

		this.formField.activateTab('information');
		this.$state.go('case.alteracao-dados.new');
	}

	decodeAndParseJson(json) {
		// console.log('decodeAndParseJson():', json);

		const x = document.createElement('div');

		x.innerHTML = json;

		return JSON.parse(x.innerHTML);
	}

	openCorrectForm() {
		const formId = this.case.editCaseData.id;

		switch (this.case.details.tipoAlteracao) {
		case 'Mudança de Imóvel / Locador':
			window.open(`apex/CapOnlineCaseLiberacaoSemPgtoPDFPage?id=${formId}`, '_blank');
			window.open(`apex/CapOnlineCaseCartaCaucaoPDFPage?id=${formId}`, '_blank');
			break;
		case 'Mudança de Locador':
			window.open(`apex/CapOnlineCaseMudancaLocadorPDFPage?id=${formId}`, '_blank');
			//window.open(`apex/CapOnlineCaseCartaCaucaoPDFPage?id=${formId}`, '_blank');
			break;
		case 'Alteração de Dados do Cliente':
			break;
		case 'Inclusão da Caução':
			window.open(`apex/CapOnlineCaseCartaCaucaoPDFPage?id=${formId}`, '_blank');
			break;
		}
	}

}

module.exports = alteracaoDadosDetailController;
