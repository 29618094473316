'use strict';

const contactUsController = require('./fale-conosco.controller');
const contactUsApi = require('./fale-conosco-api.service');

const routesConfiguration = require('./fale-conosco.routes');

const contactUsModule = angular.module('cap.secured.contactUs', [])

	.controller('contactUsController', contactUsController)
	.service('contactUsApi', contactUsApi)
	.config(routesConfiguration);

module.exports = contactUsModule;
