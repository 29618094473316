"use strict";

class inclusaoCaucaoApi {
	// @ngInject
	constructor($q, visualForceApi) {
		this.$q = $q;
		this.visualForceApi = visualForceApi;
	}

	locadorSearchAccount(searchKeyWord) {
		const deferred = this.$q.defer();

		const searchModel = {
			dataFetch: {
				searchKeyWord,
				searchTypeObject: "Account"
			}
		};

		const cap_access_token = localStorage.getItem("cap_access_token");
		searchModel.accessToken = cap_access_token;

		this.visualForceApi
			.invoke("CapCommunityPropostaControllerExt.solFetchRecords", searchModel)
			.then((data) => {
				//console.log(data.obtainedRecords);
				if (!data.obtainedRecords) data.obtainedRecords = [];
				if (data.obtainedRecords.length === 0) {
					data.obtainedRecords.push({
						action: "searchAccountFocusOut",
						Name: "Nenhum cadastro encontrado"
					});
				}
				data.obtainedRecords.push({
					action: "locadorModalHandle",
					Name: "+ Criar Cadastro"
				});
				deferred.resolve(data.obtainedRecords);
			});

		return deferred.promise;
	}

	administradorImovelSearchAccount(searchKeyWord) {
		const deferred = this.$q.defer();

		const searchModel = {
			dataFetch: {
				searchKeyWord,
				searchTypeObject: "Account"
			}
		};

		const cap_access_token = localStorage.getItem("cap_access_token");
		searchModel.accessToken = cap_access_token;

		this.visualForceApi
			.invoke("CapCommunityPropostaControllerExt.solFetchRecords", searchModel)
			.then((data) => {
				//console.log(data.obtainedRecords);
				if (!data.obtainedRecords) data.obtainedRecords = [];
				if (data.obtainedRecords.length === 0) {
					data.obtainedRecords.push({
						action: "searchAccountFocusOut",
						Name: "Nenhum cadastro encontrado"
					});
				}
				data.obtainedRecords.push({
					action: "administradorImovelModalHandle",
					Name: "+ Criar Cadastro"
				});
				deferred.resolve(data.obtainedRecords);
			});

		return deferred.promise;
	}
}

module.exports = inclusaoCaucaoApi;
