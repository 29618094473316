'use strict';

class administrarUsuarioNewController {

	// @ngInject
	constructor($scope, $window, $state, $timeout, $uibModal, administrarUsuarioNewApi, validationFormFields, feedbackMessages, breadcrumbHelper, autoCompleteService) {
		this.$scope = $scope;
		this.$window = $window;
		this.$state = $state;
		this.api = administrarUsuarioNewApi;
		this.valid = validationFormFields;
		this.messages = feedbackMessages;
		this.autoComplete = autoCompleteService;
		this.$scope.breadcrumb = breadcrumbHelper;
		this.$timeout = $timeout;
		this.$uibModal = $uibModal;

		this.setUserForm();
		this.configureBreadCrumb()
	}

	configureBreadCrumb() {
		this.$scope.breadcrumb.reset();
		this.$scope.breadcrumb.addMenuItem('Home', '/caponline/secured#!/home', true, false);
		this.$scope.breadcrumb.addMenuItem('Administrar usuário', '/caponline/secured#!/administrar-usuario/lista', true, false);
		this.$scope.breadcrumb.addMenuItem('Novo usuário', '#', false, true);
	}

	setUserForm() {
		this.user = {
			name: null,
			email: null,
			cpf: null,
			login: null,
			userType: null,
			visaoCarteira: 'Geral',
			active: true,
			PDV: null,
			permissions: this.api.initUserPermissions()
		};
	}

	changeVisao() {
		if (this.isMaster()) {

		}
	}

	setPermissionValue(nome, value) {
		if (!this.disablePermissionamento()) this.user.permissions[nome] = value;
	}

	changeUserType() {
		if ((this.user.userType !== null) && (this.user.userType.startsWith('Master')))
			this.user.permissions = this.api.initUserPermissions();
		this.user.PDV = null;
	}

	disablePermissionamento() {
		return ((this.user.userType === null) || (this.user.userType.startsWith('Master')));
	}

	isMaster() {
		return this.user.userType.startsWith('Master');
	}

	isImobiliaria() {
		return this.user.userType == 'Imobiliária';
	}

	submit(form) {
		this.messages.cleanMessages(this.$scope);

		form.$setSubmitted();

		if (form.$invalid) {
			return;
		}

		this.$scope.$emit('changeMainShowLoading', true);
		this.api.sendForm(this.user).then((response) => {
			this.$scope.$emit('changeMainShowLoading', false);
			this.$window.scrollTo(0, 0);
			if (!response.isSuccessful) {
				if (response.errorMessages) {
					if (response.errorMessages.length > 0) {
						const errorMessage = response.errorMessages[0];
						this.messages.showErrorMessage(this.$scope, errorMessage);
					}
				}
			} else {
				const successMessage = 'Usuário Salvo com sucesso!';
				this.messages.showSuccessMessage(this.$scope, successMessage);
				this.$state.go('administrar-usuario.detail', { id: response.userId });
				//REDIRECIONAMENTO PARA TELA DE DETALHES DO USUÁRIO, ARRUMAR POIS AGORA É RETORNARDO SOMENTE O CONTACT ID
			}
		});
	}

	cancel() {
		this.$state.go('administrar-usuario.list');
	}

	searchAccount(searchString) {
		console.log('entrou no searchAccount');
		const administrarUsuarioNew = this.$parent.administrarUsuarioNew;
		return administrarUsuarioNew.api.searchAccount(searchString);
	}

	searchAccountSelect(item) {
		console.log('entrou no searchAccountSelect');
		const administrarUsuarioNew = this.$parent.administrarUsuarioNew;
		if (administrarUsuarioNew.user.PDV === null) {
			if (!item) { return; }

			const hasAction = !!item.originalObject.action;

			if (hasAction) {

				const action = item.originalObject.action;

				administrarUsuarioNew[action](item);

				return;
			}

			administrarUsuarioNew.user.PDV = item.originalObject;
			//administrarUsuarioNew.user.hasError = !administrarUsuarioNew.user.PDV;
			console.log('PDV SELECTED: ', administrarUsuarioNew.user.PDV);

		}
	}

	searchAccountChanged() {
		const administrarUsuarioNew = this.$parent.administrarUsuarioNew;
		console.log('entrou no searchAccountChanged');
		//administrarUsuarioNew.this.messages.cleanMessages(mudancaLocador.$scope);
		administrarUsuarioNew.user.PDV = null;
		console.log('reset');
		//resetar todos os dados	
	}

	searchAccountFocusOut() {

		this.$timeout(() => {
			// console.log('timeout FocusOut', this.formField.account);

			if (!this.autoComplete.hasValidInput(this.user.PDV)) {

				this.autoComplete.clearInput(this.$scope, 'searchAccount');
			}
		}, 100);
	}

	modalHandle() {
		this.searchAccountFocusOut();
		const parentElem = angular.element(document.querySelector('.col-lg-4'));
		this.$uibModal.open({
			template: '<div class="modal-content acc"><div class="modal-body"><h4 class="modal-title text-center">Que tipo de cadastro quer criar?</h4><div class="col-lg-6"><a class="box-modal pull-right" href="" ui-sref="new-account" ng-click="modalController.setFromMudancaLocador(true); modalController.closeModal()"><img class="ico-type" src="{{modalController.imagePFAccountPath}}"/><p>Pessoa Física</p></a></div><div class="col-lg-6"><a class="box-modal pull-left" href="" ui-sref="new-juridical-account" ng-click="modalController.setFromMudancaLocador(true); modalController.closeModal()"><img class="ico-type" src="{{modalController.imagePJAccountPath}}"/><p>Pessoa Jurídica</p></a></div></div><div class="modal-footer"><button class="btn btn-link cancel" type="button" ng-click="modalController.closeModal()">Cancelar</button></div></div>',
			controller: 'accountTypeModalController',
			controllerAs: 'modalController',
			appendTo: parentElem
		});
	}

}

module.exports = administrarUsuarioNewController;
