'use strict';

class documentacaoPropostaNewApi {

	/* @ngInject */
	constructor(visualForceApi) {

		this.visualForceApi = visualForceApi;
	}

}

module.exports = documentacaoPropostaNewApi;
