"use strict";

class ResgateTypeService {
	/* @ngInject */
	constructor(visualForceApi, $sce, $q) {
		this.visualForceApi = visualForceApi;
		this.$q = $q;
	}

	searchProposta(searchKeyWord, accountId, selfId) {
		console.log(selfId);
		const deferred = this.$q.defer();

		const searchModel = {
			dataFetch: {
				searchKeyWord,
				searchTypeObject: "PROPOSTA__c",
				searchIdHelper: accountId,
				selfId
			}
		};

		const cap_access_token = localStorage.getItem("cap_access_token");
		searchModel.accessToken = cap_access_token;

		this.visualForceApi
			.invoke("CapCommunityPropostaControllerExt.solFetchRecords", searchModel)
			.then((data) => {
				//console.log(data.obtainedRecords);
				if (!data.obtainedRecords) data.obtainedRecords = [];
				if (data.obtainedRecords.length === 0) {
					data.obtainedRecords.push({
						action: "searchPropostaFocusOut",
						Name: "Nenhuma proposta encontrada"
					});
				}
				deferred.resolve(data.obtainedRecords);
			});

		return deferred.promise;
	}
}

module.exports = ResgateTypeService;
