'use strict';

class rentGuaranteeController {

	// @ngInject
	constructor() {
		this.teste = "asda";
	}

}

module.exports = rentGuaranteeController;
