'use strict';

class ResgateFormService {

	/* @ngInject */
	constructor() {

		this.setPristine();
	}

	setPristine() {
		this.formField = {
			id: null,
			status: null,
			decisionType: null,
			liberarCaucao: false,
			isHaveProblemBank: false,
			tabResgateInfo: '',
			tabResgateType: '',
			tabResgateData: '',
			tabResgateBank: '',
			tabResgateAttach: '',
			valorResgate: null,
			information: {
				active: true,
				mainAccount: {
					value: null,
					hasError: false,
					loading: false
				},
				proposta: {
					value: null,
					hasError: false,
					loading: false
				},
				legalRepresentative: {
					value: null,
					show: false
				},
				garantias: {
					value: null,
					hasError: false
				}
			},
			type: {
				active: false,
				caucionado: false,
				vigencia: false,
				realtyDebits: {
					value: null,
					hasError: false,
					show: false
				},
				debitValue: {
					value: null,
					hasError: false,
					isValid: true,
					show: false
				},
				hasRenterApprove: {
					value: null,
					hasError: false,
					show: false
				},
				titleAquisition: {
					value: null,
					hasError: false,
					show: false
				},
				proposta: {
					value: null,
					hasError: false,
					show: false
				},
				resgateType: {
					value: null,
					hasError: false,
					show: false
				},
				customerAgreement: {
					value: null,
					hasError: false,
					show: false
				},
				resgateFinalMessage: {
					value: null,
					show: false
				}
			},
			data: {
				active: false,
				resgateValue: {
					value: null,
					hasError: false,
					show: false,
					disabled: true
				},
				debitValue: {
					value: null,
					hasError: false,
					show: false,
					disabled: false
				},
				propostaValue: {
					value: null,
					hasError: false,
					show: false,
					disabled: false
				},
				valueRemain: {
					value: null,
					hasError: false,
					show: false,
					disabled: false
				},
				realtyInformation: {
					cep: null,
					address: null,
					addressNumber: null,
					addressComplement: null,
					neighborhood: null,
					city: null,
					uf: null,
					renterName: null,
					contractDate: {
						value: null,
						hasError: false
					}
				},
				representantData: {
					name: {
						value: null,
						hasError: false
					},
					cpfcnpj: {
						value: null,
						hasError: false,
						isValid: true
					},
					observations: null,
					show: false
				},
				debitDeclaration: {
					show: false,
					debitValue: {
						value: null,
						hasError: false
					},
					multaValue: {
						value: null,
						hasError: false
					},
					othersValue: {
						value: null,
						hasError: false
					},
					description: {
						value: null,
						hasError: false
					},
					vencimento: {
						value: null,
						hasError: false
					},
					competencia: {
						value: null,
						hasError: false
					},
					debitsSelected: {
						value: [],
						show: false
					},
					totalValue: {
						value: 0,
						hasError: false
					},
					totalMulta: 0,
					totalAcrescimo: 0
				},
				customerData: {
					name: {
						value: null,
						hasError: false
					},
					cpfcnpj: {
						value: null,
						isValid: true,
						hasError: false
					},
					inscription: null,
					emitter: null,
					professionalActivity: {
						value: null,
						hasError: false
					},
					income: {
						value: null,
						hasError: false
					},
					phone: {
						value: null,
						hasError: false
					},
					email: {
						value: null,
						hasError: false,
						isValid: true
					},
					observations: null,
					show: false
				}
			},
			bank: {
				active: false,
				bankData: {
					show: false,
					customerOwner: {
						value: null,
						hasError: false,
						show: true
					},
					accountType: {
						value: null,
						hasError: false,
						show: true
					},
					bank: {
						value: null,
						hasError: false,
						show: true
					},
					bankEmail: {
						value: null,
						hasError: false,
						show: true
					},
					debitEmail: {
						value: null,
						hasError: false,
						show: true
					},
					agency: {
						value: null,
						hasError: false,
						hasAgencyPeopleError: false,
						show: true
					},
					agencyDV: {
						value: null,
						hasError: false,
						hasAgencyDVPeopleError: false,
						show: true
					},
					accountNumber: {
						value: null,
						hasError: false,
						hasAccountNumberPeopleError: false,
						show: true
					},
					accountNumberDV: {
						value: null,
						hasError: false,
						hasAccountNumberDVPeopleError: false,
						show: true
					},
					accountOwnerName: {
						value: null,
						hasError: false,
						show: true
					},
					cpfcnpj: {
						value: null,
						hasError: false,
						isValid: true,
						show: true
					},
					professionalActivity: {
						value: null,
						hasError: false,
						show: false
					},
					income: {
						value: null,
						hasError: false,
						show: false
					},
					phone: null
				},
				bankBills: {
					show: false,
					accountType: {
						value: null,
						hasError: false,
						show: false
					},
					bank: {
						value: null,
						hasError: false,
						show: false
					},
					debitEmail: {
						value: null,
						hasError: false,
						show: false,
						isValid: true
					},
					agency: {
						value: null,
						hasError: false,
						show: false
					},
					agencyDV: {
						value: null,
						hasError: false,
						show: false
					},
					accountNumber: {
						value: null,
						hasError: false,
						show: false
					},
					accountNumberDV: {
						value: null,
						hasError: false,
						show: false
					},
					accountOwnerName: {
						value: null,
						hasError: false,
						show: false
					},
					cpfcnpj: {
						value: null,
						hasError: false,
						isValid: true,
						show: false
					},
					professionalActivity: {
						value: null,
						hasError: false,
						show: false
					},
					income: {
						value: null,
						hasError: false,
						show: false
					},
					phone: null
				}
			},
			file: {
				active: false,
				form: this.pristineAnnexObj(),
				legalRepresentative: {
					show: false,
					document: this.pristineAnnexObj(),
					identityRepresentante: this.pristineAnnexObj(),
					cnpj: this.pristineAnnexObj(),
					constituitionDocumentation: this.pristineAnnexObj(),
					identityAdmin: this.pristineAnnexObj(),
					identityRenter: this.pristineAnnexObj(),
					other: this.pristineAnnexObj()
				},
				subscritor: {
					show: false,
					identity: this.pristineAnnexObj(),
					identityAdmin: this.pristineAnnexObj(),
					cnpj: this.pristineAnnexObj(),
					constituitionDocumentation: this.pristineAnnexObj(),
					cpf: this.pristineAnnexObj(),
					proofAddress: this.pristineAnnexObj(),
					other: this.pristineAnnexObj()
				},
				beneficiario: {
					show: false,
					identity: this.pristineAnnexObj(),
					identityAdmin: this.pristineAnnexObj(),
					cpf: this.pristineAnnexObj(),
					proofAddress: this.pristineAnnexObj(),
					cnpj: this.pristineAnnexObj(),
					constituitionDocumentation: this.pristineAnnexObj(),
					other: this.pristineAnnexObj()
				}
			},
			lastFormAction: '',
			popupStategoControl: {
				origem: '',
				destino: ''
			},
			origin: {
				app: null,
				capOnline: null
			}
		}

		this.resetFormFileTypes();
	}

	resetPopupStateGoControl() {
		console.log('##############');

		this.formField.popupStategoControl = {
			origem: '',
			destino: ''
		};

		console.log(this.formField);
		console.log('##############');
	}

	resetForm() {
		this.setPristine();
		console.log('FormReseted', this.formField);
	}

	resetFormType() {
		this.formField.liberarCaucao = false;
		this.formField.type = {
			active: false,
			caucionado: false,
			vigencia: false,
			realtyDebits: {
				value: null,
				hasError: false,
				show: false
			},
			debitValue: {
				value: null,
				hasError: false,
				isValid: true,
				show: false
			},
			hasRenterApprove: {
				value: null,
				hasError: false,
				show: false
			},
			titleAquisition: {
				value: null,
				hasError: false,
				show: false
			},
			proposta: {
				value: null,
				hasError: false,
				show: false
			},
			resgateType: {
				value: null,
				hasError: false,
				show: false
			},
			customerAgreement: {
				value: null,
				hasError: false,
				show: false
			},
			resgateFinalMessage: {
				value: null,
				show: false
			}
		}
	}

	resetFormData() {
		this.setTabResgateData("");
		this.formField.data = {
			active: false,
			resgateValue: {
				value: null,
				hasError: false,
				show: false,
				disabled: true
			},
			debitValue: {
				value: null,
				hasError: false,
				show: false,
				disabled: false
			},
			propostaValue: {
				value: null,
				hasError: false,
				show: false,
				disabled: false
			},
			valueRemain: {
				value: null,
				hasError: false,
				show: false,
				disabled: false
			},
			realtyInformation: {
				cep: null,
				address: null,
				addressNumber: null,
				addressComplement: null,
				neighborhood: null,
				city: null,
				uf: null,
				renterName: null,
				contractDate: {
					value: null,
					hasError: false
				}
			},
			representantData: {
				name: {
					value: null,
					hasError: false
				},
				cpfcnpj: {
					value: null,
					hasError: false,
					isValid: true
				},
				observations: null,
				show: false
			},
			debitDeclaration: {
				show: false,
				debitValue: {
					value: null,
					hasError: false
				},
				multaValue: {
					value: null,
					hasError: false
				},
				othersValue: {
					value: null,
					hasError: false
				},
				description: {
					value: null,
					hasError: false
				},
				vencimento: {
					value: null,
					hasError: false
				},
				competencia: {
					value: null,
					hasError: false
				},
				debitsSelected: {
					value: [],
					show: false
				},
				totalValue: {
					value: 0,
					hasError: false
				},
				totalMulta: 0,
				totalAcrescimo: 0
			},
			customerData: {
				name: {
					value: null,
					hasError: false
				},
				cpfcnpj: {
					value: null,
					hasError: false,
					isValid: true
				},
				inscription: null,
				emitter: null,
				professionalActivity: {
					value: null,
					hasError: false
				},
				income: {
					value: null,
					hasError: false
				},
				phone: {
					value: null,
					hasError: false
				},
				email: {
					value: null,
					hasError: false,
					isValid: true
				},
				observations: null,
				show: false
			}
		}
	}

	resetFormBank() {
		this.setTabResgateBank("");
		this.formField.bank = {
			active: false,
			bankData: {
				show: false,
				customerOwner: {
					value: null,
					hasError: false,
					show: true
				},
				accountType: {
					value: null,
					hasError: false,
					show: true
				},
				bank: {
					value: null,
					hasError: false,
					show: true
				},
				agency: {
					value: null,
					hasError: false,
					show: true
				},
				agencyDV: {
					value: null,
					hasError: false,
					show: true
				},
				accountNumber: {
					value: null,
					hasError: false,
					show: true
				},
				accountNumberDV: {
					value: null,
					hasError: false,
					show: true
				},
				accountOwnerName: {
					value: null,
					hasError: false,
					show: true
				},
				cpfcnpj: {
					value: null,
					hasError: false,
					isValid: true,
					show: true
				},
				professionalActivity: {
					value: null,
					hasError: false,
					show: false
				},
				income: {
					value: null,
					hasError: false,
					show: false
				},
				phone: null
			},
			bankBills: {
				show: false,
				accountType: {
					value: null,
					hasError: false,
					show: false
				},
				bank: {
					value: null,
					hasError: false,
					show: false
				},
				debitEmail: {
					value: null,
					hasError: false,
					show: true,
					isValid: true
				},
				agency: {
					value: null,
					hasError: false,
					show: false
				},
				agencyDV: {
					value: null,
					hasError: false,
					show: false
				},
				accountNumber: {
					value: null,
					hasError: false,
					show: false
				},
				accountNumberDV: {
					value: null,
					hasError: false,
					show: false
				},
				accountOwnerName: {
					value: null,
					hasError: false,
					show: false
				},
				cpfcnpj: {
					value: null,
					hasError: false,
					isValid: true,
					show: false
				},
				professionalActivity: {
					value: null,
					hasError: false,
					show: false
				},
				income: {
					value: null,
					hasError: false,
					show: false
				},
				phone: null
			}
		}
	}

	resetFormFile() {
		this.setTabResgateAttach("");
		this.formField.file = {
			active: false,
			form: this.pristineAnnexObj(),
			legalRepresentative: {
				show: false,
				document: this.pristineAnnexObj(),
				identityRepresentante: this.pristineAnnexObj(),
				cnpj: this.pristineAnnexObj(),
				constituitionDocumentation: this.pristineAnnexObj(),
				identityAdmin: this.pristineAnnexObj(),
				identityRenter: this.pristineAnnexObj(),
				other: this.pristineAnnexObj()
			},
			subscritor: {
				show: false,
				identity: this.pristineAnnexObj(),
				identityAdmin: this.pristineAnnexObj(),
				cnpj: this.pristineAnnexObj(),
				constituitionDocumentation: this.pristineAnnexObj(),
				cpf: this.pristineAnnexObj(),
				proofAddress: this.pristineAnnexObj(),
				other: this.pristineAnnexObj()
			},
			beneficiario: {
				show: false,
				identity: this.pristineAnnexObj(),
				identityAdmin: this.pristineAnnexObj(),
				cpf: this.pristineAnnexObj(),
				proofAddress: this.pristineAnnexObj(),
				cnpj: this.pristineAnnexObj(),
				constituitionDocumentation: this.pristineAnnexObj(),
				other: this.pristineAnnexObj()
			}
		}

		this.resetFormFileTypes();
	}

	// péssimo, mas prazo ta apertado
	resetFormFileTypes() {

		this.formField.file.form.type = 'form';

		this.formField.file.legalRepresentative.document.type = 'legal-document';
		this.formField.file.legalRepresentative.identityRepresentante.type = 'legal-identity';
		this.formField.file.legalRepresentative.identityRenter.type = 'legal-identity-renter';
		this.formField.file.legalRepresentative.other.type = 'legal-other';

		this.formField.file.subscritor.identity.type = 'subscritor-identity';
		this.formField.file.subscritor.identityAdmin.type = 'subscritor-identity-admin';
		this.formField.file.subscritor.cnpj.type = 'subscritor-cnpj';
		this.formField.file.subscritor.constituitionDocumentation.type = 'subscritor-constituition';
		this.formField.file.subscritor.cpf.type = 'subscritor-cpf';
		this.formField.file.subscritor.proofAddress.type = 'subscritor-proof';
		this.formField.file.subscritor.other.type = 'subscritor-other';

		this.formField.file.beneficiario.identity.type = 'beneficiario-identity';
		this.formField.file.beneficiario.identityAdmin.type = 'beneficiario-identity-admin';
		this.formField.file.beneficiario.cpf.type = 'beneficiario-cpf';
		this.formField.file.beneficiario.proofAddress.type = 'beneficiario-proof';
		this.formField.file.beneficiario.cnpj.type = 'beneficiario-cnpj';
		this.formField.file.beneficiario.constituitionDocumentation.type = 'beneficiario-constituition';
		this.formField.file.beneficiario.other.type = 'beneficiario-other';
	}

	feedNewData(newData) {
		this.setPristine();
		this.mergeData(newData);
	}

	mergeData(data) {
		angular.extend(this.formField, data);
	}

	setFormField(json) {
		console.log('setForm ', json);
		this.formField = json;
	}

	getFormField() {
		return this.formField;
	}

	setTabResgateInfo(tab) {
		this.formField.tabResgateInfo = tab;
	}

	setTabResgateType(tab) {
		this.formField.tabResgateType = tab;
	}

	setTabResgateData(tab) {
		this.formField.tabResgateData = tab;
	}

	setTabResgateBank(tab) {
		this.formField.tabResgateBank = tab;
	}

	setTabResgateAttach(tab) {
		this.formField.tabResgateAttach = tab;
	}

	pristineAnnexObj() {

		return {
			files: [],
			hasError: false,
			loading: false,
			show: false,
			uploaded: false,
			uploadHasFailure: false
		};
	}

	pristineFormObj() {
		return {
			value: null,
			hasError: false,
			show: false
		};
	}

	setPopupStategoControl(paOrigem, paDestino) {
		this.formField.popupStategoControl = {
			origem: paOrigem,
			destino: paDestino
		};
	}
}

module.exports = ResgateFormService;
