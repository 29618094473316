'use strict';

class accountDetailService {

	/* @ngInject */
	constructor(visualForceApi) {
		this.visualForceApi = visualForceApi;
	}

	getAccountDetails(accountId) {

		const cap_access_token = localStorage.getItem("cap_access_token");

		return this.visualForceApi.invoke(
			'CapCommunityAccountControllerExt.getAccountDetails', accountId, cap_access_token);
	}
}

module.exports = accountDetailService;
