'use strict';

class liberacaoDaCaucaoNewController {

	// @ngInject
	constructor($scope, $window, $state, $stateParams, $timeout, feedbackMessages, liberacaoDaCaucaoFormControlService, liberacaoDaCaucaoNewApi, autoCompleteService, sobjectQueryService, liberacaoDaCaucaoService) {
		console.log('liberacaoDaCaucaoNewController');

		this.$scope = $scope;
		this.$window = $window;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.$timeout = $timeout;
		this.messages = feedbackMessages;
		this.api = liberacaoDaCaucaoNewApi;
		this.formField = liberacaoDaCaucaoFormControlService;
		this.autoComplete = autoCompleteService;
		this.sobjectQueryService = sobjectQueryService;
		this.liberacaoDaCaucaoService = liberacaoDaCaucaoService;

		// Quando vem do resgate recebe o form field do resgate via stateParam
		if ($stateParams.formField === null) {
			this.formField.formStep = 'case.liberacao-da-caucao.new';
			this.formField.information.active = true;
		} else {
			this.formField.edit = true;
			this.formField.information = $stateParams.formField.information;
			this.formField.information.legalRepresentative.value = (this.formField.information.legalRepresentative.value === "true");
			this.formField.information.status = 'Concluido';
			this.formField.information.active = false;
			this.formField.annex.active = true;
			this.liberacaoDaCaucaoService.setNextShow(false);
			this.liberacaoDaCaucaoService.setFinishShow(true);
			this.liberacaoDaCaucaoService.setPreviousDisabled(true);
			this.liberacaoDaCaucaoService.setPreviousShow(false);
			this.$state.go('case.liberacao-da-caucao.annex');
		}

		this.formField.status = this.formField.status || 'Em andamento';
		this.checkStepStatus();
		this.isEdit();
		this.checkInicialState();
		this.$scope.breadcrumb = this.$scope.$parent.breadcrumb;
		this.configureBreadCrumb();
		this.listenPopupDocumentation();
		this.liberacaoDaCaucaoService.cleanMessages();

		this.formField.popupStategoControl = {
			origem: '',
			destino: ''
		};

		// setInterval(() => { console.log('liberacaoDaCaucaoNewController', this.formField.information); }, 20000);
	}

	checkInicialState() {
		if (!this.formField.information.proposta.value) {
			if (!!this.formField.information.mainAccount.value) {
				this.getPropostasByAccountId(this.formField.information.mainAccount.value.Id);
			}
		}

		if (!!this.formField.information.mainAccount.value) {
			this.liberacaoDaCaucaoService.setSaveShow(true);
			this.liberacaoDaCaucaoService.setSaveDisabled(false);
			this.liberacaoDaCaucaoService.setNextShow(true);
			this.liberacaoDaCaucaoService.setNextDisabled(false);
		}
	}
	listenPopupDocumentation() {
		this.$scope.$on('clickPopupDocumentation', (event, value) => {
			if (value === "S") {
				this.formField.setPopupStategoControl('case.liberacao-da-caucao.new', 'case.documentacao-proposta.detail');
				this.$state.go('case.documentacao-proposta.detail', { id: this.formField.caseDocumentId });
			}
			/*else {
				this.formField.information.proposta.value = null;
				this.changePropostaField();
			}*/
			$('#alertDocumentacaoAnexosModal').modal('hide');
		});
	}

	configureBreadCrumb() {
		this.$scope.breadcrumb.reset();
		this.$scope.breadcrumb.addMenuItem('Home', '/caponline/secured#!/home', true, false);
		this.$scope.breadcrumb.addMenuItem('Solicitação', '/caponline/secured#!/solicitacoes/lista', true, false);
		this.$scope.breadcrumb.addMenuItem('Nova Liberação da Garantia', '/caponline/secured#!/solicitacoes/liberacao-da-caucao/nova', false, true);
	}

	checkStepStatus() {
		console.log('checkStepStatus:', this.formField.information.status);

		if (this.formField.information.status !== 'Concluido') {

			this.formField.information.status = 'Incompleto';

			console.log('checkStepStatus:', this.formField.information.status);
		}
	}

	isEdit() {
		console.log('isEdit()');

		if (this.formField.edit) {

			this.formField.information.mainAccount.hasError = !this.hasAccount();

			this.liberacaoDaCaucaoService.setSaveShow(true);
			this.liberacaoDaCaucaoService.setSaveDisabled(false);
			this.liberacaoDaCaucaoService.setCancelShow(true);
			this.liberacaoDaCaucaoService.setCancelDisabled(false);
			this.liberacaoDaCaucaoService.setNextShow(true);
			this.liberacaoDaCaucaoService.setNextDisabled(false);
			this.liberacaoDaCaucaoService.setPreviousDisabled(true);
			this.liberacaoDaCaucaoService.setPreviousShow(false);

			this.propostas = [];
			this.propostas.push(this.formField.information.proposta.value)

		} else {

			this.propostas = (this.formField.information.proposta.value !== null) ? this.api.getPropostas() : [];
		}
	}

	searchAccount(searchString) {
		// console.log('searchAccount', searchString);

		const caseNew = this.$parent.caseNew;
		return caseNew.api.searchAccount(searchString);
	}

	getPropostasByAccountId(accountId) {
		// console.log('getPropostasBuAccountId', accountId);

		this.formField.information.mainAccount.loading = true;
		this.propostas = [];
		this.api.setPropostas(this.propostas);

		this.sobjectQueryService.getPropostasByAccountId(accountId).then((response) => {

			this.formField.information.mainAccount.loading = false;

			if (!response.isSuccessful) {

				this.$window.scrollTo(0, 0);

				const errorMessage = angular.toJson(response.errorMessages);

				this.messages.showErrorMessage(this.$scope, errorMessage);

				return;
			}

			if (response.result.length > 0) {

				this.propostas = response.result;
				this.api.setPropostas(response.result);
			} else {

				this.liberacaoDaCaucaoService.setErrorMessage('Cliente não possui nenhuma proposta');
			}
		});
	}

	searchAccountSelect(item) {
		// console.log('searchAccountSelect', item);

		const caseNew = this.$parent.caseNew;
		if (caseNew.formField.information.mainAccount.value === null) {
			if (!item) { return; }
			const hasAction = !!item.originalObject.action;
			if (hasAction) {
				const action = item.originalObject.action;
				caseNew[action](item);
				return;
			}

			caseNew.formField.information.mainAccount.value = item.originalObject;
			caseNew.formField.information.mainAccount.hasError = !caseNew.hasAccount();

			caseNew.liberacaoDaCaucaoService.setSaveShow(true);
			caseNew.liberacaoDaCaucaoService.setSaveDisabled(false);
			caseNew.liberacaoDaCaucaoService.setCancelShow(true);
			caseNew.liberacaoDaCaucaoService.setCancelDisabled(false);
			caseNew.liberacaoDaCaucaoService.setNextShow(true);
			caseNew.liberacaoDaCaucaoService.setNextDisabled(false);

			caseNew.getPropostasByAccountId(caseNew.formField.information.mainAccount.value.Id);
		}
	}

	hasAccount() {
		return !!this.formField.information.mainAccount.value;
	}

	hasProposta() {
		return !!this.formField.information.proposta.value;
	}

	searchAccountFocusOut() {
		// console.log('searchAccountFocusOut');

		this.$timeout(() => {

			if (!this.autoComplete.hasValidInput(this.formField.information.mainAccount.value)) {

				this.liberacaoDaCaucaoService.setSaveShow(true);
				this.liberacaoDaCaucaoService.setSaveDisabled(true);
				this.liberacaoDaCaucaoService.setCancelShow(true);
				this.liberacaoDaCaucaoService.setCancelDisabled(true);
				this.liberacaoDaCaucaoService.setNextShow(true);
				this.liberacaoDaCaucaoService.setNextDisabled(true);

				this.autoComplete.clearInput(this.$scope, 'searchAccount');
			}
		}, 100);
	}

	searchAccountChanged() {
		// console.log('searchAccountChanged');

		const caseNew = this.$parent.caseNew;

		caseNew.liberacaoDaCaucaoService.cleanMessages();
		caseNew.autoComplete.resetSelected(caseNew.formField.information.mainAccount, 'value');
		caseNew.propostas = [];
		caseNew.api.setPropostas(caseNew.propostas);

		caseNew.formField.resetInformation();
		caseNew.formField.information.active = true;
		caseNew.formField.resetAnnex();
	}

	changePropostaField() {
		// console.log('changepropostaField');

		if (this.formField.information.mainAccount.value != null) {

			this.formField.resetField('information', 'garantias');
			this.formField.resetField('information', 'legalRepresentative');
			this.formField.resetAnnex();

			this.liberacaoDaCaucaoService.cleanMessages();
			if (this.formField.information.proposta.value !== null)
				this.getGarantiasByPropostaId(this.formField.information.proposta.value.objectId);
		}
	}

	getGarantiasByPropostaId(propostaId) {
		// console.log('getGarantiasByPropostaId');
		this.formField.information.proposta.loading = true;

		//CAP-2614
		const cpfcnpj = this.formField.information.mainAccount.value.cpf;

		this.sobjectQueryService.getGarantiasByPropostaId(propostaId, 'liberacao-da-caucao', cpfcnpj).then((response) => {
			//FIM CAP-2614
			this.formField.information.proposta.loading = false;
			if (!response.isSuccessful) {

				this.$window.scrollTo(0, 0);

				const errorMessage = angular.toJson(response.errorMessages);

				this.messages.showErrorMessage(this.$scope, errorMessage);

				return;
			}
			console.log('##############');
			console.log(response);
			console.log('##############');

			const statusOpenPopup = ['aguardando documentação', 'reprovado'];

			//Se a documentação da proposta não tiver sido aprovada ainda, exibe mensagem sobre documentação
			if ((response.propostaDocument) && (statusOpenPopup.indexOf(response.propostaDocument.status.toLowerCase()) >= 0)) {
				this.formField.caseDocumentId = response.propostaDocument.id;
				this.liberacaoDaCaucaoService.popUpCaseNumber = response.propostaDocument.name;
				$('#alertDocumentacaoAnexosModal').modal({ backdrop: 'static', keyboard: false });
			}
			if (response.result.length > 0) {
				this.liberacaoDaCaucaoService.cleanMessages();
				this.formField.information.garantias.value = [];
				for (let i = 0; i < response.result.length; i++) {

					this.formField.information.garantias.value.push({ objectId: response.result[i].objectId, objectName: response.result[i].objectName, vigencia: response.result[i].vigencia, finalVigencia: response.result[i].finalVigencia, selected: false, disabled: false, value: response.result[i].value });
				}
			} else {
				this.liberacaoDaCaucaoService.setWarningMessage('Um ou mais títulos relacionados à proposta selecionada já possui uma solicitação em andamento. Realize a busca da proposta ou título(s), utilizando a barra de pesquisa, e verifique o número da(s) solicitação(ões) atrelada(s) para visualizar seu status e possibilidade de dar continuidade à solicitação ou realizar o cancelamento.');
			}
		});
	}

	changeCheckGarantia(garantiaId, check) {

		this.formField.resetAnnex();

		let type = '';
		for (let i = 0; i < this.formField.information.garantias.value.length; i++) {
			if (garantiaId === this.formField.information.garantias.value[i].objectId) {
				if (this.formField.information.garantias.value[i].objectName.includes('(Descaucionado)')) {
					type = 'Descaucionado';
				} else {
					type = 'Caucionado';
				}
				break;
			}
		}

		if (check) {
			if (type === 'Caucionado') {
				for (let i = 0; i < this.formField.information.garantias.value.length; i++) {
					if (this.formField.information.garantias.value[i].objectName.includes('(Descaucionado)')) {
						this.formField.information.garantias.value[i].disabled = true;
					} else {
						this.formField.information.garantias.value[i].disabled = false;
					}
				}
			} else {
				for (let i = 0; i < this.formField.information.garantias.value.length; i++) {
					if (this.formField.information.garantias.value[i].objectName.includes('(Caucionado)')) {
						this.formField.information.garantias.value[i].disabled = true;
					} else {
						this.formField.information.garantias.value[i].disabled = false;
					}
				}
			}
		} else {
			if (type === 'Caucionado') {
				let founded = false;
				for (let i = 0; i < this.formField.information.garantias.value.length; i++) {
					if (this.formField.information.garantias.value[i].objectName.includes('(Caucionado)')) {
						if (this.formField.information.garantias.value[i].selected) {
							founded = true;
							break;
						}
					}
				}
				if (!founded) {
					for (let i = 0; i < this.formField.information.garantias.value.length; i++) {
						this.formField.information.garantias.value[i].disabled = false;
					}
				}
			} else {
				let founded = false;
				for (let i = 0; i < this.formField.information.garantias.value.length; i++) {
					if (this.formField.information.garantias.value[i].objectName.includes('(Descaucionado)')) {
						if (this.formField.information.garantias.value[i].selected) {
							founded = true;
							break;
						}
					}
				}
				if (!founded) {
					for (let i = 0; i < this.formField.information.garantias.value.length; i++) {
						this.formField.information.garantias.value[i].disabled = false;
					}
				}
			}
		}

		this.formField.information.legalRepresentative.show = this.hasTituloCaucionadoSelected();
	}

	hasTituloCaucionadoSelected() {
		let founded = false;
		if (this.formField.information.garantias.value != null) {
			for (let i = 0; i < this.formField.information.garantias.value.length; i++) {
				if (this.formField.information.garantias.value[i].selected && this.formField.information.garantias.value[i].objectName.includes('(Caucionado)')) {
					founded = true;
					break;
				}
			}
		}
		return founded;
	}
}

module.exports = liberacaoDaCaucaoNewController;
