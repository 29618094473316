'use strict';

class ResgateDataService {

	/* @ngInject */
	constructor(visualForceApi, $sce) {
		this.visualForceApi = visualForceApi;
	}

	loadDataFromPropostaId(propostaId) {
		console.log('Id proposta ', propostaId);
		return this.visualForceApi.invoke(
			'CapCommunitySearchObjectControllerExt.loadDataFromPropostaId', propostaId);
	}

	loadDataFromTituloId(tituloId) {
		console.log('Id titulo ', tituloId);
		return this.visualForceApi.invoke(
			'CapCommunitySearchObjectControllerExt.loadDataFromTituloId', tituloId);
	}
}

module.exports = ResgateDataService;
