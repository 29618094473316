'use strict';

class alteracaoDadosNewController {

	//@ngInject
	constructor($scope, $window, $state, $timeout, feedbackMessages, alteracaoDadosFormControlService, alteracaoDadosNewApi, autoCompleteService, sobjectQueryService) {
		console.log('entrou no new!');
		this.$scope = $scope;
		this.$window = $window;
		this.$state = $state;
		this.$timeout = $timeout;
		this.messages = feedbackMessages;
		this.api = alteracaoDadosNewApi;
		this.formField = alteracaoDadosFormControlService;
		this.autoComplete = autoCompleteService;
		this.buttonControl = this.formField.getButtonControl();

		this.propostas = this.api.propostas;
		this.sobjectQueryService = sobjectQueryService;
		this.formField.information.status = 'Em Andamento';

		this.isEdit();
		this.formField.formStep = 'case.alteracao-dados.new';
		this.initTypeOptions();
		this.$scope.breadcrumb = this.$scope.$parent.breadcrumb;
		this.configureBreadCrumb();
		this.listenDuplicatedCpfValidationResponse();

		console.log('Form Field:', this.formField);

	}

	listenDuplicatedCpfValidationResponse() {
		this.$scope.$on('inclusaoCaucaoDuplicatedCpfValidationResponse', (event, response) => {
			//console.log('escutando duplicatedCpfValidationResponse')
			if (this.formField.data.subCaseType == 'inclusaoCaucao') {
				if (response.length === 0) {
					this.formField.information.fields.mainAccount.isValid = true;
				} else {
					for (let i = 0; i < response.length; i++) {
						if ((this.formField.information.fields.mainAccount != null) && (response[i] === this.formField.information.fields.mainAccount.value.cpf)) {
							this.messages.showErrorMessage(this.$scope, "CPF/CNPJ já informado na solicitação");
							this.formField.information.fields.mainAccount.isValid = false;
						} else {
							this.formField.information.fields.mainAccount.isValid = true;
						}
					}
				}
			}
		});
	}

	isEdit() {
		console.log('isEdit()');

		if (this.formField.edit) {

			this.formField.information.fields.mainAccount.hasError = this.formField.requireProperty(this.formField.information.fields, 'mainAccount');

			this.formField.buttonControl.save.show = true;
			this.formField.buttonControl.save.disabled = false;
			this.formField.buttonControl.cancel.show = true;
			this.formField.buttonControl.cancel.disabled = false;
			this.formField.buttonControl.next.show = true;
			this.formField.buttonControl.next.disabled = false;

			this.propostas = [];
			this.propostas.push(this.formField.information.fields.proposta.value)

		} else {

			this.propostas = (this.formField.information.fields.proposta.value !== null) ? this.api.getPropostas() : [];
		}
	}

	initTypeOptions() {
		this.typeOptions = this.objectValuesToArray(this.formField.allTypeOptions);
	}

	objectValuesToArray(obj) {
		const arr = [];
		for (const prop in obj) {
			arr.push(obj[prop]);
		}

		return arr;
	}

	setTypeOptionsBasedOnTitles(titles) {
		let hasCaucionado = false;
		let hasDescaucionado = false;
		const options = angular.copy(this.formField.allTypeOptions);

		for (const titulo in titles) {
			if (titulo.caucionado) {
				hasCaucionado = true;
			} else {
				hasDescaucionado = true;
			}
		}

		if (!hasDescaucionado) {
			delete object.inclusaoCaucao;
		}

		this.typeOptions = this.objectValuesToArray(options);
	}

	configureBreadCrumb() {
		this.$scope.breadcrumb.reset();
		this.$scope.breadcrumb.addMenuItem('Home', '/caponline/secured#!/home', true, false);
		this.$scope.breadcrumb.addMenuItem('Solicitação', '/caponline/secured#!/solicitacoes/lista', true, false);
		this.$scope.breadcrumb.addMenuItem('Nova Alteração de dados', '/caponline/secured#!/solicitacoes/alteracao-dados/nova', false, true);
	}

	searchAccount(searchString) {
		console.log('entrou no searchAccount');
		const alteracaoDadosNew = this.$parent.alteracaoDadosNew;
		return alteracaoDadosNew.api.searchAccount(searchString);
	}

	searchAccountSelect(item) {
		console.log('entrou no searchAccountSelect');
		const alteracaoDadosNew = this.$parent.alteracaoDadosNew;
		if (alteracaoDadosNew.formField.information.fields.mainAccount.value === null) {
			if (!item) { return; }

			const hasAction = !!item.originalObject.action;

			if (hasAction) {

				const action = item.originalObject.action;

				alteracaoDadosNew[action](item);

				return;
			}

			alteracaoDadosNew.formField.information.fields.mainAccount.value = item.originalObject;
			alteracaoDadosNew.formField.information.fields.mainAccount.hasError = !alteracaoDadosNew.hasAccount();
			alteracaoDadosNew.formField.buttonControl.save.show = true;
			alteracaoDadosNew.formField.buttonControl.save.disabled = false;
			alteracaoDadosNew.formField.buttonControl.cancel.show = true;
			alteracaoDadosNew.formField.buttonControl.cancel.disabled = false;
			alteracaoDadosNew.formField.buttonControl.next.show = true;
			alteracaoDadosNew.formField.buttonControl.next.disabled = false;
			alteracaoDadosNew.formField.information.fields.mainAccount.loading = true;
			alteracaoDadosNew.getPropostasByAccountId(alteracaoDadosNew.formField.information.fields.mainAccount.value.Id);
		}
		alteracaoDadosNew.validateDuplicatedCpf();
	}

	validateDuplicatedCpf() {
		this.$scope.$emit('inclusaoCaucaoDuplicatedCpfValidation', this.formField);
	}

	getPropostasByAccountId(accountId) {
		//alteracaoDadosNew.formField.buttonControl.save.disabled = true;				
		this.propostas = [];
		this.api.setPropostas(this.propostas);
		this.sobjectQueryService.getPropostasByAccountId(accountId).then((response) => {
			this.formField.information.fields.mainAccount.loading = false;
			if (response.isSuccessful) {
				if (response.result.length > 0) {
					this.propostas = response.result;
					this.api.setPropostas(response.result);
				} else {
					this.messages.showErrorMessage(this.$scope, 'Cliente não possui nenhuma proposta apta para a solicitação');
				}
			} else {
				console.log('error ', response);
			}
		});
	}

	/*getGarantiasByPropostaId(propostaId) {
		this.formField.information.fields.proposta.loading = true;
		this.sobjectQueryService.getGarantiasByPropostaId(propostaId, 'alteracao-dados', '154.786.437-00').then((response) => {
			this.formField.information.fields.proposta.loading = false;
			if (response.isSuccessful) {
				this.formField.information.fields.garantias.value = [];
				for (let i = 0; i < response.result.length; i++) {

					this.formField.information.fields.garantias.value.push({
						objectId: response.result[i].objectId,
						objectName: response.result[i].objectName,
						vigencia: response.result[i].vigencia,
						finalVigencia: response.result[i].finalVigencia,
						selected: false,
						disabled: false,
						value: response.result[i].value,
						antecipatedValue: response.result[i].antecipatedValue
					});
				}
			} else {
				this.feedbackMessages.showErrorMessage(response.errorMessages[0]);
			}
		});
	}*/

	// changePropostaField() {
	// 	this.getGarantiasByPropostaId(this.formField.information.fields.proposta.value.objectId);
	// }

	searchAccountFocusOut() {
		console.log('entrou no searchAccountFocusOut');
		if (this.$parent) {
			const alteracaoDadosNew = this.$parent.alteracaoDadosNew;
			this.$timeout(() => {
				if (!this.autoComplete.hasValidInput(this.formField.information.fields.mainAccount.value)) {
					alteracaoDadosNew.formField.buttonControl.save.show = true;
					alteracaoDadosNew.formField.buttonControl.save.disabled = true;
					alteracaoDadosNew.formField.buttonControl.cancel.show = true;
					alteracaoDadosNew.formField.buttonControl.cancel.disabled = true;
					alteracaoDadosNew.formField.buttonControl.next.show = true;
					alteracaoDadosNew.formField.buttonControl.next.disabled = true;

					this.autoComplete.clearInput(this.$scope, 'searchAccount');
				}
			}, 100);
		}
	}

	searchAccountChanged() {
		const alteracaoDadosNew = this.$parent.alteracaoDadosNew; //CAP-2706
		console.log('entrou no searchAccountChanged');
		alteracaoDadosNew.messages.cleanMessages(alteracaoDadosNew.$scope);
		alteracaoDadosNew.autoComplete.resetSelected(alteracaoDadosNew.formField.information.fields.mainAccount, 'value');
		alteracaoDadosNew.propostas = [];
		alteracaoDadosNew.api.setPropostas(alteracaoDadosNew.propostas);
		if (alteracaoDadosNew.formField.information.fields.mainAccount.value == '' || alteracaoDadosNew.formField.information.fields.mainAccount.value == null) {
			alteracaoDadosNew.formField.buttonControl.save.disabled = true;
		}
		console.log('reset');
		//resetar todos os dados
		alteracaoDadosNew.resetForm();

	}

	hasAccount() {
		return !!this.formField.information.fields.mainAccount.value;
	}

	alteracaoTypeChange() {
		const optionShowLegalRepresentative = ['Mudança de imóvel/ locador', 'Mudança de Locador'];
		const optionShowWarning = ['Alteração de Dados do Cliente'];
		const alteracaoType = this.formField.information.fields.alteracaoType;

		//resetar os dados de anexo e da tela de dados
		this.formField.setDataPristine();
		this.formField.setAnnexPristine();

		//opção de representante legal
		if (optionShowLegalRepresentative.includes(alteracaoType.value)) {
			this.formField.information.fields.legalRepresentative.show = true;
		} else {
			this.formField.information.fields.legalRepresentative.show = false;
			this.formField.information.fields.legalRepresentative.value = null;
		}

		//alerta para determinada opção
		alteracaoType.hasWarning = optionShowWarning.includes(alteracaoType.value);

		//desabilitar títulos conforme o tipo de solicitação 
		this.disableTitlesBasedOnAlteracaoType();
	}

	//@todo refactory
	disableTitlesBasedOnAlteracaoType() {
		const alteracaoType = this.formField.information.fields.alteracaoType.value;

		switch (alteracaoType) {
		case this.formField.allTypeOptions.mudancaImovel:
			this.enableTitulos();
			if (this.hasTitulosFromType(true)) {
				this.checkAllTitulosFromType(true);
			}
			this.disableTitulos(false);
			break;
		case this.formField.allTypeOptions.mudancaLocador:
			this.enableTitulos();
			if (this.hasTitulosFromType(true)) {
				this.checkAllTitulosFromType(true);
			}
			this.disableTitulos(false);
			break;
		case this.formField.allTypeOptions.correcaoDadosCliente:
			this.checkAllTitulos();
			break;
		case this.formField.allTypeOptions.inclusaoCaucao:
			this.enableTitulos();
			this.disableTitulos(true);
			break;
		default:

		}
	}

	hasTitulosFromType(caucionado) {
		const titulos = this.formField.information.fields.garantias.value;
		for (const idx in titulos) {
			if (titulos[idx].caucionado === caucionado) return true;
		}
		return false;
	}

	disableTitulos(caucionado) {
		const titulos = this.formField.information.fields.garantias.value;
		for (const idx in titulos) {
			if (titulos[idx].caucionado === caucionado) {
				titulos[idx].selected = false;
				titulos[idx].disabled = true;
			}
		}
	}

	enableTitulos() {
		const titulos = this.formField.information.fields.garantias.value;
		for (const idx in titulos) {
			//titulos[idx].selected = false;
			titulos[idx].disabled = false;
		}
	}

	getGarantiasByPropostaId(propostaId) {
		console.log('entrou no getGarantiasByPropostaId');

		this.formField.information.fields.proposta.loading = true;

		//CAP-2614
		const cpfcnpj = this.formField.information.fields.mainAccount.value.cpf;

		this.sobjectQueryService.getGarantiasByPropostaId(propostaId, 'alteracao-dados', cpfcnpj).then((response) => {
			//FIM CAP-2614
			this.formField.information.fields.proposta.loading = false;
			if (response.isSuccessful) {
				if (response.result.length > 0) {
					this.messages.cleanMessages(this.$scope);
					this.formField.information.fields.garantias.value = [];
					for (let i = 0; i < response.result.length; i++) {

						this.formField.information.fields.garantias.value.push({
							objectId: response.result[i].objectId,
							objectName: response.result[i].objectName,
							vigencia: response.result[i].vigencia,
							finalVigencia: response.result[i].finalVigencia,
							selected: false,
							disabled: false,
							caucionado: response.result[i].caucionado,
							value: response.result[i].value,
							antecipatedValue: response.result[i].antecipatedValue
						});
					}
					this.setTypeOptionsBasedOnTitles(this.formField.information.fields.garantias.value);
				} else {
					this.messages.setWarningMessage(this.$scope, 'Um ou mais títulos relacionados à proposta selecionada já possui uma solicitação em andamento. Realize a busca da proposta ou título(s), utilizando a barra de pesquisa, e verifique o número da(s) solicitação(ões) atrelada(s) para visualizar seu status e possibilidade de dar continuidade à solicitação ou realizar o cancelamento.');
				}
			} else {
				this.messages.showErrorMessage(this.$scope, response.errorMessages[0]);
			}
		});
		console.log('saiu no getGarantiasByPropostaId');
	}

	changePropostaField() {
		console.log('entrou no changepropostaField');
		if (this.formField.information.fields.mainAccount.value != null) {
			this.resetForm();
			//this.bloqReaplicacaoFormService.resetFormFile();
			this.messages.cleanMessages();
			if (this.formField.information.fields.proposta.value !== null)
				this.getGarantiasByPropostaId(this.formField.information.fields.proposta.value.objectId);
		}
	}

	resetForm() {
		this.formField.information.fields.garantias.value = null;
		this.formField.information.fields.garantias.hasError = false;
		this.formField.information.fields.legalRepresentative.value = null;
		this.formField.information.fields.legalRepresentative.hasError = null;
		this.formField.information.fields.alteracaoType.value = null;
		this.formField.information.fields.alteracaoType.hasError = false;
	}

	//@todo refactory
	changeGarantia(objectId) {
		const fields = this.formField.information.fields;
		const optionsSelectAllCaucionados = ['Mudança de imóvel/ locador', 'Mudança de Locador'];

		if (!fields.alteracaoType.value) return;

		if (optionsSelectAllCaucionados.includes(fields.alteracaoType.value)) {
			for (let i = 0; i < fields.garantias.value.length; i++) {
				if (fields.garantias.value[i].objectId == objectId && fields.garantias.value[i].caucionado) {
					if (fields.garantias.value[i].selected) {
						this.checkAllTitulosFromType(true);
					} else {
						this.uncheckAllTitulosFromType(true);
					}
					break;
				}
			}
		}
	}

	checkAllTitulos() {
		const titulos = this.formField.information.fields.garantias.value;
		for (let i = 0; i < titulos.length; i++) {
			titulos[i].selected = true;
		}
	}

	checkAllTitulosFromType(isCaucionado) {
		const titulos = this.formField.information.fields.garantias.value;
		for (let i = 0; i < titulos.length; i++) {
			if (titulos[i].caucionado === isCaucionado) {
				titulos[i].selected = true;
			}
		}
	}

	uncheckAllTitulosFromType(isCaucionado) {
		const titulos = this.formField.information.fields.garantias.value;
		for (let i = 0; i < titulos.length; i++) {
			if (titulos[i].caucionado === isCaucionado) {
				titulos[i].selected = false;
			}
		}
	}

}

module.exports = alteracaoDadosNewController;
