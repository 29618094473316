'use strict';

class SobjectQueryService {

	/* @ngInject */
	constructor(visualForceApi, $sce, $q, $timeout) {
		this.visualForceApi = visualForceApi;
		this.$q = $q;
		this.$timeout = $timeout;
	}

	getPropostasByAccountId(accountId) {
		return this.visualForceApi.invoke(
			'CapCommunitySearchObjectControllerExt.getPropostasByAccountId', accountId);
	}

	getPropostasByAccountIdSorteio(accountId) {
		return this.visualForceApi.invoke(
			'CapCommunitySearchObjectControllerExt.getPropostasByAccountIdSorteio', accountId);
	}

	getGarantiasByPropostaId(propostaId, caseType, cpfCnpjSubscritor) {
		const requestModel = {

			propostaId,
			caseType: caseType || 'resgate',
			cpfCnpjSubscritor: cpfCnpjSubscritor || ''
		};

		return this.visualForceApi.invoke(
			'CapCommunitySearchObjectControllerExt.getGarantiasByPropostaId', requestModel);
	}

}

module.exports = SobjectQueryService;
