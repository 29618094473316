'use strict';

class documentacaoPropostaDetailApi {

	/* @ngInject */
	constructor(visualForceApi) {

		this.visualForceApi = visualForceApi;
	}

	callDownloadPDF(attach) {

		return this.visualForceApi.invoke(
			'CapCommunityPropostaControllerExt.callDownloadPDF', attach.attachId);
	}

}

module.exports = documentacaoPropostaDetailApi;
