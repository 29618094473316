'use strict';

const documentsController = require('./documents-controller');
const documentsService = require('./documents-service');

const documentsModule = angular.module('cap.secured.documentsModule', ['ui.router'])
	.controller('documentsController', documentsController)
	.service('documentsService', documentsService);

module.exports = documentsModule;
