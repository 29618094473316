"use strict";

class alteracaoImovelApi {
	// @ngInject
	constructor($q, visualForceApi) {
		this.$q = $q;
		this.visualForceApi = visualForceApi;
	}

	getTituloData(id) {

		const cap_access_token = localStorage.getItem("cap_access_token");

		return this.visualForceApi.invoke(
			"CapCommunityCaseControllerExt.getTitleAdressData",
			id, cap_access_token
		);
		// /*Mock*/
		// return {
		// 	cep: '21550510',
		// 	logradouro: 'Rua Teresa Santos',
		// 	numero: '246',
		// 	complemento: '',
		// 	bairro: 'Bento Ribeiro',
		// 	cidade: 'Rio de Janeiro',
		// 	uf: 'RJ',
		// 	tipoImovel: 'Casa',
		// 	finalidade: 'Residencial',
		// 	nomeLocador: 'Gabriel Rodrigues',
		// 	cpfLocador: '15478643700'
		// };
	}

	searchAccount(searchKeyWord) {
		const deferred = this.$q.defer();

		const searchModel = {
			dataFetch: {
				searchKeyWord,
				searchTypeObject: "Account"
			}
		};

		const cap_access_token = localStorage.getItem("cap_access_token");
		searchModel.accessToken = cap_access_token;

		this.visualForceApi
			.invoke("CapCommunityPropostaControllerExt.solFetchRecords", searchModel)
			.then((data) => {
				//console.log(data.obtainedRecords);
				if (!data.obtainedRecords) data.obtainedRecords = [];
				if (data.obtainedRecords.length === 0) {
					data.obtainedRecords.push({
						action: "searchAccountFocusOut",
						Name: "Nenhum cadastro encontrado"
					});
				}
				data.obtainedRecords.push({
					action: "modalHandle",
					Name: "+ Criar Cadastro"
				});
				deferred.resolve(data.obtainedRecords);
			});

		return deferred.promise;
	}
}

module.exports = alteracaoImovelApi;
