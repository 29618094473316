"use strict";

import VisualForceApi from "../../core/sfdc/visual-force-api.js";

class propostaPaymentService extends VisualForceApi {
	/* @ngInject */
	constructor($q) {
		super();
		this.$q = $q;
	}

	getBankList() {
		return ["341	BANCO ITAÚ", "033	BANCO SANTANDER (BRASIL) S.A."];
	}

	getBestDayList() {
		const myDaysBest = [];
		for (let i = 1; i <= 25; i++) {
			myDaysBest.push(i.toString());
		}
		return myDaysBest;
	}

	getBestDayService(melhorDia) {
		const deferred = this.$q.defer();

		console.log(melhorDia);

		const cap_access_token = localStorage.getItem("cap_access_token");

		const request = {
			dataInformada: melhorDia,
			accessToken: cap_access_token
		};

		console.log(request);

		super
			.invoke("CapCommunityPropostaControllerExt.melhorDataPagamento", request)
			.then((response) => {
				deferred.resolve(response);
			});

		return deferred.promise;
	}

	getParcelas(valorTotalProposta) {
		const deferred = this.$q.defer();

		console.log("valorTotalProposta: ", valorTotalProposta);

		const cap_access_token = localStorage.getItem("cap_access_token");

		const request = {
			valorTotalProposta,
			accessToken: cap_access_token
		};

		console.log(request);

		super
			.invoke("CapCommunityPropostaControllerExt.getParcelas", request)
			.then((response) => {
				console.log("resultado busca parcela (service): ", response);
				deferred.resolve(response);
			});

		return deferred.promise;
	}

	getParcelasDG(valorTitulo, valorSeguro) {
		const deferred = this.$q.defer();

		console.log("valorTitulo: ", valorTitulo);

		const cap_access_token = localStorage.getItem("cap_access_token");

		const request = {
			valorTitulo,
			valorSeguro,
			accessToken: cap_access_token
		};

		console.log(request);

		super
			.invoke("CapCommunityPropostaControllerExt.getParcelasDG", request)
			.then((response) => {
				console.log("resultado busca parcela (service): ", response);
				deferred.resolve(response);
			});

		return deferred.promise;
	}

	getURLPagamentoCredito(formField) {
		const deferred = this.$q.defer();

		console.log("parcela selecionada: ", formField.parcelaSelecionada);
		console.log("parcela valor: ", formField.parcelaValor);

		const request = {
			valor: formField.parcelaValor,
			valorLiquido: formField.cartaoValorLiquido,
			numeroParcelas: formField.parcelaSelecionada,
			clientStreetAddress: formField.clientStreetAddress,
			clientStreetNumber: formField.clientStreetNumber,
			clientAddressComplement: formField.clientAddressComplement,
			clientCep: formField.clientCep,
			clientCity: formField.clientCity,
			clientDistrict: formField.clientDistrict,
			clientUf: formField.clientUf,
			nome: formField.account.Name,
			cpf: formField.account.cpf,
			email: formField.account.email
		};

		console.log(request);

		const cap_access_token = localStorage.getItem("cap_access_token");

		super
			.invoke(
				"CapCommunityPropostaControllerExt.solGerarURLPagamentoCredito",
				request,
				cap_access_token
			)
			.then((response) => {
				console.log("resultado url pagamento cartao de credito: ", response);
				deferred.resolve(response);
			});

		return deferred.promise;
	}
}

module.exports = propostaPaymentService;
