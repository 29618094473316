'use strict';

/* @ngInject */
function routesConfiguration($stateProvider, sfConfig) {

	$stateProvider.state('contactUs', {

		url: '/fale-conosco',
		views: {

			'content': {

				templateUrl: `${sfConfig.resource}/views/fale-conosco/fale-conosco.html`,
				controller: 'contactUsController',
				controllerAs: 'contactUs'
			}
		}
	});
};

module.exports = routesConfiguration;
