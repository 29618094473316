'use strict';

class mainController {
	// @ngInject
	constructor($scope, $state, $timeout, sfConfig, $stateParams, $location, resgateFormService) {
		this.$scope = $scope;
		this.$location = $location;
		this.$state = $state;
		this.$timeout = $timeout;
		this.idUSerAccount = sfConfig.idUSerAccount;
		this.resgateFormService = resgateFormService;
		this.tabCommunity = {
			home: {
				name: 'Home',
				show: true,
				active: true
			},
			account: {
				name: 'Cadastro',
				show: sfConfig.account.canAccess === 'true' ? true : false,
				active: false
			},
			case: {
				name: 'Solicitação',
				show: sfConfig.case.canAccess === 'true' ? true : false,
				active: false
			},
			documentacao: {
				name: 'Documentação da Emissão',
				show: true,
				active: false
			},
			proposta: {
				name: 'Proposta',
				show: sfConfig.proposta.canAccess === 'true' ? true : false,
				active: false
			},
			contactUs: {
				name: 'Fale Conosco',
				show: true,
				active: false
			},
			garantiaAluguel: {
				name: 'Garantia de Aluguel',
				show: sfConfig.garantiaAluguel.canAccess === 'true' ? true : false,
				active: false
			},
			assistenciaLocaticia: {
				name: 'Assistência Locatícia',
				show: sfConfig.assistenciaLocaticia.canAccess === 'true' ? true : false,
				active: false
			},
			/*
			comissoesPagas: {
				name: 'Comissões Pagas',
				show: (sfConfig.comissoesPagas.canAccess === 'true') ? true : false,
				active: false
			},
			remuneracoesPagas: {
				name: 'Remunerações Pagas',
				show: (sfConfig.remuneracoesPagas.canAccess === 'true') ? true : false,
				active: false
			},
			*/
			administrarUsuario: {
				name: 'Administrar Usuário',
				show: sfConfig.administrarUsuario.canAccess === 'true' ? true : false,
				active: false
			},
			documents: {
				name: 'Documentos',
				show: true,
				active: false
			},
			solicitation: {
				name: 'Solicitação',
				show: true,
				active: false
			},
			simulador: {
				name: 'Simulador',
				show: true,
				active: false
			},
			simuladorGarantiaAluguel: {
				name: 'Simulador Garantia Aluguel',
				show: false,
				active: false
			},
			simuladorDuplaGarantia: {
				name: 'Simulador Dupla Garantia',
				show: false,
				active: false
			},
			simuladorPrestamistaAvulso: {
				name: 'Simulador Prestamista Avulso',
				show: false,
				active: false
			}
		};

		this.message = {};
		this.stateCaseType = $stateParams.stateCaseType || 'todos';

		this.sfResource = sfConfig.resource;

		console.log('USERACCOUNT SFCONFIG MAIN CONTROLLER - ', this.idUSerAccount);
		console.log('USER SFCONFIG MAIN CONTROLLER - ', this.idUSer);
		this.beginLoad();
		this.listeners();
	}

	//Este método executa a ação de voltar para o topo.
	scrollToTop() {
		$('html, body').animate({ scrollTop: 0 }, 400);
	}

	changeActiveTab(activeTabName) {
		// @todo remover está gambiarra
		if (activeTabName === 'resgate') {
			console.log('RESET FORM');
			this.resgateFormService.resetForm();
		}
		this.tabCommunity.home.active = activeTabName === 'home' ? true : false;
		this.tabCommunity.account.active = activeTabName === 'account' ? true : false;
		this.tabCommunity.case.active = activeTabName === 'case' ? true : false;
		this.tabCommunity.proposta.active = activeTabName === 'proposta' ? true : false;
		this.tabCommunity.contactUs.active = activeTabName === 'contactUs' ? true : false;
		this.tabCommunity.garantiaAluguel.active = activeTabName === 'garantia-aluguel' ? true : false;
		this.tabCommunity.assistenciaLocaticia.active = activeTabName === 'assistencia-locaticia' ? true : false;
		//this.tabCommunity.comissoesPagas.active = (activeTabName === 'comissao-paga') ? true : false;
		this.tabCommunity.administrarUsuario.active = activeTabName === 'administrar-usuario' ? true : false;
		this.tabCommunity.documents.active = activeTabName === 'documents' ? true : false;
		this.tabCommunity.documentacao.active = activeTabName === 'documentacao' ? true : false;
		this.tabCommunity.solicitation.active = activeTabName === 'solicitation' ? true : false;
		this.tabCommunity.simulador.active = activeTabName === 'simulador' ? true : false;
		this.tabCommunity.simuladorGarantiaAluguel.active = activeTabName === 'simuladorGarantiaAluguel' ? true : false;
		this.tabCommunity.simuladorDuplaGarantia.active = activeTabName === 'simuladorDuplaGarantia' ? true : false;
		this.tabCommunity.simuladorPrestamistaAvulso.active = activeTabName === 'simuladorPrestamistaAvulso' ? true : false;
	}

	beginLoad() {
		console.log('beginLoad', this.$location.path());

		if (this.$location.path().includes('/home')) {
			this.changeActiveTab('home');
		} else if (this.$location.path().includes('/proposta')) {
			this.changeActiveTab('proposta');
		} else if (this.$location.path().includes('/fale-conosco')) {
			this.changeActiveTab('contactUs');
		} else if (this.$location.path().includes('/conta')) {
			this.changeActiveTab('account');
		} else if (this.$location.path().includes('/resgate')) {
			this.changeActiveTab('resgate');
		} else if (this.$location.path().includes('/garatia-aluguel')) {
			this.changeActiveTab('garantia-aluguel');
		} else if (this.$location.path().includes('/assistencia-locaticia')) {
			this.changeActiveTab('assistencia-locaticia');
			//} else if (this.$location.path().includes('/comissoes-pagas')) {
			//	this.changeActiveTab('comissao-paga');
		} else if (this.$location.path().includes('/administrar-usuario')) {
			this.changeActiveTab('administrar-usuario');
		} else if (this.$location.path().includes('/documentos')) {
			this.changeActiveTab('documents');
		} else if (this.$location.path().includes('/solicitacoes/lista/documentacao')) {
			this.changeActiveTab('documentacao');
		} else if (this.$location.path().includes('/solicitacoes')) {
			this.changeActiveTab('case');
		} else if (this.$location.path().includes('/simulador')) {
			this.changeActiveTab('simulador');
		} else if (this.$location.path().includes('/simulador-garantia-aluguel')) {
			this.changeActiveTab('simuladorGarantiaAluguel');
		} else if (this.$location.path().includes('/simulador-dupla-garantia')) {
			this.changeActiveTab('simuladorDuplaGarantia');
		} else if (this.$location.path().includes('/simulador-prestamista-avulso')) {
			this.changeActiveTab('simuladorPrestamistaAvulso');
		}
	}

	clickCaseList(stateCaseType) {
		this.$state.go('case.list', { stateCaseType });
	}

	listeners() {
		this.listenShowLoading();
		this.listenScrollToTop();
		this.listenErrorMessage();
		this.listenCleanErrorMessage();
		this.listenSuccessMessage();
		this.listenCleanSuccessMessage();
		this.listenCleanMessages();
		this.listenChangeTab();
	}

	listenShowLoading() {
		this.$scope.$on('changeMainShowLoading', (event, status, tempo) => {
			event.stopPropagation && event.stopPropagation();

			if (status) {
				this.$timeout.cancel(this.timer);
				console.log('Cancelando o Timer', this.timer);
				this.showLoading = true;
			} else {
				this.timer = this.$timeout(
					() => {
						console.log('Execução do Timer');
						this.showLoading = false;
					},
					!tempo ? 1 : tempo
				);
			}
		});
	}

	listenScrollToTop() {
		this.$scope.$on('scrollToTop', (event) => {
			event.stopPropagation && event.stopPropagation();
			this.scrollToTop();
		});
	}

	listenChangeTab() {
		this.$scope.$on('mainChangeTab', (event, tab) => {
			event.stopPropagation && event.stopPropagation();

			this.changeActiveTab(tab);
		});
	}

	listenErrorMessage() {
		this.$scope.$on('mainErrorMessage', (event, message) => {
			event.stopPropagation && event.stopPropagation();

			this.message.genericError = message;
		});
	}

	listenCleanErrorMessage() {
		this.$scope.$on('mainCleanErrorMessage', (event) => {
			event.stopPropagation && event.stopPropagation();

			this.message.genericError = false;
		});
	}

	listenSuccessMessage() {
		this.$scope.$on('mainSuccessMessage', (event, message) => {
			event.stopPropagation && event.stopPropagation();

			this.message.genericSuccess = message;
		});
	}

	listenCleanSuccessMessage() {
		this.$scope.$on('mainCleanSuccessMessage', (event) => {
			event.stopPropagation && event.stopPropagation();

			this.message.genericSuccess = false;
		});
	}

	listenCleanMessages() {
		this.$scope.$on('mainCleanMessages', (event) => {
			event.stopPropagation && event.stopPropagation();

			this.message.genericError = false;
			this.message.genericSuccess = false;
		});
	}

	cleanMessages() {
		this.message.genericError = false;
		this.message.genericSuccess = false;
	}
}

module.exports = mainController;
