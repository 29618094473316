'use strict';

/* @ngInject */
function routesConfiguration($urlRouterProvider) {

	// $urlRouterProvider.otherwise("/home");

	/* Gambiarra pra evitar loop infinito quando o $state 
	 * não tem um state anterior para ficar
	 */
	$urlRouterProvider.otherwise(($injector) => {

		const $state = $injector.get("$state");

		$state.go('home');
	});
};

module.exports = routesConfiguration;
