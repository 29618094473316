'use strict';

class revisaoComissaoNewController {

	// @ngInject
	constructor(revisaoComissaoFormService, propostaNewApi, autoCompleteService, $scope, $state, sobjectQueryService, revisaoComissaoService, revisaoComissaoNewService, $timeout, breadcrumbHelper) {
		this.revisaoComissaoFormService = revisaoComissaoFormService;
		this.$timeout = $timeout;
		this.$state = $state;
		this.sobjectQueryService = sobjectQueryService;
		this.revisaoComissaoService = revisaoComissaoService;
		this.revisaoComissaoNewService = revisaoComissaoNewService;
		this.autoComplete = autoCompleteService;
		this.$scope = $scope;
		this.propostaNewApi = propostaNewApi;
		this.$scope.breadcrumb = breadcrumbHelper;
		this.getFormField();
		this.formField.formStep = 'case.revisao-comissao.new';
		console.log('##############');
		console.log(this.formField);
		console.log('##############');
		this.revisaoComissaoService.setPristine();
		this.revisaoComissaoService.setFinishShow(true);
		this.revisaoComissaoService.setCancelShow(true);

		this.configureBreadCrumb();
		this.getEstruturaVenda();

	}

	configureBreadCrumb() {
		this.$scope.breadcrumb.reset();
		this.$scope.breadcrumb.addMenuItem('Home', '/caponline/secured#!/home', true, false);
		this.$scope.breadcrumb.addMenuItem('Solicitação', '/caponline/secured#!/solicitacoes/lista', true, false);
		this.$scope.breadcrumb.addMenuItem('Nova Revisão de Comissão', '#', false, true);
	}

	getFormField() {
		this.formField = this.revisaoComissaoFormService.getFormField();
	}

	getEstruturaVenda() {

		console.log('getEstruturaVenda');
		this.revisaoComissaoNewService.getEstruturaVenda().then((response) => {
			console.log(response);
			console.log('response.result[0].objectName=', response.result[0].objectName);
			const estruturavenda = response.result[0].objectName;
			this.formField.information.estruturavenda.value = estruturavenda;
		});
	}

	resetForm() {
		this.formField.information.estruturavenda.value = null;
		this.formField.information.estruturavenda.hasError = false;
		this.formField.information.descricaorevisao.value = null;
		this.formField.information.descricaorevisao.hasError = false;
		this.formField.information.motivobloqueio.value = null;
		this.formField.information.motivobloqueio.hasError = false;
		this.revisaoComissaoFormService.setFormField(this.formField);
	}

}

module.exports = revisaoComissaoNewController;
