'use strict';

class mudancaLocadorController {

	// @ngInject
	constructor($scope, $window, $uibModal, $state, feedbackMessages, alteracaoDadosFormControlService, mudancaLocadorService, alteracaoDadosSetupAccountService) {
		this.$scope = $scope;
		this.$window = $window;
		this.$state = $state;
		this.$uibModal = $uibModal;
		this.feedbackMessages = feedbackMessages;
		this.formField = alteracaoDadosFormControlService;
		this.api = mudancaLocadorService;
		this.alteracaoDadosSetupAccountService = alteracaoDadosSetupAccountService;

		this.checkNewLocador();

		this.formField.data.status = 'Em Andamento';

		this.getTituloData(this.formField.information.fields.garantias.value[0].objectId); //esse tipo de alteração só pode ser feito com 1 título apenas
		console.log('Tipo Alteração', this.formField.information.fields.alteracaoType.value);
	}

	checkNewLocador() {
		const newLocador = this.alteracaoDadosSetupAccountService.getNewLocador();

		if (newLocador != null) {
			this.formField.activateTab('data');
			this.formField.data.mudancaLocador.fields.newLocador.value = newLocador;
		}
	}

	getTituloData(id) {
		this.api.getTituloData(id).then((response) => {
				this.formField.data.mudancaLocador.currentData = response.data;
			})
			.catch((response) => {
				console.log('erro ao trazer dados do título');
			});
		//this.formField.data.mudancaLocador.currentData = this.api.getTituloData(id);
	}

	requireProperty(propertyName) {
		this.formField.requireProperty(this.formField.data.mudancaLocador.fields, propertyName);
	}

	searchAccount(searchString) {
		console.log('entrou no searchAccount');
		const mudancaLocador = this.$parent.mudancaLocador;
		return mudancaLocador.api.searchAccount(searchString);
	}

	searchAccountSelect(item) {
		console.log('entrou no searchAccountSelect');
		const mudancaLocador = this.$parent.mudancaLocador;
		if (mudancaLocador.formField.data.mudancaLocador.fields.newLocador.value === null) {
			if (!item) { return; }

			const hasAction = !!item.originalObject.action;

			if (hasAction) {

				const action = item.originalObject.action;

				mudancaLocador[action](item);

				return;
			}

			mudancaLocador.formField.data.mudancaLocador.fields.newLocador.value = item.originalObject;
			mudancaLocador.formField.data.mudancaLocador.fields.newLocador.hasError = !mudancaLocador.formField.data.mudancaLocador.fields.newLocador.value;

		}
	}

	searchAccountChanged() {
		const mudancaLocador = this.$parent.mudancaLocador; //CAP-2706
		console.log('entrou no searchAccountChanged');
		mudancaLocador.feedbackMessages.cleanMessages(mudancaLocador.$scope);
		mudancaLocador.formField.data.mudancaLocador.fields.newLocador.value = null;
		console.log('reset');
		//resetar todos os dados	
	}

	//@todo Horrível. copiado da proposta.
	modalHandle() {
		//this.searchAccountFocusOut();
		const parentElem = angular.element(document.querySelector('.col-lg-4'));
		this.$uibModal.open({
			template: '<div class="modal-content acc"><div class="modal-body"><h4 class="modal-title text-center">Que tipo de cadastro quer criar?</h4><div class="col-lg-6"><a class="box-modal pull-right" href="" ui-sref="new-account" ng-click="modalController.setFromMudancaLocador(true); modalController.closeModal()"><img class="ico-type" src="{{modalController.imagePFAccountPath}}"/><p>Pessoa Física</p></a></div><div class="col-lg-6"><a class="box-modal pull-left" href="" ui-sref="new-juridical-account" ng-click="modalController.setFromMudancaLocador(true); modalController.closeModal()"><img class="ico-type" src="{{modalController.imagePJAccountPath}}"/><p>Pessoa Jurídica</p></a></div></div><div class="modal-footer"><button class="btn btn-link cancel" type="button" ng-click="modalController.closeModal()">Cancelar</button></div></div>',
			controller: 'accountTypeModalController',
			controllerAs: 'modalController',
			appendTo: parentElem
		});
	}

	nullOrEmpty(field) {
		return this.formField.nullOrEmpty(this.formField.data.mudancaLocador.fields, field);
	}

	validateField(field) {
		this.formField.validateField(this.formField.data.mudancaLocador.fields, field);
	}
}

module.exports = mudancaLocadorController;
