'use strict';

class revisaoComissaoController {

	// @ngInject
	constructor($scope, $window, $state, $timeout, revisaoComissaoFormService, revisaoComissaoService, revisaoComissaoAnnexService, annexHelper, breadcrumbHelper) {
		console.log('revisaoComissaoController');

		this.$scope = $scope;
		this.$window = $window;
		this.$state = $state;
		this.$timeout = $timeout;

		this.revisaoComissaoFormService = revisaoComissaoFormService;
		this.formField = this.revisaoComissaoFormService.getFormField();
		this.revisaoComissaoService = revisaoComissaoService;
		this.annexHelper = annexHelper;
		this.revisaoComissaoService.setPristine();

		//this.listenValidUploads();
		//this.listenSaveCaseInstance();

		if (this.revisaoComissaoService.getIsEdit() === false) {
			this.revisaoComissaoService.setPreviousShow(false);
			this.revisaoComissaoService.setPreviousDisabled(true);
			this.revisaoComissaoService.setFinishShow(false);
			this.revisaoComissaoService.setFinishDisabled(true);
			this.revisaoComissaoService.setNextShow(true);
			this.revisaoComissaoService.setNextDisabled(true);
			this.revisaoComissaoService.setSaveShow(true);
			this.revisaoComissaoService.setSaveDisabled(true);
			this.revisaoComissaoService.setCancelShow(true);
			this.revisaoComissaoService.setCancelDisabled(false);
		}

		this.revisaoComissaoService.setSaveDisabled(false);
		this.revisaoComissaoService.setNextDisabled(false);
		this.revisaoComissaoService.setCancelDisabled(false);

		console.log(this.formField);

		this.$scope.breadcrumb = breadcrumbHelper;

		// setInterval(() => { console.log(this.formField); }, 20000);
	}

	hasNavNewCase() {
		console.log(this.$state.current.name);
		return this.$state.current.name !== 'case.revisao-comissao.detail';
	}

	navStatusIcon(status) {
		const warningClass = 'fa-exclamation-triangle warning';
		const successClass = 'fa-check-circle success';
		let cssClass = '';
		if (status === 'revisaoComissao.new') {
			cssClass = successClass;
		} else if (status === 'revisaoComissao.annex') {
			cssClass = successClass;
		}
		return cssClass;
	}

	save(isSend) {

		this.revisaoComissaoService.setErrorMessage('');
		this.$scope.$emit('changeMainShowLoading', true);

		if (this.isFormValid()) {
			this.saveOrSendCaseRevisaoComissao(isSend);
		} else {
			this.$scope.$emit('changeMainShowLoading', false);
		}
	}

	saveOrSendCaseRevisaoComissao(isSend) {

		this.revisaoComissaoService.saveCaseRevisaoComissao()
			.then((response) => {

				if (response.isSuccessful) {
					this.WithOrWithoutUpload(response, isSend);
				} else {
					this.revisaoComissaoService.setErrorMessage(response.errorMessages[0]);
					this.$scope.$emit('changeMainShowLoading', false);
				}
			})
			.catch(() => {
				this.$scope.$emit('changeMainShowLoading', false);
			});
	}

	isMessageSuccessSave() {
		this.$scope.$emit('changeMainShowLoading', false);
		this.revisaoComissaoService.setSuccessMessage('Solicitação salva com sucesso.');
	}

	WithOrWithoutUpload(response, isSend) {
		this.formField.id = response.caseId;
		this.revisaoComissaoFormService.setFormField(this.formField);
		const isHaveUpload = (this.formField.file.documents.files.length > 0);

		if (isHaveUpload) {
			this.upload(isSend);
		} else if (isSend) {
			this.send();
		} else {
			this.isMessageSuccessSave();
			this.delayMessageForSuccess();
		}
	}

	delayMessageForSuccess() {
		this.$timeout(() => {
			this.revisaoComissaoService.setSuccessMessage('');
		}, 5000);
	}

	upload(isSend) {

		this.formField.file.documents.show = true;
		const annexes = [this.formField.file.documents];

		const caseId = this.formField.id;
		const gedAttachmentType = 'outros';
		const caseType = 'revisao-comissao';

		this.$scope.$emit('changeMainShowLoading', true);
		this.annexHelper.uploadAnnexes(annexes, caseId, gedAttachmentType, caseType)
			.then(() => {
				this.revisaoComissaoService.setErrorMessage('');
				if (isSend) {
					this.send();
				} else {
					this.revisaoComissaoService.updateEditCaseData()
						.then((response) => {
							console.log('Request updateEditCaseData', response);
							this.$scope.$emit('changeMainShowLoading', false);
							if (response.isSuccessful) {
								this.revisaoComissaoService.setSuccessMessage('Solicitação salva com sucesso.');
								this.delayMessageForSuccess();
							} else {
								this.revisaoComissaoService.setErrorMessage(response.errorMessages[0]);
							}
						});
				}
			})
			.catch(() => {
				this.revisaoComissaoService.updateEditCaseData();
				const genericErrorMessage = 'Um ou mais arquivos não foram anexados. Os arquivos precisam ter até 8MB, não podem possuir o mesmo nome e devem ter as extensões permitidas.';
				this.revisaoComissaoService.setErrorMessage(genericErrorMessage);
				this.$scope.$emit('changeMainShowLoading', false);
			});
	}

	send() {
		this.revisaoComissaoService.sendCaseRevisaoComissao()
			.then((response) => {
				this.$scope.$emit('changeMainShowLoading', false);
				if (response.isSuccessful) {
					//this.revisaoComissaoService.setSuccessMessage('Revisão de Comissão enviado com sucesso.');
					//this.delayMessageForSuccess();
					this.$state.go('case.revisao-comissao.detail', { id: this.formField.id });
				} else {
					this.revisaoComissaoService.setErrorMessage(response.errorMessages[0]);
				}
			})
			.catch(() => {
				this.$scope.$emit('changeMainShowLoading', false);
			});
	}

	isFormValid() {

		//this.hasRequiredEstruturaVenda();
		this.hasRequiredMotivoRevisao();
		this.hasRequiredDescricaoRevisao();

		this.revisaoComissaoFormService.setFormField(this.formField);

		return (!this.formField.information.estruturavenda.hasError && !this.formField.information.motivorevisao.hasError && !this.formField.information.descricaorevisao.hasError)
	}

	hasRequiredFields() {
		const result = (
			!!this.formField.information.motivorevisao.value &&
			!!this.formField.information.descricaorevisao.value
		);

		return result;
	}

	cancelCurrentSolicitacao() {
		this.revisaoComissaoService.setErrorMessage('');
		this.$scope.$emit('changeMainShowLoading', true);
		if (this.formField.id !== null) {
			this.$scope.$emit('changeMainShowLoading', true);
			this.revisaoComissaoService.cancelRevisaoComissao(this.formField.id).then((response) => {
				this.$scope.$emit('changeMainShowLoading', false);
				if (response.isSuccessful === false) {
					this.revisaoComissaoService.setErrorMessage(response.errorMessages[0]);
				} else {
					this.$state.go('case.list');
				}
			});
		} else {
			//todo reset form
			this.$state.go('case.list');
			this.$scope.$emit('changeMainShowLoading', false);
		}

		this.$state.go('case.list');
		this.$scope.$emit('changeMainShowLoading', false);
	}

	hasRequiredEstruturaVenda() {

		let hasRequirement = true;

		if (this.formField.information.estruturavenda.value === null) {

			hasRequirement = false;
		}

		this.formField.information.estruturavenda.hasError = !hasRequirement;
		return hasRequirement;
	}

	hasRequiredMotivoRevisao() {
		let hasRequirement = true;

		if (!this.formField.information.motivorevisao.value) {
			hasRequirement = false;
		}

		this.formField.information.motivorevisao.hasError = !hasRequirement;
		return hasRequirement;
	}

	hasRequiredDescricaoRevisao() {
		let hasRequirement = true;

		if (this.formField.information.descricaorevisao.value === null) {
			hasRequirement = false;
		}

		this.formField.information.descricaorevisao.hasError = !hasRequirement;
		return hasRequirement;
	}

	navStatus(status) {

		this.formField = this.revisaoComissaoFormService.getFormField();

		if (status === 'revisaoComissao.new') {
			if (this.formField.information.active === true) {
				console.log('entrou information');
				return 'act';
				//} else if (this.formField.information.mainAccount.value !== null) {
				//	return 'edt';
			} else {
				return '';
			}
		} else if (status === 'revisaoComissao.annex') {
			console.log('entrou annex');
			if (this.formField.file.active === true) {
				return 'act';
				//} else if ((this.formField.tabResgateBank == "C") && (this.revisaoComissaoService.getIsEdit())) {
				//	return 'edt';
			} else {
				return '';
			}
		}
	}

	navStepStatus(state) {

		this.formField = this.revisaoComissaoFormService.getFormField();

		let stepStatus = '';
		if (state === this.formField.formStep) {
			return 'act';
		}
		if (state === 'case.revisao-comissao.new') {
			stepStatus = this.formField.information.status;
			if (stepStatus !== 'Não Iniciado') {
				return 'edt';
			}
		}
		if (state === 'case.revisao-comissao.annex') {
			stepStatus = this.formField.file.status;
			if (stepStatus !== 'Não Iniciado') { // && stepStatus !== 'Incompleto'
				return 'edt';
			}
		}
		return '';
	}

	navStepGoTo(state) {
		if (state === "case.revisao-comissao.new") {
			this.doPrevious();
		} else if (state === "case.revisao-comissao.annex") {
			this.doNext();
		}
	}

	posicionaBotoesInicial() {
		this.formField.information.active = false;
		this.revisaoComissaoService.setPreviousShow(false);
		this.revisaoComissaoService.setPreviousDisabled(true);
		this.revisaoComissaoService.setNextShow(true);
		this.revisaoComissaoService.setNextDisabled(false);
		this.revisaoComissaoService.setFinishDisabled(true);
		this.revisaoComissaoService.setFinishShow(false);
	}

	doNext() {

		if (this.formField.information.active === true) {

			if (this.isFormValid()) {

				this.formField.information.active = false;
				this.formField.file.active = true;
				this.formField.information.status = 'Concluido';
				this.formField.formStep = 'case.revisao-comissao.annex';
				this.revisaoComissaoFormService.setFormField(this.formField);

				this.revisaoComissaoService.setPreviousShow(true);
				this.revisaoComissaoService.setPreviousDisabled(false);
				this.revisaoComissaoService.setNextShow(false);
				this.revisaoComissaoService.setNextDisabled(true);
				this.revisaoComissaoService.setFinishDisabled(true);
				this.revisaoComissaoService.setFinishShow(true);
				this.$state.go('case.revisao-comissao.annex');

				this.$window.scrollTo(0, 0);
			}
		}

		this.revisaoComissaoService.setFinishShow(true);
	}

	doPrevious() {

		if (this.formField.information.active === false) {

			if (this.isFormValid()) {

				this.formField.information.active = true;
				this.formField.file.active = true;
				this.formField.information.status = 'Concluido';
				this.formField.formStep = 'case.revisao-comissao.new';
				this.revisaoComissaoFormService.setFormField(this.formField);

				this.revisaoComissaoService.setPreviousShow(true);
				this.revisaoComissaoService.setPreviousDisabled(false);
				this.revisaoComissaoService.setNextShow(true);
				this.revisaoComissaoService.setNextDisabled(false);
				this.revisaoComissaoService.setFinishDisabled(false);
				this.revisaoComissaoService.setFinishShow(true);
				this.$state.go('case.revisao-comissao.new');

				this.$window.scrollTo(0, 0);
			}
		}

		this.revisaoComissaoService.setFinishShow(true);
	}
}

module.exports = revisaoComissaoController;
