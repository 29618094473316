'use strict';

/* @ngInject */

function routesConfiguration($stateProvider, sfConfig) {

	$stateProvider.state('home', {
		url: '/home',
		views: {
			'content': {
				templateUrl: `${sfConfig.resource}/views/home/home.html`
			}
		}
	});

	// console.log(permitionsService.userPermitions);
	$stateProvider.state('account-list', {
		url: '/conta/lista',
		views: {
			'content': {
				templateUrl: `${sfConfig.resource}/views/account/account-list.html`
			}
		}
	});
	$stateProvider.state('resgate-list', {
		url: '/resgate/lista',
		views: {
			'content': {
				templateUrl: `${sfConfig.resource}/views/resgate/resgate-list.html`
			}
		}
	});
	$stateProvider.state('documents', {
		url: '/documentos',
		views: {
			'content': {
				templateUrl: `${sfConfig.resource}/views/documents/documents.html`
			}
		}
	});
};

module.exports = routesConfiguration;
