'use strict';

class documentacaoPropostaUploadController {

	// @ngInject
	constructor($scope, $window, $state, documentacaoPropostaFormControlService, documentacaoPropostaUploadApi, documentacaoPropostaApi) {
		// console.log('liberacaoDaCaucaoNewController');

		this.$window = $window;
		this.$scope = $scope;
		this.$state = $state;

		this.formField = documentacaoPropostaFormControlService;
		this.documentacaoPropostaUploadApi = documentacaoPropostaUploadApi;
		this.documentacaoPropostaApi = documentacaoPropostaApi;

		console.log(this.formField);

		this.requestCaseUpdate = this.pristineRequestCaseRequestObj();

		this.formField.formStep = 'case.documentacao-proposta.upload';

		this.checkStatusOnLoad();
	}

	checkStatusOnLoad() {
		// console.log('checkStatusOnLoad', this.formField);

		if (this.formField.information.file.status !== 'Concluido') {
			this.formField.information.file.status = 'Incompleto';
			console.log('checkStepStatus:', this.formField.information.file.status);
		}

		this.formField.information.file.form.show = true;
		this.formField.information.file.documentacaoProposta.show = true;
		//this.formField.annex.documentacaoProposta.other.show = true;

		//this.isLegalRepresentative();
		//this.cleanHiddenAnnexes();

		//this.$scope.$emit('validateUploads');
	}

	checkFileUpload(salesforceId) {
		return (typeof salesforceId == 'undefined')
	}

	hideTrashByStatus() {
		const statusToHideTrash = ['Reprovado', 'Pagamento Criticado'];

		return (statusToHideTrash.indexOf(this.formField.status) >= 0);
	}

	get tooltipProposta() {
		return '<p>Documento de subscrição do título de Capitalização assinado pelo subscritor/titular ou representante legal.</p>';
	}

	get tooltipPropostaCondicoesGerais() {
		return '<p>Resumo das condições gerais da proposta de subscrição do título de Capitalização contendo a rubrica do subscritor/titular em todas as páginas.</p>';
	}

	get tooltipCartaCaucao() {
		return '<p>Documento utilizado para registro da garantia de aluguel assinado pelo subscritor/titular e credor caucionado (locador/administrador do imóvel) ou seus representantes legais.</p>';
	}

	get tooltipAnexoPessoaJuridica() {
		return '<p>Documento contendo a relação dos controladores/sócios/procuradores da pessoa jurídica, assinado pelo representante legal que subscreveu a proposta.</p>';
	}

	pristineRequestCaseRequestObj() {
		return {
			document: {
				id: '',
				status: '',
				editCaseData: ''
			}
		}
	}

	uploadFiles(files, errFiles, type) {

		//this.resgateService.setErrorMessage('');
		if (!this.validateFilesSizes(files)) {
			//this.resgateService.setErrorMessage('Um dos arquivos selecionados excedeu o limite de 8MB');
			console.log('Um dos arquivos selecionados excedeu o limite de 8MB');
			return;
		}

		console.log('arquivo : ', files);
		console.log('tipo : ', type);

		console.log(this.formField);

		const annex = this.getAnnexByType(type);

		if (!annex.files) annex.files = [];

		annex.files = annex.files.concat(files);

		this.removeDuplicatedFiles(annex);

		this.$scope.myFormField.setFormField(this.formField);

		//this.$scope.$emit('validateUploads');
	}

	getAnnexByType(type) {

		let annex;

		console.log(this);

		if (type === 'proposta') {
			annex = this.formField.information.file.documentacaoProposta.proposta;
		} else if (type === 'condicoesgerais') {
			annex = this.formField.information.file.documentacaoProposta.condicoesgerais;
		} else if (type === 'cartacaucao') {
			annex = this.formField.information.file.documentacaoProposta.cartacaucao;
		} else if (type === 'anexopj') {
			annex = this.formField.information.file.documentacaoProposta.anexopj;
		}

		return annex;
	}

	isDuplicatedFileName(fileNames, fileName) {
		return fileNames.indexOf(fileName) !== -1;
	}

	validateFilesSizes(annex) {
		let isValid = true;
		const sizeLimit = 8388608 //8MB

		for (let i = annex.length - 1; i >= 0; i--) {
			if (annex[i].size > sizeLimit) {
				isValid = false;
				break;
			}
		}

		return isValid;
	}

	removeDuplicatedFiles(annex) {
		console.log('removeDuplicatedFiles', annex);

		const fileNames = [];

		for (let i = 0; i > annex.files.length; i++) {

			const fileName = annex.files[i].name;

			if (this.isDuplicatedFileName(fileNames, fileName)) {

				annex.files.splice(i, 1);
			}

			fileNames.push(fileName);
		}
	}

	removeFiles(itemIndex, type) {
		const annex = this.getAnnexByType(type);

		if (!annex.files[itemIndex].result) {

			annex.files.splice(itemIndex, 1);

			return;
		}

		this.$scope.$emit('changeMainShowLoading', true);

		this.documentacaoPropostaApi.deleteAttachmentFile(annex.files[itemIndex].salesForceId).then((response) => {

			this.$scope.$emit('changeMainShowLoading', true);

			if (!response.isSuccessful) {

				this.$window.scrollTo(0, 0);

				const errorMessage = angular.toJson(response.errorMessages);

				this.messages.showErrorMessage(this.$scope, errorMessage);

				return;
			}

			annex.files.splice(itemIndex, 1);

			this.formField.setFormField(this.formField);

			this.requestCaseUpdate.document.id = this.$scope.myFormField.id;
			this.requestCaseUpdate.document.status = this.$scope.myFormField.status;
			this.requestCaseUpdate.document.editCaseData = JSON.stringify(this.$scope.$parent.myFormField.information);

			this.documentacaoPropostaApi.updateCaseDocumento(this.requestCaseUpdate).then((response) => {
				this.$scope.$emit('changeMainShowLoading', false);
			});

			this.$scope.$emit('validateUploads');
		});
	}

}

module.exports = documentacaoPropostaUploadController;
