'use strict';

//Controller Base
const reaplicacaoTituloController = require('./reaplicacao-titulo.controller');
const reaplicacaoTituloService = require('./reaplicacao-titulo.service');

// Upload 
//const bloqReaplicacaoAnnexController = require('./bloq-reaplicacao-annex/bloq-reaplicacao-annex.controller');
//const bloqReaplicacaoAnnexService = require('./bloq-reaplicacao-annex/bloq-reaplicacao-annex.service');

//Detail
const reaplicacaoTituloDetailController = require('./reaplicacao-titulo-detail/reaplicacao-titulo.detail.controller');
const reaplicacaoTituloDetailService = require('./reaplicacao-titulo-detail/reaplicacao-titulo.detail.service');

//New
const reaplicacaoTituloNewController = require('./reaplicacao-titulo-new/reaplicacao-titulo.new.controller');
const reaplicacaoTituloNewService = require('./reaplicacao-titulo-new/reaplicacao-titulo.new.service');

//View Model
const reaplicacaoTituloFormService = require('./reaplicacao-titulo.form.service');

//Routes
const routesConfiguration = require('./reaplicacao-titulo.routes');

//Depedency
const componentModule = angular.module('cap.secured.case.reaplicacao-titulo', [
		'ui.router'
	])
	.controller('reaplicacaoTituloController', reaplicacaoTituloController)
	.service('reaplicacaoTituloService', reaplicacaoTituloService)

	.controller('reaplicacaoTituloNewController', reaplicacaoTituloNewController)
	.service('reaplicacaoTituloNewService', reaplicacaoTituloNewService)

	.controller('reaplicacaoTituloDetailController', reaplicacaoTituloDetailController)
	.service('reaplicacaoTituloDetailService', reaplicacaoTituloDetailService)

	.service('reaplicacaoTituloService', reaplicacaoTituloService)

	.service('reaplicacaoTituloFormService', reaplicacaoTituloFormService)

	.config(routesConfiguration);

module.exports = componentModule;
