'use strict';

import VisualForceApi from '../../core/sfdc/visual-force-api.js';

class NewJuridicalAccountService extends VisualForceApi {

	/* @ngInject */
	constructor($q) {
		super();
		this.$q = $q;
	}

	createNewAccount(newAccount) {

		const cap_access_token = localStorage.getItem("cap_access_token");
		newAccount.accessToken = cap_access_token;

		return super.invoke(
			'CapCommunityAccountControllerExt.createNewAccountPj', newAccount);
	}

	getAccountDetails(accountId) {

		const cap_access_token = localStorage.getItem("cap_access_token");

		return super.invoke(
			'CapCommunityAccountControllerExt.getAccountDetails', accountId, cap_access_token);
	}

	searchResidenciaFiscal(searchKeyWord) {
		const deferred = this.$q.defer();

		const cap_access_token = localStorage.getItem("cap_access_token");

		super.invoke(
				'CapCommunityAccountControllerExt.getResidenciaFiscal', searchKeyWord, cap_access_token)
			.then((data) => {
				deferred.resolve(data.paises);
			});

		return deferred.promise;
	}

}

module.exports = NewJuridicalAccountService;
