'use strict';

class bloqReaplicacaoNewController {

	// @ngInject
	constructor(bloqReaplicacaoFormService, propostaNewApi, autoCompleteService, $scope, $state, sobjectQueryService, bloqReaplicacaoService, bloqReaplicacaoNewService, $timeout, breadcrumbHelper) {
		this.bloqReaplicacaoFormService = bloqReaplicacaoFormService;
		this.$timeout = $timeout;
		this.$state = $state;
		this.sobjectQueryService = sobjectQueryService;
		this.bloqReaplicacaoService = bloqReaplicacaoService;
		this.bloqReaplicacaoNewService = bloqReaplicacaoNewService;
		this.autoComplete = autoCompleteService;
		this.$scope = $scope;
		this.propostaNewApi = propostaNewApi;
		this.$scope.breadcrumb = breadcrumbHelper;
		this.getFormField();

		this.bloqReaplicacaoService.setPristine();
		this.bloqReaplicacaoService.setFinishShow(true);
		this.bloqReaplicacaoService.setCancelShow(true);

		this.formField.status = (this.formField.status === null) ? 'Enviado' : this.formField.status;
		if (this.bloqReaplicacaoService.getIsEdit() === true) {
			this.propostas = [];
			this.propostas.push(this.formField.information.proposta.value);
		} else {
			this.propostas = (this.formField.information.proposta.value !== null) ? this.bloqReaplicacaoNewService.getPropostas() : [];
		}

		this.configureBreadCrumb();

	}

	configureBreadCrumb() {
		this.$scope.breadcrumb.reset();
		this.$scope.breadcrumb.addMenuItem('Home', '/caponline/secured#!/home', true, false);
		this.$scope.breadcrumb.addMenuItem('Solicitação', '/caponline/secured#!/solicitacoes/lista', true, false);
		this.$scope.breadcrumb.addMenuItem('Novo Bloqueio de Reaplicação', '#', false, true);
	}

	/**
	 *  Pega o estado do formulario
	 *
	 */

	getFormField() {
		this.formField = this.bloqReaplicacaoFormService.getFormField();
	}

	/** 
	 * Pesquisar Contas
	 */
	searchAccount(searchString) {
		console.log('entrou no searchAccount');
		const bloqReaplicacaoNew = this.$parent.bloqReaplicacaoNew;
		return bloqReaplicacaoNew.bloqReaplicacaoNewService.searchAccount(searchString);
	}

	/** 
	 * Selecionar a Conta
	 */
	searchAccountSelect(item) {
		console.log('entrou no searchAccountSelect');
		const bloqReaplicacaoNew = this.$parent.bloqReaplicacaoNew;
		if (bloqReaplicacaoNew.formField.information.mainAccount.value === null) {
			if (!item) { return; }

			const hasAction = !!item.originalObject.action;

			if (hasAction) {

				const action = item.originalObject.action;

				bloqReaplicacaoNew[action](item);

				return;
			}

			bloqReaplicacaoNew.formField.information.mainAccount.value = item.originalObject;
			bloqReaplicacaoNew.formField.information.mainAccount.hasError = !bloqReaplicacaoNew.hasAccount();
			bloqReaplicacaoNew.bloqReaplicacaoService.setSaveShow(true);
			bloqReaplicacaoNew.bloqReaplicacaoService.setSaveDisabled(false);
			bloqReaplicacaoNew.bloqReaplicacaoService.setCancelShow(true);
			bloqReaplicacaoNew.bloqReaplicacaoService.setCancelDisabled(false);
			bloqReaplicacaoNew.bloqReaplicacaoService.setNextShow(true);
			bloqReaplicacaoNew.bloqReaplicacaoService.setNextDisabled(false);
			bloqReaplicacaoNew.formField.information.mainAccount.loading = true;
			bloqReaplicacaoNew.getPropostasByAccountId(bloqReaplicacaoNew.formField.information.mainAccount.value.Id);
			bloqReaplicacaoNew.bloqReaplicacaoFormService.setFormField(bloqReaplicacaoNew.formField);
		}
	}

	searchAccountFocusOut() {
		this.$timeout(() => {
			if (!this.autoComplete.hasValidInput(this.formField.information.mainAccount.value)) {
				this.bloqReaplicacaoService.setSaveShow(true);
				this.bloqReaplicacaoService.setSaveDisabled(true);
				this.bloqReaplicacaoService.setCancelShow(true);
				this.bloqReaplicacaoService.setCancelDisabled(true);
				this.bloqReaplicacaoService.setNextShow(true);
				this.bloqReaplicacaoService.setNextDisabled(true);
				this.autoComplete.clearInput(this.$scope, 'searchAccount');
				this.bloqReaplicacaoFormService.setFormField(this.formField);
			}
		}, 100);
	}

	searchAccountChanged() {
		const bloqReaplicacaoNew = this.$parent.bloqReaplicacaoNew;
		bloqReaplicacaoNew.bloqReaplicacaoService.cleanMessages();
		bloqReaplicacaoNew.autoComplete.resetSelected(bloqReaplicacaoNew.formField.information.mainAccount, 'value');
		bloqReaplicacaoNew.propostas = [];
		bloqReaplicacaoNew.bloqReaplicacaoNewService.setPropostas(bloqReaplicacaoNew.propostas);
		console.log('reset');
		bloqReaplicacaoNew.resetForm();
		bloqReaplicacaoNew.bloqReaplicacaoFormService.resetFormFile();
	}

	hasAccount() {
		return !!this.formField.information.mainAccount.value;
	}

	hasProposta() {
		return !!this.formField.information.proposta.value;
	}

	/** 
	 * Pegar propostas relacionadas a Conta
	 */
	getPropostasByAccountId(accountId) {
		this.propostas = [];
		this.bloqReaplicacaoNewService.setPropostas(this.propostas);
		this.sobjectQueryService.getPropostasByAccountId(accountId).then((response) => {
			this.formField.information.mainAccount.loading = false;
			if (response.isSuccessful) {
				if (response.result.length > 0) {
					this.propostas = response.result;
					this.bloqReaplicacaoNewService.setPropostas(response.result);
				} else {
					this.bloqReaplicacaoService.setErrorMessage(' Cliente não possui nenhuma proposta apta para a solicitação');
				}
			} else {
				console.log('error ', response);
			}
		});
	}

	/** 
	 * Ao mudar uma proposta, será chamado este método
	 */
	changePropostaField() {
		console.log('entrou no changepropostaField');
		if (this.formField.information.mainAccount.value != null) {
			this.resetForm();
			this.bloqReaplicacaoFormService.resetFormFile();
			this.bloqReaplicacaoService.cleanMessages();
			if (this.formField.information.proposta.value !== null)
				this.getGarantiasByPropostaId(this.formField.information.proposta.value.objectId);
			this.bloqReaplicacaoFormService.setFormField(this.formField);
		}
	}

	resetForm() {
		this.formField.information.garantias.value = null;
		this.formField.information.garantias.hasError = false;
		this.formField.information.motivobloqueio.value = null;
		this.formField.information.motivobloqueio.hasError = false;
		this.formField.information.autorizacaobloqueio.value = null;
		this.formField.information.autorizacaobloqueio.hasError = false;
		this.bloqReaplicacaoFormService.setFormField(this.formField);
	}

	/** 
	 * Ao ser chamado irá carregar os títulos referentes aquela proposta
	 */
	getGarantiasByPropostaId(propostaId) {
		console.log('entrou no getGarantiasByPropostaId');

		this.formField.information.proposta.loading = true;

		//CAP-2614
		const cpfcnpj = this.formField.information.mainAccount.value.cpf;

		this.sobjectQueryService.getGarantiasByPropostaId(propostaId, 'bloqueio-reaplicacao', cpfcnpj).then((response) => {
			//FIM CAP-2614
			this.formField.information.proposta.loading = false;
			console.log('Response getGarantias: ', response);
			if (response.isSuccessful) {
				if (response.result.length > 0) {
					this.bloqReaplicacaoService.cleanMessages();
					this.formField.information.garantias.value = [];
					for (let i = 0; i < response.result.length; i++) {

						this.formField.information.garantias.value.push({
							objectId: response.result[i].objectId,
							objectName: response.result[i].objectName,
							vigencia: response.result[i].vigencia,
							finalVigencia: response.result[i].finalVigencia,
							selected: true,
							disabled: false,
							value: response.result[i].value,
							antecipatedValue: response.result[i].antecipatedValue,
							numeroTitulo: response.result[i].numeroTitulo
						});
					}
				} else {

					this.bloqReaplicacaoService.setWarningMessage('Um ou mais títulos relacionados à proposta selecionada já possui uma solicitação em andamento. Realize a busca da proposta ou título(s), utilizando a barra de pesquisa, e verifique o número da(s) solicitação(ões) atrelada(s) para visualizar seu status e possibilidade de dar continuidade à solicitação ou realizar o cancelamento.');
				}
			} else {
				this.bloqReaplicacaoService.setErrorMessage(response.errorMessages[0]);
			}
		});
	}

	getCheckedGarantiaData(check) {
		const garantiasSelected = [];
		for (let i = 0; i < this.formField.information.garantias.value.length; i++) {
			if (this.formField.information.garantias.value[i].selected && check) {
				garantiasSelected.push(this.formField.information.garantias.value[i].objectId)
				console.log('GARANTIAS SELECIONADA: ', garantiasSelected)
			} else {
				console.log('GARANTIAS SELECIONADA: ', garantiasSelected)
				garantiasSelected.splice(garantiasSelected.indexOf(this.formField.information.garantias.value[i].objectId), 1)
			}
		}
		//this.$scope.$emit('changeMainShowLoading', true);
		/*const garantiaDataResponse = this.bloqReaplicacaoNewService.getGarantiaDataById(garantiasSelected[0]);
		if (garantiaDataResponse != null) {
			this.formField.garantiaData.subscritor.cpfcnpj.value = 'TESTEHAAA';
			console.log('Response: ', garantiaDataResponse);
		} else {
			this.formField.garantiaData.subscritor.cpfcnpj.value = 'TESTEHAAA2';
			console.log('Response: ', garantiaDataResponse);
		}*/
		//this.$scope.$emit('changeMainShowLoading', false);
		this.bloqReaplicacaoService.getGarantiaDataById(garantiasSelected[0]);
		//this.bloqReaplicacaoFormService.setFormField(this.formField);
		console.log('ID DA GARANTIA COM OS DADOS CARREGADOS: ', garantiasSelected[0]);
	}

	changeCheckGarantia(garantiaId, check) {
		console.log(garantiaId);
		console.log(check);
		this.bloqReaplicacaoFormService.resetFormFile();
		let type = '';
		for (let i = 0; i < this.formField.information.garantias.value.length; i++) {
			if (garantiaId === this.formField.information.garantias.value[i].objectId) {
				console.log('match');
				if (this.formField.information.garantias.value[i].objectName.includes('(Descaucionado)')) {
					type = 'Descaucionado';
				} else {
					type = 'Caucionado';
				}
				break;
			}
		}
		if (check) {
			if (type === 'Caucionado') {
				for (let i = 0; i < this.formField.information.garantias.value.length; i++) {
					if (this.formField.information.garantias.value[i].objectName.includes('(Descaucionado)')) {
						this.formField.information.garantias.value[i].disabled = true;
					} else {
						this.formField.information.garantias.value[i].disabled = false;
					}
				}
			} else {
				for (let i = 0; i < this.formField.information.garantias.value.length; i++) {
					if (this.formField.information.garantias.value[i].objectName.includes('(Caucionado)')) {
						this.formField.information.garantias.value[i].disabled = true;
					} else {
						this.formField.information.garantias.value[i].disabled = false;
					}
				}
			}
		} else {
			if (type === 'Caucionado') {
				let founded = false;
				for (let i = 0; i < this.formField.information.garantias.value.length; i++) {
					if (this.formField.information.garantias.value[i].objectName.includes('(Caucionado)')) {
						if (this.formField.information.garantias.value[i].selected) {
							founded = true;
							break;
						}
					}
				}
				if (!founded) {
					for (let i = 0; i < this.formField.information.garantias.value.length; i++) {
						this.formField.information.garantias.value[i].disabled = false;
					}
				}
			} else {
				let founded = false;
				for (let i = 0; i < this.formField.information.garantias.value.length; i++) {
					if (this.formField.information.garantias.value[i].objectName.includes('(Descaucionado)')) {
						if (this.formField.information.garantias.value[i].selected) {
							founded = true;
							break;
						}
					}
				}
				if (!founded) {
					for (let i = 0; i < this.formField.information.garantias.value.length; i++) {
						this.formField.information.garantias.value[i].disabled = false;
					}
				}
			}
		}
		this.getCheckedGarantiaData(check);

		this.bloqReaplicacaoFormService.setFormField(this.formField);
	}

}

module.exports = bloqReaplicacaoNewController;
