'use strict';

class administrarUsuarioDetailApi {

	/* @ngInject */
	constructor(visualForceApi) {

		this.visualForceApi = visualForceApi;
	}

	getDetails(userId) {

		return this.visualForceApi.invoke(
			'CapCommunityUserControllerExt.getUserDetail', userId);
	}
}

module.exports = administrarUsuarioDetailApi;
