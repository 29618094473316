'use strict';

class administrarUsuarioListController {

	// @ngInject
	constructor($scope, $window, $state, administrarUsuarioListApi, paginationHelper, feedbackMessages, ordenationHelper, breadcrumbHelper) {

		this.$window = $window;
		this.$scope = $scope;
		this.$state = $state;
		this.messages = feedbackMessages;
		this.api = administrarUsuarioListApi;
		this.pages = paginationHelper;
		this.order = ordenationHelper;
		this.order.setPristine();
		this.recordsList = [];
		this.$scope.breadcrumb = breadcrumbHelper;

		this.getListViewOptions(true);
		this.configureBreadCrumb();
	}

	configureBreadCrumb() {
		this.$scope.breadcrumb.reset();
		this.$scope.breadcrumb.addMenuItem('Home', '/caponline/secured#!/home', true, false);
		this.$scope.breadcrumb.addMenuItem('Administrar usuário', '/caponline/secured#!/administrar-usuario/lista', false, true);
	}

	getListViewOptions(getFirstList) {

		this.api.getListViewOptions().then((response) => {

			if (!response.isSuccessful) {

				this.$window.scrollTo(0, 0);

				const errorMessage = angular.toJson(response.errorMessages);

				this.messages.showErrorMessage(this.$scope, errorMessage);
			} else {

				this.listViewOptions = response.options;

				this.listViewOptionSelected = this.listViewOptions[0];

				getFirstList && this.getList(true);
			}
		});
	}

	changeOrder(field) {
		this.order.changeOrder(field);
		this.pages.setPagesPristine();
		this.getList(true);
	}

	changeViewOption() {
		this.order.setPristine();
		this.pages.setPagesPristine();
		this.getList(true);
	}

	getList(newSearch, queryModel) {

		this.showLoading = true;
		this.messages.cleanMessages(this.$scope);

		queryModel = queryModel || {
			queryType: this.listViewOptionSelected.queryType,
			queryLimit: this.pages.limitOfRecords,
			queryOffset: ((this.pages.actualPage - 1) * this.pages.limitOfRecords),
			queryOrderField: this.order.data.field,
			queryOrderDirection: this.order.data.direction,
			isQueryCount: newSearch
		};

		this.lastQuery = angular.copy(queryModel);

		this.api.getList(queryModel).then((response) => {

			this.showLoading = false;

			if (!response.isSuccessful) {

				if (response.errorMessages) {

					this.$window.scrollTo(0, 0);

					const errorMessage = angular.toJson(response.errorMessages);

					this.messages.showErrorMessage(this.$scope, errorMessage);
				}

				this.recordsList = [];
				this.pages.totalOfRecords = 0;
			} else {

				this.recordsList = response.records;

				if (newSearch) {

					this.pages.totalOfRecords = response.queryCount;
					this.pages.setPaginationParams();
				}
			}
		});
	}

	doPrevious() {
		this.pages.actualPage--;
		if (this.isPageValid()) {
			this.showLoading = true;
			this.getList(false);
		} else {
			this.pages.actualPage++;
		}
		console.log('Previous: ', this.pages.actualPage);
	}

	doNext() {
		this.pages.actualPage++;
		if (this.isPageValid()) {
			this.showLoading = true;
			this.getList(false);
		} else {
			this.pages.actualPage--;
		}
		console.log('Next: ', this.pages.actualPage);
	}

	hasNavigation() {

		return !this.showLoading && this.pages.totalOfPages > 1;
	}

	editUser(userId) {

		this.$state.go('administrar-usuario.edit', { id: userId });
	}

	setUserToRemove(userId) {

		this.userToRemove = userId;
	}

	isPageValid() {

		return (this.pages.actualPage > 0 && this.pages.actualPage <= this.pages.totalOfPages)
	}

	removeUser(userId) {

		userId = userId || this.userToRemove;

		this.$scope.$emit('changeMainShowLoading', true);
		this.messages.cleanMessages(this.$scope);

		this.api.removeUser(userId).then((response) => {

			this.$scope.$emit('changeMainShowLoading', false);

			if (!response.isSuccessful) {

				if (response.errorMessages) {

					this.$window.scrollTo(0, 0);

					const errorMessage = angular.toJson(response.errorMessages);

					this.messages.showErrorMessage(this.$scope, errorMessage);
				}
			} else {

				this.getList(this.lastQuery.isQueryCount, this.lastQuery);
			}
		});
	}
}

module.exports = administrarUsuarioListController;
