'use strict';

class rentGuaranteeListApiService {

	/* @ngInject */
	constructor($q, visualForceApi) {

		this.visualForceApi = visualForceApi;
		this.$q = $q;
	}

	getListViewOptions() {

		const deferred = this.$q.defer();

		const listViewOptions = {

			options: [

				{ label: 'Todos os títulos', queryType: 'todos', selected: true },
				{ label: 'Títulos descaucionados', queryType: 'titulos-descaucionados', selected: false },
				{ label: 'Títulos caucionados', queryType: 'Titulos-caucionados', selected: false },
				//{ label: 'Títulos com bloqueio de reaplicação', queryType: 'Titulos-Bloqueio-reaplicacao', selected: false },
				{ label: 'Títulos ativos', queryType: 'Titulos-ativos', selected: false },
				{ label: 'Títulos com bloqueio judicial', queryType: 'Titulos-Bloqueio-Judicial', selected: false },
				{ label: 'Títulos emitidos hoje', queryType: 'Titulos-emitidos-hoje', selected: false },
				{ label: 'Títulos com menos de 30 dias para o fim de vigência', queryType: 'Títulos-menos-30 dias', selected: false },
				{ label: 'Títulos com menos de 60 dias para o fim de vigência', queryType: 'Títulos-menos-60 dias', selected: false },
				{ label: 'Exibidas recentemente', queryType: 'exibidas-recentemente', selected: false }
			],
			isSuccessful: true
		};

		deferred.resolve(listViewOptions);

		return deferred.promise;
	}

	getList(query) {

		return this.visualForceApi.invoke(
			'CapCommunityGarantAluguelControllerExt.getGarantiaAluguelList', query);
	}
}

module.exports = rentGuaranteeListApiService;
