'use strict';

/* @ngInject */
function routesConfiguration($stateProvider, sfConfig) {

	$stateProvider.state('proposta', {

		abstract: true,
		url: '/proposta',
		views: {

			'content': {

				templateUrl: `${sfConfig.resource}/views/proposta/proposta.html`,
				controller: 'propostaController',
				controllerAs: 'proposta'
			}
		}
	});

	$stateProvider.state('proposta.list', {

		url: '/lista',
		views: {
			'proposta': {
				templateUrl: `${sfConfig.resource}/views/proposta/proposta-list/proposta-list.html`,
				controller: 'propostaListController',
				controllerAs: 'listController'
			}
		}
	});

	$stateProvider.state('proposta.new', {

		url: '/nova',
		views: {
			'proposta': {
				templateUrl: `${sfConfig.resource}/views/proposta/proposta-new/proposta-new.html`,
				controller: 'propostaNewController',
				controllerAs: 'propostaNew'
			}
		}
	});

	$stateProvider.state('proposta.information', {

		url: '/nova/informacao',
		views: {
			'proposta': {
				templateUrl: `${sfConfig.resource}/views/proposta/proposta-information/proposta-information.html`,
				controller: 'propostaInformationController',
				controllerAs: 'propostaInformation'
			}
		}
	});

	$stateProvider.state('proposta.caucao', {

		url: '/nova/caucao',
		views: {
			'proposta': {
				templateUrl: `${sfConfig.resource}/views/proposta/proposta-caucao/proposta-caucao.html`,
				controller: 'propostaCaucaoController',
				controllerAs: 'propostaCaucao'
			}
		}
	});

	$stateProvider.state('proposta.payment', {

		url: '/nova/payment',
		views: {
			'proposta': {
				templateUrl: `${sfConfig.resource}/views/proposta/proposta-payment/proposta-payment.html`,
				controller: 'propostaPaymentController',
				controllerAs: 'propostaPayment'
			}
		}
	});

	$stateProvider.state('proposta.generate', {

		url: '/nova/gerar',
		views: {
			'proposta': {
				templateUrl: `${sfConfig.resource}/views/proposta/proposta-generate/proposta-generate.html`,
				controller: 'propostaGenerateController',
				controllerAs: 'propostaGenerate'
			}
		}
	});

	$stateProvider.state('proposta.boleto', {

		url: '/nova/boleto',
		views: {
			'proposta': {
				templateUrl: `${sfConfig.resource}/views/proposta/proposta-gerar-boleto/proposta-boleto.html`,
				controller: 'propostaBoletoController',
				controllerAs: 'propostaBoleto'
			}
		}
	});

	// $stateProvider.state('proposta.file', {

	// 	url: '/nova/arquivo',
	// 	views: {
	// 		'proposta': {
	// 			templateUrl: `${sfConfig.resource}/views/proposta/proposta-file/proposta-file.html`,
	// 			controller: 'propostaFileController',
	// 			controllerAs: 'propostaFile'
	// 		}
	// 	}
	// });

	$stateProvider.state('proposta-detail', {
		url: '/proposta/detalhes?:id',
		views: {
			'content': {
				templateUrl: `${sfConfig.resource}/views/proposta/proposta-detail/proposta-detail.html`,
				controller: 'propostaDetailController',
				controllerAs: 'propostaDetail'
			}
		}

	});
};

module.exports = routesConfiguration;
