'use strict';

class documentacaoPropostaController {

	// @ngInject
	constructor($scope, $window, $state, $stateParams, $timeout, documentacaoPropostaApi, documentacaoPropostaFormControlService, resgateService, breadcrumbHelper, annexHelper, resgateFormService, liberacaoDaCaucaoFormControlService) {
		console.log('documentacaoPropostaController');

		this.$scope = $scope;
		this.$window = $window;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.$timeout = $timeout;
		this.annexHelper = annexHelper;

		this.resgateFormService = resgateFormService;
		this.liberacaoDaCaucaoFormControlService = liberacaoDaCaucaoFormControlService;

		this.api = documentacaoPropostaApi;
		this.documentacaoPropostaFormControlService = documentacaoPropostaFormControlService;
		this.formField = this.documentacaoPropostaFormControlService.getFormField();
		this.$scope.myFormField = this.formField;

		console.log('Constructor documentacaoPropostaController');
		console.log(this.formField);
		console.log(this.$scope.$parent)

		//this.formField = this.formField.getFormField();
		this.resgateService = resgateService;
		this.resgateService.setPristine(); //@todo 
		this.caseDocumentacaoRequest = this.pristineRequestCaseRequestObj();
		this.$scope.breadcrumb = breadcrumbHelper;

		this.case = {
			document: null
		};

		this.getCaseDetails();
		this.posicionaBotoesInicial();
	}

	hasValidUrlParams() {
		return (this.$stateParams && this.$stateParams.id) ? true : false;
	}

	getCaseDetails() {
		if (!this.hasValidUrlParams()) {
			this.showLoading = false;
			this.$state.go('case.list');
		}
		this.$scope.$emit('changeMainShowLoading', true);
		this.api.getDetails(this.$stateParams.id).then((response) => {
			this.$scope.$emit('changeMainShowLoading', false);
			// console.log(response);
			if (!response.isSuccessful) {
				this.errorMessage = response.errorMessages[0];
			} else {
				this.case.document = response.document;
				this.formField.id = this.$stateParams.id;
				if (response.document.editCaseData != '') {
					this.formField.information = JSON.parse(response.document.editCaseData);
					this.formField.information.active = true;
					this.documentacaoPropostaFormControlService.setFormField(this.formField);
				}

				console.log("GET DETAIL CASE: ", this.case);
			}
			this.showLoading = false;
		});
	}

	hasNavNewCase() {
		return this.$state.current.name !== 'case.documentacao-proposta.detail';
	}

	hasNavButtons() {
		return this.$state.current.name !== 'case.documentacao-proposta.detail';
	}

	saveCaseInstance() {
		this.checkUpload();
	}

	pristineAnnexObj() {
		return {
			files: [],
			hasError: false,
			loading: false,
			show: false,
			uploaded: false,
			uploadHasFailure: false
		};
	}

	isValidUploads() {
		const isHaveProposta = (this.$scope.myFormField.information.file.documentacaoProposta.proposta.files.length > 0);
		/*const isAnexoAPJ = (!this.case.document.clients[0].isPersonAccount ? this.$scope.myFormField.information.file.documentacaoProposta.anexopj.files.length > 0 : true);
		const isCartaCaucao = (this.$scope.myFormField.information.file.documentacaoProposta.cartacaucao.files.length > 0);
		const isHaveCondicoesgerais = (this.$scope.myFormField.information.file.documentacaoProposta.condicoesgerais.files.length > 0);*/
		return (isHaveProposta /*&& isAnexoAPJ && isCartaCaucao && isHaveCondicoesgerais*/ );
	}

	checkUpload() {

		this.resgateService.setErrorMessage('');

		if (this.isValidUploads()) {

			const annexes = this.getCurrentAnnexes();
			const caseId = this.$scope.myFormField.id;
			const gedAttachmentType = 'outros';
			const caseType = 'documentacao-da-proposta';

			this.$scope.$emit('changeMainShowLoading', true);

			this.annexHelper.uploadAnnexes(annexes, caseId, gedAttachmentType, caseType)
				.then(() => {

					this.resgateService.setErrorMessage('');
					this.saveCaseChangeStatus();
				})
				.catch(() => {
					//this.api.updateEditCaseData(this.$scope.myFormField.information);
					const genericErrorMessage = 'Um ou mais arquivos não foram anexados. Os arquivos precisam ter até 8MB, não podem possuir o mesmo nome e devem ter as extensões permitidas.';
					this.resgateService.setErrorMessage(genericErrorMessage);

					this.$scope.$emit('changeMainShowLoading', false);
				});
		} else {
			this.resgateService.setErrorMessage('Por favor anexe todos os documentos.');
		}
	}

	pristineRequestCaseRequestObj() {
		return {
			document: {
				id: '',
				status: '',
				editCaseData: ''
			}
		}
	}

	saveCaseChangeStatus() {

		File.prototype.toJSON = function () {
			return {
				// "index": this.index,
				// "$$hashKey": this.$$hashKey,
				"name": this.name,
				"result": true,
				"salesForceId": this.salesForceId
			};
		};

		this.$scope.$emit('changeMainShowLoading', true);
		this.$scope.myFormField.status = 'Enviado';
		this.formField.setFormField(this.$scope.myFormField);

		this.caseDocumentacaoRequest.document.id = this.$scope.myFormField.id;
		this.caseDocumentacaoRequest.document.status = this.$scope.myFormField.status;

		this.caseDocumentacaoRequest.document.editCaseData = JSON.stringify(this.$scope.myFormField.information);

		this.api.sendCaseDocumento(this.caseDocumentacaoRequest).then((response) => {
			this.$scope.$emit('changeMainShowLoading', false);

			this.goStateCase();

		});
	}

	goStateCase() {

		console.log('########################################');
		console.log(this.resgateFormService.formField.popupStategoControl);
		console.log(this.liberacaoDaCaucaoFormControlService.popupStategoControl);
		console.log('########################################');

		if (!!this.resgateFormService.formField.popupStategoControl && this.resgateFormService.formField.popupStategoControl.destino == 'case.documentacao-proposta.detail') {
			this.$state.go(this.resgateFormService.formField.popupStategoControl.origem);
			this.resgateFormService.resetPopupStateGoControl();
			console.log('######################################## CLEAN');
			console.log(this.resgateFormService);
		} else if (!!this.liberacaoDaCaucaoFormControlService.popupStategoControl && this.liberacaoDaCaucaoFormControlService.popupStategoControl.destino == 'case.documentacao-proposta.detail') {
			this.$state.go(this.liberacaoDaCaucaoFormControlService.popupStategoControl.origem);
			this.liberacaoDaCaucaoFormControlService.resetPopupStateGoControl();
			console.log('######################################## CLEAN');
			console.log(this.liberacaoDaCaucaoFormControlService);
		} else {
			this.$state.go('case.documentacao-proposta.detail', { id: this.$scope.myFormField.id });
			this.documentacaoPropostaFormControlService.setPristine();
		}
	}

	getCurrentAnnexes() {

		const annexes = [
			this.$scope.myFormField.information.file.documentacaoProposta.proposta,
			this.$scope.myFormField.information.file.documentacaoProposta.anexopj,
			this.$scope.myFormField.information.file.documentacaoProposta.cartacaucao,
			this.$scope.myFormField.information.file.documentacaoProposta.condicoesgerais
		];

		return annexes;
	}

	navStepGoTo(state) {
		if (state === "case.documentacao-proposta.new") {
			this.doPrevious();
		} else if (state === "case.documentacao-proposta.upload") {
			this.doNext();
		}
	}

	doPrevious() {
		console.log('BSLR-0');
		this.resgateService.setErrorMessage('');
		console.log('BSLR-1');
		this.posicionaBotoesInicial();
		this.$state.go('case.documentacao-proposta.new');

		this.$window.scrollTo(0, 0);

	}

	posicionaBotoesInicial() {
		this.formField.information.file.active = true;
		this.resgateService.setPreviousShow(true);
		this.resgateService.setPreviousDisabled(false);
		this.resgateService.setNextShow(false);
		this.resgateService.setNextDisabled(true);
		this.resgateService.setFinishDisabled(true);
		this.resgateService.setFinishShow(true);
	}

	doNext() {

		if (this.formField.information.active === true) {

			if (this.isFormValid()) {

				this.formField.information.active = false;
				this.formField.information.status = 'Concluido';
				this.resgateService.setPreviousShow(true);
				this.resgateService.setPreviousDisabled(false);
				this.resgateService.setNextShow(false);
				this.resgateService.setNextDisabled(true);
				this.resgateService.setFinishDisabled(true);
				this.resgateService.setFinishShow(true);

				this.$window.scrollTo(0, 0);
				this.$state.go('case.documentacao-proposta.upload');
			}
		}
	}

	navStepStatus(state) {
		// console.log('navStepStatus', state, this.formField.formStep);
		let stepStatus = '';

		if (state === this.formField.formStep) {
			// console.log('navStepStatus formStep:', state, this.formField.formStep);
			return 'act';
		}

		if (state === 'case.documentacao-proposta.new') {
			stepStatus = this.formField.information.status;
			if (stepStatus !== 'Não Iniciado') {
				return 'edt';
			}
		}

		if (state === 'case.documentacao-proposta.upload') {
			stepStatus = this.formField.information.file.status;
			if (stepStatus !== 'Não Iniciado') { // && stepStatus !== 'Incompleto'
				return 'edt';
			}
		}

		return '';
	}

	isFormValid() {
		let isValid = false;

		this.hasRequiredProposta();

		isValid = (!this.formField.information.proposta.hasError);

		return isValid;
	}

	hasRequiredProposta() {
		const hasRequirement = true;

		/*
		let hasRequirement = true;
		if (this.formField.information.proposta.value === null) {
			hasRequirement = false;
		}

		this.formField.information.proposta.hasError = !hasRequirement;
		*/
		return hasRequirement;
	}

	cancelCurrentInstance() {

		this.resgateService.setErrorMessage('');
		console.log('cancel liberacao', this.formField);
		if (!!this.formField.id) {

			this.$scope.$emit('changeMainShowLoading', true);

			this.api.cancelInstance(this.formField.id).then((response) => {

				this.$scope.$emit('changeMainShowLoading', false);

				if (!response.isSuccessful) {

					this.resgateService.setErrorMessage(response.errorMessages[0]);
				} else {

					this.$window.scrollTo(0, 0);
					this.formField.reset();
					this.$state.go('case.list');
				}
			});
		} else {

			this.$window.scrollTo(0, 0);
			this.formField.reset();
			this.$state.go('case.list');
		}
	}

}

module.exports = documentacaoPropostaController;
