'use strict';

class reaplicacaoTituloNewController {

	// @ngInject
	constructor(reaplicacaoTituloFormService, propostaNewApi, autoCompleteService, $scope, $state, sobjectQueryService, reaplicacaoTituloService, reaplicacaoTituloNewService, $timeout, breadcrumbHelper) {
		this.reaplicacaoTituloFormService = reaplicacaoTituloFormService;
		this.$timeout = $timeout;
		this.$state = $state;
		this.sobjectQueryService = sobjectQueryService;
		this.reaplicacaoTituloService = reaplicacaoTituloService;
		this.reaplicacaoTituloNewService = reaplicacaoTituloNewService;
		this.autoComplete = autoCompleteService;
		this.$scope = $scope;
		this.propostaNewApi = propostaNewApi;
		this.$scope.breadcrumb = breadcrumbHelper;
		this.getFormField();

		this.reaplicacaoTituloService.setPristine();
		this.reaplicacaoTituloService.setFinishShow(true);
		this.reaplicacaoTituloService.setCancelShow(true);

		this.formField.status = (this.formField.status === null) ? 'Enviado' : this.formField.status;
		if (this.reaplicacaoTituloService.getIsEdit() === true) {
			this.propostas = [];
			this.propostas.push(this.formField.information.proposta.value);
		} else {
			this.propostas = (this.formField.information.proposta.value !== null) ? this.reaplicacaoTituloNewService.getPropostas() : [];
		}

		this.configureBreadCrumb();

	}

	configureBreadCrumb() {
		this.$scope.breadcrumb.reset();
		this.$scope.breadcrumb.addMenuItem('Home', '/caponline/secured#!/home', true, false);
		this.$scope.breadcrumb.addMenuItem('Solicitação', '/caponline/secured#!/solicitacoes/lista', true, false);
		this.$scope.breadcrumb.addMenuItem('Nova Reaplicação de Título', '#', false, true);
	}

	/**
	 *  Pega o estado do formulario
	 *
	 */

	getFormField() {
		this.formField = this.reaplicacaoTituloFormService.getFormField();
	}

	/** 
	 * Pesquisar Contas
	 */
	searchAccount(searchString) {
		console.log('entrou no searchAccount');
		const reaplicacaoTituloNew = this.$parent.reaplicacaoTituloNew;
		return reaplicacaoTituloNew.reaplicacaoTituloNewService.searchAccount(searchString);
	}

	/** 
	 * Selecionar a Conta
	 */
	searchAccountSelect(item) {
		console.log('entrou no searchAccountSelect');
		const reaplicacaoTituloNew = this.$parent.reaplicacaoTituloNew;
		if (reaplicacaoTituloNew.formField.information.mainAccount.value === null) {
			if (!item) { return; }

			const hasAction = !!item.originalObject.action;

			if (hasAction) {

				const action = item.originalObject.action;

				reaplicacaoTituloNew[action](item);

				return;
			}

			reaplicacaoTituloNew.formField.information.mainAccount.value = item.originalObject;
			reaplicacaoTituloNew.formField.information.mainAccount.hasError = !reaplicacaoTituloNew.hasAccount();
			reaplicacaoTituloNew.reaplicacaoTituloService.setSaveShow(true);
			reaplicacaoTituloNew.reaplicacaoTituloService.setSaveDisabled(false);
			reaplicacaoTituloNew.reaplicacaoTituloService.setCancelShow(true);
			reaplicacaoTituloNew.reaplicacaoTituloService.setCancelDisabled(false);
			reaplicacaoTituloNew.reaplicacaoTituloService.setNextShow(true);
			reaplicacaoTituloNew.reaplicacaoTituloService.setNextDisabled(false);
			reaplicacaoTituloNew.formField.information.mainAccount.loading = true;
			reaplicacaoTituloNew.getPropostasByAccountId(reaplicacaoTituloNew.formField.information.mainAccount.value.Id);
			reaplicacaoTituloNew.reaplicacaoTituloFormService.setFormField(reaplicacaoTituloNew.formField);
		}
	}

	searchAccountFocusOut() {
		this.$timeout(() => {
			if (!this.autoComplete.hasValidInput(this.formField.information.mainAccount.value)) {
				this.reaplicacaoTituloService.setSaveShow(true);
				this.reaplicacaoTituloService.setSaveDisabled(true);
				this.reaplicacaoTituloService.setCancelShow(true);
				this.reaplicacaoTituloService.setCancelDisabled(true);
				this.reaplicacaoTituloService.setNextShow(true);
				this.reaplicacaoTituloService.setNextDisabled(true);
				this.autoComplete.clearInput(this.$scope, 'searchAccount');
				this.reaplicacaoTituloFormService.setFormField(this.formField);
			}
		}, 100);
	}

	searchAccountChanged() {
		const reaplicacaoTituloNew = this.$parent.reaplicacaoTituloNew;
		reaplicacaoTituloNew.reaplicacaoTituloService.cleanMessages();
		reaplicacaoTituloNew.autoComplete.resetSelected(reaplicacaoTituloNew.formField.information.mainAccount, 'value');
		reaplicacaoTituloNew.propostas = [];
		reaplicacaoTituloNew.reaplicacaoTituloNewService.setPropostas(reaplicacaoTituloNew.propostas);
		console.log('reset');
		reaplicacaoTituloNew.resetForm();
		reaplicacaoTituloNew.reaplicacaoTituloFormService.resetFormFile();
	}

	hasAccount() {
		return !!this.formField.information.mainAccount.value;
	}

	hasProposta() {
		return !!this.formField.information.proposta.value;
	}

	/** 
	 * Pegar propostas relacionadas a Conta
	 */
	getPropostasByAccountId(accountId) {
		this.propostas = [];
		this.reaplicacaoTituloNewService.setPropostas(this.propostas);
		this.sobjectQueryService.getPropostasByAccountId(accountId).then((response) => {
			this.formField.information.mainAccount.loading = false;
			if (response.isSuccessful) {
				if (response.result.length > 0) {
					this.propostas = response.result;
					this.reaplicacaoTituloNewService.setPropostas(response.result);
				} else {
					this.reaplicacaoTituloService.setErrorMessage(' Cliente não possui nenhuma proposta apta para a solicitação');
				}
			} else {
				console.log('error ', response);
			}
		});
	}

	/** 
	 * Ao mudar uma proposta, será chamado este método
	 */
	changePropostaField() {
		console.log('entrou no changepropostaField');
		if (this.formField.information.mainAccount.value != null) {
			this.resetForm();
			this.reaplicacaoTituloFormService.resetFormFile();
			this.reaplicacaoTituloService.cleanMessages();
			if (this.formField.information.proposta.value !== null)
				this.getGarantiasByPropostaId(this.formField.information.proposta.value.objectId);
			this.reaplicacaoTituloFormService.setFormField(this.formField);
		}
	}

	resetForm() {
		this.formField.information.garantias.value = null;
		this.formField.information.garantias.hasError = false;
		//this.formField.information.motivobloqueio.value = null;
		//this.formField.information.motivobloqueio.hasError = false;
		this.formField.information.autorizacaoreaplicacao.value = null;
		this.formField.information.autorizacaoreaplicacao.hasError = false;
		this.reaplicacaoTituloFormService.setFormField(this.formField);
	}

	/** 
	 * Ao ser chamado irá carregar os títulos referentes aquela proposta
	 */
	getGarantiasByPropostaId(propostaId) {
		console.log('entrou no getGarantiasByPropostaId');

		this.formField.information.proposta.loading = true;

		//CAP-2614
		const cpfcnpj = this.formField.information.mainAccount.value.cpf;

		this.sobjectQueryService.getGarantiasByPropostaId(propostaId, 'reaplicacao-titulo', cpfcnpj).then((response) => {
			//FIM CAP-2614
			this.formField.information.proposta.loading = false;
			if (response.isSuccessful) {
				if (response.result.length > 0) {
					this.reaplicacaoTituloService.cleanMessages();
					this.formField.information.garantias.value = [];
					for (let i = 0; i < response.result.length; i++) {

						this.formField.information.garantias.value.push({
							objectId: response.result[i].objectId,
							objectName: response.result[i].objectName,
							vigencia: response.result[i].vigencia,
							finalVigencia: response.result[i].finalVigencia,
							selected: true,
							disabled: false,
							value: response.result[i].value,
							antecipatedValue: response.result[i].antecipatedValue
						});
					}
				} else {

					this.reaplicacaoTituloService.setWarningMessage('Este título já foi habilitado para reaplicação. Após o prazo de processamento, favor consultar a nova garantia.');
				}
			} else {
				this.reaplicacaoTituloService.setErrorMessage(response.errorMessages[0]);
			}
		});
	}

	changeCheckGarantia(garantiaId, check) {
		this.reaplicacaoTituloFormService.resetFormFile();
		let type = '';
		for (let i = 0; i < this.formField.information.garantias.value.length; i++) {
			if (garantiaId === this.formField.information.garantias.value[i].objectId) {
				console.log('match');
				if (this.formField.information.garantias.value[i].objectName.includes('(Descaucionado)')) {
					type = 'Descaucionado';
				} else {
					type = 'Caucionado';
				}
				break;
			}
		}
		if (check) {
			if (type === 'Caucionado') {
				for (let i = 0; i < this.formField.information.garantias.value.length; i++) {
					if (this.formField.information.garantias.value[i].objectName.includes('(Descaucionado)')) {
						this.formField.information.garantias.value[i].disabled = true;
					} else {
						this.formField.information.garantias.value[i].disabled = false;
					}
				}
			} else {
				for (let i = 0; i < this.formField.information.garantias.value.length; i++) {
					if (this.formField.information.garantias.value[i].objectName.includes('(Caucionado)')) {
						this.formField.information.garantias.value[i].disabled = true;
					} else {
						this.formField.information.garantias.value[i].disabled = false;
					}
				}
			}
		} else {
			if (type === 'Caucionado') {
				let founded = false;
				for (let i = 0; i < this.formField.information.garantias.value.length; i++) {
					if (this.formField.information.garantias.value[i].objectName.includes('(Caucionado)')) {
						if (this.formField.information.garantias.value[i].selected) {
							founded = true;
							break;
						}
					}
				}
				if (!founded) {
					for (let i = 0; i < this.formField.information.garantias.value.length; i++) {
						this.formField.information.garantias.value[i].disabled = false;
					}
				}
			} else {
				let founded = false;
				for (let i = 0; i < this.formField.information.garantias.value.length; i++) {
					if (this.formField.information.garantias.value[i].objectName.includes('(Descaucionado)')) {
						if (this.formField.information.garantias.value[i].selected) {
							founded = true;
							break;
						}
					}
				}
				if (!founded) {
					for (let i = 0; i < this.formField.information.garantias.value.length; i++) {
						this.formField.information.garantias.value[i].disabled = false;
					}
				}
			}
		}

		this.reaplicacaoTituloFormService.setFormField(this.formField);
	}

	checkGarantiaValue() {
		let check = false;

		if (this.formField.information.garantias.value !== null) {
			for (let i = 0; i < this.formField.information.garantias.value.length; i++) {
				check = (this.formField.information.garantias.value[i].selected == true);
				if (check) {
					break;
				}
			}
		}

		return check;
	}

}

module.exports = reaplicacaoTituloNewController;
