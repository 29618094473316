'use strict';

class accountListController {

	// @ngInject
	constructor($scope, accountListService, ordenationHelper, breadcrumbHelper, utilsHelper) {
		this.accountListService = accountListService;
		this.listViews = [
			{ label: 'Exibidos recentemente', api: 'exibidos-recentemente', selected: true },
			{ label: 'Meus cadastros', api: 'meus-cadastros', selected: false },
			{ label: 'Todos os cadastros', api: 'todos-os-cadastros', selected: false }
		];
		this.listViewSelected = this.listViews[0];
		this.$scope = $scope;
		this.showLoading = true;
		this.accounts = {};
		this.setPristine();
		this.order = ordenationHelper;
		this.order.setPristine();
		this.$scope.breadcrumb = breadcrumbHelper;
		this.utilsHelper = utilsHelper;

		this.configureBreadCrumb();
		this.getAccountList(true);
	}

	configureBreadCrumb() {
		this.$scope.breadcrumb.reset();
		this.$scope.breadcrumb.addMenuItem('Home', '/caponline/secured#!/home', true, false);
		this.$scope.breadcrumb.addMenuItem('Cadastro', '/caponline/secured#!/conta/lista', false, true);
	}

	setPristine() {
		this.totalOfRecords = 0;
		this.actualPage = 1;
		this.totalOfPages = 1;
		this.limitOfRecords = 10;
	}

	changeOrder(field) {
		this.order.changeOrder(field);
		this.setPristine();
		this.getAccountList(true);
	}

	changeListView() {
		this.order.setPristine();
		this.setPristine();
		this.getAccountList(true);
	}

	getAccountList(isCount) {

		this.showLoading = true;

		this.accountListService.getAccountList({
				queryType: this.listViewSelected.api,
				queryLimit: this.limitOfRecords,
				queryOffset: ((this.actualPage - 1) * this.limitOfRecords),
				queryOrderField: this.order.data.field,
				queryOrderDirection: this.order.data.direction,
				isQueryCount: isCount
			})
			.then((response) => {
				this.showLoading = false;
				if (!response.isSuccessful) {
					this.accounts = {};
					this.totalOfRecords = 0;
				} else {
					this.accounts = response.accounts;
					if (isCount) {
						this.totalOfRecords = response.queryCount;
						this.setPaginationParams();
					}
				}

			});
	}

	hasPrevious() {
		return (this.actualPage != 1 && this.actualPage <= this.totalOfPages);
	}

	hasNext() {
		return (this.actualPage < this.totalOfPages && this.totalOfPages > 1);
	}

	doPrevious() {
		this.actualPage--;
		if (this.isPageValid()) {
			this.showLoading = true;
			this.getAccountList(false);
		} else {
			this.actualPage++;
		}
		console.log('Previous: ', this.actualPage);
	}

	doNext() {
		this.actualPage++;
		if (this.isPageValid()) {
			this.showLoading = true;
			this.getAccountList(false);
		} else {
			this.actualPage--;
		}
		console.log('Next: ', this.actualPage);
	}

	goToPage() {
		if (this.ifPageValid()) {
			this.showLoading = true;
			this.getAccountList(false);
		}
	}

	isPageValid() {
		return (this.actualPage > 0 && this.actualPage <= this.totalOfPages)
	}

	setPaginationParams() {
		this.actualPage = 1;
		this.totalOfPages = (this.totalOfRecords > this.limitOfRecords) ? parseInt(Math.ceil(this.totalOfRecords / this.limitOfRecords)) : 1;
	}

}

module.exports = accountListController;
