'use strict';

class reaplicacaoTituloDetailService {

	/* @ngInject */
	constructor(visualForceApi, $sce) {
		this.visualForceApi = visualForceApi;
	}

	getSolicitacaoDetails(caseId) {

		const cap_access_token = localStorage.getItem("cap_access_token");

		return this.visualForceApi.invoke(
			'CapCommunityCaseControllerExt.getCaseDetails', caseId, cap_access_token);
	}
}

module.exports = reaplicacaoTituloDetailService;
