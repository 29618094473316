'use strict';

const routesConfiguration = require('./account-routes');
const accountController = require('./account-controller');
const accountTypeModalController = require('./account-type-modal/account-type-modal-controller');
const accountListController = require('./account-list/account-list-controller');
const accountListService = require('./account-list/account-list-service');
const accountDetailController = require('./account-detail/account-detail.controller');
const accountDetailService = require('./account-detail/account-detail.service');
const newAccountController = require('./new-account/new-account-controller');
const newJuridicalAccountController = require('./new-juridical-account/new-juridical-account-controller');
const newAccountService = require('./new-account/new-account-service');
const newJuridicalAccountService = require('./new-juridical-account/new-juridical-account-service');
const accountUtilService = require('./account-util-service');

const myAccountModule = require('./my-account/component.module');

const accountModule = angular.module('cap.secured.accountModule', [
		'ui.router',
		'ngMask',
		myAccountModule.name
	])
	.controller('accountController', accountController)
	.controller('newAccountController', newAccountController)
	.controller('accountListController', accountListController)
	.controller('newJuridicalAccountController', newJuridicalAccountController)
	.controller('accountTypeModalController', accountTypeModalController)
	.controller('accountDetailController', accountDetailController)
	.service('accountDetailService', accountDetailService)
	.service('newAccountService', newAccountService)
	.service('newJuridicalAccountService', newJuridicalAccountService)
	.service('accountUtilService', accountUtilService)
	.service('accountListService', accountListService)
	.config(routesConfiguration);

module.exports = accountModule;
