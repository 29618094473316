'use strict';

const personEditController = require('./person/edit/edit.controller');
const juridicalEditController = require('./juridical/edit/edit.controller');

const editApiService = require('./core/edit/api.service');

const routesConfiguration = require('./component.routes');

const componentModule = angular.module('cap.secured.accountModule.my-account', [
		'ui.router'
	])

	.controller('myAccountPersonEditController', personEditController)
	.controller('myAccountJuridicalEditController', juridicalEditController)

	.service('accountEditApi', editApiService)

	.config(routesConfiguration);

module.exports = componentModule;
