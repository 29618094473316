'use strict';

class contactUsController {

	// @ngInject
	constructor($scope, $window, sfConfig, contactUsApi, validationFormFields) {
		// console.log('contactUsController', contactUsApi);

		this.sfResource = sfConfig.resource;
		this.contactUsApi = contactUsApi;
		this.valid = validationFormFields;
		this.$scope = $scope;
		this.$window = $window;

		this.setUserForm();
	}

	setUserForm() {

		this.user = {

			name: null,
			email: null,
			phone: null,
			cellphone: null,
			cpfCnpj: null,
			message: null
		};
	}

	clearMessages() {

		this.formSubmitSuccessful = false;
		this.formSubmitError = null;
	}

	submit(form) {
		// console.log('submit', form);

		this.clearMessages();

		form.$setSubmitted();

		if (form.$invalid) {

			return;
		}

		this.$scope.$emit('changeMainShowLoading', true);

		this.contactUsApi.sendForm(this.user).then((response) => {

			this.$scope.$emit('changeMainShowLoading', false);

			if (response.isSuccessful) {

				this.formSubmitSuccessful = true;
				this.setUserForm();
				form.$setPristine();
				form.$setUntouched();
			} else {

				this.formSubmitError = angular.toJson(response.errorMessages);
			}

			this.$window.scrollTo(0, 0);
		});
	}
}

module.exports = contactUsController;
