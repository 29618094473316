'use strict';

/* @ngInject */
function routesConfiguration($stateProvider, sfConfig) {

	//Rota Principal
	$stateProvider.state('case.revisao-comissao', {

		abstract: true,
		url: '/revisaocomissao',
		views: {

			'case': {

				templateUrl: `${sfConfig.resource}/views/case/revisao-comissao/base.html`,
				controller: 'revisaoComissaoController',
				controllerAs: 'caseInstance'
			}
		}
	});

	//Rota para Criação
	$stateProvider.state('case.revisao-comissao.new', {

		url: '/nova',
		views: {

			'case.revisao-comissao': {

				templateUrl: `${sfConfig.resource}/views/case/revisao-comissao/revisao-comissao-new/revisao-comissao-new.html`,
				controller: 'revisaoComissaoNewController',
				controllerAs: 'revisaoComissaoNew'
			}
		}
	});

	//Rota para Amexos
	$stateProvider.state('case.revisao-comissao.annex', {

		url: '/anexos',
		views: {

			'case.revisao-comissao': {

				templateUrl: `${sfConfig.resource}/views/case/revisao-comissao/revisao-comissao-annex/revisao-comissao-annex.html`,
				controller: 'revisaoComissaoAnnexController',
				controllerAs: 'revisaoComissaoAnnex'
			}
		}
	});

	$stateProvider.state('case.revisao-comissao.detail', {

		url: '/detalhes?:id',
		views: {

			'case.revisao-comissao': {

				templateUrl: `${sfConfig.resource}/views/case/revisao-comissao/revisao-comissao-detail/revisao-comissao-detail.html`,
				controller: 'revisaoComissaoDetailController',
				controllerAs: 'revisaoComissaoDetail'
			}
		}
	});
};

module.exports = routesConfiguration;
