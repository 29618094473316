"use strict";

import VisualForceApi from "../../core/sfdc/visual-force-api.js";

class propostaNewApi extends VisualForceApi {
	/* @ngInject */
	constructor($q) {
		super();
		this.$q = $q;
	}

	searchAccount(searchKeyWord) {
		const deferred = this.$q.defer();
		const propostaType = sessionStorage.getItem("tipo_lista");

		const searchModel = {
			dataFetch: {
				searchKeyWord,
				searchTypeObject: "Account"
			}
		};

		const cap_access_token = localStorage.getItem("cap_access_token");
		searchModel.accessToken = cap_access_token;

		if (propostaType == 'D') {
			searchModel.dataFetch.requestType = 'D';
		}

		super
			.invoke("CapCommunityPropostaControllerExt.solFetchRecords", searchModel)
			.then((data) => {
				//console.log(data.obtainedRecords);
				if (!data.obtainedRecords) data.obtainedRecords = [];
				if (data.obtainedRecords.length === 0) {
					data.obtainedRecords.push({
						action: "searchAccountFocusOut",
						Name: "Nenhum cadastro encontrado"
					});
				}
				data.obtainedRecords.push(this.addBtnCreateAccount());
				deferred.resolve(data.obtainedRecords);
			});

		return deferred.promise;
	}

	searchProfession(searchKeyWord, isPersonAccount) {
		const deferred = this.$q.defer();

		const searchModel = {
			dataFetch: {
				searchKeyWord,
				searchTypeObject: isPersonAccount ? "OCUPACAO__c" : "PROFISSAO__c", //PROFISSAO__c
				isPersonAccount
			}
		};

		const cap_access_token = localStorage.getItem("cap_access_token");
		searchModel.accessToken = cap_access_token;

		super
			.invoke("CapCommunityPropostaControllerExt.solFetchRecords", searchModel)
			.then((data) => {
				//console.log(data.obtainedRecords);

				deferred.resolve(data.obtainedRecords);
			});

		return deferred.promise;
	}

	isPEP(cpf) {
		const cap_access_token = localStorage.getItem("cap_access_token");

		return super.invoke(
			"CapCommunityPropostaControllerExt.solValidatePep",
			cpf,
			cap_access_token
		);
	}

	addBtnCreateAccount() {
		return {
			action: "modalHandle",
			Name: "+ Criar Cadastro"
		};
	}
}

module.exports = propostaNewApi;
