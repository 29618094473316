'use strict';

class propostaSetupAccountService {

	// @ngInject
	constructor() {

		this.setPristine();
	}

	setPristine() {

		this.newAccountInfo = null;
		this.newLocatorInfo = null;
		this.newEstateAdminInfo = null;
		this.fromProposta = null;
		this.fromCartaCaucaoLocator = null;
		this.fromCartaCaucaoEstateAdmin = null;
	}

	reset() {
		// console.log('propostaSetupAccountService reset');

		this.setPristine();
	}

	getNewAccountInfo() {
		return this.newAccountInfo;
	}

	setNewAccountInfo(userInfo) {
		this.newAccountInfo = userInfo;
	}

	getNewLocatorInfo() {
		return this.newLocatorInfo;
	}

	setNewLocatorInfo(locatorInfo) {
		this.newLocatorInfo = locatorInfo;
	}

	getNewEstateAdminInfo() {
		return this.newEstateAdminInfo;
	}

	setNewEstateAdminInfo(estateAdminInfo) {
		this.newEstateAdminInfo = estateAdminInfo;
	}

	getFromProposta() {
		return this.fromProposta;
	}

	setFromProposta(value) {
		// console.log('setFromProposta', value);
		this.fromProposta = value;
	}

	getFromCartaCaucaoLocator() {
		return this.fromCartaCaucaoLocator;
	}

	setFromCartaCaucaoLocator(value) {
		// console.log('setFromCartaCaucaoLocator', value);
		this.fromCartaCaucaoLocator = value;
	}

	getFromCartaCaucaoEstateAdmin() {
		return this.fromCartaCaucaoEstateAdmin;
	}

	setFromCartaCaucaoEstateAdmin(value) {
		// console.log('setFromCartaCaucaoEstateAdmin', value);
		this.fromCartaCaucaoEstateAdmin = value;
	}
}

module.exports = propostaSetupAccountService;
