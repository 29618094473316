'use strict';

class rentGuaranteeDetailsApiService {

	/* @ngInject */
	constructor($q, visualForceApi) {

		this.visualForceApi = visualForceApi;
		this.$q = $q;
	}

	getDetails(query) {
		return this.visualForceApi.invoke(
			'CapCommunityGarantAluguelControllerExt.getGarantiaAluguelDetail', query);
	}

	getResgateValue(numeroTitulo) {

		console.log('########### CHAMANDO VALOR DO RESGATE DESABILITADO!! ', numeroTitulo);
		return null;

		/* TODO: Descomentar este bloco para usar o valor do resgate
		console.log('----> entrando na busca do valor de resgate: ', numeroTitulo);

		let response = null;

		const request = {
			tituloId: numeroTitulo
		}

		response = this.visualForceApi.invoke('T_ResgateController.getValorResgate', request);

		console.log('---> response:', response);

		return response;
		*/

	}

}

module.exports = rentGuaranteeDetailsApiService;
