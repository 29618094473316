'use strict';

class documentacaoPropostaFormControlService {

	// @ngInject
	constructor() {
		// console.log('liberacaoDaCaucaoFormControlService');

		this.setPristine();
	}

	setPristine() {
		//console.log('setPristine;')

		this.status = null;
		this.formStep = null;
		this.id = null;
		this.edit = false;
		this.lastFormAction = '';

		this.setInformationPristine();
		this.setAnnexPristine();
	}

	setInformationPristine() {
		//console.log('setInformationPristine;')

		this.information = {

			active: false,
			status: 'Não Iniciado',
			mainAccount: {
				value: null,
				hasError: false,
				loading: false,
				show: false
			},
			proposta: {
				value: null,
				hasError: false,
				loading: false,
				show: false
			},
			legalRepresentative: {
				value: null,
				hasError: false,
				loading: false,
				show: false
			},
			garantias: {
				value: null,
				hasError: false,
				loading: false,
				show: false
			},
			file: {
				active: false,
				form: this.pristineAnnexObj(),
				documentacaoProposta: {
					show: false,
					proposta: this.pristineAnnexObj(),
					condicoesgerais: this.pristineAnnexObj(),
					cartacaucao: this.pristineAnnexObj(),
					anexopj: this.pristineAnnexObj()
				},
				status: null
			}

		};
	}

	setFormField(json) {
		this.formField = json;
	}

	getFormField() {
		return this;
	}

	resetInformation() {

		this.setInformationPristine();
	}

	setAnnexPristine() {

		this.information.file = {

			active: false,
			status: "Não Iniciado",
			form: this.pristineAnnexObj(),
			documentacaoProposta: {
				show: false,
				proposta: this.pristineAnnexObj(),
				condicoesgerais: this.pristineAnnexObj(),
				cartacaucao: this.pristineAnnexObj(),
				anexopj: this.pristineAnnexObj()
			}
		}

		// :´´[
		this.information.file.form.type = 'form';
		this.information.file.documentacaoProposta.proposta.type = 'proposta';
		this.information.file.documentacaoProposta.condicoesgerais.type = 'condicoes-gerais';
		this.information.file.documentacaoProposta.cartacaucao.type = 'carta-caucao';
		this.information.file.documentacaoProposta.anexopj.type = 'anexo-pj';
	}

	resetAnnex() {

		this.setAnnexPristine();
	}

	pristineAnnexObj() {

		return {
			files: [],
			hasError: false,
			loading: false,
			show: true, // refactory HelperAnnexx
			uploaded: false,
			uploadHasFailure: false
		};
	}

	resetField(type, fieldName) {

		const field = this[type][fieldName];

		switch (true) {

		case angular.isArray(field):

			this[type][fieldName] = [];
			break;

		case angular.isObject(field):

			this[type][fieldName] = {
				value: null,
				hasError: false,
				loading: false,
				show: false
			};
			break;

		case typeof field === 'boolean':

			this[type][fieldName] = false;
			break;

		default:

			this[type][fieldName] = null;
		}
	}

	reset() {
		// console.log('reset');

		this.setPristine();
	}

	extractFormControl() {
		return {
			status: this.status,
			formStep: this.formStep,
			id: this.id,
			information: this.information,
			annex: this.annex
		}
	}

	feedNewData(newData) {

		this.setPristine();

		this.mergeData(newData);
	}

	mergeData(data) {

		angular.extend(this.field, data);
	}

	resetErrorHandlerObject(errorHandlerObject) {

		for (const property in errorHandlerObject) {

			if (!errorHandlerObject.hasOwnProperty(property)) continue;

			// errorHandlerObject[property] = false;
			errorHandlerObject[property].hasError = false;
		}
	}

	requireProperty(propertyName, errorHandlerObject) {
		// console.log('requireProperty', propertyName, errorHandlerObject);

		const hasProperty = this.hasProperty(propertyName);
		// console.log('hasProperty', hasProperty);

		// errorHandlerObject[`${propertyName}Error`] = !hasProperty;
		errorHandlerObject[propertyName].hasError = !hasProperty;
		// console.log('propertyError', errorHandlerObject[`${propertyName}Error`]);
		return hasProperty;
	}

	hasProperty(propertyName) {
		// console.log('hasProperty', propertyName);

		// return !!this.field[propertyName];
		return !!this.field[propertyName].value;
	}
}

module.exports = documentacaoPropostaFormControlService;
