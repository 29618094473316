'use strict';

class administrarUsuarioListApi {

	/* @ngInject */
	constructor($q, visualForceApi) {

		this.visualForceApi = visualForceApi;
		this.$q = $q;
	}

	getListViewOptions() {

		const deferred = this.$q.defer();

		const listViewOptions = {

			options: [

				{ label: 'Exibidos recentemente', queryType: 'exibidos-recentemente', selected: true },
				{ label: 'Usuários Ativos', queryType: 'usuarios-ativos', selected: false },
				{ label: 'Usuários Inativos', queryType: 'usuarios-inativos', selected: false }
			],
			isSuccessful: true
		};

		deferred.resolve(listViewOptions);

		return deferred.promise;
	}

	getList(query) {
		// console.log('getList', query);

		return this.visualForceApi.invoke(
			'CapCommunityUserControllerExt.getUserList', query);
	}

	removeUser(userId) {
		// console.log('removeUser', userId);

		return this.visualForceApi.invoke(
			'CapCommunityUserControllerExt.deleteUser', userId);
	}
}

module.exports = administrarUsuarioListApi;
