'use strict';

class liberacaoDaCaucaoAnnexApi {

	/* @ngInject */
	constructor(visualForceApi) {

		this.visualForceApi = visualForceApi;
	}

	removeFile(attachmentId) {

		const cap_access_token = localStorage.getItem("cap_access_token");

		return this.visualForceApi.invoke(
			'CapCommunityCaseControllerExt.deleteAttachment', attachmentId, cap_access_token);
	}

	uploadFile(Id, fileName, attachment, attachmentType, part, isLastPart, contentType) {
		// console.log('uploadFileAPI', Id, fileName, attachment, attachmentType, part, isLastPart, contentType);

		const request = { Id, fileName, attachment, attachmentType, part, isLastPart, contentType };

		const cap_access_token = localStorage.getItem("cap_access_token");
		request.accessToken = cap_access_token;

		return this.visualForceApi.invoke(
			'CapCommunityCaseControllerExt.attachCase', request);
	}

	mountFile(caseId, attachmentType, attachmentId, userId, fileName, gedAttachmentType, caseType) {

		return this.visualForceApi.invoke(
			'CapCommunityCaseControllerExt.mountAttachmentFile',
			caseId,
			attachmentType,
			fileName,
			userId,
			attachmentId,
			gedAttachmentType,
			caseType);
	}
}

module.exports = liberacaoDaCaucaoAnnexApi;
