'use strict';

class liberacaoDaCaucaoController {

	// @ngInject
	constructor($scope, $window, $state, $timeout, liberacaoDaCaucaoFormControlService, liberacaoDaCaucaoApi, liberacaoDaCaucaoService, annexHelper, breadcrumbHelper, utilsHelper) {
		// console.log('liberacaoDaCaucaoController');

		this.$scope = $scope;
		this.$window = $window;
		this.$state = $state;
		this.$timeout = $timeout;

		this.api = liberacaoDaCaucaoApi;
		this.formField = liberacaoDaCaucaoFormControlService;
		this.liberacaoDaCaucaoService = liberacaoDaCaucaoService;
		this.annexHelper = annexHelper;
		this.utilsHelper = utilsHelper;

		this.listenValidUploads();
		this.listenSaveCaseInstance();

		this.$scope.breadcrumb = breadcrumbHelper;

		this.isOnlySave = false;
		// setInterval(() => { console.log(this.formField); }, 20000);
	}

	hasNavButtons() {
		return this.$state.current.name !== 'case.liberacao-da-caucao.detail';
	}

	hasNavNewCase() {
		return this.$state.current.name !== 'case.liberacao-da-caucao.detail';
	}

	clickRefuseCaseLiberacao() {
		console.log('clickRefuseCaseLiberacao');
		this.$scope.$emit('changeMainShowLoading', true);
		this.liberacaoDaCaucaoService.refuseCaseResgate(this.formField.id).then((response) => {
			this.$scope.$emit('changeMainShowLoading', false);
			if (!response.isSuccessful) {
				this.messages.showErrorMessage(this.$scope, response.errorMessages[0]);
			} else {
				this.$state.go('case.liberacao-da-caucao.detail', { id: this.formField.id });
			}
		});
	}

	clickSendLiberacao() {
		console.log('clickSendLiberacao');
		this.$scope.$emit('changeMainShowLoading', true);
		this.liberacaoDaCaucaoService.sendCaseLiberacao(this.formField.id).then((response) => {
			this.$scope.$emit('changeMainShowLoading', false);
			if (!response.isSuccessful) {
				this.messages.showErrorMessage(this.$scope, response.errorMessages[0]);
			} else {
				this.$state.go('case.liberacao-da-caucao.detail', { id: this.formField.id });
			}
		});
	}

	isFormValid() {

		let isValid = false;

		this.hasRequiredMainAccount();

		this.hasRequiredProposta();

		this.hasRequiredGarantias();

		this.hasRequiredLegalRepresentative();

		isValid = (!this.formField.information.mainAccount.hasError &&
			!this.formField.information.proposta.hasError &&
			!this.formField.information.garantias.hasError &&
			!this.formField.information.legalRepresentative.hasError);

		return isValid;
	}

	doNext() {

		if (this.formField.information.active === true) {

			if (this.isFormValid()) {

				this.formField.information.active = false;
				this.formField.information.status = 'Concluido';
				this.formField.annex.active = true;
				this.formField.annex.status = 'Incompleto';
				this.liberacaoDaCaucaoService.setPreviousShow(true);
				this.liberacaoDaCaucaoService.setPreviousDisabled(false);
				this.liberacaoDaCaucaoService.setNextShow(false);
				this.liberacaoDaCaucaoService.setNextDisabled(true);
				this.liberacaoDaCaucaoService.setFinishDisabled(true);
				this.liberacaoDaCaucaoService.setFinishShow(true);

				this.$window.scrollTo(0, 0);
				this.$state.go('case.liberacao-da-caucao.annex');
			}
		}
	}

	doPrevious() {

		this.liberacaoDaCaucaoService.setErrorMessage('');

		if (this.formField.annex.active === true) {

			this.formField.annex.active = false;
			this.liberacaoDaCaucaoService.setPreviousShow(false);
			this.liberacaoDaCaucaoService.setPreviousDisabled(true);
			this.liberacaoDaCaucaoService.setNextShow(true);
			this.liberacaoDaCaucaoService.setNextDisabled(false);
			this.liberacaoDaCaucaoService.setFinishDisabled(true);
			this.liberacaoDaCaucaoService.setFinishShow(false);

			this.$window.scrollTo(0, 0);
			this.$state.go('case.liberacao-da-caucao.new');
		}
	}

	saveCaseInstance() {
		this.liberacaoDaCaucaoService.setErrorMessage('');
		if (this.formField.information.mainAccount.value !== null) {
			const form = angular.copy(this.formField);
			this.$scope.$emit('changeMainShowLoading', true);
			return this.api.saveCaseInstance(form).then((response) => {
				this.$scope.$emit('changeMainShowLoading', false);
				if (response.isSuccessful) {
					this.formField.id = response.caseId;
					this.liberacaoDaCaucaoService.setSuccessMessage('Solicitação salva com sucesso.');
					this.$timeout(() => {
						this.liberacaoDaCaucaoService.setSuccessMessage('');
					}, 5000);
				} else {
					this.liberacaoDaCaucaoService.setErrorMessage(response.errorMessages[0]);
				}
			});

		}
	}

	cancelCurrentInstance() {
		this.liberacaoDaCaucaoService.setErrorMessage('');
		console.log('cancel liberacao', this.formField);
		if (!!this.formField.id) {
			this.$scope.$emit('changeMainShowLoading', true);
			this.api.cancelInstance(this.formField.id).then((response) => {
				this.$scope.$emit('changeMainShowLoading', false);
				if (response.isSuccessful) {
					this.$window.scrollTo(0, 0);
					this.formField.reset();
					this.$state.go('case.list');
				} else {
					this.liberacaoDaCaucaoService.setErrorMessage(response.errorMessages[0]);
				}
			});
		} else {
			this.$window.scrollTo(0, 0);
			this.formField.reset();
			this.$state.go('case.list');
		}
	}

	hasTituloCaucionadoSelected() {

		let founded = false;
		if (this.formField.information.garantias.value != null) {

			for (let i = 0; i < this.formField.information.garantias.value.length; i++) {

				if (this.formField.information.garantias.value[i].selected && this.formField.information.garantias.value[i].objectName.includes('(Caucionado)')) {

					founded = true;
					break;
				}
			}
		}

		return founded;
	}

	hasRequiredMainAccount() {

		let hasRequirement = true;

		if (this.formField.information.mainAccount.value === null) {

			hasRequirement = false;
		}

		this.formField.information.mainAccount.hasError = !hasRequirement;
		return hasRequirement;
	}

	hasRequiredProposta() {

		let hasRequirement = true;

		if (this.formField.information.proposta.value === null) {

			hasRequirement = false;
		}

		this.formField.information.proposta.hasError = !hasRequirement;
		return hasRequirement;
	}

	hasRequiredGarantias() {

		let hasRequirement = true;

		let hasGarantiaSelected = false;
		if (this.formField.information.garantias.value !== null) {

			for (let i = 0; i < this.formField.information.garantias.value.length; i++) {

				if (this.formField.information.garantias.value[i].selected) {

					hasGarantiaSelected = true;
					break;
				}
			}
		}

		if (!hasGarantiaSelected) {

			hasRequirement = false;
		}

		this.formField.information.garantias.hasError = !hasRequirement;
		return hasRequirement;
	}

	hasRequiredLegalRepresentative() {

		let hasRequirement = true;

		if (this.hasTituloCaucionadoSelected()) {

			if (this.formField.information.legalRepresentative.value === null) {

				hasRequirement = false;
			}
		}

		this.formField.information.legalRepresentative.hasError = !hasRequirement;
		return hasRequirement;
	}

	onlySave() {
		this.isOnlySave = true;
		this.saveCaseBeforeUpload();
	}

	sendResgate() {
		this.isOnlySave = false;
		this.isValidUploads() && this.saveCaseBeforeUpload();
	}

	saveCaseBeforeUpload() {
		this.liberacaoDaCaucaoService.setErrorMessage('');
		if (this.formField.information.mainAccount.value !== null) {
			const form = angular.copy(this.formField);
			this.$scope.$emit('changeMainShowLoading', true);
			this.api.saveCaseInstance(form).then((response) => {
				this.$scope.$emit('changeMainShowLoading', false);
				if (response.isSuccessful) {
					this.formField.id = response.caseId;
					this.checkUpload();
				} else {
					this.liberacaoDaCaucaoService.setErrorMessage(response.errorMessages[0]);
				}
			});
		}
	}

	listenValidUploads() {
		// console.log('listenValidUploads');

		this.$scope.$on('validateUploads', (event) => {
			// console.log('listened validateUploads');

			event.stopPropagation();

			this.liberacaoDaCaucaoService.setFinishDisabled(!this.isValidUploads());
		});
	}

	listenSaveCaseInstance() {
		// console.log('listenSaveCaseInstance');

		this.$scope.$on('saveCaseInstance', (event) => {
			// console.log('listened saveCaseInstance');

			event.stopPropagation();

			this.saveCaseInstance().then(() => {

				this.$scope.$broadcast('caseInstanceSaved');
			});
		});
	}

	isValidUploads() {
		let valid = true;
		const annexes = this.getCurrentAnnexesToValidate();
		for (let i = annexes.length - 1; i >= 0; i--) {
			if ((!annexes[i].files) || (annexes[i].files.length === 0)) {
				valid = false;
				break;
			}
		}
		valid = (this.isOnlySave ? true : valid);
		return valid;
	}

	checkUpload() {
		if (this.isValidUploads()) {

			const annexes = this.getCurrentAnnexes();
			const caseId = this.formField.id;
			const gedAttachmentType = 'outros';
			const caseType = 'liberacao-da-caucao';

			this.$scope.$emit('changeMainShowLoading', true);

			this.annexHelper.uploadAnnexes(annexes, caseId, gedAttachmentType, caseType)
				.then(() => {
					this.liberacaoDaCaucaoService.setErrorMessage('');
					if (this.isOnlySave) {
						this.$scope.$emit('changeMainShowLoading', false);
						this.liberacaoDaCaucaoService.setSuccessMessage('Solicitação salva com sucesso.');
						this.$timeout(() => {
							this.liberacaoDaCaucaoService.setSuccessMessage('');
						}, 5000);
					} else {
						this.sendCompleteInstance();
					}
				})
				.catch(() => {
					this.api.updateEditCaseData();
					const genericErrorMessage = 'Um ou mais arquivos não foram anexados. Os arquivos precisam ter até 8MB, não podem possuir o mesmo nome e devem ter as extensões permitidas.';
					this.liberacaoDaCaucaoService.setErrorMessage(genericErrorMessage);
					this.$scope.$emit('changeMainShowLoading', false);
				});
		} else {
			this.liberacaoDaCaucaoService.setErrorMessage('Por favor anexe todos os documentos.');
		}
	}

	sendCompleteInstance() {
		this.$scope.$emit('changeMainShowLoading', true);
		this.formField.status = 'Enviado';
		const form = angular.copy(this.formField);
		this.api.sendCaseInstance(form).then((response) => {
			this.$scope.$emit('changeMainShowLoading', false);
			if (!response.isSuccessful) {
				this.liberacaoDaCaucaoService.setErrorMessage(response.errorMessages[0]);
				return;
			}
			this.$state.go('case.liberacao-da-caucao.detail', { id: this.formField.id });
		});
	}

	getCurrentAnnexes() {

		const annexes = [
			this.formField.annex.form,
			this.formField.annex.legalRepresentative.document,
			this.formField.annex.legalRepresentative.identityRenter,
			this.formField.annex.legalRepresentative.other,
			this.formField.annex.subscritor.identity,
			this.formField.annex.subscritor.other,
			this.formField.annex.subscritor.identityAdmin,
			this.formField.annex.subscritor.cnpj,
			this.formField.annex.subscritor.constituitionDocumentation
		];

		for (let i = annexes.length - 1; i >= 0; i--) {
			// Garante que novas caixas de upload, irão automaticamente serem criados conforme a necessidade
			if (typeof annexes[i] === "undefined") annexes[i] = this.formField.pristineAnnexObj();
			if (!annexes[i].show) {
				angular.extend(annexes[i], this.formField.pristineAnnexObj());
				annexes.splice(i, 1);
			}
		}

		// console.log('getCurrentAnnexes', annexes);
		// console.log(this.formField.annex.form, this.formField.annex.legalRepresentative.document, this.formField.annex.legalRepresentative.identityRenter, this.formField.annex.legalRepresentative.other);

		return annexes;
	}

	getCurrentAnnexesToValidate() {

		const annexes = [
			this.formField.annex.form,
			this.formField.annex.legalRepresentative.document,
			this.formField.annex.legalRepresentative.identityRenter,
			this.formField.annex.subscritor.identity,
			//this.formField.annex.subscritor.other,
			this.formField.annex.subscritor.identityAdmin,
			this.formField.annex.subscritor.cnpj,
			this.formField.annex.subscritor.constituitionDocumentation
		];

		for (let i = annexes.length - 1; i >= 0; i--) {
			// Garante que novas caixas de upload, irão automaticamente serem criados conforme a necessidade
			if (typeof annexes[i] === "undefined") annexes[i] = this.formField.pristineAnnexObj();
			if (!annexes[i].show) {
				angular.extend(annexes[i], this.formField.pristineAnnexObj());
				annexes.splice(i, 1);
			}
		}

		// console.log('getCurrentAnnexes', annexes);
		// console.log(this.formField.annex.form, this.formField.annex.legalRepresentative.document, this.formField.annex.legalRepresentative.identityRenter, this.formField.annex.legalRepresentative.other);

		return annexes;
	}

	navStepStatus(state) {
		// console.log('navStepStatus', state, this.formField.formStep);

		let stepStatus = '';

		if (state === this.formField.formStep) {
			// console.log('navStepStatus formStep:', state, this.formField.formStep);

			return 'act';
		}

		if (state === 'case.liberacao-da-caucao.new') {

			stepStatus = this.formField.information.status;

			if (stepStatus !== 'Não Iniciado') {

				return 'edt';
			}
		}

		if (state === 'case.liberacao-da-caucao.annex') {

			stepStatus = this.formField.annex.status;

			if (stepStatus !== 'Não Iniciado') { // && stepStatus !== 'Incompleto'
				return 'edt';
			}
		}

		return '';
	}

	navStepCompleteness(state) {
		// console.log('navStepCompleteness', state);

		let stepCompletenessClass = 'hide';

		const warningClass = 'fa-exclamation-triangle warning';
		const successClass = 'fa-check-circle success';

		if (state === 'case.liberacao-da-caucao.new') {
			// console.log('state === case.liberacao-da-caucao.new:', state === 'case.liberacao-da-caucao.new')

			if (this.formField.information.status === 'Incompleto') {
				// console.log('this.formField.information.status === Incompleto', this.formField.information.status === 'Incompleto')
				stepCompletenessClass = warningClass;
			}

			if (this.formField.information.status === 'Concluido') {
				// console.log('this.formField.information.status === Concluido', this.formField.information.status === 'Concluido')
				stepCompletenessClass = successClass;
			}
		}

		if (state === 'case.liberacao-da-caucao.annex') {
			stepCompletenessClass = (this.isValidUploads() ? successClass : warningClass);
		}

		// console.log('class: ', stepCompletenessClass);
		return stepCompletenessClass;
	}

	navStepGoTo(state) {

		if (state === "case.liberacao-da-caucao.new") {
			this.doPrevious();
		} else if (state === "case.liberacao-da-caucao.annex") {
			this.doNext();
		}

		// console.log('navStepGoTo', state);
		// console.log(this.formField.status);

		// this.cleanMessages();

		// console.log('navStepGoTo going');

		//this.requiredFieldsStep();

		//this.$window.scrollTo(0, 0);

		//this.$state.go(state);
	}

	clickPopupDocumentation(value) {
		this.$scope.$broadcast('clickPopupDocumentation', value);
	}

	isButtonShow(botao) {
		if (botao === "enviar") {
			return (!this.isOriginApp()) && (this.liberacaoDaCaucaoService.buttonControl.finish.show) && (this.$state.current.name !== 'case.liberacao-da-caucao.new');
		} else if (botao === "aprovar") {
			return (this.isOriginApp()) && (this.formField.status === 'Aguardando Pré-análise') && (this.$state.current.name === 'case.liberacao-da-caucao.annex');
		} else if (botao === "proximo") {
			return this.liberacaoDaCaucaoService.buttonControl.next.show;
		} else if (botao === "anterior") {
			return this.liberacaoDaCaucaoService.buttonControl.previous.show;
		} else if (botao === "salvar") {
			return this.liberacaoDaCaucaoService.buttonControl.save.show;
		} else if (botao === "reprovar") {
			return (this.isOriginApp()) && (this.formField.status === 'Aguardando Pré-análise');
		} else if (botao === "cancelar") {
			return (!this.isOriginApp()) && (this.liberacaoDaCaucaoService.buttonControl.cancel.show);
		}
	}

	isButtonDisabled(botao) {
		if ((botao === "enviar") || (botao === "aprovar")) {
			return this.liberacaoDaCaucaoService.buttonControl.finish.disabled;
		} else if (botao === "proximo") {
			return this.liberacaoDaCaucaoService.buttonControl.next.disabled;
		} else if (botao === "anterior") {
			return this.liberacaoDaCaucaoService.buttonControl.previous.disabled;
		} else if (botao === "salvar") {
			return this.liberacaoDaCaucaoService.buttonControl.save.disabled;
		}
	}

	isOriginApp() {
		let retorno = false;
		if ((!!this.formField.origin) && (!!this.formField.origin.app)) {
			retorno = true;
		}
		return retorno;
	}

}

module.exports = liberacaoDaCaucaoController;
