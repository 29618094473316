'use strict';

const listController = require('./list/list.controller');
const listApiService = require('./list/list-api.service');

const detailController = require('./detail/detail.controller');
const detailApiService = require('./detail/detail-api.service');

const routesConfiguration = require('./comissoes-pagas.routes');

const comissoesPagasModule = angular.module('cap.secured.comissoes-pagas', [
		'ui.router'
	])
	.controller('comissoesPagasListController', listController)
	.service('comissoesPagasListApi', listApiService)

	.controller('comissoesPagasDetailController', detailController)
	.service('comissoesPagasDetailApi', detailApiService)

	.config(routesConfiguration);

module.exports = comissoesPagasModule;
