"use strict";

import VisualForceApi from "../core/sfdc/visual-force-api.js";

class propostaApi extends VisualForceApi {
	/* @ngInject */
	constructor($q) {
		super();
		this.$q = $q;
	}

	saveProposta(form) {
		const cap_access_token = localStorage.getItem("cap_access_token");

		const model = {
			accessToken: cap_access_token,
			propostaSave: form
		};

		return super.invoke(
			"CapCommunityPropostaControllerExt.solSalvaProposta",
			model
		);
	}

	generateProposta(form) {
		const cap_access_token = localStorage.getItem("cap_access_token");

		const model = {
			propostaSave: form,
			accessToken: cap_access_token
		};

		console.log("MODEL GERAR PROPOSTA: ", model);
		return super.invoke(
			"CapCommunityPropostaControllerExt.solConcluirProposta",
			model
		);
	}

	generateURLCreditCard(propostaId) {
		console.log("chamando url pra gerar a url do cartao");

		const cap_access_token = localStorage.getItem("cap_access_token");

		return super.invoke(
			"CapCommunityPropostaControllerExt.solGerarURLPagamentoCredito",
			propostaId,
			cap_access_token
		);
	}

	getProposta(propostaId) {
		// console.log('getProposta', propostaId);

		const cap_access_token = localStorage.getItem("cap_access_token");

		return super.invoke(
			"CapCommunityPropostaControllerExt.solProposta",
			propostaId,
			null,
			cap_access_token
		);
	}

	getPropostaOrigem(propostaId, origem) {
		// console.log('getProposta', propostaId);

		const cap_access_token = localStorage.getItem("cap_access_token");

		return super.invoke(
			"CapCommunityPropostaControllerExt.solProposta",
			propostaId,
			origem,
			cap_access_token
		);
	}

	cancelProposta(Id) {
		const cap_access_token = localStorage.getItem("cap_access_token");

		return super.invoke(
			"CapCommunityPropostaControllerExt.solCancelarProposta",
			Id,
			cap_access_token
		);
	}

	adviseHasPrinted(Id) {
		const cap_access_token = localStorage.getItem("cap_access_token");

		return super.invoke(
			"CapCommunityPropostaControllerExt.solPrintedProposta",
			Id,
			cap_access_token
		);
	}

	generatePropostaForm(Id) {
		const cap_access_token = localStorage.getItem("cap_access_token");

		return super.invoke(
			"CapCommunityPropostaControllerExt.generatePropostaForm",
			Id,
			cap_access_token
		);
	}
	getSusepInformation() {
		const cap_access_token = localStorage.getItem("cap_access_token");

		return super.invoke(
			"CapCommunityPropostaControllerExt.canCreateProposta", {},
			cap_access_token
		);
	}
}

module.exports = propostaApi;
