'use strict';

//Controller Base
const bloqReaplicacaoController = require('./bloq-reaplicacao.controller');
const bloqReaplicacaoService = require('./bloq-reaplicacao.service');

// Upload 
//const bloqReaplicacaoAnnexController = require('./bloq-reaplicacao-annex/bloq-reaplicacao-annex.controller');
//const bloqReaplicacaoAnnexService = require('./bloq-reaplicacao-annex/bloq-reaplicacao-annex.service');

//Detail
const bloqReaplicacaoDetailController = require('./bloq-reaplicacao-detail/bloq-reaplicacao.detail.controller');
const bloqReaplicacaoDetailService = require('./bloq-reaplicacao-detail/bloq-reaplicacao.detail.service');

//New
const bloqReaplicacaoNewController = require('./bloq-reaplicacao-new/bloq-reaplicacao.new.controller');
const bloqReaplicacaoNewService = require('./bloq-reaplicacao-new/bloq-reaplicacao.new.service');

//View Model
const bloqReaplicacaoFormService = require('./bloq-reaplicacao.form.service');

//Routes
const routesConfiguration = require('./bloq-reaplicacao.routes');

//Depedency
const componentModule = angular.module('cap.secured.case.bloq-reaplicacao', [
		'ui.router'
	])
	.controller('bloqReaplicacaoController', bloqReaplicacaoController)
	.service('bloqReaplicacaoService', bloqReaplicacaoService)

	.controller('bloqReaplicacaoNewController', bloqReaplicacaoNewController)
	.service('bloqReaplicacaoNewService', bloqReaplicacaoNewService)

	.controller('bloqReaplicacaoDetailController', bloqReaplicacaoDetailController)
	.service('bloqReaplicacaoDetailService', bloqReaplicacaoDetailService)

	.service('bloqReaplicacaoService', bloqReaplicacaoService)

	.service('bloqReaplicacaoFormService', bloqReaplicacaoFormService)

	.config(routesConfiguration);

module.exports = componentModule;
