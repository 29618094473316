'use strict';

const inputErrorClass = 'has-error';

class newJuridicalAccountController {

	// @ngInject
	constructor($scope, $state, $stateParams, $window, $timeout, sfConfig, newJuridicalAccountService, validationFormFields, accountUtilService, propostaSetupAccountService, feedbackMessages, breadcrumbHelper, cepHelper, alteracaoDadosSetupAccountService, crivoHelper, autoCompleteService) {
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.$window = $window;
		this.messages = feedbackMessages;
		this.sfConfig = sfConfig;
		this.newJuridicalAccountService = newJuridicalAccountService;
		this.accountUtilService = accountUtilService;
		this.validationFormFields = validationFormFields;
		this.propostaSetupAccountService = propostaSetupAccountService;
		this.alteracaoDadosService = alteracaoDadosSetupAccountService;
		this.$scope = $scope;
		this.$scope.breadcrumb = breadcrumbHelper;
		this.$scope.$emit('changeMainShowLoading', false);
		this.cepHelper = cepHelper;
		this.cepHelper.setPristine();
		this.crivoHelper = crivoHelper;
		this.crivoHelper.setPristine();
		this.autoComplete = autoCompleteService;
		this.$timeout = $timeout;

		this.newAccountObj = {
			id: null,
			company: {
				cnpj: {
					value: '',
					classError: ''
				},
				companyName: {
					value: '',
					classError: ''
				},
				tradingName: {
					value: '',
					classError: ''
				},
				freeRegistration: {
					value: '',
					classError: ''
				},
				registration: {
					value: '',
					classError: ''
				},
				phone1: {
					value: '',
					classError: ''
				},
				extensionNumber1: {
					value: '',
					classError: ''
				},
				phone2: {
					value: '',
					classError: ''
				},
				extensionNumber2: {
					value: '',
					classError: ''
				}
			},

			address: {
				cep: {
					value: '',
					classError: '',
					hasError: false
				},
				address: {
					value: '',
					classError: ''
				},
				addressNumber: {
					value: '',
					classError: ''
				},
				complement: {
					value: '',
					classError: ''
				},
				neighborhood: {
					value: '',
					classError: ''
				},
				city: {
					value: '',
					classError: ''
				},
				uf: {
					value: '',
					classError: ''
				},
				isResidenciaFiscalBrasil: {
					value: 'true',
					classError: ''
				},
				residenciaFiscal: {
					id: '',
					value: '',
					label: '',
					classError: ''
				}
			},

			representative: {
				name: {
					value: '',
					classError: ''
				},
				email: {
					value: '',
					classError: ''
				},
				cel: {
					value: '',
					classError: ''
				}
			},

			wishReceiveEmail: false
		};

		this.emailMsgError = '';
		this.phone1ErrorMsg = '';
		this.celErrorMsg = '';
		this.addressMsgError = '';
		this.cityMsgError = '';
		this.neighborhoodMsgError = '';
		this.errorMessages = [];
		this.setStateToGo();
		this.showSave = false;

		this.configureBreadCrumb();

		if (this.hasValidUrlParams()) {

			if (this.isMyAccount()) {

				this.$state.go('my-account-juridical-edit');
				return;
			}

			this.showSave = true;
			this.loadAccountInformation();
		}
	}

	configureBreadCrumb() {
		this.$scope.breadcrumb.reset();
		this.$scope.breadcrumb.addMenuItem('Home', '/caponline/secured#!/home', true, false);
		this.$scope.breadcrumb.addMenuItem('Cadastro', '/caponline/secured#!/conta/lista', true, false);
		this.$scope.breadcrumb.addMenuItem('Novo cadastro Pessoa Jurídica', '#', false, true);
	}

	isMyAccount() {

		return this.$stateParams.id === this.sfConfig.idUSerAccount;
	}

	hasValidUrlParams() {

		return (this.$stateParams && this.$stateParams.id) ? true : false;
	}

	loadAccountInformation() {
		console.log('loadAccountInformation()');

		this.$scope.$emit('changeMainShowLoading', true);

		this.newJuridicalAccountService.getAccountDetails(this.$stateParams.id).then((response) => {
			console.log('vamos logar o response do getAccountDetails : ', response);
			if (!response.isSuccessful) {
				this.errorMessage = response.errorMessages[0];
			} else {
				this.newAccountObj.id = this.$stateParams.id;
				this.newAccountObj.company = {
					cnpj: {
						value: response.accountPJInfo.company.cnpj,
						classError: ''
					},
					companyName: {
						value: response.accountPJInfo.company.name,
						classError: ''
					},
					tradingName: {
						value: response.accountPJInfo.company.fantasyName,
						classError: ''
					},
					freeRegistration: {
						value: response.accountPJInfo.company.isento,
						classError: ''
					},
					registration: {
						value: response.accountPJInfo.company.inscription,
						classError: ''
					},
					phone1: {
						value: response.accountPJInfo.company.mainPhone,
						classError: ''
					},
					extensionNumber1: {
						value: response.accountPJInfo.company.ramal,
						classError: ''
					},
					phone2: {
						value: response.accountPJInfo.company.alternativePhone,
						classError: ''
					},
					extensionNumber2: {
						value: response.accountPJInfo.company.ramal2,
						classError: ''
					}
				};
				this.newAccountObj.address = {
					cep: {
						value: response.accountPJInfo.address.cep,
						classError: ''
					},
					address: {
						value: response.accountPJInfo.address.address,
						classError: ''
					},
					addressNumber: {
						value: response.accountPJInfo.address.addressNumber,
						classError: ''
					},
					complement: {
						value: response.accountPJInfo.address.complement,
						classError: ''
					},
					neighborhood: {
						value: response.accountPJInfo.address.neighborhood,
						classError: ''
					},
					city: {
						value: response.accountPJInfo.address.city,
						classError: ''
					},
					uf: {
						value: response.accountPJInfo.address.uf,
						classError: ''
					},
					isResidenciaFiscalBrasil: {
						value: response.accountPJInfo.address.isResidenciaFiscalBrasil ? 'true' : 'false',
						classError: ''
					},
					residenciaFiscal: {
						id: response.accountPJInfo.address.idResidenciaFiscal,
						label: response.accountPJInfo.address.residenciaFiscal,
						classError: ''
					}
				};
				this.newAccountObj.representative = {
					name: {
						value: response.accountPJInfo.company.contactName,
						classError: ''
					},
					email: {
						value: response.accountPJInfo.company.email,
						classError: ''
					},
					cel: {
						value: response.accountPJInfo.company.phone,
						classError: ''
					}
				};
				this.newAccountObj.wishReceiveEmail = response.accountPJInfo.company.acceptEmail;
			}

			console.log('logando this.newAccountObj.address : ', this.newAccountObj.address);

			this.$scope.$emit('changeMainShowLoading', false);
		});
		console.log('fim do loadAccountInformation()');
	}

	setStateToGo() {
		if (this.propostaSetupAccountService.getFromProposta()) {
			this.accountUtilService.setStateToGo('proposta.new');
		} else if (this.propostaSetupAccountService.getFromCartaCaucaoLocator() || this.propostaSetupAccountService.getFromCartaCaucaoEstateAdmin()) {
			this.accountUtilService.setStateToGo('proposta.caucao');
		} else if (this.alteracaoDadosService.getFromAlteracaoImovel()) {
			this.accountUtilService.setStateToGo('case.alteracao-dados.alteracaoImovel');
		} else if (this.alteracaoDadosService.getFromMudancaLocador()) {
			this.accountUtilService.setStateToGo('case.alteracao-dados.mudancaLocador');
		} else if (this.alteracaoDadosService.getFromMudancaLocador()) {
			this.accountUtilService.setStateToGo('case.alteracao-dados.mudancaLocador');
		} else if (this.alteracaoDadosService.getFromInclusaoCaucaoLocador() || this.alteracaoDadosService.getFromInclusaoCaucaoAdministradorImovel()) {
			this.accountUtilService.setStateToGo('case.alteracao-dados.inclusaoCaucao');
		} else {
			this.accountUtilService.setStateToGo('account-detail');
		}
	}

	invokeNewAccount(newAccount) {

		this.newJuridicalAccountService.createNewAccount(newAccount).then((response) => {
			console.log(response);

			if (response && response.errorMessages && response.errorMessages.length > 0) {
				this.errorMessages = response.errorMessages;
				this.showLoading = false;
				this.$window.scrollTo(0, 0);
				this.$scope.$emit('changeMainShowLoading', false);

			} else if (response.isSuccessful) {
				this.errorMessages = [];

				if (response.account) {
					if (this.propostaSetupAccountService.getFromProposta()) {
						this.propostaSetupAccountService.setNewAccountInfo(response.account);
						// this.propostaSetupAccountService.setFromProposta(false);

					} else if (this.propostaSetupAccountService.getFromCartaCaucaoLocator()) {
						this.propostaSetupAccountService.setNewLocatorInfo(response.account);
						// this.propostaSetupAccountService.setFromCartaCaucaoLocator(false);

					} else if (this.propostaSetupAccountService.getFromCartaCaucaoEstateAdmin()) {
						this.propostaSetupAccountService.setNewEstateAdminInfo(response.account);
						// this.propostaSetupAccountService.setFromCartaCaucaoEstateAdmin(false);
					} else if (this.alteracaoDadosService.getFromAlteracaoImovel()) {
						this.alteracaoDadosService.setNewLocador(response.account);
					} else if (this.alteracaoDadosService.getFromMudancaLocador()) {
						this.alteracaoDadosService.setNewLocador(response.account);
					} else if (this.alteracaoDadosService.getFromInclusaoCaucaoLocador()) {
						this.alteracaoDadosService.setNewLocador(response.account);
					} else if (this.alteracaoDadosService.getFromInclusaoCaucaoAdministradorImovel()) {
						this.alteracaoDadosService.setAdministradorImovel(response.account);
					}

					if (this.accountUtilService.getStateToGo() === 'account-detail') {
						this.$scope.$emit('changeMainShowLoading', false);
						this.$state.go('account-detail', { id: response.account.Id });
					} else {
						this.$scope.$emit('changeMainShowLoading', false);
						this.$state.go(this.accountUtilService.getStateToGo());
					}
				}
			}
		});
	}

	newAccountRequest() {
		console.log(this.newAccountObj);

		if (this.validateInputs()) {

			this.errorMessages = [];

			this.invokeNewAccount(this.newAccountObj);
		} else {
			this.$scope.$emit('changeMainShowLoading', false);
			this.errorMessages = ['Revise os campos marcados em vermelho.'];
			this.$window.scrollTo(0, angular.element(document.querySelector('.has-error')).offsetTop);
		}
	}

	validateInputs() {
		let result = true;
		this.$scope.$emit('changeMainShowLoading', true);

		if (!this.accountUtilService.isValidCnpj(this.newAccountObj.company.cnpj.value)) {
			this.newAccountObj.company.cnpj.classError = inputErrorClass;
			result = false;
		} else {
			this.newAccountObj.company.cnpj.classError = '';
		}

		if (!this.newAccountObj.company.companyName.value || !this.newAccountObj.company.companyName.value.replace(/\s/g, '').length) {
			this.newAccountObj.company.companyName.classError = inputErrorClass;
		} else {
			this.newAccountObj.company.companyName.classError = '';
		}

		if (this.newAccountObj.company.phone1.value && !this.accountUtilService.isValidPhoneNumber(this.newAccountObj.company.phone1.value)) {
			this.newAccountObj.company.phone1.classError = inputErrorClass;
			this.phone1ErrorMsg = 'Campo inválido.'
			result = false;
		} else if (!this.newAccountObj.company.phone1.value) {
			this.newAccountObj.company.phone1.classError = inputErrorClass;
			this.phone1ErrorMsg = 'Campo obrigatório.'
			result = false;
		} else {
			this.newAccountObj.company.phone1.classError = '';
		}

		if (this.newAccountObj.company.phone2.value && !this.accountUtilService.isValidPhoneNumber(this.newAccountObj.company.phone2.value)) {
			this.newAccountObj.company.phone2.classError = inputErrorClass;
			result = false;
		} else {
			this.newAccountObj.company.phone2.classError = '';
		}

		if (this.newAccountObj.company.extensionNumber2.value && !this.newAccountObj.company.phone2.value) {
			this.newAccountObj.company.phone2.classError = inputErrorClass;
			result = false;
		} else {
			this.newAccountObj.company.phone2.classError = '';
		}

		if (!this.newAccountObj.company.freeRegistration.value && !this.newAccountObj.company.registration.value) {
			this.newAccountObj.company.registration.classError = inputErrorClass;
			result = false;
		} else if (this.newAccountObj.company.freeRegistration.value) {
			this.newAccountObj.company.registration.classError = '';
			this.newAccountObj.company.registration.value = ''
		} else if (!this.newAccountObj.company.freeRegistration.value && this.newAccountObj.company.registration.value) {
			this.newAccountObj.company.registration.classError = '';
		}

		if (!this.newAccountObj.address.cep.value) {
			this.newAccountObj.address.cep.classError = inputErrorClass;
			this.cepMsgError = 'Campo obrigatório';
			result = false;
		} else if (!this.accountUtilService.isValidCep(this.newAccountObj.address.cep.value)) {
			this.newAccountObj.address.cep.classError = inputErrorClass;
			this.cepMsgError = 'Campo inválido';
			result = false;
		} else if (this.newAccountObj.address.cep.hasError) {

			this.newAccountObj.address.cep.classError = inputErrorClass;
			result = false;
		} else {
			this.newAccountObj.address.cep.classError = '';
		}

		if (!this.newAccountObj.address.address.value) {
			this.newAccountObj.address.address.classError = inputErrorClass;
			this.addressMsgError = 'Campo obrigatório.';
			result = false;
		}
		/*else if (this.newAccountObj.address.address.value.length < 5) {
			this.newAccountObj.address.address.classError = inputErrorClass;
			this.addressMsgError = 'O campo precisa ter pelo 5 letras';
			result = false;
		}*/
		else {
			this.newAccountObj.address.address.classError = '';
		}

		if (!this.newAccountObj.address.addressNumber.value) {
			this.newAccountObj.address.addressNumber.classError = inputErrorClass;
			result = false;
		} else {
			this.newAccountObj.address.addressNumber.classError = '';
		}

		if (!this.newAccountObj.address.neighborhood.value) {
			this.newAccountObj.address.neighborhood.classError = inputErrorClass;
			this.neighborhoodMsgError = 'Campo obrigatório.';
			result = false;
		} else {
			this.newAccountObj.address.neighborhood.classError = '';
		}

		if (!this.newAccountObj.address.city.value) {
			this.newAccountObj.address.city.classError = inputErrorClass;
			this.cityMsgError = 'Campo obrigatório.';
			result = false;
		} else if (this.newAccountObj.address.city.value.length < 3) {
			this.newAccountObj.address.city.classError = inputErrorClass;
			this.cityMsgError = 'O campo precisa ter pelo menos 3 letras';
			result = false;
		} else {
			this.newAccountObj.address.city.classError = '';
		}

		if (!this.newAccountObj.address.uf.value) {
			this.newAccountObj.address.uf.classError = inputErrorClass;
			result = false;
		} else {
			this.newAccountObj.address.uf.classError = '';
		}

		if (this.accountUtilService.hasInvalidSequence(this.newAccountObj.representative.name.value) ||
			this.accountUtilService.hasSequencedSpace(this.newAccountObj.representative.name.value) ||
			!this.accountUtilService.hasLastName(this.newAccountObj.representative.name.value) ||
			this.validationFormFields.hasBadExpression(this.newAccountObj.representative.name.value) ||
			this.newAccountObj.representative.name.value.length < 5) {

			this.newAccountObj.representative.name.classError = inputErrorClass;
			result = false;
		} else {
			this.newAccountObj.representative.name.classError = '';
		}

		if (!this.newAccountObj.representative.email.value) {
			this.newAccountObj.representative.email.classError = inputErrorClass;
			this.emailMsgError = 'Campo obrigatório.';
			result = false;
		} else if (!this.accountUtilService.isValidEmail(this.newAccountObj.representative.email.value)) {
			this.newAccountObj.representative.email.classError = inputErrorClass;
			this.emailMsgError = 'Formato de email inválido.';
			result = false;
		} else {
			this.newAccountObj.representative.email.classError = '';
		}

		if (this.newAccountObj.representative.cel.value && !this.accountUtilService.isValidPhoneNumber(this.newAccountObj.representative.cel.value)) {
			this.newAccountObj.representative.cel.classError = inputErrorClass;
			this.celErrorMsg = 'Campo inválido.';
			result = false;
		} else if (!this.newAccountObj.representative.cel.value) {
			this.newAccountObj.representative.cel.classError = inputErrorClass;
			this.celErrorMsg = 'Campo obrigatório.';
			result = false;
		} else {
			this.newAccountObj.representative.cel.classError = '';
		}

		if (this.newAccountObj.address.isResidenciaFiscalBrasil.value == 'false' && (this.newAccountObj.address.residenciaFiscal != null && !this.newAccountObj.address.residenciaFiscal.id)) {
			this.newAccountObj.address.residenciaFiscal.classError = inputErrorClass;
			result = false;
		} else {
			this.newAccountObj.address.residenciaFiscal.classError = '';
		}

		console.log(JSON.stringify(this.newAccountObj));

		return result;
	}

	getAddressFromCEP() {
		const me = this;
		const cep = me.newAccountObj.address.cep.value;

		if ((cep.length == 9) && (cep.indexOf("-") == 5)) {
			this.$scope.$emit('changeMainShowLoading', true);
			this.cepHelper.getAddressFromCEP(cep, (response) => {

				me.newAccountObj.address.address.value = response.logradouro;
				me.newAccountObj.address.neighborhood.value = response.bairro;
				me.newAccountObj.address.city.value = response.cidade;
				me.newAccountObj.address.uf.value = response.uf;

				if (!response.isSuccessful) {
					me.newAccountObj.address.cep.classError = inputErrorClass;

					if (me.cepHelper.remoteCallErrorLimitWasExceded()) {
						me.newAccountObj.address.cep.classError = '';
						me.newAccountObj.address.cep.hasError = false;
						me.messages.showErrorMessage(me.$scope, me.cepHelper.remoteCallErrorMessage);
					} else {
						me.newAccountObj.address.cep.hasError = true;
						me.cepMsgError = response.errorMessages[0];
					}
					me.cepHelper.setPristine();
				} else {
					me.newAccountObj.address.cep.classError = '';
					me.newAccountObj.address.cep.hasError = false;
					me.cepMsgError = '';
					me.messages.showErrorMessage(me.$scope, '');
				}

				this.$scope.$emit('changeMainShowLoading', false);
			});
		} else {
			me.cepHelper.setPristine();
			me.newAccountObj.address.address.value = null;
			me.newAccountObj.address.neighborhood.value = null;
			me.newAccountObj.address.city.value = null;
			me.newAccountObj.address.uf.value = null;
		}
	}

	verificarIsencaoEstadualOuMunicipalCrivo(paReq_razaoSocial, paInscricaoEstadual, paInscricaoMunicipal, me) {

		let vaInscricao = '';

		if (paInscricaoEstadual != '' || paInscricaoMunicipal != '') {
			vaInscricao = ((paInscricaoEstadual != '') ? paInscricaoEstadual : paInscricaoMunicipal);
		} else {
			if (paReq_razaoSocial != '') {
				me.newAccountObj.company.freeRegistration.value = true;
			}
		}

		return vaInscricao;
	}

	consultarCrivo() {

		const me = this;
		const cnpj = me.newAccountObj.company.cnpj.value;

		me.$scope.$emit('changeMainShowLoading', true);

		if (this.accountUtilService.isValidCnpj(cnpj)) {

			console.log('---- 1. VAI CHAMAR RECAPTCHA: ');

			//RECAPTCHA
			grecaptcha.ready(function () {
				grecaptcha.execute('6Ld7HnMaAAAAALsutf2GlIhn9Qb-reY-1ir-38uE', { action: 'submit' }).then(function (token) {

					console.log('---- 2. GTOKEN: ', token);

					me.crivoHelper.consultarCrivo(cnpj, token, (response) => {

						console.log('--> response: ', response);

						if (!response.isSuccessful) {

							const message = response.errorMessages && response.errorMessages[0] ? response.errorMessages[0] : me.crivoHelper.remoteCallErrorMessage;
							me.messages.showErrorMessage(me.$scope, message);
							me.cepHelper.setPristine();
						} else {
							me.newAccountObj.company.companyName.value = response.req_razaoSocial.slice(0, 42);
							me.newAccountObj.company.tradingName.value = response.req_nomeFantasia.slice(0, 30);
							//me.newAccountObj.company.registration.value = me.verificarIsencaoEstadualOuMunicipalCrivo(response.req_razaoSocial, response.req_inscricaoEstadual, response.req_inscricaoMunicipal, me);
							me.messages.showErrorMessage(me.$scope, '');
						}

						me.$scope.$emit('changeMainShowLoading', false);

					});

				});
			});

		} else {
			me.crivoHelper.setPristine();
			me.newAccountObj.company.companyName.value = null
			me.newAccountObj.company.tradingName.value = null;
			me.newAccountObj.company.registration.value = null
			me.newAccountObj.company.freeRegistration.value = null
			me.messages.showErrorMessage(me.$scope, '');
		}
	}

	searchResidenciaFiscal(searchString) {
		console.log('searchResidenciaFiscal | ', searchString);
		return this.$parent.newJuridicalAccount.newJuridicalAccountService.searchResidenciaFiscal(searchString);
	}

	searchResidenciaFiscalSelect(item) {
		console.log('searchResidenciaFiscalSelect', item);

		if (!item) { return; }

		const newJuridicalAccount = this.$parent.newJuridicalAccount;

		newJuridicalAccount.newAccountObj.address.residenciaFiscal = item.originalObject;
	}

	searchResidenciaFiscalFocusOut() {
		console.log('searchResidenciaFiscalFocusOut', this);

		// @todo workaround https://github.com/ghiden/angucomplete-alt/issues/106
		this.$timeout(() => {

			const hasValidInput = this.autoComplete.hasValidInput(this.newAccountObj.address.residenciaFiscal);

			if (!hasValidInput) {
				this.autoComplete.clearInput(this.$scope, 'searchResidenciaFiscal');
			}
		}, 100);
	}

	searchResidenciaFiscalChanged() {
		console.log('searchResidenciaFiscalChanged', this);

		const newJuridicalAccount = this.$parent.newJuridicalAccount;
		newJuridicalAccount.autoComplete.resetSelected(newJuridicalAccount.newAccountObj.address, 'residenciaFiscal');
	}

	searchNoResultText() {
		const resultText = 'Nenhum país encontrado';
		return resultText;
	}

}

module.exports = newJuridicalAccountController;
