'use strict';

class administrarUsuarioEditController {

	// @ngInject
	constructor($scope, $window, $state, $stateParams, administrarUsuarioEditApi, validationFormFields, feedbackMessages) {

		this.$scope = $scope;
		this.$window = $window;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.api = administrarUsuarioEditApi;
		this.valid = validationFormFields;
		this.messages = feedbackMessages;

		this.userId = this.$stateParams.id;

		this.setUserForm();
		this.getDetails();

		// setInterval(() => { console.log('form', this.user); }, 20000);
	}

	setUserForm() {

		this.user = {
			userId: this.userId,
			name: null,
			email: null,
			cpf: null,
			login: null,
			userType: null,
			active: null,
			visaoCarteira: null,
			permissions: this.api.initUserPermissions()
		};
	}

	setPermissionValue(nome, value) {
		if (!this.disablePermissionamento()) this.user.permissions[nome] = value;
	}

	changeUserType() {
		if ((this.user.userType !== null) && (this.user.userType.startsWith('Master'))) {
			this.user.permissions = this.api.initUserPermissions();
			this.user.visaoCarteira = 'Geral';
		}
	}

	disablePermissionamento() {
		return (this.user.userType !== null && ((this.user.userType == '') || (this.user.userType.startsWith('Master'))));
	}

	isMaster() {
		return this.user.userType.startsWith('Master');
	}

	hasId() {
		return !!this.userId;
	}

	getDetails() {
		if (!this.hasId()) {
			this.$state.go('administrar-usuario.list');
		}
		this.$scope.$emit('changeMainShowLoading', true);
		this.api.getDetails(this.userId).then((response) => {
			this.$scope.$emit('changeMainShowLoading', false);
			if (!response.isSuccessful) {
				this.$window.scrollTo(0, 0);
				const errorMessage = angular.toJson(response.errorMessages);
				this.messages.showErrorMessage(this.$scope, errorMessage);
			} else {
				angular.extend(this.user, response.details);
				console.log(this.user);
				if (!this.user.isEdit) {
					this.$state.go('administrar-usuario.list');
				}
			}
		});
	}

	submit(form) {
		this.messages.cleanMessages(this.$scope);
		form.$setSubmitted();
		if (form.$invalid) {
			return;
		}

		this.$scope.$emit('changeMainShowLoading', true);
		this.api.sendForm(this.user).then((response) => {
			this.$scope.$emit('changeMainShowLoading', false);
			this.$window.scrollTo(0, 0);
			if (!response.isSuccessful) {
				const errorMessage = angular.toJson(response.errorMessages);
				this.messages.showErrorMessage(this.$scope, errorMessage);
			} else {
				const successMessage = 'Usuário Salvo com sucesso!';
				this.messages.showSuccessMessage(this.$scope, successMessage);
				this.$state.go('administrar-usuario.detail', { id: response.userId });
			}
		});
	}

	cancel() {
		this.$state.go('administrar-usuario.list');
	}
}

module.exports = administrarUsuarioEditController;
