'use strict';

class liberacaoDaCaucaoDetailController {

	// @ngInject
	constructor($stateParams, $state, sfConfig, liberacaoDaCaucaoDetailApi, liberacaoDaCaucaoFormControlService, liberacaoDaCaucaoService, feedbackMessages, $scope, breadcrumbHelper) {
		this.liberacaoDaCaucaoDetailApi = liberacaoDaCaucaoDetailApi;
		this.messages = feedbackMessages;
		this.formField = liberacaoDaCaucaoFormControlService;
		this.liberacaoDaCaucaoService = liberacaoDaCaucaoService;
		this.$stateParams = $stateParams;
		this.$state = $state;
		this.$scope = $scope;
		this.showLoading = true;
		this.case = {
			details: null,
			related: {
				propostas: null,
				rentGarantee: null,
				contacts: null,
				attachments: null,
				history: null
			}
		}
		this.errorMessage = '';
		this.getCaseDetails();
		this.$scope.breadcrumb = breadcrumbHelper;
		this.configureBreadCrumb();
		this.liberacaoDaCaucaoService.setWarningMessage('');
	}

	configureBreadCrumb() {
		this.$scope.breadcrumb.reset();
		this.$scope.breadcrumb.addMenuItem('Home', '/caponline/secured#!/home', true, false);
		this.$scope.breadcrumb.addMenuItem('Solicitação', '/caponline/secured#!/solicitacoes/lista', true, false);
		this.$scope.breadcrumb.addMenuItem('Detalhes da Liberação da Garantia', '#', false, true);
	}

	hasCaseDetails() {
		return !!this.case.details;
	}

	hasValidUrlParams() {
		return (this.$stateParams && this.$stateParams.id) ? true : false;
	}

	hasEdit() {
		const status = ['em andamento', 'reprovado', 'aguardando pré-análise'];
		const currentStatus = this.case.details ? this.case.details.status.toLowerCase() : null;
		return (status.indexOf(currentStatus) > -1);
	}

	getCaseDetails() {
		if (!this.hasValidUrlParams()) {
			this.showLoading = false;
			this.$state.go('case.list');
		}
		this.$scope.$emit('changeMainShowLoading', true);
		this.liberacaoDaCaucaoDetailApi.getDetails(this.$stateParams.id).then((response) => {
			this.$scope.$emit('changeMainShowLoading', false);
			// console.log(response);
			if (!response.isSuccessful) {
				this.errorMessage = response.errorMessages[0];
			} else {
				this.case.details = response.resgate;
				if (response.resgate.origin.app === true) {
					this.case.details.legalRepresentative = ''; //CAP-3553
				}
				this.case.related.propostas = response.resgate.propostas;
				this.case.related.rentGarantee = response.resgate.titulos;
				this.case.related.attachments = response.resgate.contatos;
				this.case.related.attachments = response.resgate.anexos;
				this.case.related.history = response.resgate.history;
				this.case.editCaseData = this.decodeAndParseJson(response.resgate.editCaseData);
				this.case.editCaseData.id = this.$stateParams.id;
				this.case.editCaseData.status = response.resgate.status;
				console.log("GET DETAIL CASE: ", this.case);
			}
			this.showLoading = false;
		});
	}

	editButtonHandler() {
		console.log('editButtonHandler()', this.case.editCaseData);

		this.formField.setPristine();
		this.liberacaoDaCaucaoService.setPristine();

		if (this.case.details.origin.app && (this.case.details.editCaseData === "")) {
			//Resgate origem app, é necessário construir o EditCaseData
			this.createEditCaseData();
		} else {
			this.formField.status = this.case.editCaseData.status;
			this.formField.formStep = this.case.editCaseData.formStep;
			this.formField.id = this.case.editCaseData.id;
			this.formField.annex = (this.case.editCaseData.annex || this.case.editCaseData.file);
			angular.merge(this.formField.information, this.formField.information, this.case.editCaseData.information);
			this.formField.edit = true;
			this.formField.origin = this.case.details.origin;
		}

		this.$state.go('case.liberacao-da-caucao.new');
	}

	createEditCaseData() {

		this.formField.id = this.case.details.id;
		this.formField.status = this.case.details.status;
		this.formField.formStep = 'case.liberacao-da-caucao.new';
		this.formField.edit = true;
		this.formField.origin = this.case.details.origin;

		/* **************************************** */
		/* ****** Information - Primeira ABA ****** */
		/* **************************************** */
		this.formField.information.status = 'Concluido';
		this.formField.information.mainAccount.value = {
			Id: this.case.details.accountId,
			Name: this.case.details.accountName,
			isPersonAccount: this.case.details.isPersonAccount
		};
		this.formField.information.proposta.value = this.case.related.propostas.map((item) => {
			return { objectId: item.propostaId, objectName: item.propostaName }
		})[0];

		this.formField.information.garantias.value = this.case.related.rentGarantee.map((item) => {
			const tituloType = (item.tituloTypeCaucionado ? '(Caucionado)' : '(Descaucionado)');
			return {
				objectId: item.titulosId,
				objectName: `${item.tituloName} / R$ ${item.tituloValue} ${tituloType}`,
				vigencia: item.tituloVigencia,
				caucionado: item.tituloTypeCaucionado,
				value: item.tituloValue,
				selected: true
			};
		});
		this.formField.information.legalRepresentative.show = true;
		this.formField.information.legalRepresentative.value = null;

		/* ********************************** */
		/* ****** Anexos - Segunda ABA ****** */
		/* ********************************** */
		this.formField.annex.form.show = false;
		this.formField.annex.legalRepresentative.document.show = false;
		this.formField.annex.legalRepresentative.identityRenter.show = false;
	}

	decodeAndParseJson(json) {
		if (json === "") {
			return { id: null };
		} else {
			// monstruosidade requisitada pelo Diogo
			const div = document.createElement('div');
			div.innerHTML = json;
			return JSON.parse(div.innerHTML);
		}
	}

}

module.exports = liberacaoDaCaucaoDetailController;
