'use strict';

class remuneracoesPagasListController {

	// @ngInject
	constructor($scope, $q, $window, $timeout, $filter, remuneracoesPagasListApi, paginationHelper, autoCompleteHelper, feedbackMessages, ordenationHelper, breadcrumbHelper) {

		this.remuneracoesPagasListApi = remuneracoesPagasListApi;
		this.$scope = $scope;
		this.$q = $q;
		this.$window = $window;
		this.$timeout = $timeout;
		this.$filter = $filter;
		this.pages = paginationHelper;
		this.order = ordenationHelper;
		this.order.setPristine();
		this.autoComplete = autoCompleteHelper;
		this.messages = feedbackMessages;
		this.$scope.breadcrumb = breadcrumbHelper;

		this.recordsList = [];
		this.productsList = null;
		this.lastFilter = null;

		this.pages.reset();

		this.setFilterPristine();

		this.setDatePickersPristine();

		this.getFilterProductsList();

		this.submitFilter(true);

		this.configureBreadCrumb();

		// setInterval(() => { console.log('form', this.filter); }, 20000);
	}

	configureBreadCrumb() {
		this.$scope.breadcrumb.reset();
		this.$scope.breadcrumb.addMenuItem('Home', '/caponline/secured#!/home', true, false);
		this.$scope.breadcrumb.addMenuItem('Remunerações Pagas', '/caponline/secured#!/remuneracoes-pagas/lista', true, true);
	}

	changeOrder(field) {
		this.order.changeOrder(field);
		this.pages.setPagesPristine();
		this.submitFilter(true);
	}

	resetLastFilter() {

		this.lastFilter = null;
	}

	// @todo precisa virar service
	setDatePickersPristine() {

		this.paymentPicker = {

			startDateOptions: {},
			endDateOptions: {},
			isOpenStartDate: false,
			isOpenEndDate: false,
			updateMax: (endDate) => {

				this.paymentPicker.startDateOptions.maxDate = endDate;
			},
			updateMin: (startDate) => {

				this.paymentPicker.endDateOptions.minDate = startDate;
			}
		}

		this.emissionPicker = {

			startDateOptions: {},
			endDateOptions: {},
			isOpenStartDate: false,
			isOpenEndDate: false,
			updateMax: (endDate) => {

				this.emissionPicker.startDateOptions.maxDate = endDate;
			},
			updateMin: (startDate) => {

				this.emissionPicker.endDateOptions.minDate = startDate;
			}
		}
	}

	resetDatePickers() {

		this.setDatePickersPristine();
	}

	setFilterPristine() {

		this.resetDatePickers();

		this.filter = {

			payment: {

				startDate: null,
				endDate: null
			},
			emission: {

				startDate: null,
				endDate: null
			},
			saleStructure: null,
			product: null,
			propostaValue: null,
			propostaNumber: null,
			remuneracaoPercentage: null,
			title: null,
			administradorImovel: null
		};
		this.autoComplete.clearInput(this.$scope, 'searchPropostaNumber');
		this.autoComplete.clearInput(this.$scope, 'searchSaleStructure');
	}

	resetFilter() {
		console.log('Pristine', this.setFilterPristine());

		this.setFilterPristine();
	}

	getFilterProductsList() {

		this.messages.cleanMessages(this.$scope);

		this.remuneracoesPagasListApi.getProductsList().then((response) => {
			// console.log('getProductsList', response);

			if (!response.isSuccessful) {

				this.$window.scrollTo(0, 0);

				const errorMessage = angular.toJson(response.errorMessages);

				this.messages.showErrorMessage(this.$scope, errorMessage);
			} else {

				this.messages.cleanMessages(this.$scope);

				this.productsList = response.products;
			}
		});
	}

	// @todo precisa virar service
	convertFilterDates(queryFilter) {

		if (queryFilter.payment.startDate) {

			queryFilter.payment.startDate = queryFilter.payment.startDate.toUTCString();
		}

		if (queryFilter.payment.endDate) {

			queryFilter.payment.endDate = queryFilter.payment.endDate.toUTCString();
		}

		if (queryFilter.emission.startDate) {

			queryFilter.emission.startDate = queryFilter.emission.startDate.toUTCString();
		}

		if (queryFilter.emission.endDate) {

			queryFilter.emission.endDate = queryFilter.emission.endDate.toUTCString();
		}
	}

	clearFieldLastFilter(field, object) {
		console.log('clearFieldLastFilter()', field);

		const newQueryFilter = angular.copy(this.lastFilter);

		if (object) {
			newQueryFilter[object][field] = null;
		} else {
			newQueryFilter[field] = null;
		}

		this.submitFilter(true, newQueryFilter);
	}

	submitFilter(newSearch, filter) {
		// console.log('submitFilter', this.filter);
		console.log('submitFilter', filter);

		this.isOpenFilter = false;

		filter = filter || this.filter;
		this.showLoading = true;
		this.messages.cleanMessages(this.$scope);

		this.resetLastFilter();

		const queryFilter = angular.copy(filter);

		this.convertFilterDates(queryFilter);

		const queryModel = {
			queryFilter,
			queryLimit: this.pages.limitOfRecords,
			queryOffset: this.queryOffset,
			queryOrderField: this.order.data.field,
			queryOrderDirection: this.order.data.direction,
			isQueryCount: !!newSearch
		};

		this.remuneracoesPagasListApi.getList(queryModel).then((response) => {

			this.showLoading = false;

			if (!response.isSuccessful) {

				if (response.errorMessages) {

					this.$window.scrollTo(0, 0);

					const errorMessage = angular.toJson(response.errorMessages);

					this.messages.showErrorMessage(this.$scope, errorMessage);
				}

				this.recordsList = [];
				this.pages.totalOfRecords = 0;
			} else {

				this.messages.cleanMessages(this.$scope);

				this.lastFilter = angular.copy(filter);

				this.recordsList = response.titles;

				if (newSearch) {

					this.pages.totalOfRecords = response.queryCount;
					this.pages.setPaginationParams();
				}
			}
		});
	}

	get queryOffset() {

		return ((this.pages.actualPage - 1) * this.pages.limitOfRecords);
	}

	doPrevious() {

		this.pages.doPrevious(this.submitFilter, this);
	}

	doNext() {

		this.pages.doNext(this.submitFilter, this);
	}

	hasNavigation() {

		return !this.showLoading && this.pages.totalOfPages > 1;
	}

	search(methodType, searchString) {
		// console.log('search', methodType, searchString);

		const deferred = this.$q.defer();

		this.remuneracoesPagasListApi[methodType](searchString).then((response) => {
			// console.log('search response', response);

			if (!response.isSuccessful) {

				this.$window.scrollTo(0, 0);

				const errorMessage = angular.toJson(response.errorMessages);

				this.messages.showErrorMessage(this.$scope, errorMessage);
			} else {

				this.messages.cleanMessages(this.$scope);
				deferred.resolve(response.obtainedRecords);
			}
		});

		return deferred.promise;
	}

	searchSaleStructureHandler(searchString) {
		// console.log('searchSaleStructureHandler', this);

		const remuneracoesPagasList = this.$parent.remuneracoesPagasList;

		return remuneracoesPagasList.search('searchSaleStructure', searchString);
	}

	searchSaleStructureFocusOut() {

		// @todo workaround https://github.com/ghiden/angucomplete-alt/issues/106
		this.$timeout(() => {

			if (!this.autoComplete.hasValidInput(this.filter.saleStructure)) {

				this.autoComplete.clearInput(this.$scope, 'searchSaleStructure');
			}
		}, 100);
	}

	searchSaleStructureChanged() {
		// console.log('searchSaleStructureChanged', this);

		const remuneracoesPagasList = this.$parent.remuneracoesPagasList;

		remuneracoesPagasList.autoComplete.resetSelected(remuneracoesPagasList.filter, 'saleStructure');
	}

	searchPropostaNumber(searchString) {
		// console.log('searchPropostaNumber', this);

		const remuneracoesPagasList = this.$parent.remuneracoesPagasList;

		return remuneracoesPagasList.remuneracoesPagasListApi.searchPropostaNumber(searchString);
	}

	searchPropostaNumberHandler(searchString) {
		// console.log('searchPropostaNumberHandler', this);

		const remuneracoesPagasList = this.$parent.remuneracoesPagasList;

		return remuneracoesPagasList.search('searchPropostaNumber', searchString);
	}

	searchPropostaNumberFocusOut() {

		// @todo workaround https://github.com/ghiden/angucomplete-alt/issues/106
		this.$timeout(() => {

			if (!this.autoComplete.hasValidInput(this.filter.propostaNumber)) {

				this.autoComplete.clearInput(this.$scope, 'searchPropostaNumber');
			}
		}, 100);
	}

	searchPropostaNumberChanged() {
		// console.log('searchPropostaNumberChanged', this);

		const remuneracoesPagasList = this.$parent.remuneracoesPagasList;

		remuneracoesPagasList.autoComplete.resetSelected(remuneracoesPagasList.filter, 'propostaNumber');
	}

	hasFilter() {
		return !!this.lastFilter;
	}

	generatePdf() {

		if (!this.hasFilter()) {

			return;
		}

		this.$scope.$emit('changeMainShowLoading', true);
		this.messages.cleanMessages(this.$scope);

		const queryFilter = angular.copy(this.lastFilter);

		this.convertFilterDates(queryFilter);

		const queryModel = {

			queryFilter,
			queryLimit: 0,
			queryOffset: 0,
			isQueryCount: false
		};

		this.remuneracoesPagasListApi.getReport(queryModel).then((response) => {
			console.log('getReport', response);

			this.$scope.$emit('changeMainShowLoading', false);

			if (!response.isSuccessful) {

				this.$window.scrollTo(0, 0);

				const errorMessage = angular.toJson(response.errorMessages);

				this.messages.showErrorMessage(this.$scope, errorMessage);
			} else {

				this.messages.cleanMessages(this.$scope);

				const columns = response.columns;

				const rows = response.rows.map(function (row) {
					row[0] = this.$filter('date')(row[0], 'dd/MM/yyyy'); //Data de pagamento
					row[4] = this.$filter('date')(row[4], 'dd/MM/yyyy'); //Data de emissão
					row[7] = this.$filter('currency')(row[7]); //Valor do título
					row[8] = this.$filter('number')(row[8]); //% Remuneração
					//row[9] = this.$filter('currency')(row[9]); //Valor da remuneração
					row[14] = this.$filter('currency')(row[14]); //Valor Total Bruto
					/*row[15] = this.$filter('currency')(row[15]); //Valor do IR total
					row[16] = this.$filter('currency')(row[16]); //Valor do ISS total
					row[17] = this.$filter('currency')(row[17]); //Valor líquido total*/
					return row;
				}, this);

				const wb = XLSX.utils.book_new();

				wb.Props = {
					Title: "Remuneração Paga",
					Subject: "Remuneração Paga",
					Author: "CapOnline"
					//CreatedDate: new Date(2018,12,07)
				};

				wb.SheetNames.push("Remuneração");
				const ws_data = columns;
				const ws = XLSX.utils.aoa_to_sheet(ws_data);
				XLSX.utils.sheet_add_aoa(ws, rows, { origin: -1 });
				wb.Sheets["Remuneração"] = ws;

				const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

				const blob = new Blob([this.bufferExcel(wbout)], { type: "application/octet-stream" });
				saveAs(blob, 'remuneracao.xlsx');

				/*const columns = response.columns;

				const rows = response.rows;

				doc.autoTable(columns, rows, {
					theme: 'grid',
					startY: 1,
					margin: 1,
					styles: {

						fontSize: 7,
						halign: 'center',
						overflow: 'linebreak'
					},
					headerStyles: {
						fillColor: [0, 32, 92]
					}
				});

				const pdfBase64String = doc.output('datauristring');

				this.openSaveBase64Pdf('Remunerações Pagas', 'RemuneracoesPagas', pdfBase64String);*/
			}
		});
	}

	bufferExcel(data) {
		const buf = new ArrayBuffer(data.length);
		const view = new Uint8Array(buf);
		for (let i = 0; i < data.length; i++) view[i] = data.charCodeAt(i) & 0xFF;
		return buf;
	}

	openSaveBase64Pdf(name, filename, data) {

		const bytes = this.base64ToArrayBuffer(data);
		const blob = new Blob([bytes], { type: 'application/pdf' });
		const fileName = `${filename}.pdf`;

		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			// IE workaround
			window.navigator.msSaveOrOpenBlob(blob, fileName);

		} else {
			// Download
			const link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.download = fileName;
			link.click();
			// Open
			//const documentWindow = window.open('', '_blank');
			//documentWindow.document.write(`<object id=\"objpdf\" data=\"${data}\" type=\"application/pdf\" width=\"100%\" height=\"100%\"></object>`);
			//documentWindow.document.title = name;
		}
	}

	base64ToArrayBuffer(data) {
		const base64 = data.replace('data:application/pdf;base64,', '');
		const binaryString = window.atob(base64);
		const binaryLen = binaryString.length;
		const bytes = new Uint8Array(binaryLen);
		for (let i = 0; i < binaryLen; i++) {
			const ascii = binaryString.charCodeAt(i);
			bytes[i] = ascii;
		}
		return bytes;
	}
}

module.exports = remuneracoesPagasListController;
