'use strict';

/* @ngInject */
function routesConfiguration($stateProvider, sfConfig) {

	//Rota Principal
	$stateProvider.state('case.reaplicacao-titulo', {

		abstract: true,
		url: '/reaplicacaotitulo',
		views: {

			'case': {

				templateUrl: `${sfConfig.resource}/views/case/reaplicacao-titulo/base.html`,
				controller: 'reaplicacaoTituloController',
				controllerAs: 'caseInstance'
			}
		}
	});

	//Rota para Criação
	$stateProvider.state('case.reaplicacao-titulo.new', {

		url: '/nova',
		views: {

			'case.reaplicacao-titulo': {

				templateUrl: `${sfConfig.resource}/views/case/reaplicacao-titulo/reaplicacao-titulo-new/reaplicacao-titulo-new.html`,
				controller: 'reaplicacaoTituloNewController',
				controllerAs: 'reaplicacaoTituloNew'
			}
		}
	});

	//Rota para Amexos
	/*$stateProvider.state('case.bloqReaplicacao.annex', {

		url: '/anexos',
		views: {

			'case.bloqReaplicacao': {

				templateUrl: `${sfConfig.resource}/views/case/bloqReaplicacao/annex/annex.html`,
				controller: 'bloqReaplicacaoAnnexController',
				controllerAs: 'bloqReaplicacaoAnnex'
			}
		}
	});*/

	$stateProvider.state('case.reaplicacao-titulo.detail', {

		url: '/detalhes?:id',
		views: {

			'case.reaplicacao-titulo': {

				templateUrl: `${sfConfig.resource}/views/case/reaplicacao-titulo/reaplicacao-titulo-detail/reaplicacao-titulo-detail.html`,
				controller: 'reaplicacaoTituloDetailController',
				controllerAs: 'reaplicacaoTituloDetail'
			}
		}
	});
};

module.exports = routesConfiguration;
