'use strict';

const bloqReaplicacaoNewService = require("../bloq-reaplicacao-new/bloq-reaplicacao.new.service");

class bloqReaplicacaoDetailController {

	// @ngInject

	constructor($stateParams, $state, sfConfig, bloqReaplicacaoDetailService, $scope, bloqReaplicacaoFormService, bloqReaplicacaoService, breadcrumbHelper) {

		this.$stateParams = $stateParams;
		this.$state = $state;
		this.$scope = $scope;
		this.bloqReaplicacaoDetailService = bloqReaplicacaoDetailService;
		this.bloqReaplicacaoFormService = bloqReaplicacaoFormService;
		this.bloqReaplicacaoService = bloqReaplicacaoService;
		this.$scope.breadcrumb = breadcrumbHelper;
		this.formField = this.bloqReaplicacaoFormService.getFormField()

		this.showLoading = true;
		this.case = {
			details: null,
			related: {
				propostas: null,
				rentGarantee: null,
				history: null,
				parsedEditCaseData: null
			}
		}

		this.errorMessage = '';
		this.getCaseDetails();
		this.configureBreadCrumb();

		this.bloqReaplicacaoService.setFinishShow(false);
		this.bloqReaplicacaoService.setCancelShow(false);

		this.bloqReaplicacaoService.setWarningMessage('');
	}

	configureBreadCrumb() {
		this.$scope.breadcrumb.reset();
		this.$scope.breadcrumb.addMenuItem('Home', '/caponline/secured#!/home', true, false);
		this.$scope.breadcrumb.addMenuItem('Solicitação', '/caponline/secured#!/solicitacoes/lista', true, false);
		this.$scope.breadcrumb.addMenuItem('Detalhes do Bloqueio de Replicação', '#', false, true);
	}

	getCaseDetails() {

		if (!this.hasValidUrlParams()) {
			this.showLoading = false;
			this.$state.go('case.list');
		}
		this.$scope.$emit('changeMainShowLoading', true);
		this.bloqReaplicacaoDetailService.getSolicitacaoDetails(this.$stateParams.id).then((response) => {
			this.$scope.$emit('changeMainShowLoading', false);
			console.log(response);
			if (!response.isSuccessful) {
				this.errorMessage = response.errorMessages[0];
			} else {
				const tituloId = response.resgate.titulos[0].titulosId;
				this.case.details = response.resgate;
				this.case.related.propostas = response.resgate.propostas;
				this.case.related.rentGarantee = response.resgate.titulos;
				this.case.related.history = response.resgate.history;
				this.case.related.parsedEditCaseData = this.parseEditCaseData(this.case.details);
				console.log(this.case);
				console.log('Titulo ID para exibir detalhes: ', tituloId);
				this.bloqReaplicacaoService.getGarantiaDataById(tituloId)
				console.log(this.formField)
			}
			this.showLoading = false;
		});

		this.showLoading = false;
	}

	hasValidUrlParams() {
		return (this.$stateParams && this.$stateParams.id) ? true : false;
	}

	parseEditCaseData() {

		// monstruosidade requisitada pelo Diogo
		const div = document.createElement('div');
		div.innerHTML = this.case.details.editCaseData;

		return JSON.parse(div.innerHTML);
	}

}

module.exports = bloqReaplicacaoDetailController;
