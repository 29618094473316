'use strict';

//Controller Base
const revisaoComissaoController = require('./revisao-comissao.controller');
const revisaoComissaoService = require('./revisao-comissao.service');

// Upload 
//const bloqReaplicacaoAnnexController = require('./bloq-reaplicacao-annex/bloq-reaplicacao-annex.controller');
//const bloqReaplicacaoAnnexService = require('./bloq-reaplicacao-annex/bloq-reaplicacao-annex.service');

//Detail
const revisaoComissaoDetailController = require('./revisao-comissao-detail/revisao-comissao.detail.controller');
const revisaoComissaoDetailService = require('./revisao-comissao-detail/revisao-comissao.detail.service');

//New
const revisaoComissaoNewController = require('./revisao-comissao-new/revisao-comissao.new.controller');
const revisaoComissaoNewService = require('./revisao-comissao-new/revisao-comissao.new.service');

//Annex
const revisaoComissaoAnnexController = require('./revisao-comissao-annex/revisao-comissao.annex.controller');
const revisaoComissaoAnnexService = require('./revisao-comissao-annex/revisao-comissao.annex.service');

//View Model
const revisaoComissaoFormService = require('./revisao-comissao.form.service');

//Routes
const routesConfiguration = require('./revisao-comissao.routes');

//Depedency
const componentModule = angular.module('cap.secured.case.revisao-comissao', [
		'ui.router'
	])
	.controller('revisaoComissaoController', revisaoComissaoController)
	.service('revisaoComissaoService', revisaoComissaoService)

	.controller('revisaoComissaoNewController', revisaoComissaoNewController)
	.service('revisaoComissaoNewService', revisaoComissaoNewService)

	.controller('revisaoComissaoDetailController', revisaoComissaoDetailController)
	.service('revisaoComissaoDetailService', revisaoComissaoDetailService)

	.controller('revisaoComissaoAnnexController', revisaoComissaoAnnexController)
	.service('revisaoComissaoAnnexService', revisaoComissaoAnnexService)

	.service('revisaoComissaoService', revisaoComissaoService)

	.service('revisaoComissaoFormService', revisaoComissaoFormService)

	.config(routesConfiguration);

module.exports = componentModule;
