'use strict';

class documentsService {

	/* @ngInject */
	constructor(visualForceApi) {

		this.visualForceApi = visualForceApi;
	}

	getDocumentsList() {

		return this.visualForceApi.invoke(
			'CapCommunityDocumentControllerExt.getDocuments', 'CAPOnline_Assistencia_Locaticia');
	}

	uploadFiles(fileName, attachment) {
		console.log('uploadFilesAPI', { fileName, attachment });

		const request = { fileName, attachment };

		return this.visualForceApi.invoke(
			'CapCommunityDocumentControllerExt.sendDocumentAssistenciaLocaticia', request);
	}
}

module.exports = documentsService;
