class bloqReaplicacaoService {

	/* @ngInject */
	constructor($q, visualForceApi, $sce, bloqReaplicacaoFormService) {

		this.$q = $q;
		this.visualForceApi = visualForceApi;
		this.bloqReaplicacaoFormService = bloqReaplicacaoFormService;

		this.setPristine();
	}

	setPristine() {

		this.errorMessage = '';
		this.warningMessage = '';
		this.successMessage = '';
		this.isEdit = false;
		this.buttonControl = {
			cancel: {
				show: true,
				disabled: true
			},
			previous: {
				show: false,
				disabled: true
			},
			next: {
				show: true,
				disabled: true
			},
			save: {
				show: true,
				disabled: true
			},
			finish: {
				show: false,
				disabled: true
			}
		};
	}

	saveCaseBloqueioReplicacao() {
		return this.visualForceApi.invoke(
			'CapCommunityCaseControllerExt.saveCaseBloqReaplicacao', this.createSaveRequest());
	}

	sendCaseBloqueioReplicacao() {

		const cap_access_token = localStorage.getItem("cap_access_token");

		const payload = this.createSaveRequest();
		payload.accessToken = cap_access_token;

		return this.visualForceApi.invoke(
			'CapCommunityCaseControllerExt.sendCaseBloqReaplicacao', payload);
	}

	bloqueiNoScap() {

		const cap_access_token = localStorage.getItem("cap_access_token");

		const payload = this.createSaveRequest();
		payload.accessToken = cap_access_token;

		return this.visualForceApi.invoke(
			'CapCommunityCaseControllerExt.bloqueioReaplicacaoScap', payload);
	}

	createSaveRequest() {
		File.prototype.toJSON = function () {
			return {
				// "index": this.index,
				// "$$hashKey": this.$$hashKey,
				"name": this.name,
				"result": this.result,
				"salesForceId": this.salesForceId
			};
		};
		const request = {
			solicitacao: {
				id: this.bloqReaplicacaoFormService.formField.id,
				accountId: (this.bloqReaplicacaoFormService.formField.information.mainAccount.value !== null) ? this.bloqReaplicacaoFormService.formField.information.mainAccount.value.Id : null,
				propostaId: (this.bloqReaplicacaoFormService.formField.information.proposta.value !== null) ? this.bloqReaplicacaoFormService.formField.information.proposta.value.objectId : null,
				titulos: [],
				motivobloqueio: this.bloqReaplicacaoFormService.formField.information.motivobloqueio.value,
				autorizacaobloqueio: (this.bloqReaplicacaoFormService.formField.information.autorizacaobloqueio.value === 'true' || this.bloqReaplicacaoFormService.formField.information.autorizacaobloqueio.value == true) ? true : false,
				editCaseData: JSON.stringify(this.bloqReaplicacaoFormService.formField),
				cpfcnpj: (this.bloqReaplicacaoFormService.formField.information.mainAccount.value !== null) ? this.bloqReaplicacaoFormService.formField.information.mainAccount.value.cpf : null
			}
		}

		if (this.bloqReaplicacaoFormService.formField.information.garantias.value !== null) {

			for (let i = 0; i < this.bloqReaplicacaoFormService.formField.information.garantias.value.length; i++) {

				if (this.bloqReaplicacaoFormService.formField.information.garantias.value[i].selected === true) {

					request.solicitacao.titulos.push({
						id: this.bloqReaplicacaoFormService.formField.information.garantias.value[i].objectId,
						value: this.bloqReaplicacaoFormService.formField.information.garantias.value[i].value,
						antecipatedValue: this.bloqReaplicacaoFormService.formField.information.garantias.value[i].antecipatedValue,
						numeroTitulo: this.bloqReaplicacaoFormService.formField.information.garantias.value[i].numeroTitulo
					});
				}
			}
		}

		console.log('REQUEST: ', request);
		return request;
	}

	getGarantiaDataById(garantiaId) {
		this.visualForceApi.invoke(
			'CapCommunityGarantAluguelControllerExt.getGarantiaAluguelDetail', garantiaId).then((response) => {
			if (response.isSuccessful) {
				console.log('RESPONSE: ', response);

				//set all data garantia

				//set subscritor fields
				this.bloqReaplicacaoFormService.formField.garantiaData.subscritor.cpf.value = response.garantiaAluguelDetail.subscritor.cpf
				this.bloqReaplicacaoFormService.formField.garantiaData.subscritor.nome.value = response.garantiaAluguelDetail.subscritor.nome

				//set endereco fields
				this.bloqReaplicacaoFormService.formField.garantiaData.endereco.cep.value = response.garantiaAluguelDetail.imovel.cep
				this.bloqReaplicacaoFormService.formField.garantiaData.endereco.logradouro.value = response.garantiaAluguelDetail.imovel.lograduro
				this.bloqReaplicacaoFormService.formField.garantiaData.endereco.numero.value = response.garantiaAluguelDetail.imovel.numero
				this.bloqReaplicacaoFormService.formField.garantiaData.endereco.complemento.value = response.garantiaAluguelDetail.imovel.complemento
				this.bloqReaplicacaoFormService.formField.garantiaData.endereco.bairro.value = response.garantiaAluguelDetail.imovel.bairro
				this.bloqReaplicacaoFormService.formField.garantiaData.endereco.cidade.value = response.garantiaAluguelDetail.imovel.cidade
				this.bloqReaplicacaoFormService.formField.garantiaData.endereco.uf.value = response.garantiaAluguelDetail.imovel.uf

				//set locador
				this.bloqReaplicacaoFormService.formField.garantiaData.locador.nome.value = response.garantiaAluguelDetail.locador.nome
				this.bloqReaplicacaoFormService.formField.garantiaData.locador.cpfcnpj.value = response.garantiaAluguelDetail.locador.cpfCnpj

				//set imobiliaria
				this.bloqReaplicacaoFormService.formField.garantiaData.imobiliaria.nome.value = response.garantiaAluguelDetail.imobiliaria.nome
				this.bloqReaplicacaoFormService.formField.garantiaData.imobiliaria.cpfcnpj.value = response.garantiaAluguelDetail.imobiliaria.cpfCnpj

				//set geral infos
				this.bloqReaplicacaoFormService.formField.garantiaData.geral.dataInicioVigencia.value = response.garantiaAluguelDetail.tituloInfo.dataInicioVigencia
				this.bloqReaplicacaoFormService.formField.garantiaData.geral.dataFimVigencia.value = response.garantiaAluguelDetail.tituloInfo.dataFimVigencia

				this.bloqReaplicacaoFormService.setFormField(this.bloqReaplicacaoFormService.formField);

				return response.garantiaAluguelDetail;
			} else {

				//set all data garantia to null

				//set subscritor fields
				this.bloqReaplicacaoFormService.formField.garantiaData.subscritor.cpf.value = ''
				this.bloqReaplicacaoFormService.formField.garantiaData.subscritor.nome.value = ''

				//set endereco fields
				this.bloqReaplicacaoFormService.formField.garantiaData.endereco.cep.value = ''
				this.bloqReaplicacaoFormService.formField.garantiaData.endereco.logradouro.value = ''
				this.bloqReaplicacaoFormService.formField.garantiaData.endereco.numero.value = ''
				this.bloqReaplicacaoFormService.formField.garantiaData.endereco.complemento.value = ''
				this.bloqReaplicacaoFormService.formField.garantiaData.endereco.bairro.value = ''
				this.bloqReaplicacaoFormService.formField.garantiaData.endereco.cidade.value = ''
				this.bloqReaplicacaoFormService.formField.garantiaData.endereco.uf.value = ''

				//set locador
				this.bloqReaplicacaoFormService.formField.garantiaData.locador.nome.value = ''
				this.bloqReaplicacaoFormService.formField.garantiaData.locador.cpfcnpj.value = ''

				//set imobiliaria
				this.bloqReaplicacaoFormService.formField.garantiaData.imobiliaria.nome.value = ''
				this.bloqReaplicacaoFormService.formField.garantiaData.imobiliaria.cpfcnpj.value = ''

				//set geral infos
				this.bloqReaplicacaoFormService.formField.garantiaData.geral.dataInicioVigencia.value = ''
				this.bloqReaplicacaoFormService.formField.garantiaData.geral.dataFimVigencia.value = ''

				this.bloqReaplicacaoFormService.setFormField(this.bloqReaplicacaoFormService.formField);
				console.log('RESPONSE: ', response.isSuccessful);
				return null;
			};
		});
	}

	cleanMessages() {
		this.setErrorMessage('');
		this.setWarningMessage('');
		this.setSuccessMessage('');
	}

	setWarningMessage(warningMessage) {
		this.warningMessage = warningMessage;
		console.log(warningMessage);
	}

	setErrorMessage(errorMessage) {
		this.errorMessage = errorMessage;
		console.log(errorMessage);
	}

	getErrorMessage() {
		return this.errorMessage;
	}

	getWarningMessage() {
		return this.warningMessage;
	}

	setIsEdit(isEdit) {
		this.isEdit = isEdit;
	}

	getIsEdit() {
		return this.isEdit;
	}

	setSuccessMessage(successMessage) {
		this.successMessage = successMessage;
	}

	getSuccessMessage() {
		return this.successMessage;
	}

	setCancelShow(show) {
		this.buttonControl.cancel.show = show;
	}

	getCancelShow() {
		return this.buttonControl.cancel.show;
	}

	setCancelDisabled(disabled) {
		this.buttonControl.cancel.disabled = disabled;
	}

	getCancelDisabled() {
		return this.buttonControl.cancel.disabled;
	}

	setPreviousShow(show) {
		this.buttonControl.previous.show = show;
	}

	//Botões da Tela 

	getPreviousShow() {
		return this.buttonControl.previous.show;
	}

	setPreviousDisabled(disabled) {
		this.buttonControl.previous.disabled = disabled;
	}

	getPreviousDisabled() {
		return this.buttonControl.previous.disabled;
	}

	setNextShow(show) {
		this.buttonControl.next.show = show;
	}

	getNextShow() {
		return this.buttonControl.next.show;
	}

	setNextDisabled(disabled) {
		this.buttonControl.next.disabled = disabled;
	}

	getNextDisabled() {
		return this.buttonControl.next.disabled;
	}

	setSaveShow(show) {
		this.buttonControl.save.show = show;
	}

	getSaveShow() {
		return this.buttonControl.save.show;
	}

	setSaveDisabled(disabled) {
		this.buttonControl.save.disabled = disabled;
	}

	getSaveDisabled() {
		return this.buttonControl.save.disabled;
	}

	setFinishShow(show) {
		this.buttonControl.finish.show = show;
	}

	getFinishShow() {
		return this.buttonControl.finish.show;
	}

	setFinishDisabled(disabled) {
		this.buttonControl.finish.disabled = disabled;
	}

	getFinishDisabled() {
		return this.buttonControl.finish.disabled;
	}

	setPropostaFromResgate(resgate) {
		this.propostaFromResgate = resgate;
	}

	getPropostaFromResgate() {
		return this.propostaFromResgate;
	}

	setObjectProposta(proposta) {
		this.proposta = proposta;
	}

	getObjectProposta() {
		return this.proposta;
	}

	setResgateAccount(account) {
		this.resgateAccount = account;
	}

	getResgateAccount() {
		return this.resgateAccount;
	}

	isItemSelected(item) {

		return item.selected === true;
	}

	pristineAnnexObj() {
		return {
			files: [],
			hasError: false,
			loading: false,
			show: false,
			uploaded: false,
			uploadHasFailure: false
		};
	}

}

module.exports = bloqReaplicacaoService;
