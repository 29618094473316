'use strict';

class alteracaoDadosAnnexController {

	// @ngInject
	constructor($scope, $window, feedbackMessages, alteracaoDadosFormControlService, alteracaoDadosApi, alteracaoDadosAnnexApi) {
		this.$window = $window;
		this.$scope = $scope;

		this.messages = feedbackMessages;
		this.alteracaoDadosFormControlService = alteracaoDadosFormControlService;
		this.alteracaoDadosApi = alteracaoDadosApi;
		this.api = alteracaoDadosAnnexApi;
		this.formField = this.alteracaoDadosFormControlService;
		this.formField.formStep = 'case.alteracao-dados.annex';
		this.setAnnexShow();
		this.isAlteracaoCaucao();
		this.annexType = {
			'form': this.formField.annex.form,
			'caucao-others': this.formField.annex.others,
			'legalRepresentative-document': this.formField.annex.legalRepresentative.document,
			'legal-identity-renter': this.formField.annex.legalRepresentative.identityRenter,
			'legal-other': this.formField.annex.legalRepresentative.other,
			'location-contract-document': this.formField.annex.locationContract.document,
			'subscritor-identityRenter': this.formField.annex.subscritor.identityRenter,
			'subscritor-cpf': this.formField.annex.subscritor.cpf,
			'subscritor-adressCheck': this.formField.annex.subscritor.adressCheck,
			'subscritor-otherPf': this.formField.annex.subscritor.otherPf,
			'subscritor-cnpj': this.formField.annex.subscritor.cnpj,
			'subscritor-constitutionIdentity': this.formField.annex.subscritor.constitutionIdentity,
			'subscritor-adminIdentity': this.formField.annex.subscritor.adminIdentity,
			'subscritor-otherPj': this.formField.annex.subscritor.otherPj
		};
		this.createArrayFiles();
		this.listenAlteracaoDadosSave();

		//this.formField = this.alteracaoDadosFormControlService.getFormField();
		this.formField.annex.status = 'Em Andamento';
		console.log('Form Field:', this.formField);
	}

	hasFormFiles() {
		return true;
	}

	getFormFiles() {
		this.$scope.$emit('saveAlteracaoDados');
	}

	listenAlteracaoDadosSave() {
		this.$scope.$on('saveAlteracaoDadosDone', (event) => {
			this.formField.lastFormAction = 'BaixarFormulario';
			this.openCorrectForm(this.formField.id);
		});
	}

	openCorrectForm(formId) {
		switch (this.formField.data.subCaseType) {
		case 'alteracaoImovel':
			window.open(`apex/CapOnlineCaseLiberacaoSemPgtoPDFPage?id=${formId}`, '_blank');
			window.open(`apex/CapOnlineCaseCartaCaucaoPDFPage?id=${formId}`, '_blank');
			break;
		case 'mudancaLocador':
			window.open(`apex/CapOnlineCaseMudancaLocadorPDFPage?id=${formId}`, '_blank');
			//window.open(`apex/CapOnlineCaseCartaCaucaoPDFPage?id=${formId}`, '_blank');
			break;
		case 'alteracaoDadosCliente':
			break;
		case 'inclusaoCaucao':
			window.open(`apex/CapOnlineCaseCartaCaucaoPDFPage?id=${formId}`, '_blank');
			break;
		}
	}

	setAnnexShow() {
		this.formField.annex.form.show = !(this.formField.information.fields.alteracaoType.value == this.formField.allTypeOptions.correcaoDadosCliente);
		this.formField.annex.others.show = this.formField.information.fields.alteracaoType.value == this.formField.allTypeOptions.inclusaoCaucao;
		this.formField.annex.legalRepresentative.document.show = this.formField.information.fields.legalRepresentative.value;
		this.formField.annex.legalRepresentative.identityRenter.show = this.isAlteracaoLocadorImovel();
		this.formField.annex.legalRepresentative.other.show = this.isAlteracaoLocadorImovel();
		this.formField.annex.locationContract.document.show = this.isAlteracaoLocadorImovel();
		if (this.formField.information.fields.alteracaoType.value == this.formField.allTypeOptions.correcaoDadosCliente || this.formField.information.fields.alteracaoType.value == this.formField.allTypeOptions.mudancaImovel || this.formField.information.fields.alteracaoType.value == this.formField.allTypeOptions.mudancaLocador) {
			this.formField.annex.subscritor.identityRenter.show = this.formField.information.fields.mainAccount.value.isPersonAccount;
			//this.formField.annex.subscritor.cpf.show = this.formField.information.fields.mainAccount.value.isPersonAccount; CAP-3543
			//this.formField.annex.subscritor.adressCheck.show = this.formField.information.fields.mainAccount.value.isPersonAccount; CAP-3543
			this.formField.annex.subscritor.otherPf.show = this.formField.information.fields.mainAccount.value.isPersonAccount;
			this.formField.annex.subscritor.cnpj.show = !this.formField.information.fields.mainAccount.value.isPersonAccount;
			this.formField.annex.subscritor.constitutionIdentity.show = !this.formField.information.fields.mainAccount.value.isPersonAccount;
			this.formField.annex.subscritor.adminIdentity.show = !this.formField.information.fields.mainAccount.value.isPersonAccount;
			this.formField.annex.subscritor.otherPj.show = !this.formField.information.fields.mainAccount.value.isPersonAccount;
		}
	}

	hideTrashByStatus() {
		const statusToHideTrash = ['Reprovado', 'Pagamento Criticado'];

		return (statusToHideTrash.indexOf(this.formField.status) >= 0);
	}

	isLegalRepresentative() {
		// console.log('isLegalRepresentative', this.formField.information.legalRepresentative);
		let legalRepresentative = false;

		if (this.formField.information.fields.legalRepresentative.value === true) {

			legalRepresentative = true;
		}

		this.formField.annex.legalRepresentative.document.show = legalRepresentative;
		c = !legalRepresentative;

		return legalRepresentative;
	}

	isAlteracaoCliente() {
		let alteracaoCliente = false;
		if (this.formField.information.fields.alteracaoType.value === this.formField.allTypeOptions.correcaoDadosCliente) {
			alteracaoCliente = true;
		}
		return alteracaoCliente;
	}

	isAlteracaoLocadorImovel() {
		let locadorImovel = false;
		if ((this.formField.information.fields.alteracaoType.value == this.formField.allTypeOptions.mudancaImovel) || (this.formField.information.fields.alteracaoType.value == this.formField.allTypeOptions.mudancaLocador)) {
			locadorImovel = true;
		}
		return locadorImovel;
	}

	getAlteracaoType() {
		const alteracaoType = this.formField.information.fields.alteracaoType.value;
		return alteracaoType;
	}

	isAlteracaoCaucao() {
		let alteracaoCaucao = false;
		if (this.formField.information.fields.alteracaoType.value == this.formField.allTypeOptions.inclusaoCaucao) {
			alteracaoCaucao = true;
		}
		return alteracaoCaucao;
	}

	checkStatusOnLoad() {
		// console.log('checkStatusOnLoad', this.formField);

		if (this.formField.annex.status !== 'Concluido') {

			this.formField.annex.status = 'Incompleto';

			console.log('checkStepStatus:', this.formField.annex.status);
		}

		this.formField.annex.form.show = true;
		this.formField.annex.legalRepresentative.show = true;
		this.formField.annex.legalRepresentative.other.show = true;
		this.formField.annex.legalRepresentative.identityRenter.show = true;
		this.formField.annex.legalRepresentative.other.show = true;
		this.formField.annex.locationContract.document.show = true;
		this.formField.annex.subscritor.identityRenter.show = true;
		//this.formField.annex.subscritor.cpf.show = true; CAP-3543
		//this.formField.annex.subscritor.adressCheck.show = true; CAP-3543
		this.formField.annex.subscritor.otherPf.show = true;
		this.formField.annex.subscritor.cnpj.show = true;
		this.formField.annex.subscritor.constitutionIdentity.show = true;
		this.formField.annex.subscritor.adminIdentity.show = true;
		this.formField.annex.subscritor.otherPj.show = true;

		this.isLegalRepresentative();
		//this.cleanHiddenAnnexes();

		this.$scope.$emit('validateUploads');
	}

	removeFile(itemIndex, type) {
		console.log('removeFile', itemIndex, type);
		const annex = this.getAnnexByType(type);

		if (!annex.files[itemIndex].result) {
			annex.files.splice(itemIndex, 1);
			return;
		}

		this.$scope.$emit('changeMainShowLoading', true);
		this.api.removeFile(annex.files[itemIndex].salesForceId).then((response) => {

			if (response.isSuccessful) {
				annex.files.splice(itemIndex, 1);
				this.alteracaoDadosFormControlService.setFormField(this.formField);
				//this.revisaoComissaoService.updateEditCaseData();
			} else {
				this.$window.scrollTo(0, 0);
				const errorMessage = angular.toJson(response.errorMessages);
				this.messages.showErrorMessage(this.$scope, errorMessage);
				return;
			}

			this.$scope.$emit('changeMainShowLoading', false);
		});
	}

	uploadFiles(files, errFiles, type) {
		console.log('uploadFiles', files, errFiles, type);
		console.log(this.formField);

		if (!this.validateFilesSizes(files)) {
			//this.resgateService.setErrorMessage('Um dos arquivos selecionados excedeu o limite de 8MB');
			console.log('Um dos arquivos selecionados excedeu o limite de 8MB');
			return;
		}

		// A variavel annex é uma referencia ao anexo do formfield
		// Então quando manipulamos annex abaixo, estamos atribuindo ao formfield
		const annex = this.getAnnexByType(type);

		console.log('annex: ', annex);

		this.createArrayFiles();

		annex.files = annex.files.concat(files);

		this.removeDuplicatedFiles(annex);

		annex.filesParsed = this.parseFiles(annex.files);
		console.log(annex.files);
		console.log(annex.filesParsed);
	}

	createArrayFiles() {
		// No método uploadFiles só criava o array Files,
		// para os itens que os arquivos eram escolhidos.
		// Garante que o Array Files irá existir para todos
		angular.forEach(this.annexType, (value, key) => {
			if (!value) {
				delete this.annexType[key];
			} else if (!value.files) {
				value.files = [];
			}
		});
	}

	validateFilesSizes(annex) {
		let isValid = true;
		const sizeLimit = 8388608 //8MB

		for (let i = annex.length - 1; i >= 0; i--) {
			if (annex[i].size > sizeLimit) {
				isValid = false;
				break;
			}
		}

		return isValid;
	}

	getAnnexByType(type) {

		const annex = this.annexType[type];

		return annex;
	}

	removeDuplicatedFiles(annex) {
		console.log('removeDuplicatedFiles', annex);
		const fileNames = [];

		for (let i = 0; i < annex.files.length; i++) {
			const fileName = annex.files[i].name;
			if (this.isDuplicatedFileName(fileNames, fileName)) {
				annex.files.splice(i, 1);
			}
			fileNames.push(fileName);
		}
	}

	isDuplicatedFileName(fileNames, fileName) {
		return fileNames.indexOf(fileName) !== -1;
	}

	parseFiles(files) {
		console.log('parseFiles(): ', files);
		//Manobra desenvolvida para evitar o sumiço dos dados do arquino ao parsear o JSON de envio
		//https://github.com/danialfarid/ng-file-upload/issues/1605

		const array = [];

		for (const file of files) {
			// console.log("files: ", file);
			array.push({
				name: file.name,
				size: file.size,
				type: file.type,
				salesForceId: file.salesForceId
			});
		}

		return array;
	}

	checkFileUpload(salesforceId) {
		return (typeof salesforceId == 'undefined')
	}

	get tooltipForm() {

		return '<p>Formulário de Alteração de dados.</p>\
						<p>Observações:</p>\
						<p>Este formulário não caracteriza a solicitação de Alteração de dados.</p>';
	}
}

module.exports = alteracaoDadosAnnexController;
