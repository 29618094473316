"use strict";

class alteracaoDadosClienteService {
	// @ngInject
	constructor($q, visualForceApi) {
		this.$q = $q;
		this.visualForceApi = visualForceApi;
	}

	getClienteData(id) {
		const cap_access_token = localStorage.getItem("cap_access_token");

		return this.visualForceApi.invoke(
			"CapCommunityCaseControllerExt.getClienteData",
			id,
			cap_access_token
		);
	}
}

module.exports = alteracaoDadosClienteService;
