'use strict';

class rentGuaranteeDetailsController {

	// @ngInject
	constructor($scope, $stateParams, rentGuaranteeDetailsApiService, breadcrumbHelper) {
		this.$scope = $scope;
		this.$stateParams = $stateParams;
		this.rentGuaranteeDetailsApiService = rentGuaranteeDetailsApiService;
		this.$scope.breadcrumb = breadcrumbHelper;

		this.rentGuarantee = {
			details: null,
			valorResgate: null,
			related: {
				cases: null
			}
		};

		this.valorResgate = null;

		this.titleId = this.$stateParams.id;
		this.rentGuaranteeDetailsPrint = `/caponline/CapOnlineTituloCapitalizacaoPDFPage?id=${this.titleId}`;
		this.errorMessage = '';

		this.getRentGuaranteeDetails(this.titleId);
		this.configureBreadCrumb();

		//TODO: Descomentar este bloco para usar o valor do resgate
		//this.getValorResgate();

	}

	configureBreadCrumb() {
		this.$scope.breadcrumb.reset();
		this.$scope.breadcrumb.addMenuItem('Home', '/caponline/secured#!/home', true, false);
		this.$scope.breadcrumb.addMenuItem('Garantia de Aluguel', '/caponline/secured#!/garantia-de-aluguel/lista', true, false);
		this.$scope.breadcrumb.addMenuItem('Detalhes da Garantia de Aluguel', '#', false, true);
	}

	getRentGuaranteeDetails(titleId) {

		this.$scope.$emit('changeMainShowLoading', true);

		this.rentGuaranteeDetailsApiService.getDetails(titleId).then((response) => {

			this.$scope.$emit('changeMainShowLoading', false);

			// console.log('Response getRentGuaranteeDetails():', response);
			// console.log('Response getRentGuaranteeDetails():', response.cases);

			if (!response.isSuccessful) {

				this.errorMessage = response.errorMessages[0];
			} else {

				console.log('---->response.garantiaAluguelDetail: ', response.garantiaAluguelDetail)
				this.rentGuarantee.details = response.garantiaAluguelDetail;
				this.rentGuarantee.valorResgate = response.garantiaAluguelDetail.tituloInfo.valorResgate;
				this.rentGuarantee.related.cases = response.cases;
			}
			// console.log('Result details:', this.rentGuarantee);
		});
	}

	getValorResgate() {

		console.log('buscando valor do resgate: ', this.titleId)

		this.rentGuaranteeDetailsApiService.getResgateValue(this.titleId).then((response) => {

			console.log('--> chamada ao sevico de resgate retornada: ', response);

			if (response.isSuccessful) {

				this.$scope.$emit('changeMainShowLoading', false);
				//this.rentGuarantee.details = response.garantiaAluguelDetail;
				//this.rentGuarantee.related.cases = response.cases;
				this.rentGuarantee.valorResgate = response.valorResgate;
				this.rentGuarantee.details.valorResgate = response.valorResgate;

			} else {
				this.errorMessage = response.errorMessages[0];
				this.resgateService.setErrorMessage(`Não foi possivel obter o valor de resgate do titulo: ${titleId}`);
			}
		});

	}

	tooltipValorResgate() {
		return 'Valor total atualizado, aplicado o percentual da tabela de resgate, conforme Condições Gerais do Produto.';
	}

}

module.exports = rentGuaranteeDetailsController;
