'use strict';

class homeBannerApiService {

	/* @ngInject */
	constructor(visualForceApi) {

		this.visualForceApi = visualForceApi;
	}

	getBannerList() {
		const param = { logado: true };
		return this.visualForceApi.invoke(
			'CapCommunityDocumentControllerExt.getBannerImages', param);
	}
}

module.exports = homeBannerApiService;
