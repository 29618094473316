'use strict';

class documentsController {

	// @ngInject
	constructor(sfConfig, documentsService) {
		this.documentsService = documentsService;
		this.documentsList = [];
		this.showLoading = true;

		this.extention = {
			downloadIco: `${sfConfig.resource}/images/documents-ico/ico4.png`,
			pdf: `${sfConfig.resource}/images/documents-ico/pdf_60.png`,
			ppt: `${sfConfig.resource}/images/documents-ico/ppt_60.png`,
			doc: `${sfConfig.resource}/images/documents-ico/word_60.png`,
			csv: `${sfConfig.resource}/images/documents-ico/csv_60.png`,
			image: `${sfConfig.resource}/images/documents-ico/image_60.png`,
			xls: `${sfConfig.resource}/images/documents-ico/excel_60.png`,
			html: `${sfConfig.resource}/images/documents-ico/html_60.png`,
			txt: `${sfConfig.resource}/images/documents-ico/txt_60.png`,
			xml: `${sfConfig.resource}/images/documents-ico/xml_60.png`,
			zip: `${sfConfig.resource}/images/documents-ico/zip_60.png`,
			attachment: `${sfConfig.resource}/images/documents-ico/attachment_60.png`
		}

		this.getDocumentList();
	}

	getDocumentList() {
		this.documentsService.getDocumentsList().then((response) => {
			console.log('GetDocumentsList');
			console.log(response);

			if (response && response.documentos && response.documentos.length > 0) {
				this.documentsList = response.documentos;
			}

			this.showLoading = false;
		});
	}

	getDocumentIcon(ext) {
		let srcIco;

		switch (ext) {
		case "pdf":
			srcIco = this.extention.pdf;
			break;
		case "ppt":
			srcIco = this.extention.ppt;
			break;
		case "doc":
			srcIco = this.extention.doc;
		case "docx":
			srcIco = this.extention.doc;
			break;
		case "csv":
			srcIco = this.extention.csv;
			break;
		case "jpeg":
		case "png":
			srcIco = this.extention.image;
			break;
		case "xls":
			srcIco = this.extention.xls;
		case "xlsx":
			srcIco = this.extention.xls;
			break;
		case "html":
			srcIco = this.extention.html;
			break;
		case "txt":
			srcIco = this.extention.txt;
			break;
		case "xml":
			srcIco = this.extention.xml;
			break;
		case "zip":
			srcIco = this.extention.zip;
			break;
		default:
			srcIco = this.extention.attachment;
		}

		return srcIco;
	}

	getFile(fileUrl) {
		const baseUrl = window.location.href.substring(0, window.location.href.indexOf("/secured"));
		return baseUrl + fileUrl;
	}

}

module.exports = documentsController;
