class revisaoComissaoService {

	/* @ngInject */
	constructor($q, visualForceApi, $sce, revisaoComissaoFormService, sfConfig) {

		this.$q = $q;
		this.visualForceApi = visualForceApi;
		this.revisaoComissaoFormService = revisaoComissaoFormService;
		this.idUSerAccount = sfConfig.idUSerAccount;

		this.setPristine();
	}

	setPristine() {

		this.errorMessage = '';
		this.successMessage = '';
		this.isEdit = false;
		this.status = 'Incompleto',
			this.buttonControl = {
				cancel: {
					show: true,
					disabled: true
				},
				previous: {
					show: false,
					disabled: true
				},
				next: {
					show: true,
					disabled: false
				},
				save: {
					show: true,
					disabled: false
				},
				finish: {
					show: false,
					disabled: true
				}
			};
	}

	saveCaseRevisaoComissao() {

		const cap_access_token = localStorage.getItem("cap_access_token");

		const payload = this.createSaveRequest();
		payload.accessToken = cap_access_token;

		return this.visualForceApi.invoke(
			'CapCommunityCaseControllerExt.saveCaseRevisaoComissao', payload);
	}

	sendCaseRevisaoComissao() {

		const cap_access_token = localStorage.getItem("cap_access_token");

		const payload = this.createSaveRequest();
		payload.accessToken = cap_access_token;

		return this.visualForceApi.invoke(
			'CapCommunityCaseControllerExt.sendCaseRevisaoComissao', payload);
	}

	cancelRevisaoComissao(Id) {

		const cap_access_token = localStorage.getItem("cap_access_token");

		return this.visualForceApi.invoke(
			'CapCommunityCaseControllerExt.cancelResgate', Id, 'revisaocomissao', cap_access_token);
	}

	createSaveRequest() {
		File.prototype.toJSON = function () {
			return {
				// "index": this.index,
				// "$$hashKey": this.$$hashKey,
				"name": this.name,
				"result": this.result,
				"salesForceId": this.salesForceId
			};
		};
		const request = {
			solicitacao: {
				id: this.revisaoComissaoFormService.formField.id,
				status: this.revisaoComissaoFormService.formField.status,
				accountId: this.idUSerAccount,
				estruturavenda: (this.revisaoComissaoFormService.formField.information.estruturavenda.value !== null) ? this.revisaoComissaoFormService.formField.information.estruturavenda.value : null,
				motivorevisao: this.revisaoComissaoFormService.formField.information.motivorevisao.value,
				descricaorevisao: (this.revisaoComissaoFormService.formField.information.descricaorevisao.value !== null) ? this.revisaoComissaoFormService.formField.information.descricaorevisao.value : null,
				editCaseData: JSON.stringify(this.revisaoComissaoFormService.formField)
			}
		}

		console.log('REQUEST: ', request);
		return request;
	}

	setErrorMessage(errorMessage) {
		this.errorMessage = errorMessage;
		console.log(errorMessage);
	}

	getErrorMessage() {
		return this.errorMessage;
	}

	setIsEdit(isEdit) {
		this.isEdit = isEdit;
	}

	getIsEdit() {
		return this.isEdit;
	}

	setSuccessMessage(successMessage) {
		this.successMessage = successMessage;
	}

	getSuccessMessage() {
		return this.successMessage;
	}

	setCancelShow(show) {
		this.buttonControl.cancel.show = show;
	}

	getCancelShow() {
		return this.buttonControl.cancel.show;
	}

	setCancelDisabled(disabled) {
		this.buttonControl.cancel.disabled = disabled;
	}

	getCancelDisabled() {
		return this.buttonControl.cancel.disabled;
	}

	setPreviousShow(show) {
		this.buttonControl.previous.show = show;
	}

	//Botões da Tela 

	getPreviousShow() {
		return this.buttonControl.previous.show;
	}

	setPreviousDisabled(disabled) {
		this.buttonControl.previous.disabled = disabled;
	}

	getPreviousDisabled() {
		return this.buttonControl.previous.disabled;
	}

	setNextShow(show) {
		this.buttonControl.next.show = show;
	}

	getNextShow() {
		return this.buttonControl.next.show;
	}

	setNextDisabled(disabled) {
		this.buttonControl.next.disabled = disabled;
	}

	getNextDisabled() {
		return this.buttonControl.next.disabled;
	}

	setSaveShow(show) {
		this.buttonControl.save.show = show;
	}

	getSaveShow() {
		return this.buttonControl.save.show;
	}

	setSaveDisabled(disabled) {
		this.buttonControl.save.disabled = disabled;
	}

	getSaveDisabled() {
		return this.buttonControl.save.disabled;
	}

	setFinishShow(show) {
		this.buttonControl.finish.show = show;
	}

	getFinishShow() {
		return this.buttonControl.finish.show;
	}

	setFinishDisabled(disabled) {
		this.buttonControl.finish.disabled = disabled;
	}

	getFinishDisabled() {
		return this.buttonControl.finish.disabled;
	}

	setPropostaFromResgate(resgate) {
		this.propostaFromResgate = resgate;
	}

	getPropostaFromResgate() {
		return this.propostaFromResgate;
	}

	setObjectProposta(proposta) {
		this.proposta = proposta;
	}

	getObjectProposta() {
		return this.proposta;
	}

	setResgateAccount(account) {
		this.resgateAccount = account;
	}

	getResgateAccount() {
		return this.resgateAccount;
	}

	isItemSelected(item) {

		return item.selected === true;
	}

	pristineAnnexObj() {
		return {
			files: [],
			hasError: false,
			loading: false,
			show: false,
			uploaded: false,
			uploadHasFailure: false
		};
	}

	//@todo gambiarra. refatorar pra deixar dinâmico em vez de marretar os anexos
	//Esse método serve pra remover os anexos que deram erro na hora de salvar o editCaseData
	removeFilesWithError(editCaseDataFiles) {
		const files = this.getAnnexes(editCaseDataFiles);

		for (let i = files.length - 1; i >= 0; i--) {
			for (let j = files[i].length - 1; j >= 0; j--) {
				if (!files[i][j].result) {
					files[i].splice(j, 1);
				}
			}
		}
	}

	getAnnexes(editCaseDataFiles) {
		const annexes = [
			editCaseDataFiles.documents.files
		];
		return annexes;
	}

	updateEditCaseData() {
		const editCaseData = JSON.parse(JSON.stringify(this.revisaoComissaoFormService.getFormField())); //@todo gambiarra. Angular.copy() não funciona bem com a classe File
		this.removeFilesWithError(editCaseData.file);
		const request = {
			caseId: this.revisaoComissaoFormService.formField.id,
			editCaseData: JSON.stringify(editCaseData)
		}

		console.log('Request updateEditCaseData', request);

		const cap_access_token = localStorage.getItem("cap_access_token");
		request.accessToken = cap_access_token;

		return this.visualForceApi.invoke(
			'CapCommunityCaseControllerExt.updateEditCaseData', request);

	}

}

module.exports = revisaoComissaoService;
