'use strict';

class revisaoComissaoFormService {

	// @ngInject
	constructor() {
		this.setPristine();
	}

	setPristine() {
		//console.log('setPristine;')

		this.status = null;
		this.formStep = null;
		this.id = null;
		this.edit = false;
		this.lastFormAction = '';

		this.setInformationPristine();
		//this.setAnnexPristine();
	}

	setInformationPristine() {
		this.formField = {
			id: null,
			status: null,
			decisionType: null,
			stepStatus: 'Não Iniciado',
			information: {
				active: true,
				status: 'Em andamento',
				estruturavenda: {
					value: null,
					hasError: false
				},
				motivorevisao: {
					value: null,
					hasError: false
				},
				descricaorevisao: {
					value: null,
					hasError: false
				}
			},
			file: {
				active: false,
				documents: this.pristineAnnexObj('revisao'),
				status: null
			},
			lastFormAction: ''
		}
	}

	pristineAnnexObj(type) {
		return {
			files: [],
			type,
			hasError: false,
			loading: false,
			show: false,
			uploaded: false,
			uploadHasFailure: false
		};
	}

	resetForm() {
		this.setPristine();
		console.log('FormReseted', this.formField);
	}

	resetFormFile() {
		this.formField.file = {
			active: false,
			documents: this.pristineAnnexObj('revisao')
		}
	}

	setFormField(json) {
		this.formField = json;
	}

	getFormField() {
		return this.formField;
	}

}

module.exports = revisaoComissaoFormService;
