'use strict';

const limitPhoneLength = 10;
const limitCelLength = 11;
const dateLength = 10;
const rgLength = 15;
const cepLength = 9;
const minimumAge = 18;

class AccountUtilService {

	/* @ngInject */
	constructor() {
		this._stateToGo = '';
	}

	getStateToGo() {
		return this._stateToGo;
	}

	setStateToGo(state) {
		this._stateToGo = state;
	}

	isValidCnpj(cnpj) {

		if (!cnpj) {
			return false;
		}

		cnpj = cnpj.replace(/[^\d]+/g, '');

		if (cnpj == '') {
			return false;
		}

		if (cnpj.length != 14) {
			return false;
		}

		// Elimina CNPJs invalidos conhecidos
		if (cnpj == "00000000000000" ||
			cnpj == "11111111111111" ||
			cnpj == "22222222222222" ||
			cnpj == "33333333333333" ||
			cnpj == "44444444444444" ||
			cnpj == "55555555555555" ||
			cnpj == "66666666666666" ||
			cnpj == "77777777777777" ||
			cnpj == "88888888888888" ||
			cnpj == "99999999999999") {

			return false;
		}

		let tamanho = cnpj.length - 2,
			numeros = cnpj.substring(0, tamanho),
			soma = 0,
			pos = tamanho - 7;

		const digitos = cnpj.substring(tamanho);

		for (let i = tamanho; i >= 1; i--) {
			soma += numeros.charAt(tamanho - i) * pos--;
			if (pos < 2)
				pos = 9;
		}

		resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

		if (resultado != digitos.charAt(0))
			return false;

		tamanho = tamanho + 1;
		numeros = cnpj.substring(0, tamanho);
		soma = 0;
		pos = tamanho - 7;

		for (let i = tamanho; i >= 1; i--) {
			soma += numeros.charAt(tamanho - i) * pos--;
			if (pos < 2)
				pos = 9;
		}

		let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

		if (resultado != digitos.charAt(1)) {
			return false;
		}

		return true;
	}

	isValidCpf(cpf) {

		if (!cpf) {
			return false;
		}

		cpf = cpf.replace(/\D/g, '');

		if (cpf.length != 11 || cpf.replace(eval(`/${cpf.charAt(1)}/g`), '') == '') {
			return false;

		} else {
			for (let n = 9; n < 11; n++) {
				let d = 0,
					c = 0;

				for (c = 0; c < n; c++) {
					d += cpf.charAt(c) * ((n + 1) - c)
				}

				d = ((10 * d) % 11) % 10;

				if (cpf.charAt(c) != d) {
					return false;
				}
			}

			return true;
		}
	}

	isValidEmail(email) {
		//const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		const re = /^[a-zA-Z0-9][a-zA-Z0-9\._-]+@([a-zA-Z0-9\._-]+\.)[a-zA-Z-0-9]{2,3}$/;
		return re.test(email);
	}

	hasLastName(name) {
		if (name.split(' ').length > 1) {
			return true;
		}

		return false;
	}

	hasInvalidSequence(str) {
		str = str.replace(/\s+/g, "_");
		return /(\S)(\1{2,})/g.test(str);
	}

	hasSequencedSpace(str) {
		return /(\s)(\1{1,})/g.test(str);
	}

	isNumber(str) {
		str = str.replace(/[^\w\s]/gi, '');
		return !isNaN(str);
	}

	isValidPhoneNumber(phone) {
		phone = phone.replace(/[^\w\s]/gi, '');

		if (!isNaN(phone) && (phone.length === limitPhoneLength || phone.length === limitCelLength)) {
			return true;
		}

		return false;
	}

	isValidDate(str) {
		return Date.parse(str) ? true : false;
	}

	parseValidDate(strDate) {
		// console.log('parseValidDate', strDate, typeof strDate);
		//format 99/99/9999

		let resultDate;

		if (typeof strDate !== 'object' && strDate.indexOf('/') !== -1) {

			if (strDate.length != dateLength) {

				return;
			}

			const dateParts = strDate.split("/");
			resultDate = new Date(dateParts[2], (dateParts[1] - 1), dateParts[0]);
		} else {

			resultDate = strDate;
		}

		// const dateParts = strDate.split("/"),
		// 	resultDate = new Date(dateParts[2], (dateParts[1] - 1), dateParts[0]);

		if (Object.prototype.toString.call(resultDate) === "[object Date]" && !isNaN(resultDate.getTime())) {
			return resultDate;
		}
		return;
	}

	isBeforeToday(date) {
		const today = new Date();
		return today > date ? true : false;
	}

	userHasMinimumAge(birthDate) {
		return this.calculateAge(birthDate) < 18 ? false : true;
	}

	calculateAge(birthDate) {
		const todayDate = new Date();
		let age = todayDate.getFullYear() - birthDate.getFullYear();

		if (todayDate.getMonth() < birthDate.getMonth()) {
			age--;
		}

		if (birthDate.getMonth() === todayDate.getMonth() && todayDate.getDate() < birthDate.getDate()) {
			age--;
		}

		return age;
	}

	isValidRg(strRg) {
		//return this.isNumber(strRg);
		return (strRg.length <= 15);
	}

	isValidCep(strCep) {
		return strCep.length != cepLength ? false : this.isNumber(strCep);
	}

}

module.exports = AccountUtilService;
