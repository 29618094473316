'use strict';

const componentController = require('./case-documentacao-proposta.controller');
const componentApi = require('./case-documentacao-proposta.service');
const routesConfiguration = require('./case-documentacao-proposta.routes');

const formControlService = require('./form-control.service');

const detailController = require('./detail/detail.controller');
const detailApiService = require('./detail/api.service');

const newController = require('./new/new.controller');
const newApiService = require('./new/api.service');

const uploadController = require('./upload/upload.controller');
const uploadApiService = require('./upload/api.service');

const componentModule = angular.module('cap.secured.case.documentacao-proposta', [
		'ui.router'
	])
	.controller('documentacaoPropostaController', componentController)
	.service('documentacaoPropostaApi', componentApi)

	.service('documentacaoPropostaFormControlService', formControlService)

	.controller('documentacaoPropostaDetailController', detailController)
	.service('documentacaoPropostaDetailApi', detailApiService)

	.controller('documentacaoPropostaNewController', newController)
	.service('documentacaoPropostaNewApi', newApiService)

	.controller('documentacaoPropostaUploadController', uploadController)
	.service('documentacaoPropostaUploadApi', uploadApiService)

	.config(routesConfiguration);

module.exports = componentModule;
