'use strict';

/* @ngInject */
function routesConfiguration($stateProvider, sfConfig) {

	$stateProvider.state('my-account-person-edit', {
		url: '/minha-conta/pf/edicao',
		views: {
			'content': {
				templateUrl: `${sfConfig.resource}/views/account/my-account/person.html`,
				controller: 'myAccountPersonEditController',
				controllerAs: 'myAccount'
			}
		}
	});

	$stateProvider.state('my-account-juridical-edit', {
		url: '/minha-conta/pj/edicao',
		views: {
			'content': {
				templateUrl: `${sfConfig.resource}/views/account/my-account/juridical.html`,
				controller: 'myAccountJuridicalEditController',
				controllerAs: 'myAccount'
			}
		}
	});
};

module.exports = routesConfiguration;
