'use strict';

class caseListController {

	// @ngInject
	constructor($scope, $window, $state, $stateParams, caseListApi, paginationHelper, feedbackMessages, caseCache, sfConfig, ordenationHelper, breadcrumbHelper) {

		this.$window = $window;
		this.$scope = $scope;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.messages = feedbackMessages;
		this.caseListApi = caseListApi;
		this.pages = paginationHelper;
		this.order = ordenationHelper;
		this.order.setPristine();
		this.caseCache = caseCache;
		this.sfConfig = sfConfig;
		this.recordsList = [];
		this.newCaseType = null;
		this.$scope.breadcrumb = breadcrumbHelper;
		this.stateCaseType = $stateParams.stateCaseType || "todos";

		this.getListViewOptions();
		this.configureBreadCrumb();
		this.caseCache.clean();
	}

	configureBreadCrumb() {
		this.$scope.breadcrumb.reset();
		this.$scope.breadcrumb.addMenuItem('Home', '/caponline/secured#!/home', true, false);
		if (this.$stateParams.stateCaseType == 'todos') {
			this.$scope.breadcrumb.addMenuItem('Solicitação', '/caponline/secured#!/solicitacoes/lista/todos', false, true);
		} else this.$scope.breadcrumb.addMenuItem('Documentação da Emissão', '/caponline/secured#!/solicitacoes/lista/documentacao', false, true);
	}

	getUrlFromCase(caseType, CaseId) {
		const liberacaocaucao = '#!/solicitacoes/liberacao-da-caucao/detalhes?id=';
		const documentacaoproposta = '#!/solicitacoes/documentacao-proposta/detalhes?id=';
		const bloqueioreaplicacao = '#!/solicitacoes/bloqueioreaplicacao/detalhes?id=';
		const reaplicacaotitulo = '#!/solicitacoes/reaplicacaotitulo/detalhes?id=';
		const revisaocomissao = '#!/solicitacoes/revisaocomissao/detalhes?id=';
		const alteracaoDados = '#!/solicitacoes/alteracao-dados/detalhes?id=';
		const sorteio = '#!/solicitacoes/sorteio/detalhes?id=';
		const solicitacao = '#!/solicitacoes/detalhes?id=';
		let url = solicitacao.concat(CaseId); // Default
		//console.log(caseType);
		if (!!caseType) {
			if (caseType.toLowerCase() === "liberação da caução" ||
				caseType.toLowerCase() === "manutenção de títulos") {
				url = liberacaocaucao;
				url = url.concat(CaseId);
			} else if (caseType.toLowerCase() === "documentação da emissão" ||
				caseType.toLowerCase() === "emissão de títulos") {
				url = documentacaoproposta;
				url = url.concat(CaseId);
			} else if (caseType.toLowerCase() === "bloqueio de reaplicação") {
				url = bloqueioreaplicacao;
				url = url.concat(CaseId);
			} else if (caseType.toLowerCase() === "reaplicação de título") {
				url = reaplicacaotitulo;
				url = url.concat(CaseId);
			} else if (caseType.toLowerCase() === "revisão de comissão") {
				url = revisaocomissao;
				url = url.concat(CaseId);
			} else if (caseType.toLowerCase() === "mudança de locador" || // Não sei porque temos 6 valores diferentes ao invés de 4. Devem ser valores antigos que ficaram presos da base. Revisar depois.
				caseType.toLowerCase() === "alteração de dados do cliente" ||
				caseType.toLowerCase() === "mudança de imóvel" ||
				caseType.toLowerCase() === "mudança de imóvel / locador" ||
				caseType.toLowerCase() === "alteração de dados do subscritor" ||
				caseType.toLowerCase() === "inclusão da caução" ||
				caseType.toLowerCase() === "alteração cadastral" ||
				caseType.toLowerCase() === "alteração de dados da garantia") {
				url = alteracaoDados;
				url = url.concat(CaseId);
			} else if (caseType.toLowerCase() === "pagamento de sorteio") {
				url = sorteio;
				url = url.concat(CaseId);
			}
		}
		return url;
	}

	getListViewOptions() {
		this.caseListApi.getListViewOptions(this.stateCaseType).then((response) => {
			if (!response.isSuccessful) {
				this.$window.scrollTo(0, 0);
				const errorMessage = angular.toJson(response.errorMessages);
				this.messages.showErrorMessage(this.$scope, errorMessage);
			} else {
				this.listViewOptions = response.options;
				this.listViewOptionSelected = this.listViewOptions[0];
				this.getList(true);
			}
		});
	}

	changeOrder(field) {
		this.order.changeOrder(field);
		this.pages.setPagesPristine();
		this.getList(true);
	}

	changeViewOption() {
		this.order.setPristine();
		this.pages.setPagesPristine();
		this.getList(true);
	}

	isShowProposta() {

		let result = true;

		console.log(this.listViewOptionSelected.queryType);

		if (this.listViewOptionSelected.queryType == 'revisao-de-comissao') {
			result = false;
		}

		return result;
	}

	getList(isCount) {

		this.showLoading = true;

		const queryModel = {
			queryType: this.listViewOptionSelected.queryType,
			queryLimit: this.pages.limitOfRecords,
			queryOffset: this.queryOffset,
			queryOrderField: this.order.data.field,
			queryOrderDirection: this.order.data.direction,
			isQueryCount: !!isCount
		};

		console.log(queryModel);
		this.caseListApi.getList(queryModel).then((response) => {
			this.showLoading = false;
			if (!response.isSuccessful) {
				if (response.errorMessages) {
					this.$window.scrollTo(0, 0);
					const errorMessage = angular.toJson(response.errorMessages);
					this.messages.showErrorMessage(this.$scope, errorMessage);
					setTimeout(this.messages.cleanMessages(this.$scope), 3000);
				}
				this.recordsList = [];
				this.pages.totalOfRecords = 0;
			} else {
				this.recordsList = response.cases;
				if (isCount) {
					this.pages.totalOfRecords = response.queryCount;
					this.pages.setPaginationParams();
				}
			}
		});
	}

	get queryOffset() {

		return ((this.pages.actualPage - 1) * this.pages.limitOfRecords);
	}

	doPrevious() {

		this.pages.doPrevious(this.getList, this);
	}

	doNext() {
		console.log(this);
		this.pages.doNext(this.getList, this);
	}

	newCase(caseType) {
		// console.log('newCase', caseType);
		console.log(caseType);
		if (caseType) {

			this.caseCache.clean();
			this.$state.reload('resgate.new');
			this.$state.reload('resgate');
			console.log('vai pro estado', caseType)
			this.$state.go(caseType);
		}
	}

	goToCaseDetail(caseType, caseId) {

		const definicao = [{
				caseType: 'liberação da caução',
				caseRoute: 'case.liberacao-da-caucao.detail'
			},
			{
				caseType: 'documentação da emissão',
				caseRoute: 'case.documentacao-proposta.detail'
			},
			{
				caseType: 'revisão de comissão',
				caseRoute: 'case.revisao-comissao.detail'
			},
			{
				caseType: 'alteração de dados',
				caseRoute: 'case.alteracao-dados.detail'
			},
			{
				caseType: 'sorteio',
				caseRoute: 'case.sorteio.detail'
			}
		];

		if (!!caseType) {
			for (const item in definicao) {
				if (caseType.toLowerCase() === definicao[item].caseType) {
					this.$state.go(definicao[item].caseRoute, { "id": caseId });
					return;
				}
			}
		}
		this.$state.go('case.detail', { "id": caseId });
	}

	goToCaseEdit(caseType, caseId) {

		const definicao = [{
			caseType: 'documentação da emissão',
			caseRoute: 'case.documentacao-proposta.upload'
		}];

		if (!!caseType) {
			for (const item in definicao) {
				if (caseType.toLowerCase() === definicao[item].caseType) {
					this.$state.go(definicao[item].caseRoute, { "id": caseId });
					return;
				}
			}
		}
	}

	isCaseType(caseType) {
		return (this.stateCaseType === caseType);
	}
}

module.exports = caseListController;
