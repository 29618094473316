'use strict';

// routes
const routesConfiguration = require('./app-secured-routes');
const appConfig = require('./app-config');
const mainModule = require('./secured/main/main-module')
const accountModule = require('./secured/account/account-module')
const documentsModule = require('./secured/documents/documents-module');
const assistenciaLocaticiaModule = require('./secured/assistencia-locaticia/assistencia-locaticia.module')
const caseModule = require('./secured/case/case-module')
const comissoesPagasModule = require('./secured/comissoes-pagas/comissoes-pagas.module')
const administrarUsuarioModule = require('./secured/administrar-usuario/component.module')
const termoModule = require('./secured/termo/component.module')
const propostaModule = require('./secured/proposta/proposta.module')
const contactUsModule = require('./secured/fale-conosco/fale-conosco.module')
const rentGuaranteeModule = require('./secured/rent-guarantee/rent-guarantee.module')
const coreSecuredModule = require('./secured/core/core-module');
const coreModule = require('./core/core.module');
const homeModule = require('./secured/home/home-module');
const permitionsModule = require('./secured/permitions/permitions-module');
const remuneracoesPagasModule = require('./secured/remuneracoes-pagas/remuneracoes-pagas.module')

// modules

//configs
const appRun = require('./app-run');
const configModule = { name: 'cap.config' };

const app = angular.module('cap.secured', [
		configModule.name,
		accountModule.name,
		documentsModule.name,
		assistenciaLocaticiaModule.name,
		caseModule.name,
		comissoesPagasModule.name,
		remuneracoesPagasModule.name,
		administrarUsuarioModule.name,
		termoModule.name,
		propostaModule.name,
		contactUsModule.name,
		rentGuaranteeModule.name,
		coreSecuredModule.name,
		coreModule.name,
		homeModule.name,
		permitionsModule.name,
		mainModule.name
	])
	.config(($sceDelegateProvider) => {
		$sceDelegateProvider.resourceUrlWhitelist([
			// Allow same origin resource loads.
			'self',
			// Allow loading from our assets domain. **.
			'https://checkout.mundipagg.com/**',
			'https://api.mundipagg.com/**',
			'https://api.pagar.me/**',
			'https://checkout.pagar.me/**'
		])
	})
	.config(routesConfiguration)
	.config(appConfig)
	.run(appRun);

module.exports = app;
