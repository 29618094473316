'use strict';

class caseListApi {

	/* @ngInject */
	constructor($q, sfConfig, visualForceApi) {

		this.visualForceApi = visualForceApi;
		this.$q = $q;
		this.sfConfig = sfConfig;

		console.log(this.sfConfig);
	}

	getListViewOptions(caseType) {
		/*
		Tipos permitidos para caseType hoje,
			"documentacao-emissao" - para documentacao da emissao da proposta
			"todos" - todos os outros
		*/
		const deferred = this.$q.defer();

		const definicao = [
			{ group: '', label: 'Últimas Solicitações', queryType: 'ultimas-solicitacoes', selected: false, check: (caseType === "todos") },
			/* TIPO */
			{ group: 'Tipo', label: 'Solicitações de Resgate', queryType: 'solicitacoes-resgate', selected: true, check: (caseType === "todos") },
			//{ group: 'Tipo', label: 'Solicitações de resgate - Pré Análise', queryType: 'solicitacoes-resgate-aguardando-pre-analise', selected: false, check: (caseType === "todos") },
			{ group: 'Tipo', label: 'Solicitações de Liberação da Garantia sem pagamento', queryType: 'liberacao-da-caucao', selected: false, check: (caseType === "todos") },
			//{ group: 'Tipo', group: 'Tipo', label: 'Solicitações de Liberação da Garantia sem pagamento - Pré análise', queryType: 'liberacao-da-caucao-pre-analise', selected: false, check: (caseType === "todos") },
			//{ group: 'Tipo', label: 'Solicitações pendentes', queryType: 'tipo-documentacao-proposta-pendente', selected: false, check: (caseType === "todos") },*
			{ group: 'Tipo', label: 'Solicitações de Bloqueio de Reaplicação', queryType: 'bloqueio-de-reaplicacao', selected: false, check: (caseType === "todos") },
			{ group: 'Tipo', label: 'Solicitações de Reaplicação de Título', queryType: 'reaplicacao-de-titulo', selected: false, check: (caseType === "todos") },
			{ group: 'Tipo', label: 'Solicitações de Alteração de Dados', queryType: 'alteracao-de-dados', selected: false, check: (caseType === "todos") },
			{ group: 'Tipo', label: 'Solicitações de Pagamento de Sorteio', queryType: 'sorteio', selected: false, check: (caseType === "todos") },
			{ group: 'Tipo', label: 'Solicitações de Revisão de Comissão', queryType: 'revisao-de-comissao', selected: false, check: (caseType === "todos" && this.sfConfig.administrarUsuario.canAccess === 'true' && this.sfConfig.imobiliaria.Id == '') },
			/* STATUS */
			{ group: 'Status', label: 'Solicitações em Elaboração', queryType: 'em-andamento', selected: false, check: (caseType === "todos") },
			{ group: 'Status', label: 'Solicitações Enviadas', queryType: 'enviado', selected: false, check: (caseType === "todos") },
			{ group: 'Status', label: 'Solicitações Canceladas', queryType: 'cancelado', selected: false, check: (caseType === "todos") },
			{ group: 'Status', label: 'Solicitações em Análise', queryType: 'em-analise', selected: false, check: (caseType === "todos") },
			{ group: 'Status', label: 'Solicitações Reprovadas', queryType: 'reprovado', selected: false, check: (caseType === "todos") },
			{ group: 'Status', label: 'Solicitações Aprovadas', queryType: 'aprovado', selected: false, check: (caseType === "todos") },
			{ group: 'Status', label: 'Solicitações Enviadas para Pagamento', queryType: 'enviadas-pagamento', selected: false, check: (caseType === "todos") },
			{ group: 'Status', label: 'Solicitações com Pagamento Críticado', queryType: 'pagamento-criticado', selected: false, check: (caseType === "todos") },
			/* DOCUMENTACÃO DA EMISSÃO */
			{ group: '', label: 'Todas as Solicitações', queryType: 'tipo-documentacao-proposta', selected: false, check: (caseType === "documentacao") },
			{ group: '', label: 'Solicitações Aprovadas', queryType: 'tipo-documentacao-proposta-aprovada', selected: false, check: (caseType === "documentacao") },
			{ group: '', label: 'Solicitações Reprovadas', queryType: 'tipo-documentacao-proposta-reprovada', selected: false, check: (caseType === "documentacao") },
			{ group: '', label: 'Solicitações Aguardando Documentação', queryType: 'tipo-documentacao-proposta-andamento', selected: false, check: (caseType === "documentacao") }
		];

		const optionsList = [];
		for (const item in definicao) {
			if (definicao[item].check) {
				optionsList.push(definicao[item]);
			}
		}

		const listViewOptions = {
			options: optionsList,
			isSuccessful: true
		};

		deferred.resolve(listViewOptions);

		return deferred.promise;
	}

	getList(query) {

		const cap_access_token = localStorage.getItem("cap_access_token");
		query.accessToken = cap_access_token;

		return this.visualForceApi.invoke(
			'CapCommunityCaseControllerExt.getCaseList', query);
	}
}

module.exports = caseListApi;
