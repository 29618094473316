'use strict';

class permitionsService {

	// @ngInject
	constructor() {
		this.userPermitions = {};
	}

	//get user permitions from the server
	getUserPermitions() {
		this.userPermitions = {
			proposta: true,
			resgate: true,
			cadastro: true,
			solicitacoes: true,
			comissoesPagas: true,
			assistenciaLocaticia: true
		};
	}
}

module.exports = permitionsService;
