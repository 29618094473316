'use strict';

class annexHelper {

	constructor($q, annexApiHelper) {
		// console.log('annexHelper constructor');

		this.$q = $q;

		this.api = annexApiHelper;

		this.annexes = null;
		this.caseId = null;
		this.gedAttachmentType = null;
		this.caseType = null;
		this.operationId = null;
		this.deferred = null;
	}

	static directiveFactory($q, annexApiHelper) {
		// console.log('annexHelper directiveFactory');
		return new annexHelper($q, annexApiHelper);
	}

	uploadAnnexes(annexes, caseId, gedAttachmentType, caseType) {
		console.log('uploadAnnexes', this.annexes, annexes, caseId, gedAttachmentType, caseType);

		this.operationId = new Date().getTime();
		this.annexes = annexes;
		this.caseId = caseId;
		this.gedAttachmentType = gedAttachmentType;
		this.caseType = caseType;
		this.deferred = this.$q.defer();

		this.resetFinishedUploads();

		for (let i = annexes.length - 1; i >= 0; i--) {
			this.doUploadFiles(annexes[i]);
		}

		this.checkAnnexesUpload();

		return this.deferred.promise;
	}

	clearFileErrors() {
		// console.log('clearFileErrors');

		const annexes = this.annexes;

		for (let i = annexes.length - 1; i >= 0; i--) {

			angular.forEach(annexes[i].files, (file) => {

				file.resultError = false;
			});
		}
	}

	isAnnexUploaded(annex) {
		if (!annex.files) annex.files = [];
		return annex.files.length === annex.uploaded;
	}

	sliceString(string, sliceLength) {

		const returnArray = [];
		// console.log('Entrou na função sliceString');
		// console.log('tamanho da string: ', string.length);
		if (string.length < sliceLength) {
			returnArray.push(string);
		} else {
			const numberOfSlices = Math.floor(string.length / sliceLength);
			const rest = string.length % sliceLength;
			let slice;

			for (let i = 0; i < numberOfSlices; i++) {
				slice = string.substring(0, sliceLength);
				string = string.substring(sliceLength);
				// console.log('string new length: ', string.length);
				returnArray.push(slice);
			}

			if (rest > 0) {
				returnArray.push(string);
			}
		}

		// console.log('Fim da função sliceString, tamanho do array: ', returnArray.length);
		return returnArray;
	}

	doUploadFiles(annex) {
		// console.log('doUploadFiles', annex);

		this.clearFileErrors();

		annex.uploadHasFailure = false;
		annex.uploaded = 0;

		angular.forEach(annex.files, (file, idx) => {
			if ((file instanceof File) && (!file.result)) {
				/* ======================================================================
				Quando o usuário seleciona um arquivo do computador dele entramos nesse
				bloco para realizar o upload
				======================================================================  */
				const fileReader = new FileReader();
				file.loader = true;
				file.succeededUploadSlices = 0;
				file.uploadedSlices = 0;
				fileReader.readAsDataURL(file);
				this.uploadFileSlices(annex, file, fileReader);

			} else if ((file.result) || (file.resultError === false)) {
				/* ======================================================================
				Se o arquivo já foi enviado com sucesso,
				======================================================================  */
				annex.uploaded++;
				this.checkAnnexesUpload();
				return;
			}

		});
	}

	uploadFileSlices(annex, file, fileReader) {

		fileReader.onload = (e) => {

			const fileAsBase64 = e.target.result;
			const fileSlices = this.sliceString(fileAsBase64, 50000);
			console.log('Nome do arquivo -> %s | quantidade de slices -> %d | operationId -> %d | tipo -> %s', file.name, fileSlices.length, this.operationId, annex.type);
			const fileType = fileSlices[0].split(',')[0].split(":")[1].split(";")[0];
			let isLast = true;

			for (let i = fileSlices.length - 1; i >= 0; i--) {

				const slice = fileSlices[i];

				this.api.uploadFile(
						this.caseId,
						file.name,
						slice,
						annex.type,
						i,
						isLast,
						fileType,
						this.operationId,
						file.salesForceId
					)
					.then((response) => {

						console.log('### file upload sucesso: ', response);
						file.uploadedSlices++;

						if (response.isSuccessful) {

							this.uploadFileSliceSucceeded(file, response);
						} else {

							this.uploadFileFailed(annex, file, response);
						}

						if (this.isLastUploadingSlice(file, fileSlices)) {

							file.loader = false;

							console.log('### this.hasFileUploadSucceeded(file, fileSlices): ', this.hasFileUploadSucceeded(file, fileSlices));

							if (this.hasFileUploadSucceeded(file, fileSlices)) {

								this.mountFile(annex, file);
							} else {
								annex.uploadHasFailure = true;
								annex.uploaded++;
								this.checkAnnexesUpload();
							}
						}
					})
					.catch((response) => {

						console.log('### file upload error: ', response);
						file.uploadedSlices++;

						this.uploadFileFailed(annex, file, response);
					});

				isLast = false;
			}
		};
	}

	uploadFileSliceSucceeded(file, response) {

		file.succeededUploadSlices++;

		// alguma chamada vai devolver esses dados
		file.salesForceId = response.attachmentId || file.salesForceId;
		file.userId = response.userId || file.userId;
		file.contentType = response.contentType || file.contentType;
	}

	uploadFileFailed(annex, file, response) {

		file.result = false;
		file.resultError = response.errorMessages ? response.errorMessages[0] : response;
		file.resultError = angular.toJson(file.resultError);
		annex.uploadHasFailure = true;
	}

	isLastUploadingSlice(file, fileSlices) {

		return file.uploadedSlices === fileSlices.length;
	}

	hasFileUploadSucceeded(file, fileSlices) {

		return file.succeededUploadSlices === fileSlices.length;
	}

	mountFile(annex, file) {
		// console.log('mountFile', annex, file);

		this.api.mountFile(
				this.caseId,
				annex.type,
				file.userId,
				file.name,
				this.gedAttachmentType,
				this.caseType,
				this.operationId,
				file.contentType
			)
			.then((response) => {
				if (response.isSuccessful) {
					file.result = true;
					file.salesForceId = response.salesForceId;
				} else {
					this.uploadFileFailed(annex, file, response);
				}
			})
			.catch((response) => {

				this.uploadFileFailed(annex, file, response);
			})
			.finally((response) => {

				annex.uploaded++;

				this.checkAnnexesUpload();
			});
	}

	hasFinishUploading() {

		const annexes = this.annexes;
		let hasFinish = true;

		for (let i = annexes.length - 1; i >= 0; i--) {

			if (annexes[i].show && !this.isAnnexUploaded(annexes[i])) {

				hasFinish = false;
				break;
			}
		}
		// console.log('hasFinishUploading', hasFinish);

		return hasFinish;
	}

	hasRequiredUploads() {

		const annexes = this.annexes;

		let hasRequired = true;

		for (let i = annexes.length - 1; i >= 0; i--) {

			if (annexes[i].uploadHasFailure) {

				hasRequired = false;
				break;
			}
		}

		if (!hasRequired) {

			this.resetFinishedUploads();
		}
		// console.log('hasRequiredUploads', hasRequired);

		return hasRequired;
	}

	resetFinishedUploads() {

		angular.forEach(this.annexes, (annex) => {

			annex.uploaded = 0;
		});
	}

	checkAnnexesUpload() {

		const annexes = this.annexes;

		if (!this.hasFinishUploading(annexes)) {
			console.log('checkAnnexesUpload not finished uploading');

			return;
		}

		if (this.hasRequiredUploads(annexes)) {
			console.log('checkAnnexesUpload resolve');

			this.deferred.resolve();
		} else {
			console.log('checkAnnexesUpload reject');

			this.deferred.reject();
		}
	}
}

annexHelper.directiveFactory.$inject = ['$q', 'annexApiHelper'];

module.exports = annexHelper.directiveFactory;
