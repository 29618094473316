"use strict";

import VisualForceApi from "../../core/sfdc/visual-force-api.js";

class propostaCaucaoApi extends VisualForceApi {
	/* @ngInject */
	constructor($q) {
		super();
		this.$q = $q;
	}

	searchLocator(searchKeyWord) {
		const deferred = this.$q.defer();

		const searchModel = {
			dataFetch: {
				searchKeyWord,
				searchTypeObject: "Account"
			}
		};

		const cap_access_token = localStorage.getItem("cap_access_token");
		searchModel.accessToken = cap_access_token;

		super
			.invoke("CapCommunityPropostaControllerExt.solFetchRecords", searchModel)
			.then((data) => {
				//console.log(data.obtainedRecords);
				if (!data.obtainedRecords) data.obtainedRecords = [];
				if (data.obtainedRecords.length === 0) {
					data.obtainedRecords.push({
						action: "searchLocatorFocusOut",
						Name: "Nenhum cadastro encontrado"
					});
				}
				data.obtainedRecords.push(this.addLocatorBtn());
				deferred.resolve(data.obtainedRecords);
			});

		return deferred.promise;
	}

	searchEstateAdmin(searchKeyWord) {
		const deferred = this.$q.defer();

		const searchModel = {
			dataFetch: {
				searchKeyWord,
				searchTypeObject: "estateAdminAccount"
			}
		};

		const cap_access_token = localStorage.getItem("cap_access_token");
		searchModel.accessToken = cap_access_token;

		super
			.invoke("CapCommunityPropostaControllerExt.solFetchRecords", searchModel)
			.then((data) => {
				//console.log(data.obtainedRecords);
				if (!data.obtainedRecords) data.obtainedRecords = [];
				if (data.obtainedRecords.length === 0) {
					data.obtainedRecords.push({
						action: "searchEstateAdminFocusOut",
						Name: "Nenhum cadastro encontrado"
					});
				}
				data.obtainedRecords.push(this.addEstateAdminBtn());
				deferred.resolve(data.obtainedRecords);
			});

		return deferred.promise;
	}

	addLocatorBtn() {
		return {
			action: "createLocatorModal",
			Name: "+ Criar Cadastro"
		};
	}

	addEstateAdminBtn() {
		return {
			action: "createEstateAdminModal",
			Name: "+ Criar Cadastro"
		};
	}

	getClientAddress(clientId) {
		const cap_access_token = localStorage.getItem("cap_access_token");

		return super.invoke(
			"CapCommunityPropostaControllerExt.solGetAddressAccount",
			clientId,
			cap_access_token
		);
	}
}

module.exports = propostaCaucaoApi;
