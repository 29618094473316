'use strict';

class autoCompleteService {

	clearInput(scope, id) {
		// console.log('clearInput', this);

		scope.$broadcast('angucomplete-alt:clearInput', id);
	}

	hasValidInput(input) {
		// console.log('hasValidInput', this);

		return !!input;
	}

	resetSelected(source, item) {
		// console.log('resetSelected', this);

		source[item] = null;
	}
}

module.exports = autoCompleteService;
