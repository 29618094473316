'use strict';

class termoDeAdesaoApi {

	/* @ngInject */
	constructor(visualForceApi) {

		this.visualForceApi = visualForceApi;
	}

	acceptTerm(type) {

		return this.visualForceApi.invoke(
			'CapCommunityUserControllerExt.acceptTerm', type);
	}

	// remove() {

	// 	return this.visualForceApi.invoke(
	// 		'CapCommunityUserControllerExt.removeTermAcceptance');
	// }
}

module.exports = termoDeAdesaoApi;
