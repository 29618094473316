'use strict';

class annexApiHelper {

	/* @ngInject */
	constructor(visualForceApi) {

		this.visualForceApi = visualForceApi;
	}

	removeFile(attachmentId) {

		const cap_access_token = localStorage.getItem("cap_access_token");

		return this.visualForceApi.invoke(
			'CapCommunityCaseControllerExt.deleteAttachment', attachmentId, cap_access_token);
	}

	uploadFile(Id, fileName, attachment, attachmentType, part, isLastPart, contentType, operationId, salesForceAttachmentId) {
		// console.log('uploadFileAPI', Id, fileName, attachment, attachmentType, part, isLastPart, contentType);

		const request = {
			Id,
			fileName,
			attachment,
			attachmentType,
			part,
			isLastPart,
			contentType,
			operationId,
			salesForceAttachmentId
		};

		console.log('chamando CapCommunityCaseControllerExt.attachCase para enviar os arquivos');

		const cap_access_token = localStorage.getItem("cap_access_token");
		request.accessToken = cap_access_token;

		return this.visualForceApi.invoke(
			'CapCommunityCaseControllerExt.attachCase', request);
	}

	mountFile(caseId, attachmentType, userId, fileName, gedAttachmentType, caseType, operationId, contentType) {

		console.log('upload pro SF com sucesso, chamando mountFile');

		const request = {
			caseId,
			attachmentType,
			fileName,
			userId,
			gedAttachmentType,
			caseType,
			operationId,
			contentType
		}
		return this.visualForceApi.invoke(
			'CapCommunityCaseControllerExt.mountFile', request);
	}
}

module.exports = annexApiHelper;
