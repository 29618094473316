'use strict';

const propostaController = require('./proposta.controller');
const propostaApi = require('./proposta-api.service');

const propostaListController = require('./proposta-list/proposta-list.controller');
const propostaListService = require('./proposta-list/proposta-list.service');

const propostaNewController = require('./proposta-new/proposta-new.controller');
const propostaNewApi = require('./proposta-new/proposta-new-api.service');

const propostaInformationController = require('./proposta-information/proposta-information.controller');
const propostaInformationApi = require('./proposta-information/proposta-information-api.service');

const propostaPaymentController = require('./proposta-payment/proposta-payment.controller');
const propostaPaymentService = require('./proposta-payment/proposta-payment.service');

const propostaCaucaoController = require('./proposta-caucao/proposta-caucao.controller');
const propostaCaucaoApi = require('./proposta-caucao/proposta-caucao-api.service');

const propostaGenerateController = require('./proposta-generate/proposta-generate.controller');
const propostaGenerateApi = require('./proposta-generate/proposta-generate-api.service');

const autoCompleteService = require('./proposta-new/proposta-new-auto-complete.service');
const formControlService = require('./core/proposta-form-control.service');

const propostaBoletoController = require('./proposta-boleto/proposta-boleto.controller');
const propostaBoletoService = require('./proposta-boleto/proposta-boleto.service');

// const propostaFileController = require('./proposta-file/proposta-file.controller');
// const propostaFileService = require('./proposta-file/proposta-file.service');

const propostaDetailController = require('./proposta-detail/proposta-detail.controller');
const propostaDetailService = require('./proposta-detail/proposta-detail.service');

const routesConfiguration = require('./proposta.routes');

const propostaSetupAccountService = require('./proposta-setup-account/proposta-setup-account-service');

const propostaModule = angular.module('cap.secured.proposta', [
		'ui.router'
	])
	.controller('propostaController', propostaController)
	.service('propostaApi', propostaApi)

	.controller('propostaListController', propostaListController)
	.service('propostaListService', propostaListService)

	.controller('propostaNewController', propostaNewController)
	.service('propostaNewApi', propostaNewApi)

	.controller('propostaInformationController', propostaInformationController)
	.service('propostaInformationApi', propostaInformationApi)

	.controller('propostaCaucaoController', propostaCaucaoController)
	.service('propostaCaucaoApi', propostaCaucaoApi)

	.controller('propostaPaymentController', propostaPaymentController)
	.service('propostaPaymentService', propostaPaymentService)

	.controller('propostaGenerateController', propostaGenerateController)
	.service('propostaGenerateApi', propostaGenerateApi)

	.controller('propostaBoletoController', propostaBoletoController)
	.service('propostaBoletoService', propostaBoletoService)

	// .controller('propostaFileController', propostaFileController)
	// .service('propostaFileService', propostaFileService)

	.controller('propostaDetailController', propostaDetailController)
	.service('propostaDetailService', propostaDetailService)

	.service('propostaSetupAccountService', propostaSetupAccountService)

	.service('autoCompleteService', autoCompleteService)
	.service('formControlService', formControlService)

	.config(routesConfiguration);

module.exports = propostaModule;
