'use strict';

class propostaGenerateController {

	/* @ngInject */
	constructor($scope, propostaGenerateApi, formControlService) {
		//console.log('propostaGenerateController');

		this.propostaGenerateApi = propostaGenerateApi;
		this.formControl = formControlService;
		this.formField = this.formControl.field;
		this.amountProposta = 0;
		this.$scope = $scope;

		this.required = {};

		this.formField.formStep = 'proposta.generate';

		this.checkOnLoadRequiredFields();

		this.listenValidationRequest();
		this.listenRequiredRequest();
		this.clearParentMessages();
		this.calculoValorTotalProposta();
	}

	checkOnLoadRequiredFields() {
		// console.log('checkOnLoadRequiredFields');

		if (!this.isFormPristine() && this.formRequired()) {

			return;
		}

		this.formRequired();

		this.formField.stepStatusPropostaGenerate = 'Incompleto';
	}

	isFormPristine() {

		return this.formField.stepStatusPropostaGenerate === 'Não Iniciado';
	}

	clearParentMessages() {

		this.$scope.$emit('clearMessages');
	}

	listenValidationRequest() {
		// console.log('listenValidationRequest');

		this.$scope.$on('stepValidate', () => {
			// console.log('listened stepValidate');

			this.$scope.$emit('stepValidateResponse', this.formValidation());
		});
	}

	listenRequiredRequest() {
		// console.log('listenRequiredRequest');

		this.$scope.$on('stepRequired', () => {
			// console.log('listened stepValidate');

			this.$scope.$emit('stepRequiredResponse', this.formRequired());
		});
	}

	isPropostaDuplaGarantia() {
		const propostaType = sessionStorage.getItem("tipo_lista");
		if (propostaType == "D") {
			return true;
		} else {
			return false;
		}
	}

	calculoValorTotalProposta() {

		let somaTotal1 = 0;
		let somaTotal2 = 0;
		let somaTotal3 = 0;

		if (this.formField.title1 != null && this.formField.title1.titleSQuantity != null && this.formField.title1.value != null) {
			const valTitleTotal1 = this.formField.title1.titleSQuantity * this.formField.title1.value;
			somaTotal1 += isNaN(valTitleTotal1) ? 0 : valTitleTotal1;
		}
		if (this.formField.title2 != null && this.formField.title2.titleSQuantity != null && this.formField.title2.value != null) {
			const valTitleTotal2 = this.formField.title2.titleSQuantity * this.formField.title2.value;
			somaTotal2 += isNaN(valTitleTotal2) ? 0 : valTitleTotal2;
		}
		if (this.formField.title3 != null && this.formField.title3.titleSQuantity != null && this.formField.title3.value != null) {
			const valTitleTotal3 = this.formField.title3.titleSQuantity * this.formField.title3.value;
			somaTotal3 += isNaN(valTitleTotal3) ? 0 : valTitleTotal3;
		}

		this.amountProposta = (somaTotal1 + somaTotal2 + somaTotal3);
		console.log(this.amountProposta);
	}

	isLocatorEmailValid() {
		if (this.formField.locator != null) {
			if (this.formField.locator.email == null || this.formField.locator.email == '') {
				console.log('Entrou valid locator');
				return false;
			} else {
				return true;
			}
		}
	}

	isEstateAdminEmailValid() {
		if (this.formField.estateAdmin != null) {
			if (this.formField.estateAdmin.email == null || this.formField.estateAdmin.email == '') {
				console.log('Entrou valid admin');
				return false;
			} else {
				return true;
			}
		}
	}

	isAccountEmailValid() {
		if (this.formField.account != null) {
			console.log('Entrou valid account');
			if (this.formField.account.email == null || this.formField.account.email == '') {
				return false;
			} else {
				return true;
			}
		}
	}

	isRetirementTypeValid() {
		if (this.formField.resourceOrigin == 'Aposentadoria' && (this.formField.retirementType == '' || this.formField.retirementType == null)) {
			console.log('Entrou no valid retirement');
			return false;
		} else {
			return true;
		}
	}

	formRequired() {

		let requiredFieldsOk = true;

		this.formControl.resetErrorHandlerObject(this.required);

		const requiredFields = [
			'account',
			'profession',
			'declarationCode',
			'product',
			'susepRegistry',
			'corretora',
			'rentValue',
			'titlesCharges',
			'titleMultiplier',
			'title1',
			'locator',
			'clientCep',
			'clientStreetAddress',
			'clientStreetNumber',
			'clientDistrict',
			'clientCity',
			'clientUf',
			'estatePurpose',
			'estateType',
			'paymentType',
			'resourceOrigin'
		];

		if (this.formField.estateAdminIsNotLocator) {
			// console.log('include', 'estateAdmin', this.formField.estateAdminIsNotLocator);

			requiredFields.push('estateAdmin');
		}

		/*
		//Bloqueio por campos bloqueados de Dupla Garantia
		if (this.isPropostaDuplaGarantia()) {
			if (this.formField.estateAdminIsNotLocator) {
				requiredFields.push('estateAdminEmail')
			}
			if (this.formField.resourceOrigin == 'Aposentadoria') {
				requiredFields.push('retirementType');
			}
			if (this.formField.locatorIsNotClient) {
				requiredFields.push('locatorEmail');
			}
			if (this.formField.account != null) {
				requiredFields.push('accountEmail');
			}
		}*/

		if (this.formField.account.isPersonAccount && this.formField.resourceOrigin == 'Outros') {
			requiredFields.push('resourceOriginOutros');
		}

		if (this.formField.paymentType === 'Débito em Conta Corrente') {
			requiredFields.push('bankDebit');
			requiredFields.push('agencyDebit');
			//requiredFields.push('agencyDVDebit');
			requiredFields.push('accountNumberDebit');
			requiredFields.push('accountNumberDVDebit');
			requiredFields.push('bestDayDebit');
			requiredFields.push('checkAuthorizationOwnerDebit');
			requiredFields.push('checkAuthorizationDebit');
			requiredFields.push('accountOwnerNameDebit');
			requiredFields.push('accountOwnerCpfCnpjDebit');
			requiredFields.push('checkAuthorizationDebit');
		}

		if (this.formField.paymentType === 'CartaoCredito') {
			requiredFields.push('parcelaSelecionada');

			if (this.formField.showDocumentoProprietarioCartao) {
				requiredFields.push('cpfTitularCartao');
				requiredFields.push('nomeTitularCartao');
			}
		}

		if (this.formField.locatorIsNotClient) {
			// console.log('include', 'locatorName', this.formField.locatorIsNotClient);

			requiredFields.push('locatorName');
			requiredFields.push('locatorCpfCnpj');
		}

		if (this.formField.declarationCode === 'Valor informado pelo cliente') {
			// console.log('include', 'incomeValue', this.formField.declarationCode);

			requiredFields.push('incomeValue');
		}

		if (this.formControl.hasProperty('account') && this.formField.account.isPEP) {
			// console.log('include', 'resourceOrigin', this.hasProperty('account'), this.formField.account.isPEP);

			requiredFields.push('resourceOrigin');
		}

		for (let i = requiredFields.length - 1; i >= 0; i--) {

			if (!this.formControl.requireProperty(requiredFields[i], this.required)) {

				requiredFieldsOk = false;
			}
		}

		if (requiredFieldsOk) {

			this.formField.stepStatusPropostaGenerate = 'Concluido';
		} else {
			this.formField.stepStatusPropostaGenerate = 'Incompleto';
		}

		return requiredFieldsOk;
	}

	formValidation() {

		return true;
	}
}

module.exports = propostaGenerateController;
