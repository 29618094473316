'use strict';

class resgateNewController {

	// @ngInject
	constructor(resgateFormService, propostaNewApi, autoCompleteService, $scope, $state, sobjectQueryService, resgateService, resgateNewService, $timeout) {
		this.resgateFormService = resgateFormService;
		this.$timeout = $timeout;
		this.$state = $state;
		this.sobjectQueryService = sobjectQueryService;
		this.resgateService = resgateService;
		this.resgateNewService = resgateNewService;
		this.autoComplete = autoCompleteService;
		this.$scope = $scope;
		this.propostaNewApi = propostaNewApi;
		this.getFormFieldStatus();
		this.formField.decisionType = (this.formField.decisionType === null) ? 'Resgate' : this.formField.decisionType;
		this.formField.status = (this.formField.status === null) ? 'Em andamento' : this.formField.status;

		this.formField.isCartaoCreditoInferior31 = false;
		this.formField.formaPagamento = 'Não Informado';

		if (this.resgateService.getIsEdit() === true) {
			this.propostas = [];
			this.propostas.push(this.formField.information.proposta.value);
		} else {
			this.propostas = (this.formField.information.proposta.value !== null) ? this.resgateNewService.getPropostas() : [];
			console.log('--- desabilitando save readonly')
			this.resgateService.setSaveDisabled(true); //CAP-2706			
		}

		this.resgateService.popUpCaseNumber = '';

		this.checkInicialState();
		this.$scope.breadcrumb = this.$scope.$parent.breadcrumb;
		this.configureBreadCrumb();
		this.listenPopupDocumentation();
		console.log('iniciou controller NEw', this.$scope.$parent);

	}

	checkInicialState() {
		if (!this.formField.information.proposta.value) {
			if (!!this.formField.information.mainAccount.value) {
				this.getPropostasByAccountId(this.formField.information.mainAccount.value.Id);
			}
		}

		//cpf populado?
		//proposta po
		//titulo selectionado
		// resgateNew.resgateService.setSaveShow(true);
		// resgateNew.resgateService.setSaveDisabled(false);
		// resgateNew.resgateService.setCancelShow(true);
		// resgateNew.resgateService.setCancelDisabled(false);
		if (!!this.formField.information.mainAccount.value) {
			this.resgateService.setSaveShow(true);
			this.resgateService.setSaveDisabled(false);
			this.resgateService.setNextShow(true);
			this.resgateService.setNextDisabled(false);
		}
		// resgateNew.getPropostasByAccountId(resgateNew.formField.information.mainAccount.value.Id);
		//resgateNew.resgateFormService.setFormField(resgateNew.formField);

	}

	listenPopupDocumentation() {
		this.$scope.$on('clickPopupDocumentation', (event, value) => {
			if (value === "S") {
				this.resgateFormService.setPopupStategoControl('resgate.new', 'case.documentacao-proposta.detail');
				this.$state.go('case.documentacao-proposta.detail', { id: this.formField.caseDocumentId });
			}
			/*else {
				this.formField.information.proposta.value = null;
				this.changePropostaField();
			}*/
			$('#alertDocumentacaoAnexosModal').modal('hide');
		});
	}

	configureBreadCrumb() {
		this.$scope.breadcrumb.reset();
		this.$scope.breadcrumb.addMenuItem('Home', '/caponline/secured#!/home', true, false);
		this.$scope.breadcrumb.addMenuItem('Solicitação', '/caponline/secured#!/solicitacoes/lista', true, false);
		this.$scope.breadcrumb.addMenuItem('Novo Resgate/Cancelamento', '/caponline/secured#!/resgate/novo', false, true);
	}

	getFormFieldStatus() {
		console.log('entrou no getFormFieldStatus');
		this.formField = this.resgateFormService.getFormField();
	}

	searchAccount(searchString) {
		console.log('entrou no searchAccount');
		const resgateNew = this.$parent.resgateNew;
		return resgateNew.resgateNewService.searchAccount(searchString);
	}

	getPropostasByAccountId(accountId) {
		this.resgateService.setSaveDisabled(true); //CAP-2706
		this.formField.decisionType = null; //CAP-2706				
		console.log('entrou no getPropostasBuAccountId');
		this.propostas = [];
		this.resgateNewService.setPropostas(this.propostas);
		console.log('--> TOTAL DE PROPOSTAS: ', this.propostas);
		this.sobjectQueryService.getPropostasByAccountId(accountId).then((response) => {

			console.log('RETORNOU DA BUSCA DE PRODPOTA');
			console.log('retornou:', response);
			this.formField.information.mainAccount.loading = false;
			if (response.isSuccessful) {
				if (response.result.length > 0) {
					this.propostas = response.result;
					this.resgateNewService.setPropostas(response.result);
				} else {
					this.resgateService.setErrorMessage('Cliente não possui nenhuma proposta apta para a solicitação');
				}
			} else {
				console.log('error ', response);
			}
		});
	}

	searchAccountSelect(item) {
		console.log('entrou no searchAccountSelect');
		const resgateNew = this.$parent.resgateNew;
		if (resgateNew.formField.information.mainAccount.value === null) {
			if (!item) { return; }

			const hasAction = !!item.originalObject.action;

			if (hasAction) {

				const action = item.originalObject.action;

				resgateNew[action](item);

				return;
			}

			resgateNew.formField.information.mainAccount.value = item.originalObject;
			resgateNew.formField.information.mainAccount.hasError = !resgateNew.hasAccount();
			resgateNew.resgateService.setSaveShow(true);
			resgateNew.resgateService.setSaveDisabled(true); //CAP-2706
			resgateNew.resgateService.setCancelShow(true);
			resgateNew.resgateService.setCancelDisabled(false);
			resgateNew.resgateService.setNextShow(true);
			resgateNew.resgateService.setNextDisabled(false);
			resgateNew.formField.information.mainAccount.loading = true;
			resgateNew.getPropostasByAccountId(resgateNew.formField.information.mainAccount.value.Id);
			resgateNew.resgateFormService.setFormField(resgateNew.formField);
		}
	}

	hasAccount() {
		return !!this.formField.information.mainAccount.value;
	}

	hasProposta() {
		return !!this.formField.information.proposta.value;
	}

	searchAccountFocusOut() {
		console.log('entrou no searchAccountFocusOut');
		this.$timeout(() => {
			if (!this.autoComplete.hasValidInput(this.formField.information.mainAccount.value)) {
				this.resgateService.setSaveShow(true);
				this.resgateService.setSaveDisabled(true);
				this.resgateService.setCancelShow(true);
				this.resgateService.setCancelDisabled(true);
				this.resgateService.setNextShow(true);
				this.resgateService.setNextDisabled(true);
				this.autoComplete.clearInput(this.$scope, 'searchAccount');
				this.resgateFormService.setFormField(this.formField);
			}
		}, 100);
	}

	searchAccountChanged() {
		const resgateNew = this.$parent.resgateNew; //CAP-2706
		console.log('entrou no searchAccountChanged');
		resgateNew.resgateService.cleanMessages();
		resgateNew.autoComplete.resetSelected(resgateNew.formField.information.mainAccount, 'value');
		resgateNew.propostas = [];
		resgateNew.resgateNewService.setPropostas(resgateNew.propostas);
		console.log('reset');
		resgateNew.resetForm();
		resgateNew.resgateFormService.resetFormType();
		resgateNew.resgateFormService.resetFormData();
		resgateNew.resgateFormService.resetFormBank();
		resgateNew.resgateFormService.resetFormFile();

		resgateNew.resgateService.setSaveDisabled(true); //CAP-2706
		resgateNew.formField.decisionType = null; //CAP-2706		
	}

	resetForm() {
		console.log('entrou no resetForm');
		this.formField.information.garantias.value = null;
		this.formField.information.garantias.hasError = false;
		this.formField.information.legalRepresentative.show = false;
		this.formField.information.legalRepresentative.value = null;
		this.resgateFormService.setFormField(this.formField);
	}

	changePropostaField() {
		console.log('entrou no changepropostaField');
		if (this.formField.information.mainAccount.value != null) {
			this.resetForm();
			this.resgateFormService.resetFormType();
			this.resgateFormService.resetFormData();
			this.resgateFormService.resetFormBank();
			this.resgateFormService.resetFormFile();
			this.resgateService.cleanMessages();
			if (this.formField.information.proposta.value !== null)
				this.getGarantiasByPropostaId(this.formField.information.proposta.value.objectId);
			this.resgateFormService.setFormField(this.formField);
		}

		//this.validateMandatoryFields();
	}

	getGarantiasByPropostaId(propostaId) {

		console.log('entrou no getGarantiasByPropostaId');

		this.formField.information.proposta.loading = true;

		this.resgateService.setSaveDisabled(true); //CAP-2706
		this.formField.decisionType = null; //CAP-2706
		//CAP-2614
		const cpfcnpj = this.formField.information.mainAccount.value.cpf;

		console.log(this.formField);

		this.sobjectQueryService.getGarantiasByPropostaId(propostaId, 'resgate', cpfcnpj).then((response) => {
			//FIM CAP-2614
			this.formField.information.proposta.loading = false;
			if (response.isSuccessful) {

				console.log('##############');
				console.log(response);
				console.log('##############');

				const statusOpenPopup = ['aguardando documentação', 'reprovado'];

				//Se a documentação da proposta não tiver sido aprovada ainda, exibe mensagem sobre documentação
				if ((response.propostaDocument) && (statusOpenPopup.indexOf(response.propostaDocument.status.toLowerCase()) >= 0)) {
					this.formField.caseDocumentId = response.propostaDocument.id;
					this.resgateService.popUpCaseNumber = response.propostaDocument.name;
					$('#alertDocumentacaoAnexosModal').modal({ backdrop: 'static', keyboard: false });
				}

				if (response.result.length > 0) {
					this.resgateService.cleanMessages();
					this.formField.information.garantias.value = [];
					for (let i = 0; i < response.result.length; i++) {

						//bsucando o valor do resgate de cada titulo
						console.log('--> buscando o valor de resgate do titulo: ', response.result[i].objectId)

						this.formField.information.garantias.value.push({
							objectId: response.result[i].objectId,
							objectName: response.result[i].objectName,
							vigencia: response.result[i].vigencia,
							finalVigencia: response.result[i].finalVigencia,
							selected: false,
							disabled: false,
							value: response.result[i].value,
							antecipatedValue: response.result[i].antecipatedValue,
							valorResgate: response.result[i].value,
							vigenciaDiasCorridos: response.result[i].vigenciaDiasCorridos,
							cartaoCredito: response.result[i].cartaoCredito,
							formaPagamento: response.result[i].formaPagamento,
							numeroParcelas: response.result[i].numeroParcelas,
							valorCartaoParcelamento: response.result[i].valorCartaoParcelamento,
							valorCobrancaCartao: response.result[i].valorCobrancaCartao
						});

						console.log('--> objeto garantia: ', response.result[i].objectId)

						/* TODO: Descomentar este bloco para usar o valor do resgate

						this.resgateService.getResgateValue(response.result[i].objectId).then((responseResgate) => {

							console.log('--> chamada ao sevico de resgate retornada: ', responseResgate);

							if (responseResgate.isSuccessful) {

								this.formField.information.garantias.value.push({
									objectId: response.result[i].objectId,
									objectName: response.result[i].objectNameEcho.replace('__RESGATE_TOKEN__', responseResgate.valorResgate),
									vigencia: response.result[i].vigencia,
									finalVigencia: response.result[i].finalVigencia,
									selected: false,
									disabled: false,
									value: response.result[i].value,
									antecipatedValue: response.result[i].antecipatedValue,
									valorResgate: responseResgate.valorResgate
								});

							} else {
								this.resgateService.setErrorMessage(`Não foi possivel obter o valor de resgate do titulo: ${response.result[i].objectName}`);
							}
						});

						*/
					}
				} else {
					this.resgateService.setWarningMessage('Um ou mais títulos relacionados à proposta selecionada já possui uma solicitação em andamento. Realize a busca da proposta ou título(s), utilizando a barra de pesquisa, e verifique o número da(s) solicitação(ões) atrelada(s) para visualizar seu status e possibilidade de dar continuidade à solicitação ou realizar o cancelamento.');
				}
			} else {
				this.resgateService.setErrorMessage(response.errorMessages[0]);
			}

			//INIIO CAP-2706-----------------------------------------------------------

			console.log('----> this.formField.information.garantias.value: ', this.formField.information.garantias.value)
			if (this.formField.information.garantias.value) {
				if (this.formField.information.garantias.value.length > 0) {
					if (this.formField.information.garantias.value[0].vigencia) {
						//vigencia: Campo formula do Salesforce: FRM_DIASVIGENCIA__c (TODAY() - DATEVALUE(DAT_INICIO_VIGENCIA__c)
						if (this.formField.information.garantias.value[0].vigencia <= 30) {
							this.formField.decisionType = 'cancelamento';
						} else {
							this.formField.decisionType = 'resgate';
						}
						this.resgateService.setSaveDisabled(false);
					}
				}
			}
			//FIm CAP-2706 ------------------------------------------------------------- 

		});
	}

	changeCheckGarantia(garantiaId, check) {
		this.resgateFormService.resetFormType();
		this.resgateFormService.resetFormData();
		this.resgateFormService.resetFormBank();
		this.resgateFormService.resetFormFile();
		let type = '';
		for (let i = 0; i < this.formField.information.garantias.value.length; i++) {
			if (garantiaId === this.formField.information.garantias.value[i].objectId) {

				console.log('match');

				if (this.formField.information.garantias.value[i].objectName.includes('(Descaucionado)')) {
					type = 'Descaucionado';
				} else {
					type = 'Caucionado';
				}

				console.log('cartao de credito ? ', this.formField.information.garantias.value[i].cartaoCredito);
				console.log('dias passados vigencia ? ', this.formField.information.garantias.value[i].vigenciaDiasCorridos);

				if (this.formField.information.garantias.value[i].cartaoCredito && this.formField.information.garantias.value[i].vigenciaDiasCorridos < 31) {
					this.formField.isCartaoCreditoInferior31 = true;
				} else {
					this.formField.isCartaoCreditoInferior31 = false;
				}

				console.log('---> isCartaoCreditoInferior31: ', this.formField.isCartaoCreditoInferior31);
				console.log('---> cartaoCredito: ', this.formField.information.garantias.value[i].cartaoCredito);
				console.log('---> formaPagamento: ', this.formField.information.garantias.value[i].formaPagamento);
				console.log('---> numeroParcelas: ', this.formField.information.garantias.value[i].numeroParcelas);

				this.formField.cartaoCredito = this.formField.information.garantias.value[i].cartaoCredito;
				this.formField.formaPagamento = this.formField.information.garantias.value[i].formaPagamento;
				this.formField.numeroParcelas = this.formField.information.garantias.value[i].numeroParcelas;
				this.formField.valorCartaoParcelamento = this.formField.information.garantias.value[i].valorCartaoParcelamento;
				this.formField.valorCobrancaCartao = this.formField.information.garantias.value[i].valorCobrancaCartao;

				break;
			}
		}
		if (check) {
			if (type === 'Caucionado') {
				for (let i = 0; i < this.formField.information.garantias.value.length; i++) {
					if (this.formField.information.garantias.value[i].objectName.includes('(Descaucionado)')) {
						this.formField.information.garantias.value[i].disabled = true;
					} else {
						this.formField.information.garantias.value[i].disabled = false;
					}
				}
			} else {
				for (let i = 0; i < this.formField.information.garantias.value.length; i++) {
					if (this.formField.information.garantias.value[i].objectName.includes('(Caucionado)')) {
						this.formField.information.garantias.value[i].disabled = true;
					} else {
						this.formField.information.garantias.value[i].disabled = false;
					}
				}
			}
		} else {
			if (type === 'Caucionado') {
				let founded = false;
				for (let i = 0; i < this.formField.information.garantias.value.length; i++) {
					if (this.formField.information.garantias.value[i].objectName.includes('(Caucionado)')) {
						if (this.formField.information.garantias.value[i].selected) {
							founded = true;
							break;
						}
					}
				}
				if (!founded) {
					for (let i = 0; i < this.formField.information.garantias.value.length; i++) {
						this.formField.information.garantias.value[i].disabled = false;
					}
				}
			} else {
				let founded = false;
				for (let i = 0; i < this.formField.information.garantias.value.length; i++) {
					if (this.formField.information.garantias.value[i].objectName.includes('(Descaucionado)')) {
						if (this.formField.information.garantias.value[i].selected) {
							founded = true;
							break;
						}
					}
				}
				if (!founded) {
					for (let i = 0; i < this.formField.information.garantias.value.length; i++) {
						this.formField.information.garantias.value[i].disabled = false;
					}
				}
			}
		}

		this.formField.information.legalRepresentative.show = this.hasTituloCaucionadoSelected();
		this.resgateFormService.setFormField(this.formField);

		//this.validateMandatoryFields();
	}

	hasTituloCaucionadoSelected() {
		let founded = false;
		if (this.formField.information.garantias.value != null) {
			for (let i = 0; i < this.formField.information.garantias.value.length; i++) {
				if (this.formField.information.garantias.value[i].selected && this.formField.information.garantias.value[i].objectName.includes('(Caucionado)')) {
					founded = true;
					break;
				}
			}
		}
		return founded;
	}

	hasRequiredLegalRepresentative() {
		let hasRequirement = true;
		if (this.hasTituloCaucionadoSelected()) {
			if (this.formField.information.legalRepresentative.value === null) {
				hasRequirement = false;
			}
		}
		this.formField.information.legalRepresentative.hasError = !hasRequirement;
		return hasRequirement;
	}

	changeLegalRepresentative() {
		this.resgateFormService.resetFormFile();
		this.resgateFormService.setFormField(this.formField);
		//this.validateMandatoryFields();
	}

}

module.exports = resgateNewController;
