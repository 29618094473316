"use strict";

import VisualForceApi from "../../core/sfdc/visual-force-api.js";

class propostaBoletoService extends VisualForceApi {
	/* @ngInject */
	constructor($q) {
		super();
		this.$q = $q;
	}

	getBillet(propostaId) {
		const cap_access_token = localStorage.getItem("cap_access_token");

		return super.invoke(
			"CapCommunityPropostaControllerExt.solGerarBoleto",
			propostaId,
			cap_access_token
		);
	}

	getPropostaDocument(propostaId, proposalType) {
		const cap_access_token = localStorage.getItem("cap_access_token");

		return super.invoke(
			"CapCommunityPropostaControllerExt.getPropostaForm",
			propostaId,
			proposalType,
			cap_access_token
		);
	}

	getURLPagamentoCredito(propostaId) {
		const cap_access_token = localStorage.getItem("cap_access_token");

		return super.invoke(
			"CapCommunityPropostaControllerExt.solGerarURLPagamentoCredito",
			propostaId,
			cap_access_token
		);
	}

	enviarURLPagamentoCreditoEmail(objEmailCartao) {
		const cap_access_token = localStorage.getItem("cap_access_token");
		objEmailCartao.accessToken = cap_access_token;

		return super.invoke(
			"CapCommunityPropostaControllerExt.enviarURLPagamentoCreditoEmail",
			objEmailCartao
		);
	}

	getEmailDestinatarioCartaoCredito(propostaId) {
		const cap_access_token = localStorage.getItem("cap_access_token");

		return super.invoke(
			"CapCommunityPropostaControllerExt.getEmailDestinatarioCartaoCredito",
			propostaId,
			cap_access_token
		);
	}

	startSignDocuments(propostaId) {
		const payload = {
			idProposta: propostaId
		};
		return super.invoke(
			"CapCommunityPropostaControllerExt.signDocuments",
			payload
		);
	}
}

module.exports = propostaBoletoService;
