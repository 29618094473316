'use strict';

class sorteioDetailController {

	// @ngInject
	constructor($stateParams, $state, sfConfig, sorteioDetailApi, sorteioFormControlService, feedbackMessages, $scope, breadcrumbHelper) {
		this.messages = feedbackMessages;
		this.formField = sorteioFormControlService;
		this.api = sorteioDetailApi;
		this.$stateParams = $stateParams;
		this.$state = $state;
		this.$scope = $scope;
		this.showLoading = true;
		this.case = {
			details: null,
			related: {
				propostas: null,
				rentGarantee: null,
				contacts: null,
				attachments: null,
				history: null
			}
		}

		this.errorMessage = '';
		this.getCaseDetails();
		this.$scope.breadcrumb = breadcrumbHelper;
		this.configureBreadCrumb();
		//this.alteracaoDadosService.setWarningMessage('');
	}

	configureBreadCrumb() {
		this.$scope.breadcrumb.reset();
		this.$scope.breadcrumb.addMenuItem('Home', '/caponline/secured#!/home', true, false);
		this.$scope.breadcrumb.addMenuItem('Solicitação', '/caponline/secured#!/solicitacoes/lista', true, false);
		this.$scope.breadcrumb.addMenuItem('Detalhes do Pagamento de Sorteio', '#', false, true);
	}

	hasValidUrlParams() {
		return (this.$stateParams && this.$stateParams.id) ? true : false;
	}

	getCaseDetails() {
		if (!this.hasValidUrlParams()) {
			this.showLoading = false;
			this.$state.go('case.list');
		}
		this.$scope.$emit('changeMainShowLoading', true);
		this.api.getDetails(this.$stateParams.id).then((response) => {
			this.$scope.$emit('changeMainShowLoading', false);
			// console.log(response);
			if (!response.isSuccessful) {
				this.errorMessage = response.errorMessages[0];
			} else {
				this.case.details = response.data;
				this.case.related.propostas = response.data.propostas;
				this.case.related.rentGarantee = response.data.titulos;
				this.case.related.attachments = response.data.anexos;
				this.case.related.history = response.data.history;
				this.case.editCaseData = this.case.editCaseData = this.decodeAndParseJson(response.data.editCaseData);
				this.case.editCaseData.id = this.$stateParams.id;
				this.case.editCaseData.status = response.data.status;

				console.log('RESPONSE EDITCASE DATA ==>', response.data.editCaseData);
				console.log("GET DETAIL CASE: ", this.case);
			}
			this.showLoading = false;
		});
	}

	clienteTitular() {
		return (this.case.editCaseData.bank.fields.clienteTitular.value === 'true') ? 'Sim' : 'Não';
	}

	editButtonHandler() {
		console.log('editButtonHandler()', this.case.editCaseData);

		this.formField.setPristine();

		this.formField.status = this.case.editCaseData.status;
		this.formField.formStep = this.case.editCaseData.formStep;
		this.formField.id = this.case.editCaseData.id;
		//angular.merge(this.formField.annex, this.formField.annex, this.case.editCaseData.annex);
		this.formField.annex = this.case.editCaseData.annex;
		this.setGambiFiles(this.formField.annex);
		angular.merge(this.formField.information, this.formField.information, this.case.editCaseData.information);
		angular.merge(this.formField.bank, this.formField.bank, this.case.editCaseData.bank);
		this.formField.edit = true;

		this.formField.activateTab('information');
		this.$state.go('case.sorteio.new');
	}

	decodeAndParseJson(json) {
		console.log('decodeAndParseJson():', json);

		const x = document.createElement('div');

		x.innerHTML = json;

		return JSON.parse(x.innerHTML);
	}

	openCorrectForm() {
		const formId = this.case.editCaseData.id;

		window.open(`apex/CapOnlineCaseTermoUsoImgVozPDFPage?id=${formId}`, '_blank');
		window.open(`apex/CapOnlineCaseSorteioFormPage?id=${formId}`, '_blank');
	}

	//@todo gambiarra
	setGambiFiles(annexes) {
		annexes.form.gambiFiles = annexes.form.files;
		annexes.auth.gambiFiles = annexes.auth.files;
		annexes.subscritor.identityRenter.gambiFiles = annexes.subscritor.identityRenter.files;
		annexes.subscritor.cpf.gambiFiles = annexes.subscritor.cpf.files;
		annexes.subscritor.adressCheck.gambiFiles = annexes.subscritor.adressCheck.files;
		annexes.subscritor.otherPf.gambiFiles = annexes.subscritor.otherPf.files;
		annexes.subscritor.cnpj.gambiFiles = annexes.subscritor.cnpj.files;
		annexes.subscritor.constitutionIdentity.gambiFiles = annexes.subscritor.constitutionIdentity.files;
		annexes.subscritor.otherPj.gambiFiles = annexes.subscritor.otherPj.files;
	}

}

module.exports = sorteioDetailController;
