'use strict';

class autocompleteApiService {

	/* @ngInject */
	constructor($q, visualForceApi) {

		this.visualForceApi = visualForceApi;
		this.$q = $q;
	}

	search(searchKeyWord) {

		console.log('search: ', searchKeyWord);

		const deferred = this.$q.defer();

		this.visualForceApi.invoke(
				'CapCommunitySearchObjectControllerExt.globalSearch', searchKeyWord)
			.then((response) => {

				deferred.resolve(response);
			});

		return deferred.promise;
	}
}

module.exports = autocompleteApiService;
