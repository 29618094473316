'use strict';

/* @ngInject */
function routesConfiguration($stateProvider, sfConfig) {

	$stateProvider.state('garantia-aluguel', {

		abstract: true,
		url: '/garantia-de-aluguel',
		views: {

			'content': {

				templateUrl: `${sfConfig.resource}/views/rent-guarantee/rent-guarantee.html`,
				controller: 'rentGuaranteeController',
				controllerAs: 'rentGuarantee'
			}
		}
	});

	$stateProvider.state('garantia-aluguel.list', {

		url: '/lista',
		views: {

			'garantia-aluguel': {

				templateUrl: `${sfConfig.resource}/views/rent-guarantee/rent-guarantee-list/rent-guarantee-list.html`,
				controller: 'rentGuaranteeListController',
				controllerAs: 'rentGuaranteeList'
			}
		}
	});

	$stateProvider.state('garantia-aluguel.details', {

		url: '/detalhes?:id',
		views: {

			'garantia-aluguel': {

				templateUrl: `${sfConfig.resource}/views/rent-guarantee/rent-guarantee-details/rent-guarantee-details.html`,
				controller: 'rentGuaranteeDetailsController',
				controllerAs: 'rentGuaranteeDetails'
			}
		}

	});
};

module.exports = routesConfiguration;
