class reaplicacaoTituloService {

	/* @ngInject */
	constructor($q, visualForceApi, $sce, reaplicacaoTituloFormService) {

		this.$q = $q;
		this.visualForceApi = visualForceApi;
		this.reaplicacaoTituloFormService = reaplicacaoTituloFormService;

		this.setPristine();
	}

	setPristine() {

		this.errorMessage = '';
		this.successMessage = '';
		this.warningMessage = '';
		this.isEdit = false;
		this.buttonControl = {
			cancel: {
				show: true,
				disabled: true
			},
			previous: {
				show: false,
				disabled: true
			},
			next: {
				show: true,
				disabled: true
			},
			save: {
				show: true,
				disabled: true
			},
			finish: {
				show: false,
				disabled: true
			}
		};
	}

	reapNoScap() {

		const cap_access_token = localStorage.getItem("cap_access_token");

		const payload = this.createSaveRequest();
		payload.accessToken = cap_access_token;

		return this.visualForceApi.invoke(
			'CapCommunityCaseControllerExt.reaplicacaoScap', payload);
	}

	sendCaseReaplicacaoTitulo() {

		const cap_access_token = localStorage.getItem("cap_access_token");

		const payload = this.createSaveRequest();
		payload.accessToken = cap_access_token;

		return this.visualForceApi.invoke(
			'CapCommunityCaseControllerExt.sendCaseReaplicacaoTitulo', payload);
	}

	createSaveRequest() {
		File.prototype.toJSON = function () {
			return {
				// "index": this.index,
				// "$$hashKey": this.$$hashKey,
				"name": this.name,
				"result": this.result,
				"salesForceId": this.salesForceId
			};
		};
		const request = {
			solicitacao: {
				id: this.reaplicacaoTituloFormService.formField.id,
				accountId: (this.reaplicacaoTituloFormService.formField.information.mainAccount.value !== null) ? this.reaplicacaoTituloFormService.formField.information.mainAccount.value.Id : null,
				propostaId: (this.reaplicacaoTituloFormService.formField.information.proposta.value !== null) ? this.reaplicacaoTituloFormService.formField.information.proposta.value.objectId : null,
				titulos: [],
				//motivobloqueio: this.reaplicacaoTituloFormService.formField.information.motivobloqueio.value,
				autorizacaoreaplicacao: (this.reaplicacaoTituloFormService.formField.information.autorizacaoreaplicacao.value === 'true' || this.reaplicacaoTituloFormService.formField.information.autorizacaoreaplicacao.value == true) ? true : false,
				editCaseData: JSON.stringify(this.reaplicacaoTituloFormService.formField),
				cpfcnpj: (this.reaplicacaoTituloFormService.formField.information.mainAccount.value !== null) ? this.reaplicacaoTituloFormService.formField.information.mainAccount.value.cpf : null
			}
		}

		if (this.reaplicacaoTituloFormService.formField.information.garantias.value !== null) {

			for (let i = 0; i < this.reaplicacaoTituloFormService.formField.information.garantias.value.length; i++) {

				if (this.reaplicacaoTituloFormService.formField.information.garantias.value[i].selected === true) {

					request.solicitacao.titulos.push({
						id: this.reaplicacaoTituloFormService.formField.information.garantias.value[i].objectId,
						value: this.reaplicacaoTituloFormService.formField.information.garantias.value[i].value,
						antecipatedValue: this.reaplicacaoTituloFormService.formField.information.garantias.value[i].antecipatedValue
					});
				}
			}
		}

		console.log('REQUEST: ', request);
		return request;
	}

	cleanMessages() {
		this.setErrorMessage('');
		this.setWarningMessage('');
		this.setSuccessMessage('');
	}

	setWarningMessage(warningMessage) {
		this.warningMessage = warningMessage;
		console.log(warningMessage);
	}

	setErrorMessage(errorMessage) {
		this.errorMessage = errorMessage;
		console.log(errorMessage);
	}

	getErrorMessage() {
		return this.errorMessage;
	}

	getWarningMessage() {
		return this.warningMessage;
	}

	setIsEdit(isEdit) {
		this.isEdit = isEdit;
	}

	getIsEdit() {
		return this.isEdit;
	}

	setSuccessMessage(successMessage) {
		this.successMessage = successMessage;
	}

	getSuccessMessage() {
		return this.successMessage;
	}

	setCancelShow(show) {
		this.buttonControl.cancel.show = show;
	}

	getCancelShow() {
		return this.buttonControl.cancel.show;
	}

	setCancelDisabled(disabled) {
		this.buttonControl.cancel.disabled = disabled;
	}

	getCancelDisabled() {
		return this.buttonControl.cancel.disabled;
	}

	setPreviousShow(show) {
		this.buttonControl.previous.show = show;
	}

	//Botões da Tela 

	getPreviousShow() {
		return this.buttonControl.previous.show;
	}

	setPreviousDisabled(disabled) {
		this.buttonControl.previous.disabled = disabled;
	}

	getPreviousDisabled() {
		return this.buttonControl.previous.disabled;
	}

	setNextShow(show) {
		this.buttonControl.next.show = show;
	}

	getNextShow() {
		return this.buttonControl.next.show;
	}

	setNextDisabled(disabled) {
		this.buttonControl.next.disabled = disabled;
	}

	getNextDisabled() {
		return this.buttonControl.next.disabled;
	}

	setSaveShow(show) {
		this.buttonControl.save.show = show;
	}

	getSaveShow() {
		return this.buttonControl.save.show;
	}

	setSaveDisabled(disabled) {
		this.buttonControl.save.disabled = disabled;
	}

	getSaveDisabled() {
		return this.buttonControl.save.disabled;
	}

	setFinishShow(show) {
		this.buttonControl.finish.show = show;
	}

	getFinishShow() {
		return this.buttonControl.finish.show;
	}

	setFinishDisabled(disabled) {
		this.buttonControl.finish.disabled = disabled;
	}

	getFinishDisabled() {
		return this.buttonControl.finish.disabled;
	}

	setPropostaFromResgate(resgate) {
		this.propostaFromResgate = resgate;
	}

	getPropostaFromResgate() {
		return this.propostaFromResgate;
	}

	setObjectProposta(proposta) {
		this.proposta = proposta;
	}

	getObjectProposta() {
		return this.proposta;
	}

	setResgateAccount(account) {
		this.resgateAccount = account;
	}

	getResgateAccount() {
		return this.resgateAccount;
	}

	isItemSelected(item) {

		return item.selected === true;
	}

	pristineAnnexObj() {
		return {
			files: [],
			hasError: false,
			loading: false,
			show: false,
			uploaded: false,
			uploadHasFailure: false
		};
	}

}

module.exports = reaplicacaoTituloService;
