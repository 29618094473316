'use strict';

class liberacaoDaCaucaoDetailApi {

	/* @ngInject */
	constructor(visualForceApi, $sce) {

		this.visualForceApi = visualForceApi;
	}

	getDetails(resgateId) {

		const cap_access_token = localStorage.getItem("cap_access_token");

		return this.visualForceApi.invoke(
			'CapCommunityCaseControllerExt.getCaseDetails', resgateId, cap_access_token);
	}

}

module.exports = liberacaoDaCaucaoDetailApi;
