'use strict'

class alteracaoDadosSetupAccountService {

	// @ngInject
	constructor() {

		this.setPristine();
	}

	setPristine() {
		this.newLocador = null;
		this.administradorImovel = null;
		this.fromAlteracaoImovel = null;
		this.fromMudancaLocador = null;
		this.fromInclusaoCaucaoLocador = null;
		this.fromInclusaoCaucaoAdministradorImovel = null;
	}

	getNewLocador() {
		return this.newLocador;
	}

	setNewLocador(newLocador) {
		this.newLocador = newLocador;
	}

	getAdministradorImovel() {
		return this.administradorImovel;
	}

	setAdministradorImovel(administradorImovel) {
		this.administradorImovel = administradorImovel;
	}

	getFromAlteracaoImovel() {
		return this.fromAlteracaoImovel;
	}

	setFromAlteracaoImovel(value) {
		this.fromAlteracaoImovel = value;
	}

	getFromMudancaLocador() {
		return this.fromMudancaLocador;
	}

	setFromMudancaLocador(value) {
		return this.fromMudancaLocador = value;
	}

	getFromInclusaoCaucaoLocador() {
		return this.fromInclusaoCaucaoLocador;
	}

	setFromInclusaoCaucaoLocador(value) {
		this.fromInclusaoCaucaoLocador = value;
	}

	getFromInclusaoCaucaoAdministradorImovel() {
		return this.fromInclusaoCaucaoAdministradorImovel;
	}

	setFromInclusaoCaucaoAdministradorImovel(value) {
		this.fromInclusaoCaucaoAdministradorImovel = value;
	}
}

module.exports = alteracaoDadosSetupAccountService;
