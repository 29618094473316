"use strict";

const VisualForceApi = require("../../core/sfdc/visual-force-api");

class administrarUsuarioListApi {
	/* @ngInject */
	constructor($q, visualForceApi) {
		this.$q = $q;
		this.visualForceApi = visualForceApi;
	}

	sendForm(form) {
		console.log("sendForm", form);

		return this.visualForceApi.invoke(
			"CapCommunityUserControllerExt.createUser",
			form
		);
	}

	searchAccount(searchKeyWord) {
		const deferred = this.$q.defer();

		const searchModel = {
			dataFetch: {
				searchKeyWord,
				searchTypeObject: "Account",
				requestType: "SearchPDV"
			}
		};

		const cap_access_token = localStorage.getItem("cap_access_token");
		searchModel.accessToken = cap_access_token;

		this.visualForceApi
			.invoke("CapCommunityPropostaControllerExt.solFetchRecords", searchModel)
			.then((data) => {
				//console.log(data.obtainedRecords);
				if (!data.obtainedRecords) data.obtainedRecords = [];
				if (data.obtainedRecords.length === 0) {
					data.obtainedRecords.push({
						action: "searchAccountFocusOut",
						Name: "Nenhum cadastro encontrado"
					});
				}
				data.obtainedRecords.push({
					action: "modalHandle",
					Name: "+ Criar Cadastro"
				});
				deferred.resolve(data.obtainedRecords);
			});

		return deferred.promise;
	}

	addBtnCreateAccount() {
		return {
			action: 'modalHandle',
			Name: '+ Criar Cadastro'
		};
	}

	initUserPermissions() {
		return {
			ga_cadastro: "rw",
			ga_proposta: "rw",
			ga_garantiaAluguel: "rw",
			ga_resgate: "rw",
			ga_liberacaoCaucao: "rw",
			ga_bloqueioReaplicacao: "rw",
			ga_reaplicacaoTitulo: "rw",
			ga_revisaoComissao: "rw",
			ga_alteracaoDeDados: "rw",
			ga_sorteio: "rw"
		};
	}
}

module.exports = administrarUsuarioListApi;
