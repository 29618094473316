"use strict";

class propostaBoletoController {
	/* @ngInject */
	constructor(
		$scope,
		$window,
		$timeout,
		sfConfig,
		formControlService,
		propostaBoletoService,
		feedbackMessages,
		resgateService
	) {
		this.boletoIcon = `${sfConfig.resource}/images/ico-bol.png`;
		this.formIcon = `${sfConfig.resource}/images/ico-prt.png`;

		this.cartaoIcon = `${sfConfig.resource}/images/ico-cartao-credito.png`;
		this.cartaoEmailIcon = `${sfConfig.resource}/images/ico-cartao-link.png`;
		this.cartaoPrintIcon = `${sfConfig.resource}/images/ico-cartao-print.png`;

		this.formControl = formControlService;
		this.formField = this.formControl.field;
		this.$window = $window;
		this.$scope = $scope;
		this.$timeout = $timeout;
		this.propostaBoletoApi = propostaBoletoService;
		this.messages = feedbackMessages;
		this.resgateService = resgateService;
		this.urlCartao = null;
		this.emailCartaoDestinatario = null;
		this.generateEmailEnviadoMsgSuccess = false;
		this.assinaturaEletronicaSucesso = false;

		this.objEmailCartao = {
			idProposta: null,
			emailCartaoDestinatario: null
		};

		const propostaId = this.formField.Id;

		this.formField.formStep = "proposta.boleto";

		this.billet = null;
		this.billetPrint = `apex/CapOnlinePropostaPDFPage?id=${propostaId}`;

		this.checkStepStatus();
		this.listenValidationRequest();
		this.listenRequiredRequest();
		this.clearParentMessages();
		this.getEmailDestinatarioCartaoCredito();

		console.log("FORM DURANTE BOLETO:", this.formField);

		// this.getBillet();
	}

	hasGenerateBillet() {
		let habilitaBotao = true;
		if (!!this.formControl.actions)
			habilitaBotao = this.formControl.actions.hasGenerateBillet;
		// CAP-2909 Resgate com recuperação de crédito para Proposta não precisa gerar Boleto
		if (
			this.formField.isOrigemResgate ||
			this.resgateService.getPropostaFromResgate()
		)
			habilitaBotao = false;
		// COC-273 Emissão de boleto no Caponline para proposta reaplicada (série 81)
		if (this.formField.numberProposta.indexOf(".81-") > 0)
			habilitaBotao = false;

		return habilitaBotao;
	}

	isPropostaDuplaGarantia() {
		const propostaType = sessionStorage.getItem("tipo_lista");
		if (propostaType == "D") {
			return true;
		} else {
			return false;
		}
	}

	hasGenerateDocument() {
		return !!this.formControl.actions ?
			this.formControl.actions.hasGenerateDocument :
			true;
	}

	hasSentSignDocuments() {
		return !!this.formControl.actions ?
			this.formControl.actions.hasSentSignDocuments :
			true;
	}

	checkStepStatus() {
		if (this.isFormPristine()) {
			this.formField.stepStatusPropostaBoleto === "Incompleto";
		}
	}

	isFormPristine() {
		return this.formField.stepStatusPropostaBoleto === "Não Iniciado";
	}

	listenValidationRequest() {
		// console.log('listenValidationRequest');
		this.$scope.$on("stepValidate", () => {
			// console.log('listened stepValidate');
			this.$scope.$emit("stepValidateResponse", true);
		});
	}

	listenRequiredRequest() {
		// console.log('listenRequiredRequest');
		this.$scope.$on("stepRequired", () => {
			// console.log('listened stepValidate');
			this.$scope.$emit("stepRequiredResponse", true);
		});
	}

	clearParentMessages() {
		this.$scope.$emit("clearMessages");
	}

	getPropostaDocument(proposalType) {
		this.$scope.$emit("changeMainShowLoading", true);
		this.propostaBoletoApi
			.getPropostaDocument(this.formField.Id, proposalType)
			.then((data) => {
				this.$scope.$emit("changeMainShowLoading", false);
				if (!data.isSuccessful) {
					this.$window.scrollTo(0, 0);
					const errorMessage = angular.toJson(data.errorMessages);
					this.messages.showErrorMessage(this.$scope, errorMessage);
					return;
				}
				const propostaDocumentBody = data.body;

				// @todo gambiarra monstrona pra janela ter título
				//const documentWindow = window.open('', '_blank');
				//documentWindow.document.write(`<iframe src="data:application/pdf;base64,${propostaDocumentBody}" frameborder="0" style="overflow:hidden;height:100%;width:100%" height="100%" width="100%"></iframe>`);
				//documentWindow.document.title = 'Proposta';
				this.openSaveBase64Pdf("Proposta", "Proposta", propostaDocumentBody);

				// this.$timeout(() => {

				// 	documentWindow.document.body.style.margin = '0px';
				// 	documentWindow.document.body.style.padding = '0px';
				// 	documentWindow.document.body.style.overflow = 'hidden';
				// }, 100);
			});
	}

	startSignDocuments() {

		this.assinaturaEletronicaSucesso = false;

		this.$scope.$emit("changeMainShowLoading", true);
		this.propostaBoletoApi
			.startSignDocuments(this.formField.Id)
			.then((data) => {
				this.$scope.$emit("changeMainShowLoading", false);
				if (!data.isSuccessful) {
					this.$window.scrollTo(0, 0);
					const errorMessage = angular.toJson(data.errorMessages);
					this.messages.showErrorMessage(this.$scope, errorMessage);
					return;
				} else {
					this.assinaturaEletronicaSucesso = true;
				}
			});
	}

	getBillet() {
		this.$scope.$emit("changeMainShowLoading", true);
		this.propostaBoletoApi.getBillet(this.formField.Id).then((data) => {
			this.$scope.$emit("changeMainShowLoading", false);
			if (!data.isSuccessful) {
				this.$window.scrollTo(0, 0);
				const errorMessage = angular.toJson(data.errorMessages);
				this.messages.showErrorMessage(this.$scope, errorMessage);
				return;
			}
			const billetUrl = data.response;

			// @todo gambiarra monstrona pra janela ter título
			//const billetWindow = window.open('', '_blank');
			//billetWindow.document.write(`<iframe src="data:application/pdf;base64,${billetUrl}" frameborder="0" style="overflow:hidden;height:100%;width:100%" height="100%" width="100%"></iframe>`);
			//billetWindow.document.title = 'Boleto';
			this.openSaveBase64Pdf("Boleto", "Boleto", billetUrl);

			// this.$timeout(() => {

			// 	billetWindow.document.body.style.margin = '0px';
			// 	billetWindow.document.body.style.padding = '0px';
			// 	billetWindow.document.body.style.overflow = 'hidden';
			// }, 100);
		});
	}

	getEmailDestinatarioCartaoCredito() {
		this.propostaBoletoApi
			.getEmailDestinatarioCartaoCredito(this.formField.Id)
			.then((data) => {
				console.log("resultado do envio: ", data);
				this.emailCartaoDestinatario = data.response;
			});
	}

	operarCartaoCreditoEmail() {
		console.log("enviando pagamento de cartao de credito por e-mail");
		this.$scope.$emit("changeMainShowLoading", true);
		console.log("id da proposta: ", this.formField.Id);
		console.log("email destinatario: ", this.emailCartaoDestinatario);

		this.objEmailCartao.idProposta = this.formField.Id;
		this.objEmailCartao.emailCartaoDestinatario = this.emailCartaoDestinatario;

		this.propostaBoletoApi
			.enviarURLPagamentoCreditoEmail(this.objEmailCartao)
			.then((data) => {
				console.log("resultado do envio: ", data);
				this.$scope.$emit("changeMainShowLoading", false);
				if (!data.isSuccessful) {
					this.$window.scrollTo(0, 0);
					const errorMessage = angular.toJson(data.errorMessages);
					this.messages.showErrorMessage(this.$scope, errorMessage);
					return;
				} else {
					this.generateEmailEnviadoMsgSuccess = true;
					this.urlCartao = data.response;
					const botaoCartaoCredito = document.getElementById(
						"cartaoCreditobotaoEmail"
					);
					console.log("botaoCartaoCredito: ", botaoCartaoCredito);
					botaoCartaoCredito.click();
					//window.open(data.response, '_blank').focus();
				}
			});
	}

	operarCartaoCreditoEmailAbrir() {
		console.log("abrindo envio de cartao de crédito online email");
		const botaoCartaoCredito = document.getElementById(
			"cartaoCreditobotaoEmail"
		);
		console.log("botaoCartaoCredito: ", botaoCartaoCredito);
		botaoCartaoCredito.click();
	}

	operarCartaoCreditoOnline() {
		console.log("processando cartao de crédito online");
		this.$scope.$emit("changeMainShowLoading", true);
		console.log("id da proposta: ", this.formField.Id);

		this.propostaBoletoApi
			.getURLPagamentoCredito(this.formField.Id)
			.then((data) => {
				console.log("resultado da busca de url: ", data);
				this.$scope.$emit("changeMainShowLoading", false);
				if (!data.isSuccessful) {
					this.$window.scrollTo(0, 0);
					const errorMessage = angular.toJson(data.errorMessages);
					this.messages.showErrorMessage(this.$scope, errorMessage);
					return;
				} else {
					this.urlCartao = data.response;
					const botaoCartaoCredito =
						document.getElementById("cartaoCreditobotao");
					console.log("botaoCartaoCredito: ", botaoCartaoCredito);
					botaoCartaoCredito.click();
					//window.open(data.response, '_blank').focus();
				}
			});

		this.propostaId;
	}

	hasCartaoPago() {
		console.log("--> hasCartaoPago: this.formField: ", this.formField);
		return false;
	}

	base64ToArrayBuffer(base64) {
		const binaryString = window.atob(base64);
		const binaryLen = binaryString.length;
		const bytes = new Uint8Array(binaryLen);
		for (let i = 0; i < binaryLen; i++) {
			const ascii = binaryString.charCodeAt(i);
			bytes[i] = ascii;
		}
		return bytes;
	}

	saveByteArray(fileName, byte) {
		const blob = new Blob([byte]);
		const link = document.createElement("a");
		link.href = window.URL.createObjectURL(blob);
		link.download = fileName;
		link.click();

		return blob;
	}

	openSaveBase64Pdf(name, reportName, data) {
		const dataPrefix = "data:application/pdf;base64,";
		const fileName = `${reportName}.pdf`;
		data = data.replace(dataPrefix, "");

		const dataArr = this.base64ToArrayBuffer(data);
		const blob = this.saveByteArray(fileName, dataArr);

		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveOrOpenBlob(blob, fileName);
		} else {
			const documentWindow = window.open("", "_blank");
			if (!!documentWindow) {
				documentWindow.document.write(
					`<object id=\"objpdf\" data=\"${dataPrefix}${data}\" type=\"application/pdf\" width=\"100%\" height=\"100%\"></object>`
				);
				documentWindow.document.title = name;
			}
		}
	}
}

module.exports = propostaBoletoController;
