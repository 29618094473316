'use strict';

class profile {

	/* @ngInject */
	constructor(profilePermissions, sfConfig) {

		this.permissions = profilePermissions.get();
		this.accountId = sfConfig.idUSerAccount;
	}
}

module.exports = profile;
