'use strict';

/* @ngInject */
function routesConfiguration($stateProvider, sfConfig) {

	$stateProvider.state('term', {

		abstract: true,
		url: '/termo',
		views: {

			'content': {

				templateUrl: `${sfConfig.resource}/views/termo/base.html`
			}
		}
	});

	$stateProvider.state('term.adhesion', {

		url: '/adesao',
		params: {

			originalRoute: {
				params: null,
				state: null
			}
		},
		views: {

			'term': {

				templateUrl: `${sfConfig.resource}/views/termo/adesao/adhesion.html`,
				controller: 'termoDeAdesaoController',
				controllerAs: 'termoDeAdesao'
			}
		}
	});
};

module.exports = routesConfiguration;
