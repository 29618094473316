"use strict";

class propostaListService {
	/* @ngInject */
	constructor(visualForceApi) {
		this.visualForceApi = visualForceApi;
	}

	getPropostaList(listView) {
		const cap_access_token = localStorage.getItem("cap_access_token");
		listView.accessToken = cap_access_token;

		return this.visualForceApi.invoke(
			"CapCommunityPropostaControllerExt.getPropostaList",
			listView
		);
	}

	getStatusPagamentoCartao(propostaId) {
		//@TODO: request enviando por string, rpecisa modificar para enviar
		const cap_access_token = localStorage.getItem("cap_access_token");
		//listView.accessToken = cap_access_token;

		return this.visualForceApi.invoke(
			"CapCommunityPropostaControllerExt.getStatusPagamentoCartao",
			propostaId,
			cap_access_token
		);
	}

	enviarURLPagamentoCreditoEmail(objEmailCartao) {
		const cap_access_token = localStorage.getItem("cap_access_token");
		objEmailCartao.accessToken = cap_access_token;

		return this.visualForceApi.invoke(
			"CapCommunityPropostaControllerExt.enviarURLPagamentoCreditoEmail",
			objEmailCartao
		);
	}
}

module.exports = propostaListService;
