'use strict';

import autocompleteController from './autocomplete.controller.js';

class autocompleteDirective extends autocompleteController {

	// @ngInject
	constructor() {

		// console.log('autocompleteDirective');

		super();

		this.restrict = 'A'
		this.controller = autocompleteController
		this.controllerAs = 'autocomplete'
		this.template = '<div id="searchMain" angucomplete-alt="" template-url="/views/helper/angucomplete-alt/search-global.html" placeholder="Buscar" maxlength="42" pause="400" remote-api-handler="autocomplete.search" selected-object="autocomplete.searchSelected" focus-out="autocomplete.searchFocusOut()" input-changed="autocomplete.searchChanged" title-field="name" description-field="type" minlength="4" input-class="form-control search-bar" match-class="highlight" text-searching="Buscando..." text-no-results="OPS! Parece que não encontramos nenhum resultado para a sua pesquisa."></div>'
		//this.templateUrl = '/views/main/autocomplete/autocomplete.directive.html'
		this.scope = {}
	}

	link(scope, element, attr, ctr) {

		// console.log('ctr', ctr);
	}

	static directiveFactory() {
		return new autocompleteDirective();
	}

}

module.exports = autocompleteDirective.directiveFactory;
