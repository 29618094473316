'use strict';

class resgateDetailController {

	// @ngInject
	constructor($stateParams, $state, sfConfig, resgateDetailService, resgateBankService, $scope, sobjectQueryService, resgateFormService, resgateService, resgateGetForm, feedbackMessages, breadcrumbHelper) {

		this.$stateParams = $stateParams;
		this.$state = $state;
		this.$scope = $scope;
		this.sobjectQueryService = sobjectQueryService;
		this.messages = feedbackMessages;
		this.resgateDetailService = resgateDetailService;
		this.resgateBankService = resgateBankService;
		this.resgateGetForm = resgateGetForm;
		this.resgateFormService = resgateFormService;
		this.resgateService = resgateService;
		this.$scope.breadcrumb = breadcrumbHelper;

		this.showLoading = true;
		this.case = {
			details: null,
			related: {
				propostas: null,
				rentGarantee: null,
				attachments: null,
				history: null,
				parsedEditCaseData: null
			}
		}
		this.errorMessage = '';
		this.getCaseDetails();
		this.configureBreadCrumb();
	}

	configureBreadCrumb() {
		this.$scope.breadcrumb.reset();
		this.$scope.breadcrumb.addMenuItem('Home', '/caponline/secured#!/home', true, false);
		this.$scope.breadcrumb.addMenuItem('Solicitação', '/caponline/secured#!/solicitacoes/lista', true, false);
		this.$scope.breadcrumb.addMenuItem('Detalhes do Resgate', '#', false, true);
	}

	hasCaseDetails() {

		return !!this.case.details;
	}

	hasValidUrlParams() {
		return (this.$stateParams && this.$stateParams.id) ? true : false;
	}

	getFormFiles() {

		const form = this.parseEditCaseData(this.case.details);
		form.id = this.case.details.id;

		//colocar na propriedade do form indicando que foi clicado o gerar formulario
		form.lastFormAction = 'GerarFormulario';

		this.resgateGetForm.openFiles(form);
	}

	hasEdit() {
		const status = ['em andamento', 'reprovado', 'pagamento criticado', 'aguardando pré-análise', 'em elaboração'];
		const currentStatus = this.case.details ? this.case.details.status.toLowerCase() : null;
		return (status.indexOf(currentStatus) > -1);
	}

	parseEditCaseData() {
		console.log('EditCase: ', this.case.details.editCaseData);
		if (this.case.details.editCaseData === "") {
			return "";
		} else {
			// monstruosidade requisitada pelo Diogo
			const div = document.createElement('div');
			div.innerHTML = this.case.details.editCaseData;
			return JSON.parse(div.innerHTML);
		}
	}

	createEditCaseData() {

		console.clear();

		/* **************************************** */
		/* ****** Information - Primeira ABA ****** */
		/* **************************************** */
		this.formField.information.mainAccount.value = {
			Id: this.case.details.accountId,
			Name: this.case.details.accountName,
			isPersonAccount: this.case.details.isPersonAccount
		};
		this.formField.information.proposta.value = this.case.related.propostas.map((item) => {
			return { objectId: item.propostaId, objectName: item.propostaName }
		})[0];

		this.formField.information.garantias.value = this.case.related.rentGarantee.map((item) => {
			const tituloType = (item.tituloTypeCaucionado ? '(Caucionado)' : '(Descaucionado)');
			return {
				objectId: item.titulosId,
				objectName: `${item.tituloName} / R$ ${item.tituloValue} ${tituloType}`,
				vigencia: item.tituloVigencia,
				caucionado: item.tituloTypeCaucionado,
				value: item.tituloValue,
				selected: true
			};
		});
		this.formField.information.legalRepresentative.show = true;
		this.formField.information.legalRepresentative.value = null;

		/* **************************************** */
		/* ****** Type - Segunda ABA ****** */
		/* **************************************** */
		const caucionado = this.formField.information.garantias.value[0].caucionado;
		const vigencia = (this.case.details.resgateType.toLowerCase() !== "cancelamento"); //(this.formField.information.garantias.value[0].vigencia > 30);
		const debitos = (this.case.details.debitValue !== "R$ 0,00");
		const antecipado = (this.case.details.resgateType.toLowerCase() === "antecipado");

		this.formField.type.caucionado = caucionado;
		this.formField.type.vigencia = vigencia;

		/* MONSTRUOSIDADE FEITA POR PROBLEMAS DE RETORNO PEOPLOE > TOTOS > SCAP */

		this.formField.isHaveProblemBank = this.case.details.isHaveProblemBank;
		console.log(this.formField.isHaveProblemBank);

		/* TIPO DO RESGATE QUE VEM DO APP */
		if (vigencia) {
			this.formField.type.resgateType.value = `Resgate ${this.case.details.resgateType.toLowerCase()}`;
			this.formField.type.resgateType.show = true;
		} else { //Cancelamento
			this.formField.type.resgateType.value = null;
			this.formField.type.resgateType.show = false;
		}
		/* DESEJA UTILIZAR PARTE DO VALOR DO RESGATE PARA QUITAR DÉBITOS RELATIVOS AO IMÓVEL? */
		this.formField.type.realtyDebits.value = debitos.toString();
		this.formField.type.realtyDebits.show = true;
		/* VALOR DO DÉBITO */
		this.formField.type.debitValue.value = (debitos) ? this.parseFloat(this.case.details.debitValue) : null;
		this.formField.type.debitValue.show = debitos;
		/* EXISTE APROVAÇÃO DO LOCATÁRIO PARA O PAGAMENTO DE DÉBITOS? */
		this.formField.type.hasRenterApprove.value = debitos.toString();
		this.formField.type.hasRenterApprove.show = debitos;
		/* DESEJA APROVEITAR PARTE DO VALOR DO RESGATE PARA A AQUISIÇÃO DE OUTRA GARANTIA DE ALUGUEL? */
		this.formField.type.titleAquisition.value = 'false';
		this.formField.type.titleAquisition.show = true;
		/* DIGITE O NÚMERO DA NOVA PROPOSTA */
		this.formField.type.proposta.value = null;
		this.formField.type.proposta.show = false;
		/* O CLIENTE ESTÁ CIENTE DA APLICAÇÃO DO FATOR DE REDUÇÃO */
		this.formField.type.customerAgreement.value = antecipado;
		this.formField.type.customerAgreement.show = antecipado;

		/* **************************************** */
		/* ****** Data - Terceira ABA  ************ */
		/* **************************************** */
		this.formField.data.resgateValue.value = this.parseFloat(this.case.details.resgateValue);
		this.formField.data.resgateValue.show = true;
		this.formField.data.resgateValue.disabled = false;

		this.formField.data.debitValue.value = (debitos) ? this.parseFloat(this.case.details.debitValue) : null;
		this.formField.data.debitValue.show = debitos;
		this.formField.data.debitValue.disabled = false;

		this.formField.data.valueRemain.value = (debitos) ? this.parseFloat(this.case.details.remanescentValue) : null;
		this.formField.data.valueRemain.show = debitos;
		this.formField.data.valueRemain.disabled = false;

		this.formField.data.realtyInformation.cep = this.case.related.propostas[0].propostaCep;
		this.formField.data.realtyInformation.address = this.case.related.propostas[0].propostaAddress;
		this.formField.data.realtyInformation.addressNumber = this.case.related.propostas[0].propostaAddressNumber;
		this.formField.data.realtyInformation.addressComplement = this.case.related.propostas[0].propostaAddressComplement;
		this.formField.data.realtyInformation.neighborhood = this.case.related.propostas[0].propostaAddressNeighborhood;
		this.formField.data.realtyInformation.city = this.case.related.propostas[0].propostaAddressCity;
		this.formField.data.realtyInformation.uf = this.case.related.propostas[0].propostaAddressUf;
		this.formField.data.realtyInformation.renterName = this.case.related.propostas[0].propostaRenterName;

		this.formField.data.representantData.name.value = null; // @ todo
		this.formField.data.representantData.name.show = false;
		this.formField.data.representantData.cpfcnpj.value = null; // @ todo
		this.formField.data.representantData.cpfcnpj.show = false;
		this.formField.data.representantData.show = false;

		this.formField.data.debitDeclaration.debitValue.value = (debitos) ? this.parseFloat(this.case.details.debitValue) : null;
		this.formField.data.debitDeclaration.multaValue.value = null; // @ todo
		this.formField.data.debitDeclaration.othersValue.value = null; // @ todo
		this.formField.data.debitDeclaration.description.value = null; // @ todo
		this.formField.data.debitDeclaration.vencimento.value = null; // @ todo
		this.formField.data.debitDeclaration.competencia.value = null; // @ todo
		this.formField.data.debitDeclaration.debitsSelected.value = []; // @ todo
		this.formField.data.debitDeclaration.debitsSelected.show = false; // @ todo
		this.formField.data.debitDeclaration.totalValue.value = 0; // @ todo
		this.formField.data.debitDeclaration.totalMulta = 0; // @ todo
		this.formField.data.debitDeclaration.totalAcrescimo = 0; // @ todo
		this.formField.data.debitDeclaration.show = (this.case.details.resgateResult == 'Execução da Caução');

		this.formField.data.customerData.name.value = this.case.details.accountName;
		this.formField.data.customerData.cpfcnpj.value = this.case.details.cpfcnpj;
		this.formField.data.customerData.inscription = this.case.details.inscription;
		this.formField.data.customerData.emitter = this.case.details.emitter;
		this.formField.data.customerData.professionalActivity.value = { Name: this.case.details.jobName };
		this.formField.data.customerData.professionalActivity.show = true;
		this.formField.data.customerData.income.value = this.deParaRenda(this.case.details.income);
		this.formField.data.customerData.income.show = true;
		this.formField.data.customerData.phone.value = this.case.details.customerPhone;
		this.formField.data.customerData.phone.show = true;
		this.formField.data.customerData.email.value = this.case.details.customerEmail;
		this.formField.data.customerData.email.show = true;
		this.formField.data.customerData.show = true;

		/* ******************************* */
		/* ****** Bank - Quarta aba ****** */
		/* ******************************* */
		this.formField.bank.bankData.customerOwner.value = "true"; // O resgate pelo app o cliente sempre é o titular da conta
		this.formField.bank.bankData.customerOwner.show = true;
		this.case.details.bankAccountType = this.case.details.bankAccountType.replace('Conta ', '');
		this.formField.bank.bankData.accountType.value = `Conta ${this.case.details.bankAccountType}`;
		this.formField.bank.bankData.accountType.show = true;
		this.formField.bank.bankData.bank.value = this.getBankName(this.case.details.bankBankName);
		this.formField.bank.bankData.bank.show = true;
		this.formField.bank.bankData.agency.value = this.case.details.bankAgency;
		this.formField.bank.bankData.agency.show = true;
		this.formField.bank.bankData.accountNumber.value = this.case.details.bankAccountNumber;
		this.formField.bank.bankData.accountNumber.show = true;
		this.formField.bank.bankData.accountOwnerName.value = this.case.details.bankAccountOwnerName;
		this.formField.bank.bankData.accountOwnerName.show = true;
		this.formField.bank.bankData.cpfcnpj.value = this.case.details.bankCPFCNPJ;
		this.formField.bank.bankData.cpfcnpj.show = true;

		/* ******************* */
		/* ****** Raiz  ****** */
		/* ******************* */
		this.formField.id = this.case.details.id;
		this.formField.status = this.case.details.status;
		this.formField.liberarCaucao = false;
		this.formField.tabResgateInfo = "C";
		this.formField.tabResgateType = "C";
		this.formField.tabResgateData = "C";
		this.formField.tabResgateBank = "C";
		this.formField.tabResgateAttach = "C";
		this.formField.origin = this.case.details.origin;
		this.formField.decisionType = this.getDecisionType();

		console.log(this.formField, this.case.details);

		const request = {
			caseId: this.case.details.id,
			editCaseData: JSON.stringify(this.formField)
		}

		this.resgateDetailService.updateEditCaseData(request);

		return this.formField;
	}

	getDecisionType() {
		let retVal = "";

		// vigencia = false quando <= 30
		if (this.formField.type.caucionado === false && this.formField.type.vigencia === false) {
			if (this.formField.type.titleAquisition.show === true && this.formField.type.titleAquisition.value.toString() === 'false') {
				retVal = 'cancelamento-100-locatario';
			} else if (this.formField.type.titleAquisition.show === true && this.formField.type.titleAquisition.value.toString() === 'true') {
				retVal = 'cancelamento-recuperacao-credito';
			}
		} else if (this.formField.type.caucionado === false && this.formField.type.vigencia === true) {
			if (this.formField.type.resgateType.show === true && this.formField.type.titleAquisition.show === true && this.formField.type.titleAquisition.value.toString() === 'true') {
				retVal = 'descaucionado-recuperacao-credito';
			} else if (this.formField.type.resgateType.show === true && this.formField.type.titleAquisition.show === true && this.formField.type.titleAquisition.value.toString() === 'false') {
				retVal = 'descaucionado-100-locatario';
			}
		} else if (this.formField.type.caucionado === true && this.formField.type.vigencia === false) {
			if (this.formField.type.realtyDebits.show === true && this.formField.type.realtyDebits.value.toString() === 'false' && this.formField.type.titleAquisition.show === true && this.formField.type.titleAquisition.value.toString() === 'true') {
				retVal = 'cancelamento-recuperacao-credito';
			} else if (this.formField.type.realtyDebits.show === true && this.formField.type.realtyDebits.value.toString() === 'true' && this.formField.type.hasRenterApprove.show === true && this.formField.type.hasRenterApprove.value.toString() === 'false') {
				retVal = 'cancelamento-execucao-caucao';
			} else if (this.formField.type.realtyDebits.show === true && this.formField.type.realtyDebits.value.toString() === 'true' && this.formField.type.hasRenterApprove.value.toString() === 'true' && this.formField.type.hasRenterApprove.show === true && this.formField.type.titleAquisition.show === true && this.formField.type.titleAquisition.value.toString() === 'false') {
				retVal = 'cancelamento-divisao-valores';
			} else if (this.formField.type.realtyDebits.show === true && this.formField.type.realtyDebits.value.toString() === 'false' && this.formField.type.titleAquisition.show === true && this.formField.type.titleAquisition.value.toString() === 'false') {
				retVal = 'cancelamento-100-locatario';
			} else if (this.formField.type.realtyDebits.show === true && this.formField.type.realtyDebits.value.toString() === 'true' && this.formField.type.hasRenterApprove.value.toString() === 'true' && this.formField.type.hasRenterApprove.show === true && this.formField.type.titleAquisition.show === true && this.formField.type.titleAquisition.value.toString() === 'true') {
				retVal = 'cancelamento-divisao-valores-recuperacao-credito';
			}
		} else if (this.formField.type.caucionado === true && this.formField.type.vigencia === true) {
			if (this.formField.type.resgateType.show === true && this.formField.type.realtyDebits.show === true && this.formField.type.realtyDebits.value.toString() === 'true' && this.formField.type.hasRenterApprove.show === true && this.formField.type.hasRenterApprove.value.toString() === 'false') {
				retVal = (this.formField.type.resgateType.value === 'Resgate antecipado') ? 'execucao-caucao-resgate-antecipado' : 'execucao-caucao-resgate-final';

			} else if (this.formField.type.resgateType.show === true && this.formField.type.realtyDebits.show === true && this.formField.type.realtyDebits.value.toString() === 'true' && this.formField.type.hasRenterApprove.show === true && this.formField.type.hasRenterApprove.value.toString() === 'true' && this.formField.type.titleAquisition.show === true && this.formField.type.titleAquisition.value.toString() === 'true') {
				retVal = (this.formField.type.resgateType.value === 'Resgate antecipado') ? 'divisao-valores-recuperacao-credito-resgate-antecipado' : 'divisao-valores-recuperacao-credito-resgate-final';

			} else if (this.formField.type.resgateType.show === true && this.formField.type.realtyDebits.show === true && this.formField.type.realtyDebits.value.toString() === 'true' && this.formField.type.hasRenterApprove.show === true && this.formField.type.hasRenterApprove.value.toString() === 'true' && this.formField.type.titleAquisition.show === true && this.formField.type.titleAquisition.value.toString() === 'false') {
				retVal = (this.formField.type.resgateType.value === 'Resgate antecipado') ? 'divisao-de-valores-resgate-antecipado' : 'divisao-de-valores-resgate-final';

			} else if (this.formField.type.resgateType.show === true && this.formField.type.realtyDebits.show === true && this.formField.type.realtyDebits.value.toString() === 'false' && this.formField.type.titleAquisition.show === true && this.formField.type.titleAquisition.value.toString() === 'true') {
				retVal = (this.formField.type.resgateType.value === 'Resgate antecipado') ? 'recuperacao-credito-resgate-antecipado' : 'recuperacao-credito-resgate-final';

			} else if (this.formField.type.resgateType.show === true && this.formField.type.realtyDebits.show === true && this.formField.type.realtyDebits.value.toString() === 'false' && this.formField.type.titleAquisition.show === true && this.formField.type.titleAquisition.value.toString() === 'false') {
				retVal = (this.formField.type.resgateType.value === 'Resgate antecipado') ? '100-locatario-resgate-antecipado' : '100-locatario-resgate-final';
			}
		}

		console.log('getDecisionType', retVal);

		return retVal;
	}

	deParaRenda(valor) {
		const rendaApp = [
			'R$ 1.000,00 a R$ 5.000,00',
			'R$ 5.000,01 a R$ 10.000,00',
			'R$ 10.000,01 a R$ 15.000,00',
			'acima de R$ 15.000,01',
			'Se recusa a informar'
		];
		const rendaBack = [
			'R$ 1.000,00 a R$ 5.000,00',
			'R$ 5.000,01 a R$ 10.000,00',
			'R$ 10.000,01 a R$ 15.000,00',
			'acima de R$ 15.000,01',
			'Se recusou a informar'
		];
		const index = rendaApp.indexOf(valor);
		if (index == -1) {
			return "";
		} else {
			return rendaBack[index];
		}
	}

	getBankName(codeBank) {
		let retVal = "";
		this.resgateBankService.getBankList().every((item) => {
			retVal = item;
			return (!item.startsWith(codeBank));
		});
		return retVal;
	}

	parseFloat(value) {
		if (!value) value = "0";
		value = value.replace("R$ ", "").replace(".", "").replace(",", ".");
		if (isNaN(value)) value = "0";
		value = parseFloat(value);
		return value;
	}

	//CAP-3553
	/*@todo
		Esse método atrubui o decisionType do response baseado no resgate result também do response.
		Mas existem mais decisions types, portanto o método precisa inferir esta propriedade de outra forma.
		Como o tempo é curto, está sendo feito dessa maneira.
	*/
	getDetailPageDecisionType() {
		switch (this.case.details.resgateResult) {
		case '100% locatário':
			this.case.details.decisionType = '100-locatario';
			break;
		case 'Divisão de Valores':
			this.case.details.decisionType = 'divisao-valores';
			break;
		case 'Execução da Caução':
			this.case.details.decisionType = 'execucao-caucao';
			break;
		}
	}

	getCaseDetails() {
		if (!this.hasValidUrlParams()) {
			this.showLoading = false;
			this.$state.go('case.list');
		}
		this.$scope.$emit('changeMainShowLoading', true);
		this.resgateDetailService.getResgateDetails(this.$stateParams.id).then((response) => {
			this.$scope.$emit('changeMainShowLoading', false);
			console.log(response);
			if (!response.isSuccessful) {
				this.errorMessage = response.errorMessages[0];
			} else {
				this.case.details = response.resgate;
				if (response.resgate.origin.app === true) {
					this.getDetailPageDecisionType(); //CAP-3553
					this.case.details.legalRepresentative = ''; //CAP-3553
				}
				this.case.related.propostas = response.resgate.propostas;
				this.case.related.rentGarantee = response.resgate.titulos;
				this.case.related.attachments = response.resgate.anexos;
				this.case.related.history = response.resgate.history;
				this.case.related.parsedEditCaseData = this.parseEditCaseData(this.case.details);
				//this.case.parsedEditCaseData.status = response.resgate.status;
				console.log(this.case);
			}
			this.showLoading = false;
		});
	}

	editButtonHandler(status, id) {
		this.resgateFormService.resetForm();
		this.formField = this.resgateFormService.getFormField();
		console.log('FORM: ', this.formField);

		let json = "";
		if (this.case.details.origin.app && (this.case.details.editCaseData === "")) {
			json = this.createEditCaseData();
		} else {
			json = this.parseEditCaseData();
		}

		json.id = this.case.details.id;
		json.origin = this.case.details.origin;
		json.status = this.case.details.status;
		json.isHaveProblemBank = this.case.details.isHaveProblemBank;

		this.resgateFormService.setFormField(json);
		const form = this.resgateFormService.getFormField();
		this.resgateService.setIsEdit(true);

		if (form.type.active === true) {
			this.resgateService.setSaveDisabled(false);
			this.resgateService.setPreviousShow(true);
			this.resgateService.setPreviousDisabled(false);
			this.resgateService.setNextShow(true);
			this.resgateService.setNextDisabled(false);
			this.resgateService.getFinishDisabled(true);
			this.resgateService.setFinishShow(false);
			this.$state.go('resgate.type');
		} else if (form.data.active === true) {
			this.resgateService.setSaveDisabled(false);
			this.resgateService.setPreviousShow(true);
			this.resgateService.setPreviousDisabled(false);
			this.resgateService.setNextShow(true);
			this.resgateService.setNextDisabled(false);
			this.resgateService.getFinishDisabled(true);
			this.resgateService.setFinishShow(false);
			this.$state.go('resgate.data');
		} else if (form.bank.active === true) {
			this.resgateService.setSaveDisabled(false);
			this.resgateService.setPreviousShow(true);
			this.resgateService.setPreviousDisabled(false);
			this.resgateService.setNextShow(true);
			this.resgateService.setNextDisabled(false);
			this.resgateService.getFinishDisabled(true);
			this.resgateService.setFinishShow(false);
			this.$state.go('resgate.bank');
		} else if (form.file.active === true) {
			this.resgateService.setSaveDisabled(false);
			this.resgateService.setPreviousShow(true);
			this.resgateService.setPreviousDisabled(false);
			this.resgateService.setNextShow(false);
			this.resgateService.setNextDisabled(true);
			this.resgateService.setFinishDisabled(false);
			this.resgateService.setFinishShow(true);
			this.$state.go('resgate.file');
		} else { // form.information.active === true
			form.information.active = true;
			this.resgateService.setSaveDisabled(false);
			this.resgateService.setPreviousShow(false);
			this.resgateService.setPreviousDisabled(true);
			this.resgateService.setNextShow(true);
			this.resgateService.setNextDisabled(false);
			this.resgateService.getFinishDisabled(true);
			this.resgateService.setFinishShow(false);
			this.$state.go('resgate.new');
		}

	}
}

module.exports = resgateDetailController;
