'use strict';

const componentController = require('./component.controller');
const componentApi = require('./api.service');

const formControlService = require('./form-control.service');

const annexController = require('./annex/annex.controller');
const annexApiService = require('./annex/api.service');

// const detailController = require('./detail/detail.controller');
// const detailApiService = require('./detail/api.service');

const newController = require('./new/new.controller');
const newApiService = require('./new/api.service');

const detailController = require('./detail/detail.controller');
const detailApiService = require('./detail/api.service');

const liberacaoDaCaucaoService = require('./component.service');

// const editController = require('./edit/edit.controller');
// const editApiService = require('./edit/api.service');

const routesConfiguration = require('./component.routes');

const componentModule = angular.module('cap.secured.case.liberacao-da-caucao', [
		'ui.router'
	])
	.controller('liberacaoDaCaucaoController', componentController)
	.service('liberacaoDaCaucaoApi', componentApi)

	.service('liberacaoDaCaucaoFormControlService', formControlService)

	.controller('liberacaoDaCaucaoAnnexController', annexController)
	.service('liberacaoDaCaucaoAnnexApi', annexApiService)

	.controller('liberacaoDaCaucaoDetailController', detailController)
	.service('liberacaoDaCaucaoDetailApi', detailApiService)

	.service('liberacaoDaCaucaoService', liberacaoDaCaucaoService)

	// .controller('administrarUsuarioDetailController', detailController)
	// .service('administrarUsuarioDetailApi', detailApiService)

	.controller('liberacaoDaCaucaoNewController', newController)
	.service('liberacaoDaCaucaoNewApi', newApiService)

	// .controller('administrarUsuarioEditController', editController)
	// .service('administrarUsuarioEditApi', editApiService)

	.config(routesConfiguration);

module.exports = componentModule;
