'use strict';

/* @ngInject */
function routesConfiguration($stateProvider, sfConfig) {

	$stateProvider.state('comissoes-pagas', {

		abstract: true,
		url: '/comissoes-pagas',
		views: {

			'content': {

				templateUrl: `${sfConfig.resource}/views/comissoes-pagas/comissoes-pagas.html`
			}
		}
	});

	$stateProvider.state('comissoes-pagas.list', {

		url: '/lista',
		views: {

			'comissoes-pagas': {

				templateUrl: `${sfConfig.resource}/views/comissoes-pagas/list/list.html`,
				controller: 'comissoesPagasListController',
				controllerAs: 'comissoesPagasList'
			}
		}
	});

	$stateProvider.state('comissoes-pagas.detail', {

		url: '/detalhes/:id',
		views: {

			'comissoes-pagas': {

				templateUrl: `${sfConfig.resource}/views/comissoes-pagas/detail/detail.html`,
				controller: 'comissoesPagasDetailController',
				controllerAs: 'comissoesPagasDetail'
			}
		}
	});
};

module.exports = routesConfiguration;
