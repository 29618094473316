'use strict';

class documentacaoPropostaDetailController {

	// @ngInject
	constructor($scope, $state, $window, documentacaoPropostaDetailApi, documentacaoPropostaFormControlService, breadcrumbHelper) {
		// console.log('liberacaoDaCaucaoNewController');

		this.$window = $window;
		this.$scope = $scope;
		this.$state = $state;
		this.$scope.breadcrumb = breadcrumbHelper;

		this.documentacaoPropostaDetailApi = documentacaoPropostaDetailApi;
		this.formField = documentacaoPropostaFormControlService;
		this.formField.setPristine();

		this.configureBreadCrumb();
		this.$scope.$parent.caseInstance.getCaseDetails();
	}

	configureBreadCrumb() {
		this.$scope.breadcrumb.reset();
		this.$scope.breadcrumb.addMenuItem('Home', '/caponline/secured#!/home', true, false);
		this.$scope.breadcrumb.addMenuItem('Solicitação', '/caponline/secured#!/solicitacoes/lista', true, false);
		this.$scope.breadcrumb.addMenuItem('Detalhes da Documentação de Emissão', '#', false, true);
	}

	editButtonHandler() {
		this.$state.go('case.documentacao-proposta.new', { id: this.formField.id });
	}

	downloadPDF(attach) {
		this.$scope.$emit("changeMainShowLoading", true);
		console.log('DOWNLOAD PDF CHAMADO ->', attach);
		this.documentacaoPropostaDetailApi.callDownloadPDF(attach)
			.then((data) => {
				this.$scope.$emit("changeMainShowLoading", false);
				if (!data.isSuccessful) {
					this.$window.scrollTo(0, 0);
					const errorMessage = angular.toJson(data.errorMessages);
					this.messages.showErrorMessage(this.$scope, errorMessage);
					return;
				}
				const propostaDocumentBody = data.body;

				this.openSaveBase64Pdf("Proposta", "Proposta", propostaDocumentBody);

			});
	}

	base64ToArrayBuffer(base64) {
		const binaryString = window.atob(base64);
		const binaryLen = binaryString.length;
		const bytes = new Uint8Array(binaryLen);
		for (let i = 0; i < binaryLen; i++) {
			const ascii = binaryString.charCodeAt(i);
			bytes[i] = ascii;
		}
		return bytes;
	}

	saveByteArray(fileName, byte) {
		const blob = new Blob([byte]);
		const link = document.createElement("a");
		link.href = window.URL.createObjectURL(blob);
		link.download = fileName;
		link.click();

		return blob;
	}

	openSaveBase64Pdf(name, reportName, data) {
		const dataPrefix = "data:application/pdf;base64,";
		const fileName = `${reportName}.pdf`;
		data = data.replace(dataPrefix, "");

		const dataArr = this.base64ToArrayBuffer(data);
		const blob = this.saveByteArray(fileName, dataArr);

		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveOrOpenBlob(blob, fileName);
		} else {
			const documentWindow = window.open("", "_blank");
			if (!!documentWindow) {
				documentWindow.document.write(
					`<object id=\"objpdf\" data=\"${dataPrefix}${data}\" type=\"application/pdf\" width=\"100%\" height=\"100%\"></object>`
				);
				documentWindow.document.title = name;
			}
		}
	}

}

module.exports = documentacaoPropostaDetailController;
