'use strict';

class alteracaoImovelController {

	// @ngInject
	constructor($scope, $window, $uibModal, $state, feedbackMessages, alteracaoDadosFormControlService, alteracaoImovelService, cepHelper, alteracaoDadosSetupAccountService) {
		this.$scope = $scope;
		this.$window = $window;
		this.$state = $state;
		this.$uibModal = $uibModal;
		this.feedbackMessages = feedbackMessages;
		this.formField = alteracaoDadosFormControlService;
		this.api = alteracaoImovelService;
		this.cepHelper = cepHelper;
		this.alteracaoDadosSetupAccountService = alteracaoDadosSetupAccountService;

		this.cepHelper.setPristine();
		this.checkNewLocador();

		this.formField.data.status = 'Em Andamento';
		this.initComboFieldsOptions();

		this.getTituloData(this.formField.information.fields.garantias.value[0].objectId); //esse tipo de alteração só pode ser feito com 1 título apenas
		console.log('Tipo Alteração', this.formField.information.fields.alteracaoType.value);
		console.log('Form Field:', this.formField);
	}

	checkNewLocador() {
		const newLocador = this.alteracaoDadosSetupAccountService.getNewLocador();

		if (newLocador != null) {
			this.formField.activateTab('data');
			this.formField.data.alteracaoImovel.fields.newLocador.value = newLocador;
		}
	}

	initComboFieldsOptions() {
		this.comboOptions = {
			tipoImovel: ['Apartamento', 'Box', 'Casa', 'Flat', 'Galpão', 'Loja', 'Quiosque', 'Sala Comercial', 'Outros'], //tipoImovel: ['Casa', 'Apartamento'],
			finalidade: ['Comercial', 'Outros', 'Residencial', 'Veraneio'] //finalidade: ['Residencial', 'Comercial']
		}
	}

	getTituloData(id) {
		this.api.getTituloData(id).then((response) => {
				this.formField.data.alteracaoImovel.currentData = response.data;
			})
			.catch((response) => {
				console.log('erro ao trazer dados do título');
			});
	}

	requireProperty(propertyName) {
		this.formField.requireProperty(this.formField.data.alteracaoImovel.fields, propertyName);
	}

	searchAccount(searchString) {
		console.log('entrou no searchAccount');
		const alteracaoImovel = this.$parent.alteracaoImovel;
		return alteracaoImovel.api.searchAccount(searchString);
	}

	searchAccountSelect(item) {
		console.log('entrou no searchAccountSelect');
		const alteracaoImovel = this.$parent.alteracaoImovel;
		if (alteracaoImovel.formField.data.alteracaoImovel.fields.newLocador.value === null) {
			if (!item) { return; }

			const hasAction = !!item.originalObject.action;

			if (hasAction) {

				const action = item.originalObject.action;

				alteracaoImovel[action](item);

				return;
			}

			alteracaoImovel.formField.data.alteracaoImovel.fields.newLocador.value = item.originalObject;
			alteracaoImovel.formField.data.alteracaoImovel.fields.newLocador.hasError = !alteracaoImovel.formField.data.alteracaoImovel.fields.newLocador.value;

		}
	}

	searchAccountChanged() {
		const alteracaoImovel = this.$parent.alteracaoImovel; //CAP-2706
		console.log('entrou no searchAccountChanged');
		alteracaoImovel.feedbackMessages.cleanMessages(alteracaoImovel.$scope);
		alteracaoImovel.formField.data.alteracaoImovel.fields.newLocador.value = null;
		console.log('reset');
		//resetar todos os dados	
	}

	//@todo Horrível. copiado da proposta.
	modalHandle() {
		//this.searchAccountFocusOut();
		const parentElem = angular.element(document.querySelector('.col-lg-4'));
		this.$uibModal.open({
			template: '<div class="modal-content acc"><div class="modal-body"><h4 class="modal-title text-center">Que tipo de cadastro quer criar?</h4><div class="col-lg-6"><a class="box-modal pull-right" href="" ui-sref="new-account" ng-click="modalController.setFromAlteracaoImovel(true); modalController.closeModal()"><img class="ico-type" src="{{modalController.imagePFAccountPath}}"/><p>Pessoa Física</p></a></div><div class="col-lg-6"><a class="box-modal pull-left" href="" ui-sref="new-juridical-account" ng-click="modalController.setFromAlteracaoImovel(true); modalController.closeModal()"><img class="ico-type" src="{{modalController.imagePJAccountPath}}"/><p>Pessoa Jurídica</p></a></div></div><div class="modal-footer"><button class="btn btn-link cancel" type="button" ng-click="modalController.closeModal()">Cancelar</button></div></div>',
			controller: 'accountTypeModalController',
			controllerAs: 'modalController',
			appendTo: parentElem
		});
	}

	getAddressFromCEP() {
		//const me = this;
		const cep = this.formField.data.alteracaoImovel.fields.cep;

		if ((cep.value.length == 9) && (cep.value.indexOf("-") == 5)) {

			this.$scope.$emit('changeMainShowLoading', true);
			this.cepHelper.getAddressFromCEP(cep.value, (response) => {

				this.feedbackMessages.cleanMessages(this.$scope);

				this.formField.data.alteracaoImovel.fields.logradouro.value = response.logradouro;
				this.formField.data.alteracaoImovel.fields.bairro.value = response.bairro;
				this.formField.data.alteracaoImovel.fields.cidade.value = response.cidade;
				this.formField.data.alteracaoImovel.fields.uf.value = response.uf;

				if (!response.isSuccessful) {

					if (this.cepHelper.remoteCallErrorLimitWasExceded()) {
						cep.hasError = false;
						cep.value = null;
						this.feedbackMessages.showErrorMessage(this.$scope, this.cepHelper.remoteCallErrorMessage);
					} else {
						cep.isValid = false;
						this.feedbackMessages.showErrorMessage(this.$scope, response.errorMessages[0]);
						this.$window.scrollTo(0, 0);
						//cep.message = response.errorMessages[0];
					}
					this.cepHelper.setPristine();
				} else {
					this.requireProperty('logradouro');
					this.requireProperty('bairro');
					this.requireProperty('cidade');
					this.requireProperty('uf');
					//this.validated.clientCepError = false;
					cep.isValid = true;
					cep.message = null;
					this.feedbackMessages.showErrorMessage(this.$scope, '');
				}

				this.$scope.$emit('changeMainShowLoading', false);
			});
		} else {
			this.cepHelper.setPristine();
			this.formField.data.alteracaoImovel.fields.logradouro.value = null;
			this.formField.data.alteracaoImovel.fields.bairro.value = null;
			this.formField.data.alteracaoImovel.fields.cidade.value = null;
			this.formField.data.alteracaoImovel.fields.uf.value = null;
		}
	}

	nullOrEmpty(field) {
		return this.formField.nullOrEmpty(this.formField.data.alteracaoImovel.fields, field);
	}

	validateField(field) {
		this.formField.validateField(this.formField.data.alteracaoImovel.fields, field);
	}
}

module.exports = alteracaoImovelController;
