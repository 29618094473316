'use strict';

import VisualForceApi from '../../core/sfdc/visual-force-api.js';

class propostaGenerateApi extends VisualForceApi {

	/* @ngInject */
	constructor($q) {
		super();
		this.$q = $q;
	}

}

module.exports = propostaGenerateApi;
