class bloqReaplicacaoNewService {
	/* @ngInject */
	constructor(visualForceApi, $sce, $q, bloqReaplicacaoFormService) {
		this.visualForceApi = visualForceApi;
		this.$q = $q;
		this.propostas = [];
		this.bloqReaplicacaoFormService = bloqReaplicacaoFormService;
	}

	/**
	 *
	 * @param {*String do Informe CPF/CNPJ} searchKeyWord
	 */
	searchAccount(searchKeyWord) {
		const deferred = this.$q.defer();

		const searchModel = {
			dataFetch: {
				searchKeyWord,
				searchTypeObject: "Account"
			}
		};

		const cap_access_token = localStorage.getItem("cap_access_token");
		searchModel.accessToken = cap_access_token;

		this.visualForceApi
			.invoke("CapCommunityPropostaControllerExt.solFetchRecords", searchModel)
			.then((data) => {
				//console.log(data.obtainedRecords);
				if (!data.obtainedRecords) data.obtainedRecords = [];
				if (data.obtainedRecords.length === 0) {
					data.obtainedRecords.push({
						action: "searchAccountFocusOut",
						Name: "Nenhum cadastro encontrado"
					});
				}
				deferred.resolve(data.obtainedRecords);
			});

		return deferred.promise;
	}

	/**
	 * Pega as propostas dentro do scopo da bloqReaplicacaoNewService
	 */
	getPropostas() {
		return this.propostas;
	}

	/**
	 * Atribuir as propostas dentro do scopo da bloqReaplicacaoNewService
	 */
	setPropostas(propostas) {
		this.propostas = propostas;
	}

	/*getGarantiaDataById(garantiaId) {
		this.visualForceApi.invoke(
			'CapCommunityGarantAluguelControllerExt.getGarantiaAluguelDetail', garantiaId).then((response) => {
			if (response.isSuccessful) {
				console.log('RESPONSE: ', response);

				//set all data garantia

				//set subscritor fields
				this.bloqReaplicacaoFormService.formField.garantiaData.subscritor.cpf.value = response.garantiaAluguelDetail.subscritor.cpf
				this.bloqReaplicacaoFormService.formField.garantiaData.subscritor.nome.value = response.garantiaAluguelDetail.subscritor.nome

				//set endereco fields
				this.bloqReaplicacaoFormService.formField.garantiaData.endereco.cep.value = response.garantiaAluguelDetail.imovel.cep
				this.bloqReaplicacaoFormService.formField.garantiaData.endereco.logradouro.value = response.garantiaAluguelDetail.imovel.logradouro
				this.bloqReaplicacaoFormService.formField.garantiaData.endereco.numero.value = response.garantiaAluguelDetail.imovel.numero
				this.bloqReaplicacaoFormService.formField.garantiaData.endereco.complemento.value = response.garantiaAluguelDetail.imovel.complemento
				this.bloqReaplicacaoFormService.formField.garantiaData.endereco.bairro.value = response.garantiaAluguelDetail.imovel.bairro
				this.bloqReaplicacaoFormService.formField.garantiaData.endereco.cidade.value = response.garantiaAluguelDetail.imovel.cidade
				this.bloqReaplicacaoFormService.formField.garantiaData.endereco.uf.value = response.garantiaAluguelDetail.imovel.uf

				//set locador
				this.bloqReaplicacaoFormService.formField.garantiaData.locador.nome.value = response.garantiaAluguelDetail.locador.nome
				this.bloqReaplicacaoFormService.formField.garantiaData.locador.cpfcnpj.value = response.garantiaAluguelDetail.locador.cpfCnpj

				//set imobiliaria
				this.bloqReaplicacaoFormService.formField.garantiaData.imobiliaria.nome.value = response.garantiaAluguelDetail.imobiliaria.nome
				this.bloqReaplicacaoFormService.formField.garantiaData.imobiliaria.cpfcnpj.value = response.garantiaAluguelDetail.imobiliaria.cpfCnpj

				//set geral infos
				this.bloqReaplicacaoFormService.formField.garantiaData.geral.dataInicioVigencia.value = response.garantiaAluguelDetail.tituloInfo.dataInicioVigencia
				this.bloqReaplicacaoFormService.formField.garantiaData.geral.dataFimVigencia.value = response.garantiaAluguelDetail.tituloInfo.dataFimVigencia

				this.bloqReaplicacaoFormService.setFormField(this.bloqReaplicacaoFormService.formField);

				return response.garantiaAluguelDetail;
			} else {

				//set all data garantia to null

				//set subscritor fields
				this.bloqReaplicacaoFormService.formField.garantiaData.subscritor.cpf.value = ''
				this.bloqReaplicacaoFormService.formField.garantiaData.subscritor.nome.value = ''

				//set endereco fields
				this.bloqReaplicacaoFormService.formField.garantiaData.endereco.cep.value = ''
				this.bloqReaplicacaoFormService.formField.garantiaData.endereco.logradouro.value = ''
				this.bloqReaplicacaoFormService.formField.garantiaData.endereco.numero.value = ''
				this.bloqReaplicacaoFormService.formField.garantiaData.endereco.complemento.value = ''
				this.bloqReaplicacaoFormService.formField.garantiaData.endereco.bairro.value = ''
				this.bloqReaplicacaoFormService.formField.garantiaData.endereco.cidade.value = ''
				this.bloqReaplicacaoFormService.formField.garantiaData.endereco.uf.value = ''

				//set locador
				this.bloqReaplicacaoFormService.formField.garantiaData.locador.nome.value = ''
				this.bloqReaplicacaoFormService.formField.garantiaData.locador.cpfcnpj.value = ''

				//set imobiliaria
				this.bloqReaplicacaoFormService.formField.garantiaData.imobiliaria.nome.value = ''
				this.bloqReaplicacaoFormService.formField.garantiaData.imobiliaria.cpfcnpj.value = ''

				//set geral infos
				this.bloqReaplicacaoFormService.formField.garantiaData.geral.dataInicioVigencia.value = ''
				this.bloqReaplicacaoFormService.formField.garantiaData.geral.dataFimVigencia.value = ''

				this.bloqReaplicacaoFormService.setFormField(this.bloqReaplicacaoFormService.formField);
				console.log('RESPONSE: ', response.isSuccessful);
				return null;
			};
		});
	} */
}

module.exports = bloqReaplicacaoNewService;
