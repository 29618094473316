'use strict';

class liberacaoDaCaucaoApi {

	/* @ngInject */
	constructor(visualForceApi, liberacaoDaCaucaoFormControlService) {

		this.visualForceApi = visualForceApi;
		this.formField = liberacaoDaCaucaoFormControlService;
	}

	saveCaseInstance(form) {
		return this.visualForceApi.invoke(
			'CapCommunityCaseControllerExt.saveCaseLiberacaoDaCaucao', this.createSaveRequest(form));
	}

	sendCaseInstance(form) {

		const payload = this.createSaveRequest(form);

		const cap_access_token = localStorage.getItem("cap_access_token");
		payload.accessToken = cap_access_token;

		return this.visualForceApi.invoke(
			'CapCommunityCaseControllerExt.sendCaseLiberacaoDaCaucao', payload);
	}

	cancelInstance(Id) {

		const cap_access_token = localStorage.getItem("cap_access_token");

		return this.visualForceApi.invoke(
			'CapCommunityCaseControllerExt.cancelResgate', Id, 'liberacao', cap_access_token);
	}

	parseFiles(files) {
		console.log('parseFiles(): ', files);
		//Manobra desenvolvida para evitar o sumiço dos dados do arquino ao parsear o JSON de envio
		//https://github.com/danialfarid/ng-file-upload/issues/1605

		const array = [];

		for (const file of files) {
			// console.log("files: ", file);
			array.push({
				name: file.name,
				size: file.size,
				type: file.type,
				result: file.result,
				salesForceId: file.salesForceId
			});
		}

		return array;
	}

	prepareFilesToSaveRequest(formControl) {
		//Se estiver enviando, parseia os arquivos para colocar no JSON de envio
		formControl.annex.form.filesParsed = this.parseFiles(this.formField.annex.form.files);
		formControl.annex.legalRepresentative.document.filesParsed = this.parseFiles(this.formField.annex.legalRepresentative.document.files);
		formControl.annex.legalRepresentative.identityRenter.filesParsed = this.parseFiles(this.formField.annex.legalRepresentative.identityRenter.files);
		formControl.annex.legalRepresentative.other.filesParsed = this.parseFiles(this.formField.annex.legalRepresentative.other.files);

		formControl.annex.form.files = formControl.annex.form.filesParsed;
		formControl.annex.legalRepresentative.document.files = formControl.annex.legalRepresentative.document.filesParsed;
		formControl.annex.legalRepresentative.identityRenter.files = formControl.annex.legalRepresentative.identityRenter.filesParsed;
		formControl.annex.legalRepresentative.other.files = formControl.annex.legalRepresentative.other.filesParsed;

		formControl.annex.subscritor.identity.files = formControl.annex.subscritor.identity.filesParsed;
		formControl.annex.subscritor.other.files = formControl.annex.subscritor.other.filesParsed;
		formControl.annex.subscritor.identityAdmin.files = formControl.annex.subscritor.identityAdmin.filesParsed;
		formControl.annex.subscritor.cnpj.files = formControl.annex.subscritor.cnpj.filesParsed;
		formControl.annex.subscritor.constituitionDocumentation.files = formControl.annex.subscritor.constituitionDocumentation.filesParsed;
	}

	//@todo gambiarra. refatorar pra deixar dinâmico em vez de marretar os anexos
	//Esse método serve pra remover os anexos que deram erro na hora de salvar o editCaseData
	removeFilesWithError(editCaseDataFiles) {
		const files = this.getAnnexes(editCaseDataFiles);

		for (let i = files.length - 1; i >= 0; i--) {
			for (let j = files[i].length - 1; j >= 0; j--) {
				if (!files[i][j].result) {
					files[i].splice(j, 1);
				}
			}
		}
	}

	getAnnexes(editCaseDataFiles) {
		const annexes = [
			editCaseDataFiles.form.files,
			editCaseDataFiles.legalRepresentative.document.files,
			editCaseDataFiles.legalRepresentative.identityRenter.files,
			editCaseDataFiles.legalRepresentative.other.files,
			editCaseDataFiles.subscritor.identity,
			editCaseDataFiles.subscritor.other,
			editCaseDataFiles.subscritor.identityAdmin,
			editCaseDataFiles.subscritor.cnpj,
			editCaseDataFiles.subscritor.constituitionDocumentation
		];
		return annexes;
	}

	updateEditCaseData() {
		const editCaseData = JSON.parse(JSON.stringify(this.formField.extractFormControl())); //@todo gambiarra. Angular.copy() não funciona bem com a classe File
		this.removeFilesWithError(editCaseData.annex);
		const request = {
			caseId: this.resgateFormService.formField.id,
			editCaseData: JSON.stringify(editCaseData)
		}

		console.log('Request updateEditCaseData', request);

		const cap_access_token = localStorage.getItem("cap_access_token");
		request.accessToken = cap_access_token;

		return this.visualForceApi.invoke(
			'CapCommunityCaseControllerExt.updateEditCaseData', request);

	}

	createSaveRequest(form) {

		//Manobra desenvolvida para evitar o sumiço dos dados do arquino ao parsear o JSON de envio
		//https://github.com/danialfarid/ng-file-upload/issues/1605

		const formControl = angular.copy(this.formField.extractFormControl());
		// console.log('getOwnPropertyDescriptor: ', Object.getOwnPropertyDescriptor(formControl.annex.form.files[0], 'name'));

		this.prepareFilesToSaveRequest(formControl);

		// console.log('formControl: ', formControl);
		const request = {

			caseInstance: {

				id: form.id,
				cpfCnpj: form.information.mainAccount.value.cpf,
				accountId: form.information.mainAccount.value.Id,
				propostaId: form.information.proposta.value ? form.information.proposta.value.objectId : null,
				observacoes: form.information.observacoes.value,
				titulos: [],
				isLegalRepresentative: form.information.legalRepresentative.value,
				status: form.status,
				editCaseData: JSON.stringify(formControl)
			}
		};
		// console.log('files[0].name: ', formControl.annex.form.files[0].name);
		console.log('caseInstance.editCaseData JSON: ', request.caseInstance.editCaseData);
		// console.log('caseInstance.editCaseData: ', angular.fromJson(request.caseInstance.editCaseData));

		if (form.information.garantias.value !== null) {

			for (let i = 0; i < form.information.garantias.value.length; i++) {

				if (form.information.garantias.value[i].selected === true) {

					request.caseInstance.titulos.push({ id: form.information.garantias.value[i].objectId, value: form.information.garantias.value[i].value });
				}
			}
		}

		// console.log('createSaveRequest', request);
		return request;
	}
}

module.exports = liberacaoDaCaucaoApi;
