class alteracaoDadosFormValidationService {

	/* @ngInject */
	constructor() {
		this.a = '';
	}

	/*@todo refactory: Os métodos isRequired e is Valid podem ser apenas 1
	  apenas passando qual a página que vai ser validada. As páginas podem estar num objeto de configuração. 
	*/

	setFormField(data) {
		this.formField = data;
	}

	hasRequiredFieldsInformationForm(setFieldErrors) {
		const information = this.formField.information.fields
		let noErrors = true;
		for (const field in information) {
			if ((information[field].show != false) && ((information[field].isValid === false) || (!this.hasRequiredField(information[field], setFieldErrors)))) {
				noErrors = false;
			}
		}

		//@todo refactory a validação de garantias não é igual às outras
		//repensar o modo de validar ou persistir

		if (!this.hasRequiredGarantias(setFieldErrors)) noErrors = false;

		return noErrors;
	}

	//os campos da primeira tela não tem validação
	isValidFieldsInformationForm() {
		return true;
	}

	hasRequiredFieldsAlteracaoImovelForm(setFieldErrors) {
		const alteracaoImovel = this.formField.data.alteracaoImovel.fields;
		let noErrors = true
		for (const field in alteracaoImovel) {
			if ((alteracaoImovel[field].required != false) && (alteracaoImovel[field].show != false) && (!this.hasRequiredField(alteracaoImovel[field], setFieldErrors))) {
				noErrors = false;
			}
		}

		return noErrors;
	}

	isValidFieldsAlteracaoImovelForm() {
		const alteracaoImovel = this.formField.data.alteracaoImovel.fields;
		let noErrors = true;
		for (const field in alteracaoImovel) {
			if (alteracaoImovel[field].isValid === false) {
				noErrors = false;
			}
		}

		return noErrors;
	}

	hasRequiredFieldsMudancaLocadorForm(setFieldErrors) {
		const mudancaLocador = this.formField.data.mudancaLocador.fields;
		let noErrors = true
		for (const field in mudancaLocador) {
			if ((mudancaLocador[field].required != false) && (mudancaLocador[field].show != false) && (!this.hasRequiredField(mudancaLocador[field], setFieldErrors))) {
				noErrors = false;
			}
		}

		return noErrors;
	}

	isValidFieldsMudancaLocadorForm() {
		const mudancaLocador = this.formField.data.mudancaLocador.fields;
		let noErrors = true;
		for (const field in mudancaLocador) {
			if (mudancaLocador[field].isValid === false) {
				noErrors = false;
			}
		}

		return noErrors;
	}

	hasRequiredFieldsAlteracaoDadosClienteForm(setFieldErrors) {
		let alteracaoDadosCliente;
		if (this.formField.data.alteracaoDadosCliente.isPersonAccount) {
			alteracaoDadosCliente = this.formField.data.alteracaoDadosCliente.fields.pf;
		} else {
			alteracaoDadosCliente = this.formField.data.alteracaoDadosCliente.fields.pj;
		}
		let noErrors = true
		for (const field in alteracaoDadosCliente) {
			//@todo gambiarra. feito por causa do required do inscrição estadual que é condicional
			if (typeof alteracaoDadosCliente[field].required == 'function') {
				if ((alteracaoDadosCliente[field].required(this.formField) != false) && (alteracaoDadosCliente[field].show != false) && (!this.hasRequiredField(alteracaoDadosCliente[field], setFieldErrors))) {
					noErrors = false;
				}
			} else {
				if ((alteracaoDadosCliente[field].required != false) && (alteracaoDadosCliente[field].show != false) && (!this.hasRequiredField(alteracaoDadosCliente[field], setFieldErrors))) {
					noErrors = false;
				}
			}
		}

		return noErrors;
	}

	isValidFieldsAlteracaoDadosClienteForm() {
		let alteracaoDadosCliente;
		if (this.formField.data.alteracaoDadosCliente.isPersonAccount) {
			alteracaoDadosCliente = this.formField.data.alteracaoDadosCliente.fields.pf;
		} else {
			alteracaoDadosCliente = this.formField.data.alteracaoDadosCliente.fields.pj;
		}
		let noErrors = true;
		for (const field in alteracaoDadosCliente) {
			if (alteracaoDadosCliente[field].isValid === false) {
				noErrors = false;
			}
		}

		return noErrors;
	}

	hasRequiredFieldsInclusaoCaucaoForm(setFieldErrors) {
		const inclusaoCaucao = this.formField.data.inclusaoCaucao.fields;
		let noErrors = true
		for (const field in inclusaoCaucao) {
			if ((inclusaoCaucao[field].required != false) && (inclusaoCaucao[field].show != false) && ((!this.hasRequiredField(inclusaoCaucao[field], setFieldErrors)) || (inclusaoCaucao[field].isValid === false))) {
				noErrors = false;
			}
		}

		return noErrors;
	}

	isValidFieldsInclusaoCaucaoForm() {
		const inclusaoCaucao = this.formField.data.inclusaoCaucao.fields;
		let noErrors = true;
		for (const field in inclusaoCaucao) {
			if (inclusaoCaucao[field].isValid === false) {
				noErrors = false;
			}
		}

		return noErrors;
	}

	hasRequiredFieldsAnnexForm() {
		return true; //@todo implementar
	}

	isValidFieldsAnnexForm() {
		return true; //@todo implementar
	}

	hasRequiredGarantias(setFieldErrors) {

		let hasRequirement = true;

		let hasGarantiaSelected = false;
		if (this.formField.information.fields.garantias.value !== null) {

			for (let i = 0; i < this.formField.information.fields.garantias.value.length; i++) {

				if (this.formField.information.fields.garantias.value[i].selected) {

					hasGarantiaSelected = true;
					break;
				}
			}
		}

		if (!hasGarantiaSelected) {

			hasRequirement = false;
		}

		if (setFieldErrors) {
			this.formField.information.fields.garantias.hasError = !hasRequirement;
		}
		return hasRequirement;
	}

	hasRequiredField(field, setFieldErrors) {
		const hasRequirement = ((field.value !== null) && (field.value !== ""));
		if (setFieldErrors) {
			field.hasError = !hasRequirement;
		}
		return hasRequirement;
	}

}

module.exports = alteracaoDadosFormValidationService;
