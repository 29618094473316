'use strict';

/* @ngInject */
function routesConfiguration($stateProvider, sfConfig) {

	$stateProvider.state('resgate', {

		abstract: true,
		url: '/resgate',
		views: {

			'content': {

				templateUrl: `${sfConfig.resource}/views/case/resgate/resgate.html`,
				controller: 'resgateController',
				controllerAs: 'resgate'
			}
		}
	});

	$stateProvider.state('resgate.new', {

		url: '/novo',
		views: {
			'resgate': {
				templateUrl: `${sfConfig.resource}/views/case/resgate/resgate-new/resgate-new.html`,
				controller: 'resgateNewController',
				controllerAs: 'resgateNew'
			}
		}
	});

	$stateProvider.state('case', {

		abstract: true,
		url: '/solicitacoes',
		views: {

			'content': {

				templateUrl: `${sfConfig.resource}/views/case/case.html`,
				controller: 'caseController',
				controllerAs: 'case'
			}
		}
	});

	$stateProvider.state('case.list', {

		url: '/lista/:stateCaseType',
		views: {

			'case': {

				templateUrl: `${sfConfig.resource}/views/case/case-list/case-list.html`,
				controller: 'caseListController',
				controllerAs: 'caseList'
			}
		}
	});

	$stateProvider.state('case.detail', {
		url: '/detalhes?:id',
		views: {
			'case': {
				templateUrl: `${sfConfig.resource}/views/case/resgate/resgate-detail/resgate-detail.html`,
				controller: 'resgateDetailController',
				controllerAs: 'resgateDetail'
			}
		}
	});

	$stateProvider.state('resgate.type', {

		url: '/tipo',
		views: {
			'resgate': {
				templateUrl: `${sfConfig.resource}/views/case/resgate/resgate-type/resgate-type.html`,
				controller: 'resgateTypeController',
				controllerAs: 'resgateType'
			}
		}
	});

	$stateProvider.state('resgate.data', {

		url: '/dados',
		views: {
			'resgate': {
				templateUrl: `${sfConfig.resource}/views/case/resgate/resgate-data/resgate-data.html`,
				controller: 'resgateDataController',
				controllerAs: 'resgateData'
			}
		}
	});

	$stateProvider.state('resgate.bank', {

		url: '/banco',
		views: {
			'resgate': {
				templateUrl: `${sfConfig.resource}/views/case/resgate/resgate-bank/resgate-bank.html`,
				controller: 'resgateBankController',
				controllerAs: 'resgateBank'
			}
		}
	});

	$stateProvider.state('resgate.file', {

		url: '/anexos',
		views: {
			'resgate': {
				templateUrl: `${sfConfig.resource}/views/case/resgate/resgate-file/resgate-file.html`,
				controller: 'resgateFileController',
				controllerAs: 'resgateFile'
			}
		}
	});

};

module.exports = routesConfiguration;
