'use strict';

const profileService = require('./profile.service');
const profilePermissionsService = require('./permissions.service');

const componentModule = angular.module('cap.secured.core.profile', [])
	.service('profile', profileService)
	.service('profilePermissions', profilePermissionsService);

module.exports = componentModule;
