'use strict';

class resgateTypeController {

	// @ngInject
	constructor(resgateFormService, autoCompleteService, sfConfig, $scope, sobjectQueryService, resgateService, resgateTypeService, liberacaoDaCaucaoFormControlService, $state, $timeout, formControlService) {
		this.resgateFormService = resgateFormService;
		this.formControlService = formControlService;
		this.$timeout = $timeout;
		this.sobjectQueryService = sobjectQueryService;
		this.resgateService = resgateService;
		this.resgateTypeService = resgateTypeService;
		this.autoComplete = autoCompleteService;
		this.$scope = $scope;
		this.sfConfig = sfConfig;
		this.$state = $state;
		this.liberacaoDaCaucaoFormControlService = liberacaoDaCaucaoFormControlService;
		this.getFormFieldStatus();
		this.setToolTips();
		if (this.formField.information.mainAccount.value === null) {
			this.$state.go('resgate.new');
		}

		console.log('carregando resgate-type: ', this.resgateService.getPropostaFromResgate());

		if (this.resgateService.getPropostaFromResgate() === true) {
			this.formField.type.proposta.value = this.resgateService.getObjectProposta();
			console.log(this.formField.type.proposta.value);
			this.resgateService.setPreviousShow(true);
			this.resgateService.setPreviousDisabled(false);
			this.resgateService.setNextShow(true);
			this.resgateService.setNextDisabled(false);
			this.resgateService.setSaveShow(true);
			this.resgateService.setSaveDisabled(false);
			this.resgateService.getFinishDisabled(true);
			this.resgateService.setFinishShow(false);
		} else {
			this.checkStatusOnLoad();
		}

		this.$scope.breadcrumb = this.$scope.$parent.breadcrumb;
		this.configureBreadCrumb();

	}

	configureBreadCrumb() {
		this.$scope.breadcrumb.reset();
		this.$scope.breadcrumb.addMenuItem('Home', '/caponline/secured#!/home', true, false);
		this.$scope.breadcrumb.addMenuItem('Solicitação', '/caponline/secured#!/solicitacoes/lista', true, false);
		this.$scope.breadcrumb.addMenuItem('Novo Resgate/Cancelamento', '/caponline/secured#!/resgate/novo', false, true);
	}

	setToolTips() {
		this.tooltip = {
			tipoDeResgate: `${this.sfConfig.resource}/views/case/resgate/resgate-type/resgate-type-tooltip.html`
		}
	}

	getFormFieldStatus() {
		this.formField = this.resgateFormService.getFormField();

		console.log('----> FORM FIELD RESGATE TYPE', this.formField);
	}

	checkStatusOnLoad() {

		console.log('--> carregando resgateType');
		console.log('--> show: ', this.formField.type.resgateType.show);

		for (let i = 0; i < this.formField.information.garantias.value.length; i++) {

			console.log('--> garantias', this.formField.information.garantias);
			console.log('--> garantias selected', this.formField.information.garantias.value[i].selected);

			this.resgateService.setSaveDisabled(false);

			if (this.formField.information.garantias.value[i].selected === true) {
				if (this.formField.information.garantias.value[i].objectName.includes('(Caucionado)') && this.formField.information.garantias.value[i].vigencia <= 30) {
					this.formField.type.realtyDebits.show = true;
					this.formField.type.caucionado = true;
					this.formField.type.vigencia = false;
					break;
				} else if (this.formField.information.garantias.value[i].objectName.includes('(Caucionado)') && this.formField.information.garantias.value[i].vigencia > 30) {
					this.formField.type.resgateType.show = true;
					this.formField.type.caucionado = true;
					this.formField.type.vigencia = true;
					break;
				} else if (this.formField.information.garantias.value[i].objectName.includes('(Descaucionado)') && this.formField.information.garantias.value[i].vigencia <= 30) {
					this.formField.type.titleAquisition.show = true;
					this.formField.type.titleAquisition.value = 'false';
					this.formField.type.caucionado = false;
					this.formField.type.vigencia = false;
					break;
				} else if (this.formField.information.garantias.value[i].objectName.includes('(Descaucionado)') && this.formField.information.garantias.value[i].vigencia > 30) {
					this.formField.type.resgateType.show = true;
					this.formField.type.caucionado = false;
					this.formField.type.vigencia = true;
					break;
				} else {

					console.log('--> nenhuma condicao atendida!');

					this.formField.type.caucionado = null;
					this.formField.type.vigencia = false;
					this.formField.type.realtyDebits.value = null;
					this.formField.type.realtyDebits.show = false;
					this.formField.type.realtyDebits.hasError = false;
					this.formField.type.debitValue.value = null;
					this.formField.type.debitValue.show = false;
					this.formField.type.debitValue.hasError = false;
					this.formField.type.hasRenterApprove.value = null;
					this.formField.type.hasRenterApprove.show = false;
					this.formField.type.hasRenterApprove.hasError = false;
					this.formField.type.titleAquisition.value = null;
					this.formField.type.titleAquisition.show = false;
					this.formField.type.titleAquisition.hasError = false;
					this.formField.type.proposta.value = null;
					this.autoComplete.clearInput(this.$scope, 'searchProposta');
					this.formField.type.proposta.show = false;
					this.formField.type.proposta.hasError = false;
					this.formField.type.resgateType.show = false;
					this.formField.type.resgateType.hasError = false;
					this.formField.type.resgateType.value = null;
					this.formField.type.customerAgreement.show = false;
					this.formField.type.customerAgreement.hasError = false;
					this.formField.type.customerAgreement.value = null;
					this.formField.type.resgateFinalMessage.show = false;
					this.formField.type.resgateFinalMessage.value = null;

				}
			}
		}

	}

	/*

		this.formField.type.debitValue.value = null;
			this.formField.type.debitValue.show = false;
			this.formField.type.hasRenterApprove.value = null;
			this.formField.type.hasRenterApprove.show = false;
			this.formField.type.titleAquisition.value = null;
			this.formField.type.titleAquisition.show = false;
			this.formField.type.proposta.value = null;
			this.formField.type.proposta.show = false;
			this.formField.type.resgateType.show = false;
			this.formField.type.resgateType.value = null;
			this.formField.type.customerAgreement.show = false;
			this.formField.type.customerAgreement.value = null;
			this.formField.type.resgateFinalMessage.show = false;
			this.formField.type.resgateFinalMessage.value = null;

	*/

	changeRealtyDebits() {
		this.resgateFormService.resetFormData();
		this.resgateFormService.resetFormBank();
		this.resgateFormService.resetFormFile();
		if (this.formField.type.realtyDebits.value === 'true') {
			this.formField.type.debitValue.show = true;
			this.formField.type.debitValue.value = null;
			this.formField.type.hasRenterApprove.show = true;
			this.formField.type.hasRenterApprove.value = null;
			this.formField.type.titleAquisition.show = false;
			this.formField.type.titleAquisition.hasError = false;
			this.formField.type.titleAquisition.value = null;
			this.formField.type.titleAquisition.hasError = false;
			this.formField.type.proposta.value = null;
			this.formField.type.proposta.show = false;
			this.autoComplete.clearInput(this.$scope, 'searchProposta');
		} else {
			this.formField.type.debitValue.show = false;
			this.formField.type.debitValue.value = null;
			this.formField.type.debitValue.hasError = false;
			this.formField.type.hasRenterApprove.show = false;
			this.formField.type.hasRenterApprove.value = null;
			this.formField.type.hasRenterApprove.hasError = false;
			this.formField.type.titleAquisition.show = true;
			this.formField.type.titleAquisition.value = 'false';
		}
		this.resgateFormService.setFormField(this.formField);

	}

	changeHasRenterApprove() {
		this.resgateFormService.resetFormData();
		this.resgateFormService.resetFormBank();
		this.resgateFormService.resetFormFile();

		console.log('changeHasRenterApprove');
		console.log(this.formField.type.hasRenterApprove.value);

		if (this.formField.type.hasRenterApprove.value === 'true') {
			this.formField.type.titleAquisition.show = true;
			this.formField.type.titleAquisition.value = 'false';
			if (this.formField.type.resgateType.value === 'Resgate antecipado') {
				this.formField.type.customerAgreement.show = true;
			}
		} else {
			this.formField.type.titleAquisition.show = false;
			this.formField.type.customerAgreement.show = false;
			this.formField.type.titleAquisition.value = null;
			this.formField.type.titleAquisition.hasError = false;
			this.formField.type.proposta.show = false;
			this.formField.type.proposta.value = null;
			this.formField.type.proposta.hasError = false;
			this.formField.type.proposta.hasError = false;
		}
		this.resgateFormService.setFormField(this.formField);

	}

	changeTitleAquisition() {
		this.resgateFormService.resetFormData();
		this.resgateFormService.resetFormBank();
		this.resgateFormService.resetFormFile();
		if (this.formField.type.titleAquisition.value === 'true') {
			this.formField.type.proposta.show = true;
			this.formField.type.proposta.value = null;
			this.autoComplete.clearInput(this.$scope, 'searchProposta');
		} else {
			this.formField.type.proposta.show = false;
			this.formField.type.proposta.value = null;
			this.autoComplete.clearInput(this.$scope, 'searchProposta');
			this.formField.type.proposta.hasError = false;
		}

	}

	changeResgateType() {
		this.resgateFormService.resetFormData();
		this.resgateFormService.resetFormBank();
		this.resgateFormService.resetFormFile();
		this.formField.liberarCaucao = false;

		this.resgateFormService.setFormField(this.formField);
		if (this.formField.type.resgateType.value === 'Resgate antecipado' && this.formField.type.caucionado === false) {
			this.formField.type.customerAgreement.show = true;
			this.formField.type.customerAgreement.value = null;
			this.formField.type.titleAquisition.show = true;
			this.formField.type.titleAquisition.value = 'false';
			this.formField.type.resgateFinalMessage.show = false;
			this.formField.type.resgateFinalMessage.value = null;
		} else if (this.formField.type.resgateType.value === 'Resgate final' && this.formField.type.caucionado === false) {
			this.formField.type.customerAgreement.show = false;
			this.formField.type.customerAgreement.hasError = false;
			this.formField.type.customerAgreement.value = null;
			this.formField.type.titleAquisition.show = false;
			this.formField.type.titleAquisition.hasError = false;
			this.formField.type.titleAquisition.value = null;
			this.formField.type.proposta.value = null;
			this.autoComplete.clearInput(this.$scope, 'searchProposta');
			this.formField.type.proposta.show = false;
			this.formField.type.proposta.hasError = false;
			for (let i = 0; i < this.formField.information.garantias.value.length; i++) {
				console.log(this.formField.information.garantias.value[i].finalVigencia);
				if (this.formField.information.garantias.value[i].objectName.includes('(Descaucionado)') && this.formField.information.garantias.value[i].vigencia > 30 && this.formField.information.garantias.value[i].finalVigencia > 150 && this.formField.information.garantias.value[i].selected === true) {
					this.formField.type.resgateFinalMessage.show = true;
					this.formField.type.resgateFinalMessage.value = 'A solicitação de resgate final será possível apenas com até 150 dias de antecedência do termino da vigência do título. Neste momento é possível solicitar somente liberação da caução sem pagamento, onde o título deixará de ser uma garantia do imóvel locado.';
					this.formField.type.titleAquisition.show = false;
					this.formField.type.titleAquisition.hasError = false;
					this.formField.type.titleAquisition.value = null;
					break;
				} else {
					this.formField.type.resgateFinalMessage.show = false;
					this.formField.type.resgateFinalMessage.value = null;
					this.formField.type.titleAquisition.show = true;
					this.formField.type.titleAquisition.value = 'false';
				}
			}
			if (this.formField.type.resgateFinalMessage.show === false) {
				this.formField.type.titleAquisition.show = true;
				this.formField.type.titleAquisition.value = 'false';
			}

		} else if (this.formField.type.resgateType.value === 'Resgate antecipado' && this.formField.type.caucionado === true) {
			this.formField.type.customerAgreement.show = true;
			this.formField.type.customerAgreement.value = null;
			this.formField.type.realtyDebits.show = true;
			this.formField.type.realtyDebits.value = null;
			this.formField.type.proposta.show = false;
			this.formField.type.proposta.hasError = false;
			this.formField.type.proposta.value = null;
			this.autoComplete.clearInput(this.$scope, 'searchProposta');
			this.formField.type.debitValue.show = false;
			this.formField.type.debitValue.hasError = false;
			this.formField.type.debitValue.value = null;
			this.formField.type.hasRenterApprove.show = false;
			this.formField.type.hasRenterApprove.hasError = false;
			this.formField.type.hasRenterApprove.value = null;
			this.formField.type.resgateFinalMessage.show = false;
			this.formField.type.resgateFinalMessage.hasError = false;
			this.formField.type.resgateFinalMessage.value = null;
			this.formField.type.titleAquisition.show = false;
			this.formField.type.titleAquisition.hasError = false;
			this.formField.type.titleAquisition.value = null;
		} else if (this.formField.type.resgateType.value === 'Resgate final' && this.formField.type.caucionado === true) {
			this.formField.type.customerAgreement.show = false;
			this.formField.type.customerAgreement.hasError = false;
			this.formField.type.customerAgreement.value = null;
			this.formField.type.realtyDebits.show = false;
			this.formField.type.realtyDebits.hasError = false;
			this.formField.type.realtyDebits.value = null;
			this.formField.type.proposta.show = false;
			this.formField.type.proposta.hasError = false;
			this.formField.type.proposta.value = null;
			this.autoComplete.clearInput(this.$scope, 'searchProposta');
			this.formField.type.debitValue.show = false;
			this.formField.type.debitValue.hasError = false;
			this.formField.type.debitValue.value = null;
			this.formField.type.hasRenterApprove.show = false;
			this.formField.type.hasRenterApprove.hasError = false;
			this.formField.type.hasRenterApprove.value = null;
			this.formField.type.resgateFinalMessage.show = false;
			this.formField.type.resgateFinalMessage.value = null;
			this.formField.type.titleAquisition.show = false;
			this.formField.type.titleAquisition.hasError = false;
			this.formField.type.titleAquisition.value = null;
			for (let i = 0; i < this.formField.information.garantias.value.length; i++) {
				console.log(this.formField.information.garantias.value[i].finalVigencia);
				if (this.formField.information.garantias.value[i].objectName.includes('(Caucionado)') && this.formField.information.garantias.value[i].vigencia > 30 && this.formField.information.garantias.value[i].finalVigencia > 150 && this.formField.information.garantias.value[i].selected === true) {
					this.formField.type.resgateFinalMessage.show = true;
					this.formField.type.resgateFinalMessage.value = 'A solicitação de resgate final será possível apenas com até 150 dias de antecedência do termino da vigência do título. Neste momento é possível solicitar somente liberação da caução sem pagamento, onde o título deixará de ser uma garantia do imóvel locado.';
					this.formField.type.realtyDebits.show = false;
					this.formField.type.realtyDebits.hasError = false;
					this.formField.type.realtyDebits.value = null;
					break;
				} else {
					this.formField.type.resgateFinalMessage.show = false;
					this.formField.type.resgateFinalMessage.value = null;
				}
			}
			if (this.formField.type.resgateFinalMessage.show === false) {
				this.formField.type.realtyDebits.show = true;
				this.formField.type.realtyDebits.value = null;
			}
		}
	}

	liberarCaucao() {
		this.resgateService.setErrorMessage('');

		// Prepara um novo objeto de dados de liberação da caução
		// Aproveitando apenas a aba information
		this.liberacaoDaCaucaoFormControlService.setPristine();
		angular.merge(this.liberacaoDaCaucaoFormControlService.information, this.liberacaoDaCaucaoFormControlService.information, this.formField.information);

		if (this.formField.id !== null) {
			this.$scope.$emit('changeMainShowLoading', true);
			this.resgateService.cancelResgate(this.formField.id).then((response) => {
				this.$scope.$emit('changeMainShowLoading', false);
				if (!response.isSuccessful) this.resgateService.setErrorMessage(response.errorMessages[0]);
			});
		}
		this.$state.go('case.liberacao-da-caucao.new', { formField: this.liberacaoDaCaucaoFormControlService });
	}

	searchProposta(searchString) {
		const resgateType = this.$parent.resgateType;
		return resgateType.resgateTypeService.searchProposta(searchString, resgateType.formField.information.mainAccount.value.Id, resgateType.formField.information.proposta.value.objectId);
	}

	searchPropostaSelect(item) {
		const resgateType = this.$parent.resgateType;
		if (resgateType.formField.type.proposta.value === null) {
			if (!item) { return; }

			const hasAction = !!item.originalObject.action;

			if (hasAction) {

				const action = item.originalObject.action;

				resgateType[action](item);

				return;
			}

			resgateType.formField.type.proposta.value = item.originalObject;
			resgateType.formField.type.proposta.hasError = !resgateType.hasProposta();
			resgateType.resgateFormService.setFormField(resgateType.formField);
		}
	}

	hasProposta() {
		return !!this.formField.type.proposta.value;
	}

	searchPropostaFocusOut() {
		this.$timeout(() => {
			if (!this.autoComplete.hasValidInput(this.formField.type.proposta.value)) {
				this.autoComplete.clearInput(this.$scope, 'searchProposta');
				this.resgateFormService.setFormField(this.formField);
			}
		}, 100);
	}

	searchPropostaChanged() {
		console.log('entrou no searchPropostaChanged');
		const resgateType = this.$parent.resgateType;
		resgateType.resgateService.setErrorMessage('');
		resgateType.autoComplete.resetSelected(resgateType.formField.type.proposta, 'value');
		resgateType.resgateFormService.resetFormData();
		resgateType.resgateFormService.resetFormBank();
		resgateType.resgateFormService.resetFormFile();
	}

	newProposta() {
		this.formControlService.reset();
		this.resgateService.setPropostaFromResgate(true);
		this.resgateService.setResgateAccount(this.formField.information.mainAccount.value);
		this.$state.go('proposta.new');
	}

}

module.exports = resgateTypeController;
