'use strict';

const inputErrorClass = 'has-error';
const minUnitValue = 1000;
const maxUnitValue = 500000;

class propostaInformationController {
	/* @ngInject */
	constructor($scope, sfConfig, propostaInformationApi, formControlService) {
		this.imageRemove = `${sfConfig.resource}/images/ico-rem.png`;

		this.$scope = $scope;
		this.propostaInformationApi = propostaInformationApi;
		this.formControl = formControlService;
		this.formField = this.formControl.field;

		this.formField.formStep = 'proposta.information';

		this.fieldErrors = {
			productError: '',
			susepError: '',
			corretoraError: '',
			saleStructureError: '',
			rentError: '',
			chargesError: '',
			titleMultiplierError: '',
			title1: {
				qtdError: '',
				unitError: '',
				unitErrorMsg: ''
			},
			title2: {
				qtdError: '',
				unitError: '',
				unitErrorMsg: ''
			},
			title3: {
				qtdError: '',
				unitError: '',
				unitErrorMsg: ''
			},
			title: {
				qtdError: '',
				unitError: '',
				unitErrorMsg: ''
			}
		};

		this.title = this.initializeTitle();
		this.resetDGFields();
		this.setProductsList();
		this.setCorretorasList();
		this.setSalesStructureList();
		this.checkOnLoadRequiredFields();
		this.listenValidationRequest();
		this.listenRequiredRequest();
		this.listenNextStep();
		this.clearParentMessages();

		$('[data-toggle="tooltip"]').tooltip();
	}

	hasTitle() {
		return this.formField.title1 ? true : false;
	}

	changeDGrentValue() {
		console.log('RENT VALUE DG: ', this.formField.DG);
	}

	checkOnLoadRequiredFields() {
		// console.log('checkOnLoadRequiredFields');

		if (!this.isFormPristine() && this.formRequired()) {
			return;
		}

		this.formField.stepStatusPropostaInformation = 'Incompleto';
	}

	isFormPristine() {
		// console.log('isFormPristine');

		return this.formField.stepStatusPropostaInformation === 'Não Iniciado';
	}

	clearParentMessages() {
		this.$scope.$emit('clearMessages');
	}

	listenValidationRequest() {
		this.$scope.$on('stepValidate', () => {
			// console.log('passou validationRequest');
			this.$scope.$emit('stepValidateResponse', this.formValidation());
		});
	}

	listenRequiredRequest() {
		this.$scope.$on('stepRequired', () => {
			// console.log('passou requiredRequest');
			this.$scope.$emit('stepRequiredResponse', this.formRequired());
		});
	}

	listenNextStep() {
		this.$scope.$on('nextStep', () => {
			// console.log('listen nextState propostaInformation');
			this.checkAddNewTitle();
		});
	}

	isPropostaDuplaGarantia() {
		const propostaType = sessionStorage.getItem('tipo_lista');
		if (propostaType == 'D') {
			return true;
		} else {
			return false;
		}
	}

	setProductsList() {
		const proposalType = this.formField.proposalType;

		this.propostaInformationApi.getProductsList(proposalType).then((response) => {
			console.log('PRODUTOS');
			console.log(JSON.stringify(response));
			Array.isArray(response) ? (this.products = response) : (this.products = []);
		});

		/*
		if (this.formField.proposalType == 'D') {
			this.propostaInformationApi.getProductsListDG().then((response) => {
				console.log('PRODUTOS DUPLA GARANTIA');
				console.log(JSON.stringify(response));
				Array.isArray(response) ? (this.productsDG = response) : (this.productsDG = []);
			});
		} */
	}

	setCorretorasList() {
		this.propostaInformationApi.getCorretorasList().then((response) => {
			if (response.isSuccessful) {
				if (response.corretoras.length > 0) {
					this.corretoras = response.corretoras;
				} else {
					this.$scope.proposta.saveMsgError = 'Nenhum registro de corretora encontrado';
				}
			} else {
				this.$scope.proposta.saveMsgError = response.errorMessages;
			}
		});
	}

	setSalesStructureList() {
		this.propostaInformationApi.getSalesStructureList().then((response) => {
			// console.log('ESTRUTURAS');
			// console.log(JSON.stringify(response.lstEstrutura));
			// console.log(JSON.stringify(response));
			response.isSuccessful ? (this.saleStructures = response.lstEstrutura) : (this.saleStructures = []);
		});
	}

	initializeTitle() {
		return {
			titleSQuantity: '1',
			value: ''
		};
	}

	checkAddNewTitle() {
		if (this.title.titleSQuantity || this.title.value) {
			this.addNewTitle();
		}
	}

	addNewTitle() {
		if (this.formField.status && this.formField.status != 'Em andamento') {
			return;
		}

		if (!this.validateTitle()) {
			return;
		}

		if (!this.formField.title1) {
			this.formField.title1 = this.title;
		} else if (!this.formField.title2) {
			this.formField.title2 = this.title;
		} else if (!this.formField.title3) {
			this.formField.title3 = this.title;
		}

		this.title = this.initializeTitle();
	}

	hasTitleFormComplete() {
		return !!this.formField.title1; // && !!this.formField.title2 && !!this.formField.title3
	}

	validateTitle() {
		// console.log('validateTitle', this.title.titleSQuantity, this.title.value);
		let result = true;

		if (!this.title.titleSQuantity) {
			this.fieldErrors.title.qtdError = inputErrorClass;
			result = false;
		} else {
			this.fieldErrors.title.qtdError = '';
		}

		if (!this.title.value) {
			// console.log('!this.title.value', this.title.value);
			this.fieldErrors.title.unitError = inputErrorClass;
			this.fieldErrors.title.unitErrorMsg = 'Campo obrigatório';
			result = false;
		} else if (this.title.value < minUnitValue || this.title.value > maxUnitValue) {
			this.fieldErrors.title.unitError = inputErrorClass;
			this.fieldErrors.title.unitErrorMsg = 'Informação inválida';
			result = false;
		} else if (this.isMoreThanResgateValue()) {
			this.fieldErrors.title.unitError = inputErrorClass;
			this.fieldErrors.title.unitErrorMsg = 'O valor da proposta não pode ser maior que o valor do resgate.';
			result = false;
		} else {
			this.fieldErrors.title.unitError = '';
		}

		return result;
	}

	isMoreThanResgateValue() {
		const resgateValue = this.formControl.data.resgateValue;

		// console.log(this.title.titleSQuantity, this.title.value, resgateValue);

		return this.title.titleSQuantity && resgateValue !== null && this.sumTitles() > resgateValue;
	}

	totalSingleTitle(title) {
		let sumTotal = 0;

		if (title) {
			sumTotal += title.titleSQuantity * title.value;
		}
		// console.log('totalSingleTitle', sumTotal);

		return sumTotal;
	}

	sumTitles() {
		let sumTotal = 0;

		sumTotal += this.totalSingleTitle(this.title);
		sumTotal += this.totalSingleTitle(this.formField.title1);
		sumTotal += this.totalSingleTitle(this.formField.title2);
		sumTotal += this.totalSingleTitle(this.formField.title3);
		// console.log('sumTitles', sumTotal);

		return sumTotal;
	}

	removeTitle(title) {
		if (this.formField.status && this.formField.status != 'Em andamento') {
			return;
		}

		if (title == this.formField.title3) {
			this.formField.title3 = null;
		} else if (title == this.formField.title2 && this.formField.title3) {
			const titleAux = this.formField.title3;
			this.formField.title3 = null;
			this.formField.title2 = titleAux;
		} else if (title == this.formField.title2 && !this.formField.title3) {
			this.formField.title2 = null;
		} else if (title == this.formField.title1 && this.formField.title2) {
			const titleAux = this.formField.title2;
			this.formField.title2 = null;
			this.formField.title1 = titleAux;
		} else if (title == this.formField.title1) {
			this.formField.title1 = null;
		}
	}

	formValidation() {
		return true;
	}

	formRequired() {
		let result = true;

		if (!this.formField.product) {
			console.log('PRODUTO com problema');
			this.fieldErrors.productError = inputErrorClass;
			result = false;
		} else {
			this.fieldErrors.productError = '';
		}

		if (!this.formField.susepRegistry) {
			console.log('SUSEP com problema');
			this.fieldErrors.susepError = inputErrorClass;
			result = false;
		} else {
			this.fieldErrors.susepError = '';
		}

		if (!this.formField.corretora || this.isEmpty(this.formField.corretora)) {
			this.fieldErrors.corretoraError = inputErrorClass;
			console.log('CORRETORA com problema');
			result = false;
		} else {
			this.fieldErrors.corretoraError = '';
		}

		if (!this.formField.rentValue /* || !this.propostaInformationApi.isValidMoneyValue(this.formField.rentValue)*/ ) {
			console.log('VALOR DO ALUGUEL com problema');
			this.fieldErrors.rentError = inputErrorClass;
			result = false;
		} else {
			this.fieldErrors.rentError = '';
		}

		if (!this.formField.titlesCharges) {
			console.log('TAXAS DO TITULO com problema');
			this.fieldErrors.chargesError = inputErrorClass;
			result = false;
		} else {
			this.fieldErrors.chargesError = '';
		}

		if (!this.formField.titleMultiplier || isNaN(this.formField.titleMultiplier)) {
			console.log('MULTIPLICADOR com problema');
			this.fieldErrors.titleMultiplierError = inputErrorClass;
			result = false;
		} else {
			this.fieldErrors.titleMultiplierError = '';
		}

		if (!this.formField.title1) {
			console.log('TITULO 1 com problema');
			this.validateTitle();
			result = false;
		}

		if (result) {
			this.formField.stepStatusPropostaInformation = 'Concluido';
		} else {
			this.formField.stepStatusPropostaInformation = 'Incompleto';
		}

		return result;
	}

	requireProduct() {
		if (!this.formField.product) {
			this.fieldErrors.productError = inputErrorClass;
			return false;
		} else {
			this.fieldErrors.productError = '';
			return true;
		}
	}

	requireSusepRegistry() {
		if (!this.formField.susepRegistry) {
			this.fieldErrors.susepError = inputErrorClass;
			return false;
		} else {
			this.fieldErrors.susepError = '';
			return true;
		}
	}

	requireCorretora() {
		if (!this.formField.corretora || this.isEmpty(this.formField.corretora)) {
			this.fieldErrors.corretoraError = inputErrorClass;
			return false;
		} else {
			console.log('Corretora: ', this.formField.corretora);

			if (this.isPropostaDuplaGarantia() && this.formField.corretora != null) {
				if (this.formField.corretora.cpfCnpj != null) {
					this.$scope.$emit('changeMainShowLoading', true);
					this.propostaInformationApi.getBrokerAccountAPI(this.formField.corretora.cpfCnpj).then((response) => {
						console.log('RESPOSTA API CORRETOR: ', response);
						if (response[0].isSuccessful == true) {
							//this.formField.DG.brokerAccount = this.formField.corretora;
							this.formField.APIerrorMessage = null;
							this.formField.DG.brokerAccount = response[0];
							this.formField.DG.susepRegistry = response[0].codigoSusep;
							this.formField.susepRegistry = response[0].codigoSusep;

							//Recarrega os produtos para a corretora selecionada
							this.propostaInformationApi.getProductsListDG(this.formField.corretora.value).then((response) => {
								Array.isArray(response) ? (this.formField.DG.productList = response) : (this.formField.DG.productList = []);
								console.log('Produtos DG: ', this.formField.DG.productList);
							});
						} else {
							//IsSucessfull vem sempre true, mesmo quando há erro, por isso a validação é feita em cima do errorMessages
							//A flag isSucessfull só retorna false caso de erro HTTP
							if (response[0].errorMessages != null) {
								console.log('Entrou no reset de produtos DG');
								//Caso a corretora não exista no SISVIDA, limpa o campo de produtos DG
								this.formField.DG.productList = null;
							}
							console.log('Entrou no trecho de erro, integração corretor SISVIDA');
							this.formField.DG.brokerAccount = null;
							this.formField.DG.susepRegistry = null;
							this.formField.susepRegistry = null;
							this.formField.APIerrorMessage = response[0].errorMessages[0];
							$('#warningErrorAPI').modal('show');
						}
						this.$scope.$emit('changeMainShowLoading', false);
					});
				}
			}

			this.fieldErrors.corretoraError = '';
			return true;
		}
	}

	requireSaleStructure() {
		if (!this.formField.saleStructure) {
			this.fieldErrors.saleStructureError = inputErrorClass;
			return false;
		} else {
			this.fieldErrors.saleStructureError = '';
			return true;
		}
	}

	requireTitleSQuantity() {
		if (!this.title.titleSQuantity) {
			this.fieldErrors.title.qtdError = inputErrorClass;
			return false;
		} else {
			this.fieldErrors.title.qtdError = '';
			return true;
		}
	}

	requireTitleValue() {
		if (!this.title.value) {
			// console.log('!this.title.value', this.title.value);
			this.fieldErrors.title.unitError = inputErrorClass;
			this.fieldErrors.title.unitErrorMsg = 'Campo obrigatório';
			return false;
		} else if (this.title.value < minUnitValue || this.title.value > maxUnitValue) {
			this.fieldErrors.title.unitError = inputErrorClass;
			this.fieldErrors.title.unitErrorMsg = 'Informação inválida';
			return false;
		} else {
			this.fieldErrors.title.unitError = '';
			return true;
		}
	}

	requireRentValue() {
		if (!this.formField.rentValue) {
			this.fieldErrors.rentError = inputErrorClass;
			return false;
		} else {
			this.fieldErrors.rentError = '';

			//Agora o valor do seguro é calculado no change do campo Produto Seguro Prestamista
			//this.calculateDgFields();
			this.resetDGFields();

			return true;
		}
	}

	calcPercent(num, percentage) {
		const result = num * (percentage / 100);
		return parseFloat(result);
	}

	canCalculateLifeTermValue() {
		console.log('CPF: ', this.formField.account.cpf);
		console.log('Person Birth Date: ', this.formField.account.personBirthdate);
		console.log('Insurance Term: ', this.formField.DG.insuranceTerm);
		console.log('Rent Value: ', this.formField.rentValue);
		if (this.formField.account.cpf != null && this.formField.account.personBirthdate && this.formField.DG.insuranceTerm != null && this.formField.rentValue != null) {
			return 'true';
		} else {
			return 'false';
		}
	}

	resetDGFields() {
		this.formField.DG.product = null;
		this.formField.DG.lifeTermValue = null;

		//Agora todos os campos de valor são resetados ao mudar o valor de aluguel,
		//Pq todos são calculados a partir da vigencia de cada produto
		this.formField.DG.rentLossValue = null;
		this.formField.DG.anyCauseValue = null;
		this.formField.DG.totalByAccidentValue = null;
		this.formField.DG.insuranceTerm = null;
	}

	changeDGProduct(productSelected) {
		const productForms = this.formField.DG.product;

		//Verifica se o produto está preenchido antes de calcular
		if (productForms != null) {
			//STEP 1:
			//Antes de calcular o valor do seguro, carrega as informações do produto -> NOVO
			console.log('Produto selecionado - ', productSelected);

			this.propostaInformationApi.getProductInfo(productSelected).then((response) => {
				this.$scope.$emit('changeMainShowLoading', true);
				console.log('PRODUCT INFO RESPONSE: ');
				console.log(JSON.stringify(response));
				if (response != null) {
					this.formField.DG.productInfo = response;
					this.formField.DG.insuranceTerm = response.NR_PRAZO_CAPIT_MESES__c;
				} else {
					this.formField.DG.productInfo = null;
				}

				//STEP 2:
				//Calcula valor de morte, perda de renda e invalidez permanente
				this.calculateDgFields();

				//STEP 3:
				//Calcula o valor do seguro prestmista
				console.log('Pode calcular valor do seguro prestamista? ', this.canCalculateLifeTermValue());

				if (this.canCalculateLifeTermValue() == 'true') {
					console.log('Entrou no calcula seguro');
					const underline = '_';
					const grupo = this.formField.DG.productInfo.Grupo__c;
					const subGrupo = this.formField.DG.productInfo.Subgrupo__c;
					const modulo = this.formField.DG.productInfo.Modulo__c;
					//this.$scope.$emit("changeMainShowLoading", true);
					const productCode = grupo + underline + subGrupo + underline + modulo;
					// Gambiarra pois a versão do ESLint não aceita concatenação usando ${} e nem '' + ''
					console.log('Código do produto gerado: ', productCode);

					this.propostaInformationApi
						.getLifeTermValue(this.formField.account.cpf, this.formField.account.personBirthdate, this.formField.DG.insuranceTerm, this.formField.rentValue, productCode)
						.then((response) => {
							console.log('RESPOSTA VALOR DO SEGURO: ', response);
							if ((response.isSuccessful = 'true')) {
								this.formControl.field.APIerrorMessage = null;
								this.formField.DG.lifeTermValue = response.valorPremioTotal;
								for (let i = 0; i < response.coberturas.length; i++) {
									if (response.coberturas[i].numero == 1) {
										this.formField.DG.anyCauseValueSISVIDA = response.coberturas[i].valorPremio;
									}
									if (response.coberturas[i].numero == 24) {
										this.formField.DG.totalByAccidentValueSISVIDA = response.coberturas[i].valorPremio;
									}
									if (response.coberturas[i].numero == 108) {
										this.formField.DG.rentLossValueSISVIDA = response.coberturas[i].valorPremio;
									}
								}
								console.log('DG FORMS ATUALIZADO: ', this.formField.DG);
							} else {
								this.formField.APIerrorMessage = 'Por favor, acione o seu Assessor Comercial.';
								this.formField.DG.lifeTermValue = 0;
								$('#warningErrorAPI').modal('show');
							}
							this.$scope.$emit('changeMainShowLoading', false);
						});
				} else {
					this.formField.DG.lifeTermValue = 0;
					this.formControl.field.APIerrorMessage = 'Por favor, acione o seu Assessor Comercial.';
					$('#warningErrorAPI').modal('show');
				}

				this.$scope.$emit('changeMainShowLoading', false);
			});
		}
	}

	calculateDgFields() {
		console.log('iniciando calculo dg ----');
		console.log('this.formField.rentValue: ', this.formField.rentValue);
		console.log('this.formField.DG.insuranceTerm: ', this.formField.DG.insuranceTerm);

		const rentValue = parseFloat(this.formField.rentValue);
		const anyCauseValue = rentValue * this.formField.DG.insuranceTerm;
		const totalByAccidentValue = rentValue * this.formField.DG.insuranceTerm;

		console.log('rentValue: ', rentValue);
		console.log('anyCauseValue: ', anyCauseValue);
		console.log('totalByAccidentValue: ', totalByAccidentValue);

		console.log('');

		this.formField.DG.anyCauseValue = anyCauseValue < 100000 ? anyCauseValue.toFixed(2) : 100000.0;
		this.formField.DG.totalByAccidentValue = totalByAccidentValue < 100000 ? totalByAccidentValue.toFixed(2) : 100000.0;
		this.formField.DG.rentLossValue = this.calcPercent(this.formField.DG.totalByAccidentValue, 10).toFixed(2);

		console.log('DG FORM FIELDS: ', this.formField.DG);
		console.log('RENT VALUR: ', this.formField.rentValue);
	}

	requireTitlesCharges() {
		if (!this.formField.titlesCharges) {
			this.fieldErrors.chargesError = inputErrorClass;
			return false;
		} else {
			this.fieldErrors.chargesError = '';
			return true;
		}
	}

	requireTitleMultiplier() {
		if (!this.formField.titleMultiplier || isNaN(this.formField.titleMultiplier)) {
			this.fieldErrors.titleMultiplierError = inputErrorClass;
			return false;
		} else {
			this.fieldErrors.titleMultiplierError = '';
			return true;
		}
	}

	isEmpty(obj) {
		for (const key in obj) {
			if (obj.hasOwnProperty(key)) return false;
		}
		return true;
	}
}

module.exports = propostaInformationController;
