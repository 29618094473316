'use strict';

const annexHelper = require('./annex.service');
const annexApiHelper = require('./api.service');

const coreModule = angular.module('cap.secured.core.helper.annex', [])
	.factory('annexHelper', annexHelper)
	.service('annexApiHelper', annexApiHelper);

module.exports = coreModule;
