'use strict';

class resgateController {

	// @ngInject
	constructor(resgateService, resgateFormService, $scope, $timeout, $state, resgateFileService, dadosBancariosHelper, $window, accountUtilService, validationFormFields, annexHelper, breadcrumbHelper, utilsHelper) {
		this.$scope = $scope;
		this.$state = $state;
		this.$window = $window;
		this.$timeout = $timeout;

		this.resgateService = resgateService;
		this.resgateFormService = resgateFormService;
		this.resgateFileService = resgateFileService;
		this.accountUtilService = accountUtilService;
		this.annexHelper = annexHelper;
		this.utilsHelper = utilsHelper;
		this.dadosBancariosHelper = dadosBancariosHelper;
		this.formField = this.resgateFormService.getFormField();
		this.valid = validationFormFields;

		if (this.resgateService.getIsEdit() === false) {
			this.resgateService.setPreviousShow(false);
			this.resgateService.setPreviousDisabled(true);
			this.resgateService.setFinishShow(false);
			this.resgateService.setFinishDisabled(true);
			this.resgateService.setNextShow(true);
			this.resgateService.setNextDisabled(true);
			this.resgateService.setSaveShow(true);
			this.resgateService.setSaveDisabled(false);
			this.resgateService.setCancelShow(true);
			this.resgateService.setCancelDisabled(false);
		}

		/** QUANDO JÁ FOR ENVIADA A MANIFESTAÇÃO E O STATUS DO TÍTULO FOR RESGATADO, NÃO DEIXAR CANCELAR */
		if (this.formField.isHaveProblemBank) {
			this.resgateService.setCancelShow(false);
		} else {
			this.resgateService.setCancelShow(true);
		}

		this.resgateService.setErrorMessage('');
		this.resgateService.setWarningMessage('');
		this.filesAsyncRequest = [];
		this.isOnlySave = false;
		// console.log('iniciando resgate controller');

		this.$scope.breadcrumb = breadcrumbHelper;

		this.dataCheckNewFields();
		this.listenHasRequired();
		this.listenValidUploads();

		/* Se identificar que algum  upload não está integro, atualizar o editcasedata */
		if (!this.checkUploadIntegrity()) this.saveCaseUpdateEditCaseData();
	}

	dataCheckNewFields() {
		// Novas chaves no Json são criadas no FormField do legado
		// Para não acontecer erros de undefined na estrutura
		let dataTemp = null; // para trabalhar com dados temporarios
		//--- 2018-07-04 CAP-3709
		if (!this.formField.file.legalRepresentative.identityRepresentante) this.formField.file.legalRepresentative.identityRepresentante = this.resgateFormService.pristineAnnexObj();
		//--- 2018-07-11
		if (!this.formField.bank.bankData.agencyDV) this.formField.bank.bankData.agencyDV = this.resgateFormService.pristineFormObj();
		if (!this.formField.bank.bankData.accountNumberDV) this.formField.bank.bankData.accountNumberDV = this.resgateFormService.pristineFormObj();
		if (!this.formField.bank.bankBills.agencyDV) this.formField.bank.bankBills.agencyDV = this.resgateFormService.pristineFormObj();
		if (!this.formField.bank.bankBills.accountNumberDV) this.formField.bank.bankBills.accountNumberDV = this.resgateFormService.pristineFormObj();
		// CAP-3696 Quebrar no layout e adaptar no modelo para termos campos de DV para agência e conta
		if ((this.formField.bank.bankData.agency.value) && (this.formField.bank.bankData.agency.value.includes("-"))) {
			dataTemp = this.formField.bank.bankData.agency.value.split("-", 2);
			this.formField.bank.bankData.agency.value = dataTemp[0];
			this.formField.bank.bankData.agencyDV.value = dataTemp[1];
		}
		if ((this.formField.bank.bankData.accountNumber.value) && (this.formField.bank.bankData.accountNumber.value.includes("-"))) {
			dataTemp = this.formField.bank.bankData.accountNumber.value.split("-", 2);
			this.formField.bank.bankData.accountNumber.value = dataTemp[0];
			this.formField.bank.bankData.accountNumberDV.value = dataTemp[1];
		}
		if ((this.formField.bank.bankBills.agency.value) && (this.formField.bank.bankBills.agency.value.includes("-"))) {
			dataTemp = this.formField.bank.bankBills.agency.value.split("-", 2);
			this.formField.bank.bankBills.agency.value = dataTemp[0];
			this.formField.bank.bankBills.agencyDV.value = dataTemp[1];
		}
		if ((this.formField.bank.bankBills.accountNumber.value) && (this.formField.bank.bankBills.accountNumber.value.includes("-"))) {
			dataTemp = this.formField.bank.bankBills.accountNumber.value.split("-", 2);
			this.formField.bank.bankBills.accountNumber.value = dataTemp[0];
			this.formField.bank.bankBills.accountNumberDV.value = dataTemp[1];
		}
	}

	listenValidUploads() {
		// console.log('listenValidUploads');
		this.$scope.$on('validateUploads', (event) => {
			console.log('listened validateUploads');
			event.stopPropagation();
			this.resgateService.setFinishDisabled(!this.isValidUploads());
		});
	}

	//@todo gambiarra pq as validações estão aqui e não num serviço
	listenHasRequired() {
		// console.log('listenHasRequired');
		this.$scope.$on('resgateHasRequired', (event, requirementType) => {
			// console.log('listened resgateHasRequired', event, requirementType);
			this[`hasRequired${requirementType}`]();
		});
	}

	isMainAccountPerson() {
		return this.hasRequiredMainAccount() && this.formField.information.mainAccount.value.isPersonAccount;
	}

	hasTituloCaucionadoSelected() {
		let founded = false;
		if (this.formField.information.garantias.value != null) {
			for (let i = 0; i < this.formField.information.garantias.value.length; i++) {
				if (this.formField.information.garantias.value[i].selected && this.formField.information.garantias.value[i].objectName.includes('(Caucionado)')) {
					founded = true;
					break;
				}
			}
		}
		return founded;
	}

	calculateRemainValue(valorDebito) {

		console.log('--> calculateRemainValue iniciado')

		for (let i = 0; i < this.formField.information.garantias.value.length; i++) {
			const tituloId = this.formField.information.garantias.value[i].objectId;
			const valorResgate = this.formField.information.garantias.value[i].valorResgate;

			console.log('--> tituloId: ', tituloId)
			console.log('--> valorResgate: ', valorResgate)

			this.resgateService.calculateRemainValue(valorDebito, tituloId, valorResgate);
		}

	}

	isValidDebitValue() {

		let value = 0;

		for (let i = 0; i < this.formField.information.garantias.value.length; i++) {

			const tituloId = this.formField.information.garantias.value[i].objectId;

			console.log('tituloId: ', tituloId);
			console.log('valorResgate: ', this.formField.information.garantias.value[i].valorResgate);

			const valorResgate = this.formField.information.garantias.value[i].valorResgate;

			if (valorResgate == null || valorResgate == undefined) {
				this.formField.type.debitValue.isValid = false;
				return false;
			}

			console.log('valorResgate: ', valorResgate)

			if (this.formField.information.garantias.value[i].selected === true) {
				if (this.formField.information.garantias.value[i].value > this.formField.information.garantias.value[i].antecipatedValue) {
					value += this.formField.information.garantias.value[i].value;
				} else {
					value += this.formField.information.garantias.value[i].antecipatedValue;
				}
			}

			if (this.formField.type.debitValue.value > valorResgate) {
				this.formField.type.debitValue.isValid = false;
				return false;
			}
		}

		this.formField.type.debitValue.isValid = true;
		return true;
	}

	hasRequiredDebitValue() {
		let hasRequirement = true;
		if (this.formField.type.debitValue.value === null || this.formField.type.debitValue.value === 0 || this.formField.type.debitValue.value === '') {
			this.formField.type.debitValue.isValid = true;
			this.resgateService.setErrorMessage('');
			hasRequirement = false;
		}
		this.formField.type.debitValue.hasError = !hasRequirement;
		return hasRequirement;
	}

	hasRequiredMainAccount() {
		let hasRequirement = true;
		if (this.formField.information.mainAccount.value === null) {
			hasRequirement = false;
		}
		this.formField.information.mainAccount.hasError = !hasRequirement;
		return hasRequirement;
	}

	hasButton(buttonName) {
		/* Método com as regras de exibição dos botões da tela de resgate, usado no ng-show dos botoes */
		if (buttonName === "anterior") {
			return this.resgateService.buttonControl.previous.show;
		} else if (buttonName === "proximo") {
			return this.resgateService.buttonControl.next.show;
		} else if (buttonName === "salvar") {
			return (!this.formField.origin.app) && (this.resgateService.buttonControl.save.show) && (this.formField.status !== 'Pagamento Criticado');
		} else if (buttonName === "cancelar") {
			return (!this.formField.origin.app) && (this.resgateService.buttonControl.cancel.show) && (this.formField.status !== 'Pagamento Criticado');
		} else if (buttonName === "reprovar") {
			return (this.formField.origin.app) &&
				((this.formField.status === 'Aguardando Pré-análise') || (this.formField.status === 'Em andamento'));
		} else if (buttonName === "aprovar") {
			return (this.formField.origin.app) &&
				((this.formField.status === 'Aguardando Pré-análise') || (this.formField.status === 'Em andamento')) &&
				(this.$state.current.name === 'resgate.file');
		} else if (buttonName === "enviar") {
			return (!this.formField.origin.app) && (this.resgateService.buttonControl.finish.show);
		}
	}

	hasRequiredProposta() {
		let hasRequirement = true;
		if (this.formField.information.proposta.value === null) {
			hasRequirement = false;
		}
		this.formField.information.proposta.hasError = !hasRequirement;
		return hasRequirement;
	}

	hasRequiredGarantias() {
		let hasRequirement = true;
		let hasGarantiaSelected = false;
		if (this.formField.information.garantias.value !== null) {
			for (let i = 0; i < this.formField.information.garantias.value.length; i++) {
				if (this.formField.information.garantias.value[i].selected) {
					hasGarantiaSelected = true;
					break;
				}
			}
		}
		if (!hasGarantiaSelected) {
			hasRequirement = false;
		}
		this.formField.information.garantias.hasError = !hasRequirement;
		return hasRequirement;
	}

	hasRequiredLegalRepresentative() {
		let hasRequirement = true;
		if (this.hasTituloCaucionadoSelected()) {
			if (this.formField.information.legalRepresentative.value === null) {
				hasRequirement = false;
			}
		}
		this.formField.information.legalRepresentative.hasError = !hasRequirement;
		return hasRequirement;
	}

	hasRequiredRealtyDebits() {
		let hasRequirement = true;
		if (this.formField.type.realtyDebits.value === null) {
			hasRequirement = false;
		}
		this.formField.type.realtyDebits.hasError = !hasRequirement;
		return hasRequirement;
	}

	hasRequiredRenterApprove() {
		let hasRequirement = true;
		if (this.formField.type.hasRenterApprove.value === null) {
			hasRequirement = false;
		}
		this.formField.type.hasRenterApprove.hasError = !hasRequirement;
		return hasRequirement;
	}

	hasRequiredTypeProposta() {
		let hasRequirement = true;
		if (this.formField.type.proposta.value === null) {
			hasRequirement = false;
		}
		this.formField.type.proposta.hasError = !hasRequirement;
		return hasRequirement;
	}

	hasRequiredTitleAquisition() {
		let hasRequirement = true;
		if (this.formField.type.titleAquisition.value === null) {
			hasRequirement = false;
		}
		this.formField.type.titleAquisition.hasError = !hasRequirement;
		return hasRequirement;
	}

	hasRequiredResgateType() {
		let hasRequirement = true;
		if (this.formField.type.resgateType.value === null) {
			hasRequirement = false;
		}
		this.formField.type.resgateType.hasError = !hasRequirement;
		return hasRequirement;
	}

	hasRequiredCustomerAgreement() {
		let hasRequirement = true;
		if (this.formField.type.customerAgreement.value === null || this.formField.type.customerAgreement.value === false) {
			hasRequirement = false;
		}
		this.formField.type.customerAgreement.hasError = !hasRequirement;
		return hasRequirement;
	}

	hasRequiredCustomerDataProfessionalActivity() {
		let hasRequirement = true;
		if (this.formField.data.customerData.professionalActivity.value === null) {
			hasRequirement = false;
		}
		this.formField.data.customerData.professionalActivity.hasError = !hasRequirement;
		return hasRequirement;
	}

	hasRequiredCustomerDataEmail() {
		let hasRequirement = true;
		if (this.formField.data.customerData.email.value === null || this.formField.data.customerData.email.value === '') {
			this.formField.data.customerData.email.isValid = true;
			hasRequirement = false;
		}
		this.formField.data.customerData.email.hasError = !hasRequirement;
		return hasRequirement;
	}

	isValidCustomerDataEmail() {
		let isValid = true;
		if (this.accountUtilService.isValidEmail(this.formField.data.customerData.email.value) === false) {
			isValid = false;
		}
		this.formField.data.customerData.email.isValid = isValid;
		return isValid;
	}

	hasRequiredCustomerDataPhone() {
		let hasRequirement = true;
		if (this.formField.data.customerData.phone.value === null || this.formField.data.customerData.phone.value === '') {
			hasRequirement = false;
		}
		this.formField.data.customerData.phone.hasError = !hasRequirement;
		return hasRequirement;
	}

	hasRequiredCustomerDataIncome() {

		let hasRequirement = true;

		if (this.formField.data.customerData.income.value === null || this.formField.data.customerData.income.value === '') {

			hasRequirement = false;
		}

		this.formField.data.customerData.income.hasError = !hasRequirement;
		return hasRequirement;
	}

	hasRequiredDebitDeclaration() {
		let hasRequirement = true;
		if (this.formField.data.debitDeclaration.debitsSelected.value.length === 0 || this.formField.data.debitDeclaration.totalValue.value === 0) {
			hasRequirement = false;
		}
		this.formField.data.debitDeclaration.totalValue.hasError = !hasRequirement;
		return hasRequirement;
	}

	hasRequiredRepresentantDataName() {

		let hasRequirement = true;

		if (this.formField.data.representantData.name.value === null || this.formField.data.representantData.name.value === '') {

			hasRequirement = false;
		}

		this.formField.data.representantData.name.hasError = !hasRequirement;
		return hasRequirement;
	}

	hasRequiredRepresentantDataCpfcnpj() {

		let hasRequirement = true;

		if (this.formField.data.representantData.cpfcnpj.value === null || this.formField.data.representantData.cpfcnpj.value === '') {

			this.formField.data.representantData.cpfcnpj.isValid = true;
			hasRequirement = false;
		}

		this.formField.data.representantData.cpfcnpj.hasError = !hasRequirement;
		return hasRequirement;
	}

	isValidRepresentantDataCpfcnpj() {

		let isValid = true;

		if ((this.accountUtilService.isValidCpf(this.formField.data.representantData.cpfcnpj.value) === false && this.accountUtilService.isValidCnpj(this.formField.data.representantData.cpfcnpj.value) === false)) {

			isValid = false;
		}

		this.formField.data.representantData.cpfcnpj.isValid = isValid;
		return isValid;
	}

	hasRequiredDataDebitValue() {

		let hasRequirement = true;

		if (this.formField.data.debitValue.value === null || this.formField.data.debitValue.value === 0) {

			hasRequirement = false;
		}

		this.formField.data.debitValue.hasError = !hasRequirement;
		return hasRequirement;
	}

	hasRequiredValueRemain() {

		let hasRequirement = true;

		if (this.formField.data.valueRemain.value === null || this.formField.data.valueRemain.value < 0) {

			hasRequirement = false;
		}

		this.formField.data.valueRemain.hasError = !hasRequirement;
		return hasRequirement;
	}

	hasRequiredContractDate() {

		let hasRequirement = true;

		if (this.formField.type.caucionado) {

			//@todo gambiarra por causa do jquery e ngModel que não funciona no resgate
			const contractDate = $('#contratoLocacao').val();

			if (contractDate !== undefined) {
				if (contractDate === null || contractDate === '') {
					hasRequirement = false;
					this.formField.data.realtyInformation.contractDate.value = null;
				} else {

					this.formField.data.realtyInformation.contractDate.value = contractDate;
				}
			}
		}

		this.formField.data.realtyInformation.contractDate.hasError = !hasRequirement;
		this.resgateFormService.setFormField(this.formField);

		// console.log('hasRequiredContractDate', hasRequirement);

		return hasRequirement;
	}

	hasRequiredBankDataCustomerOwner() {

		let hasRequirement = true;

		if (this.formField.bank.bankData.customerOwner.value === null) {

			hasRequirement = false;
		}

		if (this.formField.isCartaoCreditoInferior31)
			hasRequirement = true;

		this.formField.bank.bankData.customerOwner.hasError = !hasRequirement;
		return hasRequirement;
	}

	hasRequiredBankDataAccountType() {
		let hasRequirement = true;
		if (this.formField.bank.bankData.accountType.value === null) {
			hasRequirement = false;
		}

		if (this.formField.isCartaoCreditoInferior31)
			hasRequirement = true;

		this.formField.bank.bankData.accountType.hasError = !hasRequirement;
		return hasRequirement;
	}

	hasRequiredBankDataBank() {
		let hasRequirement = true;
		if (this.formField.bank.bankData.bank.value === null || this.formField.bank.bankData.bank.value === '') {
			hasRequirement = false;
		}

		if (this.formField.isCartaoCreditoInferior31)
			hasRequirement = true;

		this.formField.bank.bankData.bank.hasError = !hasRequirement;
		return hasRequirement;
	}

	hasRequiredBankDataAgency() {

		let hasRequirement = true;
		if (this.formField.bank.bankData.agency.value === null || this.formField.bank.bankData.agency.value === '') {
			hasRequirement = false;
		}

		if (this.formField.isCartaoCreditoInferior31)
			hasRequirement = true;

		this.formField.bank.bankData.agency.hasError = !hasRequirement;
		this.formField.bank.bankData.agencyDV.hasAgencyDVPeopleError = false;
		return hasRequirement;
	}

	hasRequiredBankDataAccountNumber() {
		let hasRequirement = true;
		if (this.formField.bank.bankData.accountNumber.value === null || this.formField.bank.bankData.accountNumber.value === '') {
			hasRequirement = false;
		}

		if (this.formField.isCartaoCreditoInferior31)
			hasRequirement = true;

		this.formField.bank.bankData.accountNumber.hasError = !hasRequirement;
		this.formField.bank.bankData.accountNumberDV.hasAccountNumberDVPeopleError = false;
		return hasRequirement;
	}

	hasRequiredBankDataAccountOwnerName() {
		let hasRequirement = true;
		if (this.formField.bank.bankData.accountOwnerName.value === null || this.formField.bank.bankData.accountOwnerName.value === '') {
			hasRequirement = false;
		}

		if (this.formField.isCartaoCreditoInferior31)
			hasRequirement = true;

		this.formField.bank.bankData.accountOwnerName.hasError = !hasRequirement;
		return hasRequirement;
	}

	hasRequiredBankDataCpfcnpj() {
		let hasRequirement = true;
		if (this.formField.bank.bankData.cpfcnpj.value === null || this.formField.bank.bankData.cpfcnpj.value === '') {
			this.formField.bank.bankData.cpfcnpj.isValid = true;
			hasRequirement = false;
		}

		if (this.formField.isCartaoCreditoInferior31)
			hasRequirement = true;

		this.formField.bank.bankData.cpfcnpj.hasError = !hasRequirement;
		return hasRequirement;
	}

	isValidBankDataCpfcnpj() {
		let isValid = true;
		if (this.formField.bank.bankData.customerOwner.value === 'false') {
			if ((this.accountUtilService.isValidCpf(this.formField.bank.bankData.cpfcnpj.value) === false && this.accountUtilService.isValidCnpj(this.formField.bank.bankData.cpfcnpj.value) === false)) {

				isValid = false;
			}
		}

		if (this.formField.isCartaoCreditoInferior31)
			isValid = true;

		this.formField.bank.bankData.cpfcnpj.isValid = isValid;
		return isValid;
	}

	hasRequiredBankDataProfessionalActivity() {
		let hasRequirement = true;
		if (this.formField.bank.bankData.professionalActivity.value === null && this.formField.bank.bankData.professionalActivity.show === true) {
			hasRequirement = false;
		}

		if (this.formField.isCartaoCreditoInferior31)
			hasRequirement = true;

		this.formField.bank.bankData.professionalActivity.hasError = !hasRequirement;
		return hasRequirement;
	}

	hasRequiredBankDataIncome() {

		let hasRequirement = true;
		if (this.formField.bank.bankData.income.value === null && this.formField.bank.bankData.income.show === true) {
			hasRequirement = false;
		}

		if (this.formField.isCartaoCreditoInferior31)
			hasRequirement = true;

		this.formField.bank.bankData.income.hasError = !hasRequirement;
		return hasRequirement;
	}

	hasRequiredBankBillsAccountType() {
		let hasRequirement = true;
		if (this.formField.bank.bankBills.accountType.value === null) {
			hasRequirement = false;
		}

		if (this.formField.isCartaoCreditoInferior31)
			hasRequirement = true;

		this.formField.bank.bankBills.accountType.hasError = !hasRequirement;
		return hasRequirement;
	}

	hasRequiredBankBillsBank() {
		let hasRequirement = true;
		if (this.formField.bank.bankBills.bank.value === null || this.formField.bank.bankBills.bank.value === '') {
			hasRequirement = false;
		}

		if (this.formField.isCartaoCreditoInferior31)
			hasRequirement = true;

		this.formField.bank.bankBills.bank.hasError = !hasRequirement;
		return hasRequirement;
	}

	hasRequiredBankBillsAgency() {
		let hasRequirement = true;
		if (this.formField.bank.bankBills.agency.value === null || this.formField.bank.bankBills.agency.value === '') {
			hasRequirement = false;
		}

		if (this.formField.isCartaoCreditoInferior31)
			hasRequirement = true;

		this.formField.bank.bankBills.agency.hasError = !hasRequirement;
		this.formField.bank.bankBills.agencyDV.hasAgencyDVPeopleError = false;
		return hasRequirement;
	}

	hasRequiredBankBillsAccountNumber() {
		let hasRequirement = true;
		if (this.formField.bank.bankBills.accountNumber.value === null || this.formField.bank.bankBills.accountNumber.value === '') {
			hasRequirement = false;
		}

		if (this.formField.isCartaoCreditoInferior31)
			hasRequirement = true;

		this.formField.bank.bankBills.accountNumber.hasError = !hasRequirement;
		this.formField.bank.bankBills.accountNumberDV.hasAccountNumberDVPeopleError = false;
		return hasRequirement;
	}

	hasRequiredBankBillsAccountOwnerName() {

		let hasRequirement = true;

		if (this.formField.bank.bankBills.accountOwnerName.value === null || this.formField.bank.bankBills.accountOwnerName.value === '') {

			hasRequirement = false;
		}

		if (this.formField.isCartaoCreditoInferior31)
			hasRequirement = true;

		this.formField.bank.bankBills.accountOwnerName.hasError = !hasRequirement;
		return hasRequirement;
	}

	hasRequiredBankBillsCpfcnpj() {

		let hasRequirement = true;

		if (this.formField.bank.bankBills.cpfcnpj.value === null || this.formField.bank.bankBills.cpfcnpj.value === '') {

			this.formField.bank.bankBills.cpfcnpj.isValid = true;
			hasRequirement = false;
		}

		if (this.formField.isCartaoCreditoInferior31)
			hasRequirement = true;

		this.formField.bank.bankBills.cpfcnpj.hasError = !hasRequirement;
		return hasRequirement;
	}

	isValidBankBillsCpfcnpj() {

		let isValid = true;

		if ((this.accountUtilService.isValidCpf(this.formField.bank.bankBills.cpfcnpj.value) === false && this.accountUtilService.isValidCnpj(this.formField.bank.bankBills.cpfcnpj.value) === false)) {

			isValid = false;
		}

		if (this.formField.isCartaoCreditoInferior31)
			isValid = true;

		this.formField.bank.bankBills.cpfcnpj.isValid = isValid;
		return isValid;
	}

	hasRequiredBankBillsProfessionalActivity() {

		let hasRequirement = true;

		if (this.formField.bank.bankBills.professionalActivity.value === null) {

			hasRequirement = false;
		}

		if (this.formField.isCartaoCreditoInferior31)
			hasRequirement = true;

		this.formField.bank.bankBills.professionalActivity.hasError = !hasRequirement;
		return hasRequirement;
	}

	hasRequiredBankBillsIncome() {

		let hasRequirement = true;

		if (this.formField.bank.bankBills.income.value === null) {

			hasRequirement = false;
		}

		if (this.formField.isCartaoCreditoInferior31)
			hasRequirement = true;

		this.formField.bank.bankBills.income.hasError = !hasRequirement;
		return hasRequirement;
	}

	hasRequiredBankBillsDebitEmail() {

		let hasRequirement = true;

		if (!this.formField.bank.bankBills.debitEmail.value) {
			this.formField.bank.bankBills.debitEmail.isValid = true;
			hasRequirement = false;
		}

		if (this.formField.isCartaoCreditoInferior31)
			hasRequirement = true;

		this.formField.bank.bankBills.debitEmail.hasError = !hasRequirement;
		return hasRequirement;
	}

	isValidBankBillsDebitEmail() {

		let isValid = true;

		if (this.accountUtilService.isValidEmail(this.formField.bank.bankBills.debitEmail.value) === false) {

			isValid = false;
		}

		if (this.formField.isCartaoCreditoInferior31)
			isValid = true;

		this.formField.bank.bankBills.debitEmail.isValid = isValid;
		return isValid;
	}

	isFormValid(page) {

		if (page === 'resgate.new') {

			//return true;

			this.hasRequiredMainAccount();
			this.hasRequiredProposta();
			this.hasRequiredGarantias();
			this.hasRequiredLegalRepresentative();
			this.resgateFormService.setFormField(this.formField);

			return (!this.formField.information.mainAccount.hasError && !this.formField.information.proposta.hasError && !this.formField.information.garantias.hasError && !this.formField.information.legalRepresentative.hasError)

		} else if (page === 'resgate.type') {

			this.resgateService.setErrorMessage('');

			console.log('--> validando resgate type');
			console.log('valor do resgate armazenado: ', this.formField.type.valorResgate)
			console.log('valor do resgate armazenado 2: ', this.formField.valorResgate)

			if (this.formField.type.resgateFinalMessage.show === true) return false;
			if (this.formField.type.realtyDebits.show === true) this.hasRequiredRealtyDebits();
			if (this.formField.type.debitValue.show === true) this.hasRequiredDebitValue() && this.isValidDebitValue();
			if (this.formField.type.hasRenterApprove.show === true) this.hasRequiredRenterApprove();
			if (this.formField.type.proposta.show === true) this.hasRequiredTypeProposta();
			if (this.formField.type.titleAquisition.show === true) this.hasRequiredTitleAquisition();
			if (this.formField.type.resgateType.show === true) this.hasRequiredResgateType();
			if (this.formField.type.customerAgreement.show === true) this.hasRequiredCustomerAgreement();
			if (this.formField.type.realtyDebits.show === true &&
				this.formField.type.realtyDebits.value === 'true' &&
				this.formField.type.proposta.show === true &&
				this.formField.type.proposta.value !== null) {

				console.log('--> validando resgate informado: ', this.formField.type);
				console.log('--> proposta: ', this.formField.type.proposta.value.value);

				if (this.formField.type.proposta.value.value !== null) {

					const propostaValue = this.formField.type.proposta.value.value + this.formField.type.debitValue.value;

					console.log('proposta value: ', propostaValue)

					if (this.formField.valorResgate == null || this.formField.valorResgate == undefined) {
						this.resgateService.setErrorMessage('Não foi possivel obter o valor do resgate, tente novamente mais tarde.');
					}

					if (propostaValue > this.formField.type.valorResgate) {
						this.resgateService.setErrorMessage('O valor de débito não pode ser maior que o valor do resgate.');
					}

				}
			} else if (this.formField.type.proposta.show === true &&
				this.formField.type.proposta.value !== null) {
				const propostaValue = this.formField.type.proposta.value.value;

				console.log('---> #### VALIDACAO VALOR DO RESGATE: ', this.formField.valorResgate)
				if (propostaValue > this.resgateService.getResgateValue()) {
					this.resgateService.setErrorMessage('O valor de débito não pode ser maior que o valor do resgate.');
				}
			}
			this.resgateFormService.setFormField(this.formField);
			this.$window.scrollTo(0, 0);

			return (!this.formField.type.realtyDebits.hasError && !this.formField.type.debitValue.hasError && (this.resgateService.getErrorMessage() === '') && this.formField.type.debitValue.isValid && !this.formField.type.hasRenterApprove.hasError && !this.formField.type.proposta.hasError && !this.formField.type.titleAquisition.hasError && !this.formField.type.customerAgreement.hasError && !this.formField.type.resgateType.hasError && !this.formField.type.resgateFinalMessage.show);

		} else if (page === 'resgate.data') {

			if (this.formField.data.customerData.show === true) {
				this.hasRequiredCustomerDataProfessionalActivity();
				this.hasRequiredCustomerDataEmail() && this.isValidCustomerDataEmail();
				this.hasRequiredCustomerDataPhone();
				this.hasRequiredCustomerDataIncome();
			}

			if (this.formField.data.debitDeclaration.show === true) this.hasRequiredDebitDeclaration();
			if (this.formField.data.representantData.show === true) {
				this.hasRequiredRepresentantDataName();
				this.hasRequiredRepresentantDataCpfcnpj() && this.isValidRepresentantDataCpfcnpj();
			}
			if (this.formField.data.debitValue.show === true) {
				this.hasRequiredDataDebitValue();
			} else {
				this.formField.data.debitValue.hasError = false;
			}
			if (this.formField.data.valueRemain.show === true) {
				this.hasRequiredValueRemain();
			} else {
				this.formField.data.valueRemain.hasError = false;
			}
			this.hasRequiredContractDate();
			this.resgateFormService.setFormField(this.formField);
			return (!this.formField.data.customerData.professionalActivity.hasError &&
				!this.formField.data.customerData.email.hasError &&
				this.formField.data.customerData.email.isValid &&
				!this.formField.data.customerData.phone.hasError &&
				!this.formField.data.customerData.income.hasError &&
				!this.formField.data.valueRemain.hasError &&
				!this.formField.data.debitValue.hasError &&
				!this.formField.data.customerData.name.hasError &&
				!this.formField.data.customerData.cpfcnpj.hasError &&
				this.formField.data.customerData.cpfcnpj.isValid &&
				!this.formField.data.debitDeclaration.totalValue.hasError &&
				!this.formField.data.representantData.name.hasError &&
				!this.formField.data.representantData.cpfcnpj.hasError &&
				!this.formField.data.realtyInformation.contractDate.hasError &&
				this.formField.data.representantData.cpfcnpj.isValid);

		} else if (page === 'resgate.bank') {

			let bankDataValid = true;
			let bankBillsValid = true;

			if (this.formField.isCartaoCreditoInferior31)
				return true;

			if (this.formField.bank.bankData.show === true) {
				this.hasRequiredBankDataCustomerOwner();
				this.hasRequiredBankDataAccountType();
				this.hasRequiredBankDataBank();
				this.hasRequiredBankDataAgency();
				this.hasRequiredBankDataAccountNumber();
				this.hasRequiredBankDataAccountOwnerName();
				this.hasRequiredBankDataCpfcnpj() && this.isValidBankDataCpfcnpj();
				this.hasRequiredBankDataProfessionalActivity();
				this.hasRequiredBankDataIncome();

				// Verifica se todos os campos da seção BANKDATA estão válidos
				bankDataValid = (!this.formField.bank.bankData.customerOwner.hasError &&
					!this.formField.bank.bankData.accountType.hasError &&
					!this.formField.bank.bankData.bank.hasError &&
					!this.formField.bank.bankData.agency.hasError &&
					!this.formField.bank.bankData.accountNumber.hasError &&
					!this.formField.bank.bankData.accountOwnerName.hasError &&
					!this.formField.bank.bankData.cpfcnpj.hasError &&
					this.formField.bank.bankData.cpfcnpj.isValid &&
					!this.formField.bank.bankData.professionalActivity.hasError &&
					!this.formField.bank.bankData.income.hasError);
			}

			if (this.formField.bank.bankBills.show === true) {
				this.hasRequiredBankBillsAccountType();
				this.hasRequiredBankBillsBank();
				this.hasRequiredBankBillsAgency();
				this.hasRequiredBankBillsAccountNumber();
				this.hasRequiredBankBillsAccountOwnerName();
				this.hasRequiredBankBillsCpfcnpj() && this.isValidBankBillsCpfcnpj();
				this.hasRequiredBankBillsDebitEmail() && this.isValidBankBillsDebitEmail();
				this.hasRequiredBankBillsProfessionalActivity();
				this.hasRequiredBankBillsIncome();

				// Verifica se todos os campos da seção BANKBILLS estão válidos
				bankBillsValid = (!this.formField.bank.bankBills.accountType.hasError &&
					!this.formField.bank.bankBills.bank.hasError &&
					!this.formField.bank.bankBills.agency.hasError &&
					!this.formField.bank.bankBills.accountNumber.hasError &&
					!this.formField.bank.bankBills.accountOwnerName.hasError &&
					!this.formField.bank.bankBills.cpfcnpj.hasError &&
					this.formField.bank.bankBills.cpfcnpj.isValid &&
					!this.formField.bank.bankBills.debitEmail.hasError &&
					this.formField.bank.bankBills.debitEmail.isValid &&
					!this.formField.bank.bankBills.professionalActivity.hasError &&
					!this.formField.bank.bankBills.income.hasError);
			}
			this.resgateFormService.setFormField(this.formField);
			return (bankDataValid && bankBillsValid);
		}

		return false;
	}

	onlySave() {
		this.isOnlySave = true;
		this.resgateService.setErrorMessage('');
		this.saveCaseBeforeUpload();
	}

	saveCaseResgate() {
		this.resgateService.setErrorMessage('');
		this.formField = this.resgateFormService.getFormField();
		if (this.formField.information.mainAccount.value !== null) {
			if (this.formField.data.active === true) {
				this.formField.data.realtyInformation.contractDate.value = $('#contratoLocacao').val();
			}
			this.$scope.$emit('changeMainShowLoading', true);
			this.resgateService.saveCaseResgate().then((response) => {
				if (response.isSuccessful) {
					this.formField.id = response.caseId;
					this.resgateService.setSuccessMessage('Resgate salvo com sucesso.');
					this.resgateFormService.setFormField(this.formField);
					this.$timeout(() => {
						this.resgateService.setSuccessMessage('');
					}, 5000);
				} else {
					// console.log('error: ', response);
					this.resgateService.setErrorMessage(response.errorMessages[0]);
				}
				this.$scope.$emit('changeMainShowLoading', false, 4000);
			});
		}
	}

	saveCaseBeforeUpload() {

		const me = this;

		this.resgateService.setErrorMessage('');
		//Tive que fazer mais uma monstruosidade para validar cenários antigos :(
		//Demanda 318394
		//const isCriticalPaymentBankErrorOnSave = (this.isOnlySave && this.formField.isHaveProblemBank);
		//this.formField.status = (isCriticalPaymentBankErrorOnSave ? 'Pagamento Criticado' : 'Em andamento');

		this.formField.status = 'Em andamento';

		if (this.formField.information.mainAccount.value !== null) {
			this.$scope.$emit('changeMainShowLoading', true);
			this.resgateService.saveCaseResgate().then((response) => {
				if (response.isSuccessful) {
					me.formField.id = response.caseId;
					me.resgateFormService.setFormField(this.formField);

					/* Se identificar que algum  upload não está integro, atualizar o editcasedata */
					if (!me.checkUploadIntegrity()) this.saveCaseUpdateEditCaseData();

					if (me.verifyAnnexes()) {
						me.checkUpload();
					} else {
						me.$scope.$emit('changeMainShowLoading', false, 2000);
						me.resgateService.setSuccessMessage('Resgate salvo com sucesso.');
						me.resgateFormService.setFormField(this.formField);
						me.$timeout(() => {
							me.resgateService.setSuccessMessage('');
						}, 5000);
					}
				} else {
					me.$scope.$emit('changeMainShowLoading', false, 2000);
					me.resgateService.setErrorMessage(response.errorMessages[0]);
				}
			});
		}
	}

	verifyAnnexes() {
		let valid = false;
		if (this.isOnlySave) {
			const annexes = this.getCurrentAnnexes();
			for (const annex of annexes) {
				for (const file of annex.files) {
					if (file instanceof File) {
						console.log('verifyAnnexes', true);
						return true; //TODO: Verificar como sair de loops aninhados
						// para nao precisar usar este return
					}
				}
			}
		} else {
			valid = true; // Para entrar no CheckUpload no caso de clicar no botao Enviar
		}
		console.log('verifyAnnexes', valid);
		return valid;
	}

	saveCaseChangeStatus() {
		//Tive que fazer mais uma monstruosidade para validar cenários antigos :(
		//Demanda 318394
		//const isCriticalPaymentBankErrorOnSave = (this.isOnlySave && this.formField.isHaveProblemBank);
		//this.formField.status = (isCriticalPaymentBankErrorOnSave ? 'Pagamento Criticado' : 'Em andamento');

		this.formField.status = 'Em andamento';

		this.resgateFormService.setFormField(this.formField);
		this.resgateService.sendCaseResgate().then((response) => {
			this.$scope.$emit('changeMainShowLoading', false, 2200);
			if (this.formField.liberarCaucao === true) {
				this.$state.go('case.liberacao-da-caucao.detail', { id: this.formField.id });
			} else {
				this.$state.go('case.detail', { id: this.formField.id });
			}
		});
	}

	doNext() {

		this.formField = this.resgateFormService.getFormField();
		console.log('this.formField.information.active: ', this.formField.information.active);
		console.log('this.formField.type.active: ', this.formField.type.active);
		console.log('this.formField.data.active: ', this.formField.data.active);
		console.log('this.formField.bank.active: ', this.formField.bank.active);
		console.log('this.isFormValid.resgate.new:', this.isFormValid('resgate.new'));
		console.log('this.formField.isHaveProblemBank: ', this.formField.isHaveProblemBank);

		if (this.formField.information.active === true) {

			if (this.isFormValid('resgate.new') && !this.formField.isHaveProblemBank) {
				this.formField.information.active = false;
				this.formField.type.active = true;
				this.resgateService.setPreviousShow(true);
				this.resgateService.setPreviousDisabled(false);
				this.resgateService.setNextShow(true);
				this.resgateService.setNextDisabled(false);
				this.resgateService.getFinishDisabled(true);
				this.resgateService.setFinishShow(false);
				this.resgateFormService.setFormField(this.formField);
				this.$window.scrollTo(0, 0);
				this.$state.go('resgate.type');
			}

		} else if (this.formField.type.active === true && !this.formField.isHaveProblemBank) {
			if (this.isFormValid('resgate.type')) {
				this.formField.type.active = false;
				this.formField.data.active = true;
				this.resgateService.setPreviousShow(true);
				this.resgateService.setPreviousDisabled(false);
				this.resgateService.setNextShow(true);
				this.resgateService.setNextDisabled(false);
				this.resgateService.getFinishDisabled(true);
				this.resgateService.setFinishShow(false);
				this.resgateFormService.setFormField(this.formField);
				this.$window.scrollTo(0, 0);
				this.$state.go('resgate.data');
			}
		} else if (this.formField.data.active === true) {
			this.formField.data.realtyInformation.contractDate.value = $('#contratoLocacao').val();
			this.resgateFormService.setFormField(this.formField);
			if (this.isFormValid('resgate.data')) {
				this.formField.information.active = false;
				this.formField.type.active = false;
				this.formField.data.active = false;
				this.formField.bank.active = true;
				this.resgateService.setPreviousShow(true);
				this.resgateService.setPreviousDisabled(false);
				this.resgateService.setNextShow(true);
				this.resgateService.setNextDisabled(false);
				this.resgateService.getFinishDisabled(true);
				this.resgateService.setFinishShow(false);
				this.resgateFormService.setFormField(this.formField);
				this.$window.scrollTo(0, 0);
				this.$state.go('resgate.bank');
			}
		} else if (this.formField.bank.active === true) {
			if (this.isFormValid('resgate.bank')) {
				this.validaContaBancaria();
			}
		}
	}

	validaContaBancaria() {

		console.log('-- validando conta bancaria');
		// Inicia a array de controle para indentificar se ja realizou todas as chamadas necessarias ao people
		this.peopleServiceCalled = {
			bankData: (this.formField.bank.bankData.show === true) ? "TOCALL" : "NOCALL",
			bankBills: (this.formField.bank.bankBills.show === true) ? "TOCALL" : "NOCALL"
		};

		console.log('peopleServiceCalled: ', this.peopleServiceCalled);

		if (this.peopleServiceCalled.bankData === "TOCALL") {
			this.isContaBancariaValida("dados-bancarios",
				this.formField.bank.bankData.accountType.value,
				this.formField.bank.bankData.cpfcnpj.value,
				this.formField.bank.bankData.bank.value,
				this.formField.bank.bankData.agency.value,
				this.formField.bank.bankData.agencyDV.value,
				this.formField.bank.bankData.accountNumber.value,
				this.formField.bank.bankData.accountNumberDV.value);
		}
		if (this.peopleServiceCalled.bankBills === "TOCALL") {
			this.isContaBancariaValida("pagamento-debitos",
				this.formField.bank.bankBills.accountType.value,
				this.formField.bank.bankBills.cpfcnpj.value,
				this.formField.bank.bankBills.bank.value,
				this.formField.bank.bankBills.agency.value,
				this.formField.bank.bankBills.agencyDV.value,
				this.formField.bank.bankBills.accountNumber.value,
				this.formField.bank.bankBills.accountNumberDV.value);
		}
	}

	isContaBancariaValida(secao, tipoConta, cpfCnpj, banco, agencia, agenciaDV, conta, contaDV) {
		const me = this;
		me.$scope.$emit('changeMainShowLoading', true);
		me.dadosBancariosHelper.consultaDadosBancariosSeValidos(tipoConta, cpfCnpj, banco, agencia, agenciaDV, conta, contaDV, (response) => {

			console.log('--> retornou consultaDadosBancariosSeValidos');
			me.$scope.$broadcast('applyErrorBankFields', response, secao);

			console.log('--> retornou dados bancarios validosL ', conta);

			if (this.formField.isCartaoCreditoInferior31 || ((response.isSuccessful) && (response.isValid))) {

				console.log('--> dados validos da conta');

				setTimeout(() => {
					if (secao === "dados-bancarios") me.peopleServiceCalled.bankData = "CALLED";
					if (secao === "pagamento-debitos") me.peopleServiceCalled.bankBills = "CALLED";
					// Para processar o callback do serviço do people para validar as informacoes bancarias,
					// A seção bankData precisa já estar com o retorno definido (CALLED), 
					// e o bankBills com o retorno definido (CALLED) ou com a flag que indica que nao precisa chamar (NOCALL)

					console.log('--> me.peopleServiceCalled.bankData: ', me.peopleServiceCalled.bankData);
					console.log('--> me.peopleServiceCalled.bankBills: ', me.peopleServiceCalled.bankBills);

					if ((me.peopleServiceCalled.bankData !== "TOCALL") && (me.peopleServiceCalled.bankBills !== "TOCALL")) {
						me.formField.information.active = false;
						me.formField.type.active = false;
						me.formField.data.active = false;
						me.formField.bank.active = false;
						me.formField.file.active = true;
						me.resgateService.setPreviousShow(true);
						me.resgateService.setPreviousDisabled(false);
						me.resgateService.setNextShow(false);
						me.resgateService.setNextDisabled(true);
						me.resgateService.setFinishDisabled(false);
						me.resgateService.setFinishShow(true);
						me.resgateFormService.setFormField(me.formField);
						me.$window.scrollTo(0, 0);
						me.$state.go("resgate.file");
					}
					me.$scope.$emit('changeMainShowLoading', false);
				}, 500);
			} else {
				me.$scope.$emit('changeMainShowLoading', false);
			}
		});
	}

	doPrevious() {
		// console.log(this.$state.current);
		this.resgateService.setErrorMessage('');
		if (this.formField.type.active === true && !this.formField.isHaveProblemBank) {
			this.formField.information.active = true;
			this.formField.type.active = false;
			this.resgateService.setPreviousShow(false);
			this.resgateService.setPreviousDisabled(true);
			this.resgateService.setNextShow(true);
			this.resgateService.setNextDisabled(false);
			this.resgateService.getFinishDisabled(true);
			this.resgateService.setFinishShow(false);
			this.$window.scrollTo(0, 0);
			this.$state.go('resgate.new');
		} else if (this.formField.data.active === true && !this.formField.isHaveProblemBank) {
			this.formField.type.active = true;
			this.formField.data.active = false;
			this.resgateService.setPreviousShow(true);
			this.resgateService.setPreviousDisabled(false);
			this.resgateService.setNextShow(true);
			this.resgateService.setNextDisabled(false);
			this.resgateService.getFinishDisabled(true);
			this.resgateService.setFinishShow(false);
			this.$window.scrollTo(0, 0);
			this.formField.data.realtyInformation.contractDate.value = $('#contratoLocacao').val();
			this.resgateFormService.setFormField(this.formField);
			this.$state.go('resgate.type');
		} else if (this.formField.bank.active === true) {
			this.formField.data.active = true;
			this.formField.bank.active = false;
			this.resgateService.setPreviousShow(true);
			this.resgateService.setPreviousDisabled(false);
			this.resgateService.setNextShow(true);
			this.resgateService.setNextDisabled(false);
			this.resgateService.getFinishDisabled(true);
			this.resgateService.setFinishShow(false);
			this.$window.scrollTo(0, 0);
			this.resgateFormService.setFormField(this.formField);
			this.$state.go('resgate.data');
		} else if (this.formField.file.active === true) {
			if (this.formField.liberarCaucao === true) {
				this.formField.type.active = true;
			} else {
				this.formField.bank.active = true;
			}
			this.formField.file.active = false;
			this.resgateService.setPreviousShow(true);
			this.resgateService.setPreviousDisabled(false);
			this.resgateService.setNextShow(true);
			this.resgateService.setNextDisabled(false);
			this.resgateService.getFinishDisabled(true);
			this.resgateService.setFinishShow(false);
			this.$window.scrollTo(0, 0);
			if (this.formField.liberarCaucao === true) {
				this.$state.go('resgate.type');
			} else {
				this.$state.go('resgate.bank');
			}
		}
	}

	goTo(type) {
		// Se estou na tela BANK, e tento ir para aba de anexos, estando com o form preenchido (valido), valido a conta!
		if ((this.formField.bank.active) && (type === "resgate.file") && this.isFormValid('resgate.bank')) {
			this.validaContaBancaria();
		} else if (type === 'resgate.new' && !this.formField.isHaveProblemBank) {
			if (this.formField.information.active === false) {
				this.formField.information.active = true;
				this.formField.type.active = false;
				this.formField.data.active = false;
				this.formField.bank.active = false;
				this.formField.file.active = false;
				this.resgateService.setPreviousShow(false);
				this.resgateService.setPreviousDisabled(true);
				this.resgateService.setNextShow(true);
				this.resgateService.setNextDisabled(false);
				this.resgateService.getFinishDisabled(true);
				this.resgateService.setFinishShow(false);
				this.resgateFormService.setFormField(this.formField);
				this.$window.scrollTo(0, 0);
				this.$state.go('resgate.new');
			}
		} else if (type === 'resgate.type' && !this.formField.isHaveProblemBank) {
			if (this.formField.type.active === false) {
				if (this.navStatus('resgate.type') != '') {
					if (this.isFormValid('resgate.new')) {
						this.formField.information.active = false;
						this.formField.type.active = true;
						this.formField.data.active = false;
						this.formField.bank.active = false;
						this.formField.file.active = false;
						this.resgateService.setPreviousShow(true);
						this.resgateService.setPreviousDisabled(false);
						this.resgateService.setNextShow(true);
						this.resgateService.setNextDisabled(false);
						this.resgateService.getFinishDisabled(true);
						this.resgateService.setFinishShow(false);
						this.resgateFormService.setFormField(this.formField);
						this.$window.scrollTo(0, 0);
						this.$state.go('resgate.type');
					}
				}
			}
		} else if (type === 'resgate.data') {
			if (this.formField.data.active === false) {
				if (this.navStatus('resgate.data') != '') {
					if (this.isFormValid('resgate.type') && this.isFormValid('resgate.new')) {
						this.formField.information.active = false;
						this.formField.type.active = false;
						this.formField.data.active = true;
						this.formField.bank.active = false;
						this.formField.file.active = false;
						this.resgateService.setPreviousShow(true);
						this.resgateService.setPreviousDisabled(false);
						this.resgateService.setNextShow(true);
						this.resgateService.setNextDisabled(false);
						this.resgateService.getFinishDisabled(true);
						this.resgateService.setFinishShow(false);
						this.resgateFormService.setFormField(this.formField);
						this.$window.scrollTo(0, 0);
						this.$state.go('resgate.data');
					}
				}
			}
		} else if (type === 'resgate.bank') {
			if (this.formField.bank.active === false) {
				if (this.navStatus('resgate.bank') != '') {
					if (this.isFormValid('resgate.type') && this.isFormValid('resgate.new') && this.isFormValid('resgate.data')) {
						this.formField.information.active = false;
						this.formField.type.active = false;
						this.formField.data.active = false;
						this.formField.bank.active = true;
						this.formField.file.active = false;
						this.resgateService.setPreviousShow(true);
						this.resgateService.setPreviousDisabled(false);
						this.resgateService.setNextShow(true);
						this.resgateService.setNextDisabled(false);
						this.resgateService.getFinishDisabled(true);
						this.resgateService.setFinishShow(false);
						this.resgateFormService.setFormField(this.formField);
						this.$window.scrollTo(0, 0);
						this.$state.go('resgate.bank');
					}
				}
			}
		} else if (type === 'resgate.file') {
			if (this.formField.file.active === false) {
				if (this.navStatus('resgate.file') != '') {
					if (this.isFormValid('resgate.type') && this.isFormValid('resgate.new') && this.isFormValid('resgate.data') && this.isFormValid('resgate.bank')) {
						this.formField.information.active = false;
						this.formField.type.active = false;
						this.formField.data.active = false;
						this.formField.bank.active = false;
						this.formField.file.active = true;
						this.resgateService.setPreviousShow(true);
						this.resgateService.setPreviousDisabled(false);
						this.resgateService.setNextShow(false);
						this.resgateService.setNextDisabled(true);
						this.resgateService.getFinishDisabled(false);
						this.resgateService.setFinishShow(true);
						this.resgateFormService.setFormField(this.formField);
						this.$window.scrollTo(0, 0);
						this.$state.go('resgate.file');
					}
				}
			}
		}
	}

	cancelCurrentResgate() {
		this.resgateService.setErrorMessage('');
		if (this.formField.id !== null) {
			this.$scope.$emit('changeMainShowLoading', true);
			this.resgateService.cancelResgate(this.formField.id).then((response) => {
				if (response.isSuccessful === false) {
					this.resgateService.setErrorMessage(response.errorMessages[0]);
				} else {
					this.$state.go('home');
				}
				this.$scope.$emit('changeMainShowLoading', false, 4000);
			});
		} else {
			//todo reset form
			this.$state.go('home');
		}
	}

	navStatus(status) {
		if (status === 'resgate.new') {
			if (this.formField.information.active === true) {
				return 'act';
			} else if (this.formField.information.mainAccount.value !== null) {
				return 'edt';
			} else {
				return '';
			}
		} else if (status === 'resgate.type') {
			if (this.formField.type.active === true) {
				return 'act';
			} else if (this.formField.type.realtyDebits.value !== null || this.formField.type.resgateType.value !== null || this.formField.type.titleAquisition.value !== null) {
				return 'edt';
			} else {
				return '';
			}
		} else if (status === 'resgate.data') {
			if (this.formField.data.active === true) {
				return 'act';
			} else if (this.formField.data.customerData.professionalActivity.value !== null || this.formField.data.customerData.phone.value !== null || this.formField.data.customerData.email.value !== null || this.formField.data.customerData.income.value !== null || this.formField.data.customerData.observations !== null || this.formField.data.debitValue.value !== null || this.formField.data.valueRemain.value !== null ||
				this.formField.data.representantData.name.value !== null || this.formField.data.representantData.cpfcnpj.value !== null || this.formField.data.representantData.observations !== null ||
				this.formField.data.debitDeclaration.totalValue.value !== 0) {
				return 'edt';
			} else {
				return '';
			}
		} else if (status === 'resgate.bank') {
			if (this.formField.bank.active === true) {
				return 'act';
			} else if (this.formField.bank.bankData.accountType.value !== null || this.formField.bank.bankData.customerOwner.value !== null || this.formField.bank.bankData.bank.value !== null || this.formField.bank.bankData.agency.value !== null || this.formField.bank.bankData.phone !== null || this.formField.bank.bankData.accountNumber.value !== null || this.formField.bank.bankData.accountOwnerName.value !== null || this.formField.bank.bankData.cpfcnpj.value !== null || this.formField.bank.bankData.professionalActivity.value !== null || this.formField.bank.bankData.income.value !== null ||
				this.formField.bank.bankBills.accountType.value !== null || this.formField.bank.bankBills.bank.value !== null || this.formField.bank.bankBills.agency.value !== null || this.formField.bank.bankBills.phone !== null || this.formField.bank.bankBills.accountNumber.value !== null || this.formField.bank.bankBills.accountOwnerName.value !== null || this.formField.bank.bankBills.cpfcnpj.value !== null || this.formField.bank.bankBills.professionalActivity.value !== null || this.formField.bank.bankBills.income.value !== null) {
				return 'edt';
			} else {
				return '';
			}
		} else if (status === 'resgate.file') {
			if (this.formField.file.active === true) {
				return 'act';
			} else if ((this.formField.tabResgateBank == "C") && (this.resgateService.getIsEdit())) {
				return 'edt';
			} else {
				return '';
			}
		}
	}

	navStatusIcon(status) {
		const warningClass = 'fa-exclamation-triangle warning';
		const successClass = 'fa-check-circle success';
		let cssClass = '';
		if (status === 'resgate.new') {
			if (this.formField.information.mainAccount.value !== null) {
				if (!this.formField.information.proposta.value) {
					cssClass = warningClass
				} else {
					if (this.formField.information.proposta.hasError === false) {
						if (!this.formField.information.garantias.value) {
							cssClass = warningClass;
						} else {
							let hasGarantiaSelected = false;
							for (let i = 0; i < this.formField.information.garantias.value.length; i++) {
								if (this.formField.information.garantias.value[i].selected) {
									hasGarantiaSelected = true;
									break;
								}
							}
							if (hasGarantiaSelected === false) {
								cssClass = warningClass;
							} else {
								if (this.hasTituloCaucionadoSelected()) {
									if (!this.formField.information.legalRepresentative.value) {
										cssClass = warningClass;
									} else {
										cssClass = successClass;
									}
								} else {
									cssClass = successClass;
								}
							}
						}
					} else {
						cssClass = warningClass;
					}
				}
			}

			if (cssClass === warningClass) {
				this.resgateFormService.setTabResgateInfo('W');
			} else if (cssClass === successClass) {
				this.resgateFormService.setTabResgateInfo('C');
			} else {
				this.resgateFormService.setTabResgateInfo('');
			}
		} else if (status === 'resgate.type') {
			if (this.formField.type.realtyDebits.show === true) {
				if (!this.formField.type.realtyDebits.value) {
					cssClass = warningClass;
				} else {
					cssClass = 'change';
				}
			}
			if (this.formField.type.titleAquisition.show === true) {
				if (!this.formField.type.titleAquisition.value) {
					cssClass = warningClass;
				} else {
					cssClass = 'change';
				}
			}
			if (this.formField.type.resgateType.show === true) {
				if (!this.formField.type.resgateType.value) {
					cssClass = warningClass;
				} else {
					cssClass = 'change';
				}
			}
			if (this.formField.type.debitValue.show === true) {
				if (!this.formField.type.debitValue.value) {
					cssClass = warningClass;
				}
			}
			if (this.formField.type.hasRenterApprove.show === true) {
				if (!this.formField.type.hasRenterApprove.value) {
					cssClass = warningClass;
				}
			}
			if (this.formField.type.proposta.show === true) {
				if (!this.formField.type.proposta.value) {
					cssClass = warningClass;
				}
			}
			if (this.formField.type.resgateFinalMessage.show === true) {
				cssClass = warningClass;
			}
			if (this.formField.type.resgateType.show === true) {
				if (!this.formField.type.resgateType.value) {
					cssClass = warningClass;
				}
			}
			if (this.formField.type.customerAgreement.show === true) {
				if (!this.formField.type.customerAgreement.value || this.formField.type.customerAgreement.value === false) {
					cssClass = warningClass;
				}
			}
			if (this.formField.liberarCaucao === true) {
				cssClass = successClass;
			}

			if (this.formField.type.realtyDebits.show === true && this.formField.type.realtyDebits.value === 'true' && this.formField.type.proposta.show === true && this.formField.type.proposta.value !== null) {
				if (this.formField.type.proposta.value.value !== null) {
					let value = 0;
					for (let i = 0; i < this.formField.information.garantias.value.length; i++) {
						if (this.formField.information.garantias.value[i].value > this.formField.information.garantias.value[i].antecipatedValue) {
							value += this.formField.information.garantias.value[i].value;
						} else {
							value += this.formField.information.garantias.value[i].antecipatedValue;
						}
					}
					if ((this.formField.type.proposta.value.value + this.formField.type.debitValue.value) > value) {
						cssClass = warningClass;
					}
				}
			} else if (this.formField.type.proposta.show === true && this.formField.type.proposta.value !== null) {
				let value = 0;
				for (let i = 0; i < this.formField.information.garantias.value.length; i++) {
					if (this.formField.information.garantias.value[i].selected === true) {
						if (this.formField.information.garantias.value[i].value > this.formField.information.garantias.value[i].antecipatedValue) {
							value += this.formField.information.garantias.value[i].value;
						} else {
							value += this.formField.information.garantias.value[i].antecipatedValue;
						}
					}
				}
				if (this.formField.type.proposta.value.value > value) {
					cssClass = warningClass;
				}
			} else if (this.formField.type.realtyDebits.show === true && this.formField.type.realtyDebits.value === 'true' && this.formField.type.debitValue.value !== null) {
				let value = 0;
				for (let i = 0; i < this.formField.information.garantias.value.length; i++) {
					if (this.formField.information.garantias.value[i].selected === true) {
						if (this.formField.information.garantias.value[i].value > this.formField.information.garantias.value[i].antecipatedValue) {
							value += this.formField.information.garantias.value[i].value;
						} else {
							value += this.formField.information.garantias.value[i].antecipatedValue;
						}
					}
				}
				if (this.formField.type.debitValue.value > value) {
					cssClass = warningClass;
				}
			}

			if (cssClass === 'change') {
				cssClass = successClass;
			}

			if (cssClass === warningClass) {
				this.resgateFormService.setTabResgateType('W');
			} else if (cssClass === successClass) {
				this.resgateFormService.setTabResgateType('C');
			} else {
				this.resgateFormService.setTabResgateType('');
			}
		} else if (status === 'resgate.data') {
			cssClass = '';
			if (this.navStatus('resgate.data') !== '') {
				if (this.formField.type.caucionado === true) { //Campo dados do imóvel está visível
					if (!this.formField.data.realtyInformation.contractDate.value) {
						cssClass = warningClass;
					}
				}
				if (this.formField.data.customerData.show === true) {
					if (!this.formField.data.customerData.phone.value && this.formField.data.customerData.phone.show === true) {
						// console.log('warningClass', this.formField.data.customerData.phone.value, this.formField.data.customerData.phone.show);
						cssClass = warningClass;
					}
					if ((!this.formField.data.customerData.email.value || !this.accountUtilService.isValidEmail(this.formField.data.customerData.email.value)) && this.formField.data.customerData.email.show === true) {
						// console.log('warningClass', this.formField.data.customerData.email.value, this.accountUtilService.isValidEmail(this.formField.data.customerData.email.value), this.formField.data.customerData.email.show);
						cssClass = warningClass;
					}
					if (!this.formField.data.customerData.professionalActivity.value && this.formField.data.customerData.professionalActivity.show === true) {
						// console.log('warningClass', this.formField.data.customerData.professionalActivity.value, this.formField.data.customerData.professionalActivity.show);
						cssClass = warningClass;
					}
					if (!this.formField.data.customerData.income.value && this.formField.data.customerData.income.show === true) {
						// console.log('warningClass', this.formField.data.customerData.income.value, this.formField.data.customerData.income.show);
						cssClass = warningClass;
					}
					// console.log('cssClass', cssClass);
				}
				if (this.formField.decisionType === 'cancelamento-execucao-caucao') {

					if (!this.formField.data.customerData.name.value) {

						cssClass = warningClass;
					}
					if (!this.formField.data.customerData.cpfcnpj.value) {

						cssClass = warningClass;
					}
					// console.log('cssClass', cssClass);
				}
				if (this.formField.data.debitDeclaration.show === true) {

					if (this.formField.data.debitDeclaration.totalValue.value === 0) {

						cssClass = warningClass;
					}
					// console.log('cssClass', cssClass);
				}
				if (this.formField.data.representantData.show === true) {

					if (!this.formField.data.representantData.name.value || this.formField.data.representantData.name.value === '') {
						cssClass = warningClass;
					}
					if (!this.formField.data.representantData.cpfcnpj.value || this.formField.data.representantData.cpfcnpj.value === '') {
						cssClass = warningClass;
					}
					// console.log('cssClass', cssClass);
				}
				if (this.formField.data.debitValue.show === true) {
					if (!this.formField.data.debitValue.value || this.formField.data.debitValue.value === 0) {
						cssClass = warningClass;
					}
					// console.log('cssClass', cssClass);
				}
				if (this.formField.data.valueRemain.show === true) {
					if (this.formField.data.valueRemain.value === null || this.formField.data.valueRemain.value < 0) {
						cssClass = warningClass;
					}
					// console.log('cssClass', cssClass);
				}
				if (cssClass === '' && (this.formField.data.customerData.phone.value !== null ||
						this.formField.data.customerData.email.value !== null ||
						this.formField.data.customerData.professionalActivity.value !== null ||
						this.formField.data.customerData.income.value !== null ||
						(this.formField.data.debitValue.value !== null && this.formField.data.debitValue.value >= 0) ||
						(this.formField.data.valueRemain.value !== null && this.formField.data.valueRemain.value >= 0) ||
						this.formField.data.representantData.name.value !== null ||
						this.formField.data.representantData.cpfcnpj.value !== null) === true) {
					cssClass = successClass;
				}

				if (cssClass === warningClass) {
					this.resgateFormService.setTabResgateData('W');
				} else if (cssClass === successClass) {
					this.resgateFormService.setTabResgateData('C');
				} else {
					this.resgateFormService.setTabResgateData('');
				}
			}
		} else if (status === 'resgate.bank') {
			cssClass = '';
			if (this.navStatus('resgate.bank') !== '') {
				if (this.formField.bank.bankData.show === true) {
					if (!this.formField.bank.bankData.customerOwner.value && this.formField.bank.bankData.customerOwner.show === true) {
						cssClass = warningClass;
					}
					if (!this.formField.bank.bankData.accountType.value && this.formField.bank.bankData.accountType.show === true) {
						cssClass = warningClass;
					}
					if (!this.formField.bank.bankData.bank.value && this.formField.bank.bankData.bank.show === true) {
						cssClass = warningClass;
					}
					if (!this.formField.bank.bankData.agency.value && this.formField.bank.bankData.agency.show === true) {
						cssClass = warningClass;
					}
					if (!this.formField.bank.bankData.accountNumber.value && this.formField.bank.bankData.accountNumber.show === true) {
						cssClass = warningClass;
					}
					if (!this.formField.bank.bankData.accountOwnerName.value && this.formField.bank.bankData.accountOwnerName.show === true) {
						cssClass = warningClass;
					}
					if (!this.formField.bank.bankData.cpfcnpj.value && this.formField.bank.bankData.cpfcnpj.show === true) {
						cssClass = warningClass;
					}
					if (!this.formField.bank.bankData.professionalActivity.value && this.formField.bank.bankData.professionalActivity.show === true) {
						cssClass = warningClass;
					}
					if (!this.formField.bank.bankData.income.value && this.formField.bank.bankData.income.show === true) {
						cssClass = warningClass;
					}
					if (this.formField.bank.bankData.agency.hasError || this.formField.bank.bankData.agencyDV.hasAgencyDVPeopleError ||
						this.formField.bank.bankData.accountNumber.hasError || this.formField.bank.bankData.accountNumberDV.hasAccountNumberDVPeopleError) {
						cssClass = warningClass;
					}
				}

				if (this.formField.bank.bankBills.show === true) {
					if (!this.formField.bank.bankBills.accountType.value && this.formField.bank.bankBills.accountType.show === true) {
						cssClass = warningClass;
					}
					if (!this.formField.bank.bankBills.bank.value && this.formField.bank.bankBills.bank.show === true) {
						cssClass = warningClass;
					}
					if (!this.formField.bank.bankBills.agency.value && this.formField.bank.bankBills.agency.show === true) {
						cssClass = warningClass;
					}
					if (!this.formField.bank.bankBills.accountNumber.value && this.formField.bank.bankBills.accountNumber.show === true) {
						cssClass = warningClass;
					}
					if (!this.formField.bank.bankBills.accountOwnerName.value && this.formField.bank.bankBills.accountOwnerName.show === true) {
						cssClass = warningClass;
					}
					if (!this.formField.bank.bankBills.cpfcnpj.value && this.formField.bank.bankBills.cpfcnpj.show === true) {
						cssClass = warningClass;
					}
					if (!this.formField.bank.bankBills.professionalActivity.value && this.formField.bank.bankBills.professionalActivity.show === true) {
						cssClass = warningClass;
					}
					if (!this.formField.bank.bankBills.income.value && this.formField.bank.bankBills.income.show === true) {
						cssClass = warningClass;
					}
					if (this.formField.bank.bankBills.agency.hasError || this.formField.bank.bankBills.agencyDV.hasAgencyDVPeopleError ||
						this.formField.bank.bankBills.accountNumber.hasError || this.formField.bank.bankBills.accountNumberDV.hasAccountNumberDVPeopleError) {
						cssClass = warningClass;
					}

					if (!this.formField.bank.bankBills.debitEmail) {
						cssClass = warningClass;
					} else {
						if ((!this.formField.bank.bankBills.debitEmail.value || !this.formField.bank.bankBills.debitEmail.isValid) && this.formField.bank.bankBills.debitEmail.show === true) {
							cssClass = warningClass;
						}
					}
				}
				if (cssClass === '' && (this.formField.bank.bankData.customerOwner.value !== null ||
						this.formField.bank.bankData.accountType.value !== null ||
						this.formField.bank.bankData.bank.value !== null ||
						this.formField.bank.bankData.agency.value !== null ||
						this.formField.bank.bankData.accountNumber.value !== null ||
						this.formField.bank.bankData.accountOwnerName.value !== null ||
						this.formField.bank.bankData.cpfcnpj.value !== null ||
						this.formField.bank.bankData.professionalActivity.value !== null ||
						this.formField.bank.bankData.income.value !== null ||
						this.formField.bank.bankBills.accountType.value !== null ||
						this.formField.bank.bankBills.bank.value !== null ||
						this.formField.bank.bankBills.agency.value !== null ||
						this.formField.bank.bankBills.accountNumber.value !== null ||
						this.formField.bank.bankBills.accountOwnerName.value !== null ||
						this.formField.bank.bankBills.cpfcnpj.value !== null ||
						this.formField.bank.bankBills.professionalActivity.value !== null ||
						this.formField.bank.bankBills.income.value !== null) === true) {
					cssClass = successClass;
				}

				if (cssClass === warningClass) {
					this.resgateFormService.setTabResgateBank('W')
				} else if (cssClass === successClass) {
					this.resgateFormService.setTabResgateBank('C');
				} else {
					this.resgateFormService.setTabResgateBank('');
				}
			}
		} else if (status === 'resgate.file') {
			cssClass = '';
			if (this.navStatus('resgate.file') !== '') {

				/* Verifica se todos os campos
					de imagem que são exibidos, estão preenchidos */
				const campos = new Array();
				let exibiu = false;
				campos.push(this.formField.file.form);
				campos.push(this.formField.file.legalRepresentative.document);
				campos.push(this.formField.file.legalRepresentative.identityRenter);
				campos.push(this.formField.file.legalRepresentative.other);
				campos.push(this.formField.file.subscritor.identity);
				campos.push(this.formField.file.subscritor.identityAdmin);
				campos.push(this.formField.file.subscritor.cnpj);
				campos.push(this.formField.file.subscritor.constituitionDocumentation);
				campos.push(this.formField.file.subscritor.cpf);
				campos.push(this.formField.file.subscritor.proofAddress);
				campos.push(this.formField.file.subscritor.other);
				campos.push(this.formField.file.beneficiario.identity);
				campos.push(this.formField.file.beneficiario.identityAdmin);
				campos.push(this.formField.file.beneficiario.cpf);
				campos.push(this.formField.file.beneficiario.proofAddress);
				campos.push(this.formField.file.beneficiario.cnpj);
				campos.push(this.formField.file.beneficiario.constituitionDocumentation);
				campos.push(this.formField.file.beneficiario.other);

				for (let j = 0; j < campos.length; j++) {
					if (campos[j].show) { //Campo está sendo exibido
						exibiu = true;
						if (!campos[j].value) { // E não preenchido
							cssClass = warningClass;
							break;
						}
					}
				}
				if ((cssClass == '') && (exibiu)) cssClass = successClass;
			}
		}
		return cssClass;
	}

	clickRefuseCaseResgate() {
		console.log('clickRefuseCaseResgate');
		this.$scope.$emit('changeMainShowLoading', true);
		this.resgateService.refuseCaseResgateApp(this.formField.id).then((response) => {
			if (!response.isSuccessful) {
				this.messages.showErrorMessage(this.$scope, response.errorMessages[0]);
			} else {
				this.$state.go('case.detail', { id: this.formField.id });
			}
			this.$scope.$emit('changeMainShowLoading', false, 4000);
		});
	}

	clickSendResgate() {
		console.log('clickSendResgate');
		this.$scope.$emit('changeMainShowLoading', true);
		this.resgateService.sendCaseResgateApp(this.formField.id).then((response) => {
			if (!response.isSuccessful) {
				this.messages.showErrorMessage(this.$scope, response.errorMessages[0]);
			} else {
				this.$state.go('case.detail', { id: this.formField.id });
			}
			this.$scope.$emit('changeMainShowLoading', false, 4000);
		});
	}

	isDuplicatedAnnexes() {
		let valid = false;
		const annexes = this.getCurrentAnnexes();
		const filesJoin = [];

		for (let i = 0; i < annexes.length; i++) {
			Array.prototype.push.apply(filesJoin, annexes[i].files);
		}
		console.log('###################');
		console.log('isDuplicatedAnnexes');
		console.log(filesJoin);
		console.log('###################');

		for (let i = 0; i < filesJoin.length; i++) {
			for (let j = 0; j < filesJoin.length; j++) {
				if (i != j && filesJoin[i].name == filesJoin[j].name) {
					valid = true;
				}
			}
		}

		return valid;
	}

	isValidUploads() {
		let valid = true;
		const annexes = this.getCurrentAnnexesToValidate();
		for (let i = annexes.length - 1; i >= 0; i--) {
			if ((!annexes[i].files) || (annexes[i].files.length === 0)) {
				valid = false;
				break;
			}
		}
		valid = (this.isOnlySave ? true : valid);
		return valid;
	}

	checkUploadIntegrity() {
		let valid = true;
		const annexes = this.getCurrentAnnexes();
		for (let i = annexes.length - 1; i >= 0; i--) {
			for (let f = annexes[i].files.length - 1; f >= 0; f--) {
				annexes[i].files[f].result = !(annexes[i].files[f].result !== true);
				if (!(annexes[i].files[f] instanceof File) && (!annexes[i].files[f].result)) {
					valid = false;
					console.log("removendo arquivo", i, f);
					annexes[i].files.remove(annexes[i].files[f]);
				}
			}
		}
		console.log("checkUploadIntegrity", valid);
		return valid;
	}

	saveCaseUpdateEditCaseData() {
		this.resgateFormService.setFormField(this.formField);
		this.resgateService.updateEditCaseDataForSave().then((response) => {
			if (response.isSuccessful) {
				this.formField.id = response.caseId;
				this.resgateFormService.setFormField(this.formField);
				if (this.isOnlySave) this.resgateService.setSuccessMessage('Resgate salvo com sucesso.');
				this.$timeout(() => {
					this.resgateService.setSuccessMessage('');
				}, 5000);
			} else {
				this.resgateService.setErrorMessage(response.errorMessages[0]);
			}
			this.$scope.$emit('changeMainShowLoading', false, 2000);
		});
	}

	checkUpload() {
		console.log('checkUpload');
		this.resgateService.setErrorMessage('');

		if (this.isDuplicatedAnnexes()) {
			this.resgateService.setErrorMessage('Por favor verifique os anexos duplicados.');
			this.$scope.$emit('changeMainShowLoading', false, 1000);
		} else if (this.isValidUploads()) {

			const annexes = this.getCurrentAnnexes();
			const caseId = this.formField.id;
			const gedAttachmentType = 'outros';
			const caseType = (this.formField.liberarCaucao === true) ? 'liberacao-da-caucao' : 'resgate';

			this.annexHelper.uploadAnnexes(annexes, caseId, gedAttachmentType, caseType)
				.then(() => {

					this.resgateService.setErrorMessage('');
					if (this.isOnlySave == false) {
						this.saveCaseChangeStatus();
					} else {
						this.saveCaseUpdateEditCaseData();
					}
				})
				.catch(() => {

					const genericErrorMessage = 'Um ou mais arquivos não foram anexados. Os arquivos precisam ter até 8MB, não podem possuir o mesmo nome e devem ter as extensões permitidas.';
					this.resgateService.setErrorMessage(genericErrorMessage);
					this.$scope.$emit('changeMainShowLoading', false, 4000);
				});
		} else {

			this.resgateService.setErrorMessage('Por favor anexe todos os documentos.');
			this.$scope.$emit('changeMainShowLoading', false, 1000);
		}
	}

	getCurrentAnnexes() {

		const annexes = [
			this.formField.file.form,
			this.formField.file.legalRepresentative.document,
			this.formField.file.legalRepresentative.identityRepresentante,
			this.formField.file.legalRepresentative.constituitionDocumentation,
			this.formField.file.legalRepresentative.cnpj,
			this.formField.file.legalRepresentative.identityAdmin,
			this.formField.file.legalRepresentative.identityRenter,
			this.formField.file.legalRepresentative.other,
			this.formField.file.subscritor.constituitionDocumentation,
			this.formField.file.subscritor.identity,
			this.formField.file.subscritor.identityAdmin,
			this.formField.file.subscritor.cnpj,
			this.formField.file.subscritor.cpf,
			this.formField.file.subscritor.proofAddress,
			this.formField.file.subscritor.other,
			this.formField.file.beneficiario.identity,
			this.formField.file.beneficiario.identityAdmin,
			this.formField.file.beneficiario.cnpj,
			this.formField.file.beneficiario.cpf,
			this.formField.file.beneficiario.proofAddress,
			this.formField.file.beneficiario.constituitionDocumentation,
			this.formField.file.beneficiario.other
		];

		for (let i = annexes.length - 1; i >= 0; i--) {
			// Garante que novas caixas de upload, irão automaticamente serem criados conforme a necessidade
			if (typeof annexes[i] === "undefined") annexes[i] = this.resgateFormService.pristineAnnexObj();
			if (!annexes[i].show) {
				angular.extend(annexes[i], this.resgateFormService.pristineAnnexObj());
				annexes.splice(i, 1);
			}
		}

		// console.log('getCurrentAnnexes', annexes);
		// console.log(this.formField.annex.form, this.formField.annex.legalRepresentative.document, this.formField.annex.legalRepresentative.identityRenter, this.formField.annex.legalRepresentative.other);

		return annexes;
	}

	getCurrentAnnexesToValidate() {
		//@todo tem de por alguma propriedade pra definir se é obrigatório 
		//e usar a mesma lista do getCurrentAnnexes
		const annexes = [

			this.formField.file.form,
			this.formField.file.legalRepresentative.document,
			this.formField.file.legalRepresentative.identityRepresentante,
			this.formField.file.legalRepresentative.constituitionDocumentation,
			this.formField.file.legalRepresentative.cnpj,
			this.formField.file.legalRepresentative.identityAdmin,
			this.formField.file.legalRepresentative.identityRenter,
			this.formField.file.subscritor.constituitionDocumentation,
			this.formField.file.subscritor.identity,
			this.formField.file.subscritor.identityAdmin,
			this.formField.file.subscritor.cnpj,
			this.formField.file.subscritor.cpf,
			this.formField.file.subscritor.proofAddress,
			this.formField.file.beneficiario.identity,
			this.formField.file.beneficiario.identityAdmin,
			this.formField.file.beneficiario.cnpj,
			this.formField.file.beneficiario.cpf,
			this.formField.file.beneficiario.proofAddress,
			this.formField.file.beneficiario.constituitionDocumentation
		];

		for (let i = annexes.length - 1; i >= 0; i--) {
			// Garante que novas caixas de upload, irão automaticamente serem criados conforme a necessidade
			if (typeof annexes[i] === "undefined") annexes[i] = this.resgateFormService.pristineAnnexObj();
			if (!annexes[i].show) {
				angular.extend(annexes[i], this.resgateFormService.pristineAnnexObj());
				annexes.splice(i, 1);
			}
		}

		// console.log('getCurrentAnnexes', annexes);
		// console.log(this.formField.annex.form, this.formField.annex.legalRepresentative.document, this.formField.annex.legalRepresentative.identityRenter, this.formField.annex.legalRepresentative.other);

		return annexes;
	}

	sendResgate() {
		this.isOnlySave = false;
		this.resgateService.setErrorMessage('');
		this.saveCaseBeforeUpload();
	}

	clickPopupDocumentation(value) {
		this.$scope.$broadcast('clickPopupDocumentation', value);
	}

}

module.exports = resgateController;
