"use strict";

class ResgateBankService {
	/* @ngInject */
	constructor(visualForceApi, $sce, $q) {
		this.visualForceApi = visualForceApi;
		this.$q = $q;
	}

	getBankList() {
		return [
			"341	BANCO ITAÚ",
			"260    NUBANK",
			"001	BANCO DO BRASIL S.A.",
			"237	BRADESCO S.A.",
			"104	CAIXA ECONÔMICA FEDERAL",
			"007	BNDES",
			"033	BANCO SANTANDER (BRASIL) S.A.",
			"399	HSBC BANK BRASIL SABANCO MULTIP",
			"041	BANCO EST. RIO GRANDE DO SUL",
			"004	BANCO NORDESTE DO BRASIL S.A.",
			"070	BRB - BANCO DE BRASILIA S.A.",
			"021	BANESTES",
			"037	BANCO EST. DO PARA",
			"136	CONF NACIONAL COOP CENTRAIS UN.",
			"206	BANCO MARTINELLI S.A.",
			"223	BANCO INTERUNION S.A.",
			"077	BANCO INTERMEDIUM S.A.",
			"241	BANCO CLASSICO S.A.",
			"262	BOREALS.A. BANCO COMERCIAL",
			"308	BANCO COMERCIAL BANCESA S.A.",
			"375	BANCO FENICIA S.A.",
			"755	BANK OF AMERICA MERRILL LYNCH",
			"472	LLOYDS BANK PLC",
			"026	BANCO EST. DO ACRE S.A.",
			"702	BANCO SANTOS S.A.",
			"047	BANCO EST. DE SERGIPE",
			"089	COOPERATIVA CREDITO RURAL REGI",
			"028	BANEB S.A.",
			"049	BANCO DESEN.DO EST. DA BAHIA S.A.",
			"165	BANCO NORCHEM S.A.",
			"091	Unicred Central do RJ",
			"212	BANCO ORIGINAL",
			"735	BANCO NEON S.A",
			"168	BANCO MONTREAL S.A.",
			"229	BANCO CRUZEIRO DO SUL S.A.",
			"214	BANCO DIBENS S.A.",
			"231	BANCO BOAVISTA S.A.",
			"246	BANCO ABC-ROMA S.A.",
			"249	BANCO INVESTCRED S.A.",
			"267	BANCO BAHIA S.A.",
			"344	BANCO MERCANTIL S.A.",
			"277	PLANIBANC S.A.",
			"347	BANCO SUDAMERIS BRASIL S.A.",
			"394	BMC S.A.",
			"422	BANCO SAFRA S.A.",
			"505	BANCO CREDIT SUISSE FIRST BOST",
			"641	BILBAO VISCAYA",
			"747	BANCO RABOBANK",
			"604	BANCO INDUSTRIAL DO BRASIL",
			"023	BANCO DE DESEN. DE MG S.A.",
			"039	BANCO EST. DO PIAUI S.A.",
			"008	SANTAND.MERIDIONAL",
			"055	BANCO DESEN. DO RG DO NORTE S.A.",
			"106	BANCO CREFISUL S.A.",
			"201	BANCO AUGUSTA IND. E COM. S.A.",
			"219	BANCO CRED. DE SAO PAULO S.A.",
			"235	BANCO LIBERAL S.A.",
			"255	BANCO BRASCAN",
			"300	BANCO LA NACION ARGENTINA",
			"369	DIGIBANCOS.A.",
			"424	BANCO SANTANDER NOROESTE",
			"643	BANCO PINE S.A.",
			"741	BANCO RIBEIRÃO PRETO S.A.",
			"024	BANCO EST. DE PERNAMBUCO",
			"085	CECRED",
			"153	CAIXA ECON. EST. DO RG DO SUL",
			"210	DEUTSCH SUDAMERIKANICHE BANK",
			"227	BANCO ROSA S.A.",
			"244	BANCO CIDADE S.A.",
			"265	BANCO FATOR S.A.",
			"334	BANCO ECONOMICO",
			"389	BANCO MERCANTIL DO BRASIL",
			"610	VR S.A.",
			"752	BANCOBNP PARIBÏS BRASILS.A.",
			"019	BANCO AZTECA",
			"035	BANCO EST. DO CEARA",
			"059	BANCO EST. DE RONDONIA",
			"109	CREDIBANCO S.A.",
			"204	BANCO MANTRUSTSRL S.A.",
			"221	BANCO GRAPHUS S.A.",
			"258	BANCO INDUSCRED S.A.",
			"303	BANCO MOSSORO S.A.",
			"342	CARTØO RECE",
			"456	BANCO TOKYO-MITSUBISHI BRASIL",
			"655	BANCO VOTORANTIN",
			"025	BANCO ALFA",
			"043	BANCO EST. RIO GRANDE DO NORTE",
			"087	COOPERATIVA UNICRED CENTRAL ST",
			"164	BANCO CRED.COMMERC.DE FRANCES.A.",
			"211	BANCO SISTEMA S.A.",
			"228	BANCO ICATU S.A.",
			"245	BANCO EMPRESARIAL S.A.",
			"266	BANCO CÉDULA S.A.",
			"392	BANCO MERCANTIL DE SAO PAULO S.A",
			"492	ING BANK",
			"745	CITIBANK",
			"032	BANCO EST. DE MATO GROSSO S.A.",
			"053	BANCO DESEN. DO CEARA S.A.",
			"200	BANCO FICRISA AXELRUD S.A.",
			"218	BONSUCESSO FACTORING LTDA",
			"124	BANCO WOORI BANK DO BRASIL S.A",
			"254	PARANÁ BANCO S.A.",
			"295	BANCO CREDIPLAN S.A.",
			"366	BANCO SOGERAL S.A.",
			"464	BANCO SUMITOMO BRASILEIRO S.A.",
			"658	BANCO PORTO REAL S.A.",
			"653	BANCO INDUSVAL S.A.",
			"027	BANCO EST. DE STA CATARINA",
			"048	BANCO EST. DE MINAS GERAIS",
			"090	COOPERATIVA DAS UNICREDS",
			"166	BANCO INTER-ATLANTICO S.A.",
			"213	BANCO ARBI S.A.",
			"230	BANCO BANDEIRANTES S.A.",
			"247	UBS S.A.",
			"275	BANCO ABN AMRO REAL S.A.",
			"346	BANCO FRANCES E BRASILEIRO S.A.",
			"601	BIC BANCO S.A.",
			"084	BANCO UNIPRIME COOPERATIVA DE",
			"748	BANCO COOPERATIVO SICREDI S.A.",
			"010	CREDICOAMO CRÉDITO RURAL COOPE",
			"151	NOSSA CAIXA- NOSSO BANCO S.A.",
			"002	BANCO CENTRAL DO BRASIL",
			"029	BANCO BANERJ S.A.",
			"050	BANCO DESEN. EST. DO RJ S.A.",
			"094	PETRA",
			"171	BANCO CRED. E COM. DE INV. S.A.",
			"215	BANCO AMERICA DO SUL S.A.",
			"232	BANCO INTERPART S.A.",
			"250	BANCO SCHAHINCURY S.A.",
			"282	BANCO BRASILEIRO COMERCIAL S.A.",
			"351	BANCO BOZANO SIMONSEN S.A.",
			"412	BANCO NACIONAL DA BAHIA S.A.",
			"623	BANCO PANAMERICANO",
			"756	BANCO COOPERATIVO DO BRASIL S.A.",
			"020	BANCO EST. DE ALAGOAS S.A.",
			"036	BANCO EST. DO MARANHÃO",
			"066	BANCO MORGAN STANLEY S.A.",
			"112	BANCO UNICRED",
			"205	BANCO SUL AMERICA S.A.",
			"222	BHM S.A.",
			"239	BANCRED S.A.",
			"261	BANCO VARIG S.A.",
			"304	BANCO PONTUAL S.A.",
			"372	BANCO ITAMARATI S.A.",
			"487	DEUTSCHE BANK AG",
			"733	BANCO DAS NACOES S.A.",
			"031	BANCO EST. DE GOIAS S.A.",
			"052	BANCO DESEN. DO EST. DO MARANHAO",
			"099	CENTRAL INTERESTADUAL DE COOP",
			"184	BANCO BBA-CREDITANSTALT S.A.",
			"217	BANCO JOHN DEERE",
			"234	BANCO LAVRA S.A.",
			"252	BANCO FININVEST S.A.",
			"034	BANCO EST. DO AMAZONAS",
			"056	BANCO DESEN. EST. DO RG DO SUL",
			"107	BANCO BBM",
			"202	BANCO BRASEG S.A.",
			"220	BANCO ANTONIO DE QUEIROZ S.A.",
			"236	BANCO CAMBIAL S.A.",
			"256	BANCO GULFINVEST S.A.",
			"302	BANCO PROGRESSO S.A.",
			"370	BANCO EUROPEU P/ AMERICA LATINA",
			"453	BANCO RURAL",
			"654	BANCO A. J. RENNER S.A.",
			"515	UNICRED FLORIANOPOLIS",
			"208	BANCO PACTUAL S.A.",
			"225	BANCO BRASCAN",
			"243	BANCO STOCK S.A.",
			"264	BANCO PERFORMANCES.A.",
			"320	BICBANCO S.A.",
			"388	BANCO MERCANTIL DE DESCONTOS S.A.",
			"479	BANCO DE BOSTON S.A.",
			"721	CREDIBEL S.A.",
			"003	BANCO DA AMAZONIA",
			"030	BANCO EST. DA PARAIBA S.A.",
			"051	BANCO DESEN. DO ESP. SANTO S.A.",
			"097	BANCO JICRED",
			"175	CONTINENTAL BANCO S.A.",
			"216	BANCO REGIONAL.MALCON S.A.",
			"233	BANCO MAPPIN S.A.",
			"251	BANCO SAO JORGE S.A.",
			"291	BANCO CREDITO NACIONAL S.A.",
			"353	SANTANDER BRASIL",
			"415	BANCO NACIONAL",
			"624	BANCO GENERAL MOTORS S.A.",
			"999	BANCO CREDICOAMO",
			"294	BANCO CREDITO REAL RG DO SUL",
			"356	BANCO ABN AMRO REAL S.A.",
			"420	BANORTE S.A.",
			"633	BANCO RENDIMENTO S.A.",
			"9999	SEC.ESTADO DE FAZENDA/RJ",
			"022	BANCO CRED. REAL MINAS GERAIS",
			"038	BANCO BANESTADO S.A.",
			"083	BANCO DA CHINA BRASIL S.A.",
			"148	MULTI BANCO S.A.",
			"207	BANCO GARAVELO S.A.",
			"224	BANCO FIBRA S.A.",
			"242	BANCO EUROINVEST S.A.",
			"263	BANCO CACIQUE S.A.",
			"318	BMG BANCO COMERCIAL S.A.",
			"376	BANCO J.P. MORGAN S.A.",
			"477	CITYBANKN.A.",
			"707	DAYCOVAL S.A."
		];
	}

	getBankListContaPoupanca() {
		return [
			"341	BANCO ITAÚ",
			"237	BRADESCO S.A.",
			"033	BANCO SANTANDER (BRASIL) S.A.",
			"424	BANCO SANTANDER NOROESTE",
			"353	SANTANDER BRASIL"
		];
	}
}

module.exports = ResgateBankService;
