"use strict";

/* @ngInject */
function routesConfiguration($stateProvider, sfConfig) {
	$stateProvider.state("new-account", {
		url: "/nova-conta",
		views: {
			content: {
				templateUrl: `${sfConfig.resource}/views/account/new-account.html`
			}
		}
	});

	$stateProvider.state("new-juridical-account", {
		url: "/nova-conta-juridica",
		views: {
			content: {
				templateUrl: `${sfConfig.resource}/views/account/new-juridical-account.html`
			}
		}
	});

	$stateProvider.state("account-detail", {
		url: "/conta/detalhes",
		views: {
			content: {
				templateUrl: `${sfConfig.resource}/views/account/account-detail.html`,
				controller: "accountDetailController",
				controllerAs: "accountDetail",
				params: ["id"]
			}
		},
		params: {
			id: null
		}
	});

	$stateProvider.state("edit-juridical-account", {
		url: "/conta/pj/edicao?:id",
		views: {
			content: {
				templateUrl: `${sfConfig.resource}/views/account/new-juridical-account.html`,
				params: ["id"]
			}
		}
	});

	$stateProvider.state("edit-account", {
		url: "/conta/pf/edicao?:id",
		views: {
			content: {
				templateUrl: `${sfConfig.resource}/views/account/new-account.html`,
				params: ["id"]
			}
		}
	});
}

module.exports = routesConfiguration;
