'use strict';

const listController = require('./list/list.controller');
const listApiService = require('./list/list-api.service');

const detailController = require('./detail/detail.controller');
const detailApiService = require('./detail/detail-api.service');

const routesConfiguration = require('./remuneracoes-pagas.routes');

const remuneracoesPagasModule = angular.module('cap.secured.remuneracoes-pagas', [
		'ui.router'
	])
	.controller('remuneracoesPagasListController', listController)
	.service('remuneracoesPagasListApi', listApiService)

	.controller('remuneracoesPagasDetailController', detailController)
	.service('remuneracoesPagasDetailApi', detailApiService)

	.config(routesConfiguration);

module.exports = remuneracoesPagasModule;
