class revisaoComissaoNewService {

	/* @ngInject */
	constructor(visualForceApi, $sce, $q, sfConfig) {
		this.visualForceApi = visualForceApi;
		this.$q = $q;
		this.estrutraVenda = '';
		this.idUSerAccount = sfConfig.idUSerAccount;
	}

	/**
	 * 
	 * @param {*String do Informe CPF/CNPJ} searchKeyWord 
	 */
	getEstruturaVenda() {
		return this.visualForceApi.invoke(
			'CapCommunitySearchObjectControllerExt.getEstruturaDeVendaByAccountId', this.idUSerAccount);
	}

	/**
	 * Pega as propostas dentro do scopo da reaplicacaoTituloNewService
	 */
	getPropostas() {
		return this.propostas;
	}

	/**
	 * Atribuir as propostas dentro do scopo da reaplicacaoTituloNewService
	 */
	setPropostas(propostas) {
		this.propostas = propostas;
	}
}

module.exports = revisaoComissaoNewService;
