'use strict';

/* @ngInject */
function routesConfiguration($stateProvider, sfConfig) {

	$stateProvider.state('case.documentacao-proposta', {
		abstract: true,
		url: '/documentacao-proposta?:id',
		views: {
			'case': {
				templateUrl: `${sfConfig.resource}/views/case/case-documentacao-proposta/base.html`,
				controller: 'documentacaoPropostaController',
				controllerAs: 'caseInstance'
			}
		}
	});

	$stateProvider.state('case.documentacao-proposta.detail', {
		url: '/detalhes',
		views: {
			'case.documentacao-proposta': {
				templateUrl: `${sfConfig.resource}/views/case/case-documentacao-proposta/detail/detail.html`,
				controller: 'documentacaoPropostaDetailController',
				controllerAs: 'caseDetail'
			}
		}
	});

	$stateProvider.state('case.documentacao-proposta.new', {
		url: '/nova',
		views: {
			'case.documentacao-proposta': {
				templateUrl: `${sfConfig.resource}/views/case/case-documentacao-proposta/new/new.html`,
				controller: 'documentacaoPropostaNewController',
				controllerAs: 'caseNew'
			}
		}
	});

	$stateProvider.state('case.documentacao-proposta.upload', {
		url: '/upload',
		views: {
			'case.documentacao-proposta': {
				templateUrl: `${sfConfig.resource}/views/case/case-documentacao-proposta/upload/upload.html`,
				controller: 'documentacaoPropostaUploadController',
				controllerAs: 'caseUpload'
			}
		}
	});

};

module.exports = routesConfiguration;
