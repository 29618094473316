'use strict';

class accountTypeModalController {

	// @ngInject
	constructor(sfConfig, accountUtilService, propostaSetupAccountService, alteracaoDadosSetupAccountService, $state, $uibModalStack) {
		this.imagePFAccountPath = `${sfConfig.resource}/images/ico-fis.png`
		this.imagePJAccountPath = `${sfConfig.resource}/images/ico-jur.png`
		this.accountUtilService = accountUtilService;
		this.propostaSetupAccountService = propostaSetupAccountService;
		this.alteracaoDadosService = alteracaoDadosSetupAccountService;
		this.$state = $state;
		this.$uibModalStack = $uibModalStack;
	}

	setFromInclusaoCaucaoLocador(value) {
		this.alteracaoDadosService.setFromInclusaoCaucaoLocador(value);
	}

	setFromInclusaoCaucaoAdministradorImovel(value) {
		this.alteracaoDadosService.setFromInclusaoCaucaoAdministradorImovel(value);
	}

	setFromAlteracaoImovel(value) {
		this.alteracaoDadosService.setFromAlteracaoImovel(value);
	}

	setFromMudancaLocador(value) {
		this.alteracaoDadosService.setFromMudancaLocador(value);
	}

	setFromProposta(value) {
		this.propostaSetupAccountService.setFromProposta(value);
	}

	setFromCartaCaucaoLocator(value) {
		this.propostaSetupAccountService.setFromCartaCaucaoLocator(value);
	}

	setFromCartaCaucaoEstateAdmin(value) {
		this.propostaSetupAccountService.setFromCartaCaucaoEstateAdmin(value);
	}

	closeModal(state) {

		this.$uibModalStack.dismissAll();
		$('#previewModal').modal('hide');

		if (this.accountUtilService.getStateToGo() !== '') {
			this.$state.go(this.accountUtilService.getStateToGo());
		}
	}

}

module.exports = accountTypeModalController;
