'use strict';

/* @ngInject */
function routesConfiguration($stateProvider, sfConfig) {

	$stateProvider.state('administrar-usuario', {

		abstract: true,
		url: '/administrar-usuario',
		views: {

			'content': {

				templateUrl: `${sfConfig.resource}/views/administrar-usuario/base.html`
			}
		}
	});

	$stateProvider.state('administrar-usuario.list', {

		url: '/lista',
		views: {

			'administrar-usuario': {

				templateUrl: `${sfConfig.resource}/views/administrar-usuario/list/list.html`,
				controller: 'administrarUsuarioListController',
				controllerAs: 'administrarUsuarioList'
			}
		}
	});

	$stateProvider.state('administrar-usuario.new', {

		url: '/novo',
		views: {

			'administrar-usuario': {

				templateUrl: `${sfConfig.resource}/views/administrar-usuario/new/new.html`,
				controller: 'administrarUsuarioNewController',
				controllerAs: 'administrarUsuarioNew'
			}
		}
	});

	$stateProvider.state('administrar-usuario.detail', {

		url: '/detalhes/:id',
		views: {

			'administrar-usuario': {

				templateUrl: `${sfConfig.resource}/views/administrar-usuario/detail/detail.html`,
				controller: 'administrarUsuarioDetailController',
				controllerAs: 'administrarUsuarioDetail'
			}
		}
	});

	$stateProvider.state('administrar-usuario.edit', {

		url: '/editar/:id',
		views: {

			'administrar-usuario': {

				templateUrl: `${sfConfig.resource}/views/administrar-usuario/edit/edit.html`,
				controller: 'administrarUsuarioEditController',
				controllerAs: 'administrarUsuarioEdit'
			}
		}
	});
};

module.exports = routesConfiguration;
