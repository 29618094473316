'use strict';

class administrarUsuarioEditApi {

	/* @ngInject */
	constructor(visualForceApi) {

		this.visualForceApi = visualForceApi;
	}

	sendForm(form) {
		console.log('sendForm', form);

		return this.visualForceApi.invoke(
			'CapCommunityUserControllerExt.editUser', form);
	}

	getDetails(userId) {

		return this.visualForceApi.invoke(
			'CapCommunityUserControllerExt.getUserDetail', userId);
	}

	initUserPermissions() {
		return {
			ga_cadastro: 'rw',
			ga_proposta: 'rw',
			ga_garantiaAluguel: 'rw',
			ga_resgate: 'rw',
			ga_liberacaoCaucao: 'rw',
			ga_bloqueioReaplicacao: 'rw',
			ga_reaplicacaoTitulo: 'rw',
			ga_revisaoComissao: 'rw',
			ga_alteracaoDeDados: 'rw',
			ga_sorteio: 'rw'
		};
	}
}

module.exports = administrarUsuarioEditApi;
