'use strict';

class sorteioDetailApi {

	/* @ngInject */
	constructor(visualForceApi, $timeout, $q) {

		this.visualForceApi = visualForceApi;
		this.$timeout = $timeout;
		this.$q = $q;
	}

	getDetails(resgateId) {

		const cap_access_token = localStorage.getItem("cap_access_token");

		return this.visualForceApi.invoke(
			'CapCommunityCaseControllerExt.getCaseSorteioDetails', resgateId, cap_access_token);
	}
}

module.exports = sorteioDetailApi;
