'use strict';

class reaplicacaoTituloController {

	// @ngInject
	constructor($scope, $window, $state, $timeout, reaplicacaoTituloFormService, reaplicacaoTituloService, annexHelper, breadcrumbHelper) {
		console.log('reaplicacaoTituloController');

		this.$scope = $scope;
		this.$window = $window;
		this.$state = $state;
		this.$timeout = $timeout;

		this.reaplicacaoTituloFormService = reaplicacaoTituloFormService;
		this.formField = this.reaplicacaoTituloFormService.getFormField();
		this.reaplicacaoTituloService = reaplicacaoTituloService;
		this.annexHelper = annexHelper;

		//this.listenValidUploads();
		//this.listenSaveCaseInstance();

		if (this.reaplicacaoTituloService.getIsEdit() === false) {
			this.reaplicacaoTituloService.setPreviousShow(false);
			this.reaplicacaoTituloService.setPreviousDisabled(true);
			this.reaplicacaoTituloService.setFinishShow(false);
			this.reaplicacaoTituloService.setFinishDisabled(true);
			this.reaplicacaoTituloService.setNextShow(true);
			this.reaplicacaoTituloService.setNextDisabled(true);
			this.reaplicacaoTituloService.setSaveShow(true);
			this.reaplicacaoTituloService.setSaveDisabled(true);
			this.reaplicacaoTituloService.setCancelShow(true);
			this.reaplicacaoTituloService.setCancelDisabled(false);
		}

		this.reaplicacaoTituloService.setSaveDisabled(false);
		this.reaplicacaoTituloService.setNextDisabled(false);
		this.reaplicacaoTituloService.setCancelDisabled(false);

		console.log(this.formField);

		this.$scope.breadcrumb = breadcrumbHelper;

		// setInterval(() => { console.log(this.formField); }, 20000);
	}

	hasNavNewCase() {
		return this.$state.current.name !== 'case.reaplicacaoTitulo.detail';
	}

	navStatusIcon(status) {
		const warningClass = 'fa-exclamation-triangle warning';
		const successClass = 'fa-check-circle success';
		let cssClass = '';
		if (status === 'reaplicacaoTitulo.new') {
			cssClass = successClass;
		} else if (status === 'reaplicacaoTitulo.file') {
			cssClass = successClass;
		}
		return cssClass;
	}

	goTo(type) {
		if (type === 'reaplicacaoTitulo.new') {
			if (this.formField.information.active === false) {
				this.formField.information.active = true;
				this.formField.file.active = false;
				this.reaplicacaoTituloService.setPreviousShow(false);
				this.reaplicacaoTituloService.setPreviousDisabled(true);
				this.reaplicacaoTituloService.setNextShow(true);
				this.reaplicacaoTituloService.setNextDisabled(false);
				this.reaplicacaoTituloService.getFinishDisabled(true);
				this.reaplicacaoTituloService.setFinishShow(false);
				this.reaplicacaoTituloFormService.setFormField(this.formField);
				this.$window.scrollTo(0, 0);
				this.$state.go('reaplicacaoTitulo.new');
			}
		} else if (type === 'reaplicacaoTitulo.file') {
			if (this.formField.file.active === false) {
				if (this.navStatus('reaplicacaoTitulo.file') != '') {
					this.formField.information.active = false;
					this.formField.file.active = true;
					this.reaplicacaoTituloService.setPreviousShow(true);
					this.reaplicacaoTituloService.setPreviousDisabled(false);
					this.reaplicacaoTituloService.setNextShow(false);
					this.reaplicacaoTituloService.setNextDisabled(true);
					this.reaplicacaoTituloService.getFinishDisabled(false);
					this.reaplicacaoTituloService.setFinishShow(true);
					this.reaplicacaoTituloFormService.setFormField(this.formField);
					this.$window.scrollTo(0, 0);
					this.$state.go('reaplicacaoTitulo.file');
				}
			}
		}
	}

	save() {
		console.log(this.formField);
		this.reaplicacaoTituloService.setErrorMessage('');
		this.$scope.$emit('changeMainShowLoading', true);
		if (this.isFormValid()) {
			this.reaplicacaoTituloService.sendCaseReaplicacaoTitulo()
				.then((response) => {

					if (response.isSuccessful) {
						this.formField.id = response.caseId;
						this.reaplicacaoTituloFormService.setFormField(this.formField);
						this.scapReap();
					} else {
						this.$scope.$emit('changeMainShowLoading', false);
						this.reaplicacaoTituloService.setErrorMessage(response.errorMessages[0]);
					}
				})
		}
	}

	scapReap() {
		this.reaplicacaoTituloService.reapNoScap()
			.then((response) => {
				this.$scope.$emit('changeMainShowLoading', false);
				if (response.isSuccessful) {
					//this.reaplicacaoTituloService.setSuccessMessage('Reaplicação de Título enviado com sucesso.');
					//this.$timeout(() => {
					//	this.reaplicacaoTituloService.setSuccessMessage('');
					//}, 5000);
					this.$state.go('case.reaplicacao-titulo.detail', { id: this.formField.id });
					//this.reaplicacaoTituloFormService.setPristine();
				} else {
					this.reaplicacaoTituloService.setErrorMessage(response.errorMessages[0]);
				}
			});
	}

	isFormValid() {

		this.hasRequiredMainAccount();

		this.hasRequiredProposta();

		this.hasRequiredGarantias();

		//this.hasRequiredMotivoBloqueio();

		this.hasRequiredAutorizacaoReaplicacao();

		this.reaplicacaoTituloFormService.setFormField(this.formField);

		return (!this.formField.information.mainAccount.hasError && !this.formField.information.proposta.hasError && !this.formField.information.garantias.hasError && !this.formField.information.autorizacaoreaplicacao.hasError)
	}

	hasRequiredFields() {
		const result = (!!this.formField.information.mainAccount.value &&
			!!this.formField.information.proposta.value &&
			!!this.hasGarantiasSelected() &&
			//!!this.formField.information.motivobloqueio.value &&
			!!this.formField.information.autorizacaoreaplicacao.value
		);

		return result;
	}

	hasRequiredMainAccount() {

		let hasRequirement = true;

		if (this.formField.information.mainAccount.value === null) {

			hasRequirement = false;
		}

		this.formField.information.mainAccount.hasError = !hasRequirement;
		return hasRequirement;
	}

	cancelCurrentSolicitacao() {
		this.reaplicacaoTituloService.setErrorMessage('');
		this.$scope.$emit('changeMainShowLoading', true);
		this.$state.go('case.list');
		this.$scope.$emit('changeMainShowLoading', false);
	}

	hasRequiredProposta() {

		let hasRequirement = true;

		if (this.formField.information.proposta.value === null) {

			hasRequirement = false;
		}

		this.formField.information.proposta.hasError = !hasRequirement;
		return hasRequirement;
	}

	hasGarantiasSelected() {
		let hasGarantiaSelected = false;
		if (this.formField.information.garantias.value !== null) {
			for (let i = 0; i < this.formField.information.garantias.value.length; i++) {

				if (this.formField.information.garantias.value[i].selected) {

					hasGarantiaSelected = true;
					break;
				}
			}
		}
		return hasGarantiaSelected;
	}

	hasRequiredGarantias() {

		let hasRequirement = true;

		let hasGarantiaSelected = false;
		hasGarantiaSelected = this.hasGarantiasSelected();

		if (!hasGarantiaSelected) {

			hasRequirement = false;
		}

		this.formField.information.garantias.hasError = !hasRequirement;
		return hasRequirement;
	}

	/*hasRequiredMotivoBloqueio() {
		let hasRequirement = true;

		if (this.formField.information.motivobloqueio === null) {
			hasRequirement = false;
		}

		this.formField.information.motivobloqueio.hasError = !hasRequirement;
		return hasRequirement;
	}*/

	hasRequiredAutorizacaoReaplicacao() {
		let hasRequirement = true;

		if (this.formField.information.autorizacaoreaplicacao === null) {
			hasRequirement = false;
		}

		this.formField.information.autorizacaoreaplicacao.hasError = !hasRequirement;
		return hasRequirement;
	}

	navStatus(status) {
		if (status === 'reaplicacaoTitulo.new') {
			if (this.formField.information.active === true) {
				return 'act';
				//} else if (this.formField.information.mainAccount.value !== null) {
				//	return 'edt';
			} else {
				return '';
			}
		} else if (status === 'reaplicacaoTitulo.file') {
			if (this.formField.file.active === true) {
				return 'act';
				//} else if ((this.formField.tabResgateBank == "C") && (this.resgateService.getIsEdit())) {
				//	return 'edt';
			} else {
				return '';
			}
		}
	}
}

module.exports = reaplicacaoTituloController;
