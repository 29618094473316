'use strict';

const rentGuaranteeController = require('./rent-guarantee.controller');
const routesConfiguration = require('./rent-guarantee.routes');

const rentGuaranteeListController = require('./rent-guarantee-list/rent-guarantee-list.controller');
const rentGuaranteeListApiService = require('./rent-guarantee-list/rent-guarantee-list-api.service');

const rentGuaranteeDetailsController = require('./rent-guarantee-details/rent-guarantee-details.controller');
const rentGuaranteeDetailsApiService = require('./rent-guarantee-details/rent-guarantee-details-api.service');

const rentGuaranteeModule = angular.module('cap.secured.rent-guarantee', [
		'ui.router'
	])
	.controller('rentGuaranteeController', rentGuaranteeController)

	.controller('rentGuaranteeListController', rentGuaranteeListController)
	.service('rentGuaranteeListApiService', rentGuaranteeListApiService)

	.controller('rentGuaranteeDetailsController', rentGuaranteeDetailsController)
	.service('rentGuaranteeDetailsApiService', rentGuaranteeDetailsApiService)

	.config(routesConfiguration);

module.exports = rentGuaranteeModule;
