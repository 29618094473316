'use strict';

class documentacaoPropostaApi {

	/* @ngInject */
	constructor(visualForceApi) {

		this.visualForceApi = visualForceApi;
	}

	uploadFiles(Id, fileName, attachment, attachmentType, part, isLastPart, contentType, operationId) {
		console.log('uploadFilesAPI', { Id, fileName, attachment, attachmentType, part, isLastPart });

		const request = { Id, fileName, attachment, attachmentType, part, isLastPart, contentType, operationId };

		const cap_access_token = localStorage.getItem("cap_access_token");
		request.accessToken = cap_access_token;

		return this.visualForceApi.invoke(
			'CapCommunityCaseControllerExt.attachCase', request);
	}

	mountFiles(caseId, attachmentType, attachmentId, userId, fileName, gedAttachmentType, caseType) {
		//const request = { caseId, attachmentType, attachmentId, userId, fileName };

		return this.visualForceApi.invoke('CapCommunityCaseControllerExt.mountAttachmentFile', caseId, attachmentType, fileName, userId, attachmentId, gedAttachmentType, caseType);
	}

	deleteAttachmentFile(attachmentId) {

		const cap_access_token = localStorage.getItem("cap_access_token");

		return this.visualForceApi.invoke('CapCommunityCaseControllerExt.deleteAttachment', attachmentId, cap_access_token);
	}

	cancelInstance(Id) {

		const cap_access_token = localStorage.getItem("cap_access_token");

		return this.visualForceApi.invoke(
			'CapCommunityCaseControllerExt.cancelResgate', Id, 'liberacao', cap_access_token);
	}

	getDetails(caseId) {

		const cap_access_token = localStorage.getItem("cap_access_token");

		return this.visualForceApi.invoke(
			'CapCommunityCaseControllerExt.getCaseDocumentDetails', caseId, cap_access_token);
	}

	sendCaseDocumento(request) {

		const cap_access_token = localStorage.getItem("cap_access_token");
		request.accessToken = cap_access_token;

		return this.visualForceApi.invoke(
			'CapCommunityCaseControllerExt.sendCaseDocument', request);
	}

	updateCaseDocumento(request) {

		const cap_access_token = localStorage.getItem("cap_access_token");
		request.accessToken = cap_access_token;

		return this.visualForceApi.invoke(
			'CapCommunityCaseControllerExt.updateCaseDocument', request);
	}

	//@todo gambiarra. refatorar pra deixar dinâmico em vez de marretar os anexos
	//Esse método serve pra remover os anexos que deram erro na hora de salvar o editCaseData
	removeFilesWithError(editCaseDataFiles) {
		const files = this.getAnnexes(editCaseDataFiles);

		for (let i = files.length - 1; i >= 0; i--) {
			for (let j = files[i].length - 1; j >= 0; j--) {
				if (!files[i][j].result) {
					files[i].splice(j, 1);
				}
			}
		}
	}

	getAnnexes(editCaseDataFiles) {
		const annexes = [
			editCaseDataFiles.form.files,
			editCaseDataFiles.documentacaoProposta.proposta.files,
			editCaseDataFiles.documentacaoProposta.condicoesgerais.files,
			editCaseDataFiles.documentacaoProposta.cartacaucao.files,
			editCaseDataFiles.documentacaoProposta.anexopj.files
		];
		return annexes;
	}

	updateEditCaseData(paEditCaseData) {
		const editCaseData = JSON.parse(JSON.stringify(paEditCaseData)); //@todo gambiarra. Angular.copy() não funciona bem com a classe File
		this.removeFilesWithError(editCaseData.file);
		const request = {
			caseId: this.resgateFormService.formField.id,
			editCaseData: JSON.stringify(editCaseData)
		}

		console.log('Request updateEditCaseData', request);

		const cap_access_token = localStorage.getItem("cap_access_token");
		request.accessToken = cap_access_token;

		return this.visualForceApi.invoke(
			'CapCommunityCaseControllerExt.updateEditCaseData', request);

	}
}

module.exports = documentacaoPropostaApi;
