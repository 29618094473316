'use strict';

class remuneracoesPagasDetailController {

	// @ngInject
	constructor($scope, $window, $stateParams, $state, remuneracoesPagasDetailApi, feedbackMessages, breadcrumbHelper) {
		this.$stateParams = $stateParams;
		this.$state = $state;
		this.$scope = $scope;
		this.$window = $window;
		this.messages = feedbackMessages;
		this.apiService = remuneracoesPagasDetailApi;
		this.$scope.breadcrumb = breadcrumbHelper;

		this.comissaoId = this.$stateParams.id;
		this.data = null;

		this.getDetails();
		this.configureBreadCrumb();
		console.log('Entrei na controler de detalhes');
	}

	configureBreadCrumb() {
		this.$scope.breadcrumb.reset();
		this.$scope.breadcrumb.addMenuItem('Home', '/caponline/secured#!/home', true, false);
		this.$scope.breadcrumb.addMenuItem('Remunerações Pagas', '/caponline/secured#!/remuneracoes-pagas/lista', true, false);
		this.$scope.breadcrumb.addMenuItem('Detalhes de Remunerações Pagas ', '#', false, true);
	}

	hasId() {

		return !!this.comissaoId;
	}

	getDetails() {
		if (!this.hasId()) {

			this.$state.go('remuneracoes-pagas.list');
		}

		this.$scope.$emit('changeMainShowLoading', true);

		this.apiService.getDetails(this.$stateParams.id).then((response) => {
			console.log('getDetails', response);

			this.$scope.$emit('changeMainShowLoading', false);

			if (!response.isSuccessful) {

				this.$window.scrollTo(0, 0);

				const errorMessage = angular.toJson(response.errorMessages);

				this.messages.showErrorMessage(this.$scope, errorMessage);
			} else {

				this.data = response.details;
			}
		});
	}
}

module.exports = remuneracoesPagasDetailController;
