'use strict';

class bloqReaplicacaoFormService {

	// @ngInject
	constructor() {

		this.setPristine();
	}

	setPristine() {
		this.formField = {
			id: null,
			status: null,
			decisionType: null,
			liberarCaucao: false,
			tabResgateInfo: '',
			tabResgateAttach: '',
			information: {
				active: true,
				mainAccount: {
					value: null,
					hasError: false,
					loading: false
				},
				proposta: {
					value: null,
					hasError: false,
					loading: false
				},
				garantias: {
					value: null,
					hasError: false
				},
				motivobloqueio: {
					value: null,
					hasError: false
				},
				autorizacaobloqueio: {
					value: null,
					hasError: false
				}
			},
			garantiaData: {
				subscritor: {
					nome: { value: null },
					cpf: { value: null }
				},
				endereco: {
					cep: { value: null },
					logradouro: { value: null },
					numero: { value: null },
					complemento: { value: null },
					bairro: { value: null },
					cidade: { value: null },
					uf: { value: null }
				},
				locador: {
					nome: { value: null },
					cpfcnpj: { value: null }
				},
				imobiliaria: {
					nome: { value: null },
					cpfcnpj: { value: null }
				},
				geral: {
					dataInicioVigencia: { value: null },
					dataFimVigencia: { value: null }
				}
			},
			file: {
				active: false,
				form: this.pristineAnnexObj(),
				legalRepresentative: {
					show: false,
					document: this.pristineAnnexObj(),
					cnpj: this.pristineAnnexObj(),
					constituitionDocumentation: this.pristineAnnexObj(),
					identityAdmin: this.pristineAnnexObj(),
					identityRenter: this.pristineAnnexObj(),
					other: this.pristineAnnexObj()
				},
				subscritor: {
					show: false,
					identity: this.pristineAnnexObj(),
					identityAdmin: this.pristineAnnexObj(),
					cnpj: this.pristineAnnexObj(),
					constituitionDocumentation: this.pristineAnnexObj(),
					cpf: this.pristineAnnexObj(),
					proofAddress: this.pristineAnnexObj(),
					other: this.pristineAnnexObj()
				},
				beneficiario: {
					show: false,
					identity: this.pristineAnnexObj(),
					identityAdmin: this.pristineAnnexObj(),
					cpf: this.pristineAnnexObj(),
					proofAddress: this.pristineAnnexObj(),
					cnpj: this.pristineAnnexObj(),
					constituitionDocumentation: this.pristineAnnexObj(),
					other: this.pristineAnnexObj()
				}
			},
			lastFormAction: ''
		}

		this.resetFormFileTypes();
	}

	resetFormFileTypes() {

		this.formField.file.form.type = 'form';

		this.formField.file.legalRepresentative.document.type = 'legal-document';
		this.formField.file.legalRepresentative.identityRenter.type = 'legal-identity-renter';
		this.formField.file.legalRepresentative.other.type = 'legal-other';

		this.formField.file.subscritor.identity.type = 'subscritor-identity';
		this.formField.file.subscritor.identityAdmin.type = 'subscritor-identity-admin';
		this.formField.file.subscritor.cnpj.type = 'subscritor-cnpj';
		this.formField.file.subscritor.constituitionDocumentation.type = 'subscritor-constituition';
		this.formField.file.subscritor.cpf.type = 'subscritor-cpf';
		this.formField.file.subscritor.proofAddress.type = 'subscritor-proof';
		this.formField.file.subscritor.other.type = 'subscritor-other';

		this.formField.file.beneficiario.identity.type = 'beneficiario-identity';
		this.formField.file.beneficiario.identityAdmin.type = 'beneficiario-identity-admin';
		this.formField.file.beneficiario.cpf.type = 'beneficiario-cpf';
		this.formField.file.beneficiario.proofAddress.type = 'beneficiario-proof';
		this.formField.file.beneficiario.cnpj.type = 'beneficiario-cnpj';
		this.formField.file.beneficiario.constituitionDocumentation.type = 'beneficiario-constituition';
		this.formField.file.beneficiario.other.type = 'beneficiario-other';
	}

	pristineAnnexObj() {
		return {
			files: [],
			hasError: false,
			loading: false,
			show: false,
			uploaded: false,
			uploadHasFailure: false
		};
	}

	setFormField(json) {
		this.formField = json;
	}

	getFormField() {
		return this.formField;
	}

	resetFormFile() {
		this.setTabResgateAttach("");
		this.formField.file = {
			active: false,
			form: this.pristineAnnexObj(),
			legalRepresentative: {
				show: false,
				document: this.pristineAnnexObj(),
				cnpj: this.pristineAnnexObj(),
				constituitionDocumentation: this.pristineAnnexObj(),
				identityAdmin: this.pristineAnnexObj(),
				identityRenter: this.pristineAnnexObj(),
				other: this.pristineAnnexObj()
			},
			subscritor: {
				show: false,
				identity: this.pristineAnnexObj(),
				identityAdmin: this.pristineAnnexObj(),
				cnpj: this.pristineAnnexObj(),
				constituitionDocumentation: this.pristineAnnexObj(),
				cpf: this.pristineAnnexObj(),
				proofAddress: this.pristineAnnexObj(),
				other: this.pristineAnnexObj()
			},
			beneficiario: {
				show: false,
				identity: this.pristineAnnexObj(),
				identityAdmin: this.pristineAnnexObj(),
				cpf: this.pristineAnnexObj(),
				proofAddress: this.pristineAnnexObj(),
				cnpj: this.pristineAnnexObj(),
				constituitionDocumentation: this.pristineAnnexObj(),
				other: this.pristineAnnexObj()
			}
		}

		this.resetFormFileTypes();
	}

	setTabResgateInfo(tab) {
		this.formField.tabResgateInfo = tab;
	}

	setTabResgateAttach(tab) {
		this.formField.tabResgateAttach = tab;
	}

}

module.exports = bloqReaplicacaoFormService;
