'use strict';

class alteracaoImovelController {
	// @ngInject
	constructor($scope, $window, feedbackMessages, alteracaoDadosFormControlService, alteracaoDadosClienteService) {
		this.$scope = $scope;
		this.$window = $window
		this.feedbackMessages = feedbackMessages;
		this.formField = alteracaoDadosFormControlService;
		this.api = alteracaoDadosClienteService;

		this.datepickerOptions = {

			minDate: new Date(1700, 1, 3)
		};

		this.formField.data.status = 'Em Andamento';
		this.getClienteData(this.formField.information.fields.garantias.value[0].objectId);
		console.log('Id Titulo ====>', this.formField.information.fields.garantias.value[0].objectId);
		this.isClientePf(); //@todo a informação tem que vir do back
	}

	//@todo a informação tem que vir do back
	isClientePf() {
		this.formField.data.alteracaoDadosCliente.isPersonAccount = (this.formField.information.fields.mainAccount.value.cpf.length < 15);
	}

	getClienteData(id) {
		this.api.getClienteData(id).then((response) => {
				this.formField.data.alteracaoDadosCliente.currentData = response.data;
			})
			.catch((response) => {
				console.log('erro ao trazer dados do título');
			});
		//this.formField.data.alteracaoDadosCliente.currentData = this.api.getClienteData(id).data;

	}

	requireProperty(field) {
		if (this.formField.data.alteracaoDadosCliente.fields.pf[field]) {
			this.formField.requireProperty(this.formField.data.alteracaoDadosCliente.fields.pf, field);
		} else if (this.formField.data.alteracaoDadosCliente.fields.pj[field]) {
			this.formField.requireProperty(this.formField.data.alteracaoDadosCliente.fields.pj, field);
		}
	}

	validateField(field) {
		if (this.formField.data.alteracaoDadosCliente.fields.pf[field]) {
			this.formField.validateField(this.formField.data.alteracaoDadosCliente.fields.pf, field);
		} else if (this.formField.data.alteracaoDadosCliente.fields.pj[field]) {
			this.formField.validateField(this.formField.data.alteracaoDadosCliente.fields.pj, field);
		}
	}

	nullOrEmpty(field) {
		if (this.formField.data.alteracaoDadosCliente.fields.pf[field]) {
			return this.formField.nullOrEmpty(this.formField.data.alteracaoDadosCliente.fields.pf, field);
		} else if (this.formField.data.alteracaoDadosCliente.fields.pj[field]) {
			return this.formField.nullOrEmpty(this.formField.data.alteracaoDadosCliente.fields.pj, field);
		} else {
			console.log('erro no null or empty');
		}
	}
	//@todo ver se pode melhorar. copiado da account

	closeDatepickerOnTabPress(keyEvent) {
		if (keyEvent.which === 9) {
			$('.uib-datepicker-popup').remove();
		}
	}

	focusBirthDate() {
		$('#birthDateId').focus();
	}

	focusExpDate() {
		$('#expeditionDateId').focus();
	}
}

module.exports = alteracaoImovelController;
