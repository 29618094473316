"use strict";

class autocompleteController {
	// @ngInject
	constructor(
		$q,
		$state,
		$scope,
		$timeout,
		$window,
		sfConfig,
		autocompleteApiService,
		autoCompleteHelper
	) {
		this.$q = $q;
		this.$state = $state;
		this.$scope = $scope;
		this.$timeout = $timeout;
		this.$window = $window;
		this.sfConfig = sfConfig;
		this.autoCompleteHelper = autoCompleteHelper;
		this.autocompleteApi = autocompleteApiService;

		// console.log('CONTROLLER DIRECTIVE', this);
		this.setSearchInput();
	}

	setSearchInput() {
		this.searchInput = {};
	}

	search(searchString) {
		console.log("Search:", searchString);

		const autocomplete = this.$parent.autocomplete;
		const deferred = autocomplete.$q.defer();

		autocomplete.autocompleteApi.search(searchString).then((response) => {
			console.log("search response", response);

			if (!response.isSuccessful) {
				const errorMessage = angular.toJson(response.errorMessages);
				console.log(errorMessage);
				//this.messages.showErrorMessage(this.$scope, errorMessage);
			} else {

				if (response.lstRecords[0].recordType == 'D') {
					sessionStorage.setItem("tipo_lista", 'D');
				} else {
					sessionStorage.setItem("tipo_lista", 'C');
				}

				//this.messages.cleanMessages(this.$scope);
				deferred.resolve(response.lstRecords);
				console.log(response.lstRecords);
			}
		});

		return deferred.promise;
	}

	searchFocusOut() {
		console.log("searchFocusOut");

		// // @todo workaround https://github.com/ghiden/angucomplete-alt/issues/106
		// this.$timeout(() => {

		// 	if (!this.autoCompleteHelper.hasValidInput(this.searchInput)) {

		// 		this.autoCompleteHelper.clearInput(this.$scope, 'searchMain');
		// 	}
		// }, 100);
	}

	changeTab(tabName) {
		this.$scope.$emit("mainChangeTab", tabName);
	}

	directToCaseRoute(object) {
		const subType = object.subType.toLowerCase();

		console.log("subType=", subType);

		if (subType === "resgate" || subType === "cancelamento") {
			this.$state.go("case.detail", { id: object.id });
		} else if (
			subType === "liberação da caução" ||
			subType === "manutenção de títulos"
		) {
			this.$state.go("case.liberacao-da-caucao.detail", { id: object.id });
		} else if (
			subType === "documentação da emissão" ||
			subType === "emissão de títulos"
		) {
			this.$state.go("case.documentacao-proposta.detail", { id: object.id });
		} else if (subType === "bloqueio de reaplicação") {
			this.$state.go("case.bloq-reaplicacao.detail", { id: object.id });
		} else if (subType === "reaplicação de título") {
			this.$state.go("case.reaplicacao-titulo.detail", { id: object.id });
		} else if (subType === "revisão de comissão") {
			this.$state.go("case.revisao-comissao.detail", { id: object.id });
		} else if (
			subType === "mudança de imóvel / locador" ||
			subType === "inclusão da caução" ||
			subType === "mudança de locador" ||
			subType === "alteração de dados do cliente" ||
			subType === "alteração cadastral" ||
			subType === "alteração de dados da garantia" ||
			subType === "inclusão da caução"
		) {
			this.$state.go("case.alteracao-dados.detail", { id: object.id });
		} else if (subType === "pagamento de sorteio") {
			this.$state.go("case.sorteio.detail", { id: object.id });
		}
	}

	searchSelected(selectedItem) {
		console.log("searchSelected() -> selectedItem: ", selectedItem);

		if (selectedItem) {
			const autocomplete = this.$parent.autocomplete;

			this.searchInput = selectedItem.originalObject;

			console.log(this.searchInput);

			if (!this.searchInput.type) {
				console.log("if: ", selectedItem);
			} else {
				switch (this.searchInput.type) {
				case "Account":
					autocomplete.changeTab("account");
					autocomplete.$state.go("account-detail", {
						id: this.searchInput.id
					});
					break;

				case "Proposta":
					autocomplete.changeTab("proposta");
					autocomplete.$state.go("proposta-detail", {
						id: this.searchInput.id
					});
					break;

				case "Document":
					autocomplete.changeTab("document");
					autocomplete.$window.open(
						`servlet/servlet.FileDownload?file=${this.searchInput.id}`,
						"_blank"
					);
					break;

				case "case":
					autocomplete.changeTab("case");
					autocomplete.directToCaseRoute(this.searchInput);
					break;

				case "garantia-Aluguel":
					autocomplete.changeTab("garantia-aluguel");
					autocomplete.$state.go("garantia-aluguel.details", {
						id: this.searchInput.id
					});
					break;
				}

				console.log("else: ", selectedItem);
			}
		}
	}

	searchChanged() {
		console.log("searchChanged", this);

		const autocomplete = this.$parent.autocomplete;

		autocomplete.autoCompleteHelper.resetSelected(autocomplete, "searchInput");
	}
}

module.exports = autocompleteController;
