"use strict";

import VisualForceApi from "../../core/sfdc/visual-force-api.js";

class propostaInformationApi extends VisualForceApi {
	/* @ngInject */
	constructor($q) {
		super();
		this.$q = $q;
	}

	getProductsList(proposalType) {
		const cap_access_token = localStorage.getItem("cap_access_token");
		return super.invoke('CapCommunityPropostaControllerExt.solProdutos', proposalType, cap_access_token);
	}

	getProductInfo(productName) {

		console.log('Produto chamado no serviço: ', productName);

		const cap_access_token = localStorage.getItem("cap_access_token");
		return super.invoke('CapCommunityPropostaControllerExt.getProductInfo', productName, cap_access_token);

	}

	getLifeTermValue(cpf, personBirthDate, insuranceTerm, rentValue, productCode) {
		const cap_access_token = localStorage.getItem("cap_access_token");
		return super.invoke('CapCommunityPropostaControllerExt.getLifeTermValue', cpf, personBirthDate, insuranceTerm, rentValue, productCode, cap_access_token);
	}

	getBrokerAccountAPI(cpfCnpj) {
		const cap_access_token = localStorage.getItem("cap_access_token");
		return super.invoke('CapCommunityPropostaControllerExt.getBrokerAccountAPI', cpfCnpj, cap_access_token);
	}

	getProductsListDG(corretoraId) {
		const cap_access_token = localStorage.getItem("cap_access_token");
		return super.invoke('CapCommunityPropostaControllerExt.solProdutosDG', corretoraId, cap_access_token);
	}

	getCorretorasList() {
		const cap_access_token = localStorage.getItem("cap_access_token");

		return super.invoke(
			"CapCommunityPropostaControllerExt.solCorretoras",
			"notUsed",
			cap_access_token
		);
	}

	getSalesStructureList() {
		const cap_access_token = localStorage.getItem("cap_access_token");

		return super.invoke(
			"CapCommunityPropostaControllerExt.solEstruturas",
			"teste",
			cap_access_token
		);
	}

	isValidMoneyValue(value) {
		if (value && value.length > 3) {
			value = value.split("R$ ").pop();
			return !isNaN(value);
		}
		return false;
	}

	isBetweenMaxMin(value, max, min) {
		if (value && value.length > 3) {
			value = value.split("R$ ").pop();

			if (isNaN(value)) {
				return false;
			}

			if (value >= min && value <= max) {
				return true;
			}
		}
		return false;
	}

	searchAccount(searchKeyWord, idCorretora) {
		const deferred = this.$q.defer();

		const searchModel = {
			dataFetch: {
				searchKeyWord,
				searchTypeObject: "Account",
				requestType: "SearchPDV",
				idCorretoraSelecionada: idCorretora
			}
		};

		const cap_access_token = localStorage.getItem("cap_access_token");
		searchModel.accessToken = cap_access_token;

		super
			.invoke("CapCommunityPropostaControllerExt.solFetchRecords", searchModel)
			.then((data) => {
				//console.log(data.obtainedRecords);
				if (!data.obtainedRecords) data.obtainedRecords = [];
				if (data.obtainedRecords.length === 0) {
					data.obtainedRecords.push({
						action: "searchAccountFocusOut",
						Name: "Nenhum cadastro encontrado"
					});
				}
				data.obtainedRecords.push({
					action: "modalHandle",
					Name: "+ Criar Cadastro"
				});
				deferred.resolve(data.obtainedRecords);
			});

		return deferred.promise;
	}
}

module.exports = propostaInformationApi;
