'use strict';

class checkTerm {

	/* @ngInject */
	constructor($q, $rootScope, $window, visualForceApi, feedbackMessages) {

		this.$q = $q;
		this.$rootScope = $rootScope;
		this.$window = $window;

		this.messages = feedbackMessages;
		this.visualForceApi = visualForceApi;
	}

	get adhesion() {

		return _termAccepted.adhesion;
	}

	reset(type) {

		_termAccepted[type] = null;
	}

	getStatus(type) {
		// console.log('getStatus', type);

		const deferred = this.$q.defer();

		this.$rootScope.$broadcast('changeMainShowLoading', true);

		this.visualForceApi.invoke(
				'CapCommunityUserControllerExt.getUserAcceptedTerms')
			.then((response) => {
				// console.log('response', response);

				this.$rootScope.$broadcast('changeMainShowLoading', false);

				if (!response.isSuccessful) {

					this.$window.scrollTo(0, 0);

					const errorMessage = angular.toJson(response.errorMessages);

					this.messages.showErrorMessage(false, errorMessage);
				} else {

					_termAccepted[type] = response[`${type}Accepted`];

					deferred.resolve();
				}
			});

		return deferred.promise;
	}
}

module.exports = checkTerm;

const _termAccepted = {

	adhesion: null
};
