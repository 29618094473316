'use strict';

/* @ngInject */
function routesConfiguration($stateProvider, sfConfig) {

	$stateProvider.state('case.sorteio', {

		abstract: true,
		url: '/sorteio',
		views: {
			'case': {
				templateUrl: `${sfConfig.resource}/views/case/sorteio/base.html`,
				controller: 'sorteioController',
				controllerAs: 'sorteio'
			}
		}
	});

	$stateProvider.state('case.sorteio.detail', {
		url: '/detalhes?:id',
		views: {
			'case.sorteio': {
				templateUrl: `${sfConfig.resource}/views/case/sorteio/sorteio-detail/sorteio-detail.html`,
				controller: 'sorteioDetailController',
				controllerAs: 'sorteioDetail'
			}
		}
	});

	$stateProvider.state('case.sorteio.new', {
		url: '/nova',
		views: {
			'case.sorteio': {
				templateUrl: `${sfConfig.resource}/views/case/sorteio/sorteio-new/sorteio-new.html`,
				controller: 'sorteioNewController',
				controllerAs: 'sorteioNew'
			}
		}
	});

	$stateProvider.state('case.sorteio.bank', {
		url: '/dados/sorteio-bank',
		views: {
			'case.sorteio': {
				templateUrl: `${sfConfig.resource}/views/case/sorteio/sorteio-bank/sorteio-bank.html`,
				controller: 'sorteioBankController',
				controllerAs: 'sorteioBank'
			}
		}
	});

	$stateProvider.state('case.sorteio.annex', {
		url: '/anexo',
		views: {
			'case.sorteio': {
				templateUrl: `${sfConfig.resource}/views/case/sorteio/sorteio-upload/upload.html`,
				controller: 'sorteioAnnexController',
				controllerAs: 'uploadController'
			}
		}
	});

};

module.exports = routesConfiguration;
