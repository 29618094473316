'use strict';

/* @ngInject */
function routesConfiguration($stateProvider, sfConfig) {

	$stateProvider.state('case.liberacao-da-caucao', {

		abstract: true,
		url: '/liberacao-da-caucao',
		views: {

			'case': {

				templateUrl: `${sfConfig.resource}/views/case/case-liberacao-da-caucao/base.html`,
				controller: 'liberacaoDaCaucaoController',
				controllerAs: 'caseInstance'
			}
		}
	});

	$stateProvider.state('case.liberacao-da-caucao.new', {

		url: '/nova',
		views: {
			'case.liberacao-da-caucao': {
				templateUrl: `${sfConfig.resource}/views/case/case-liberacao-da-caucao/new/new.html`,
				controller: 'liberacaoDaCaucaoNewController',
				controllerAs: 'caseNew'
			}
		},
		params: {
			formField: null
		}
	});

	$stateProvider.state('case.liberacao-da-caucao.annex', {

		url: '/anexos',
		views: {

			'case.liberacao-da-caucao': {

				templateUrl: `${sfConfig.resource}/views/case/case-liberacao-da-caucao/annex/annex.html`,
				controller: 'liberacaoDaCaucaoAnnexController',
				controllerAs: 'caseAnnex'
			}
		}
	});

	$stateProvider.state('case.liberacao-da-caucao.detail', {

		url: '/detalhes?:id',
		views: {

			'case.liberacao-da-caucao': {

				templateUrl: `${sfConfig.resource}/views/case/case-liberacao-da-caucao/detail/detail.html`,
				controller: 'liberacaoDaCaucaoDetailController',
				controllerAs: 'caseDetail'
			}
		}
	});
};

module.exports = routesConfiguration;
