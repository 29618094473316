'use strict';

const termoDeAdesaoController = require('./adesao/adhesion.controller');
const termoDeAdesaoApiService = require('./adesao/api.service');

const routesConfiguration = require('./component.routes');

const componentModule = angular.module('cap.secured.termo', [
		'ui.router'
	])
	.controller('termoDeAdesaoController', termoDeAdesaoController)
	.service('termoDeAdesaoApi', termoDeAdesaoApiService)

	.config(routesConfiguration);

module.exports = componentModule;
