'use strict';

class documentacaoPropostaNewController {

	// @ngInject
	constructor($scope, $window, $state, $stateParams, resgateService, documentacaoPropostaFormControlService, documentacaoPropostaNewApi, breadcrumbHelper) {
		// console.log('documentacaoPropostaNewController');

		this.$window = $window;
		this.$scope = $scope;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.$scope.breadcrumb = breadcrumbHelper;

		this.formField = documentacaoPropostaFormControlService;
		if ((!this.formField.id) && (this.hasValidUrlParams())) {
			this.$scope.$parent.caseInstance.getCaseDetails();
		}

		this.documentacaoPropostaNewApi = documentacaoPropostaNewApi;
		this.formField.formStep = 'case.documentacao-proposta.new';
		this.formField.status = this.formField.status || 'Enviado';
		this.formField.information.active = true;
		this.resgateService = resgateService;
		this.resgateService.setPristine();
		this.posicionaBotoes();

		this.configureBreadCrumb();

	}

	hasValidUrlParams() {
		return (this.$stateParams && this.$stateParams.id);
	}

	posicionaBotoes() {
		this.formField.information.file.active = true;
		this.resgateService.setPreviousShow(false);
		this.resgateService.setPreviousDisabled(true);
		this.resgateService.setNextShow(true);
		this.resgateService.setNextDisabled(false);
		this.resgateService.setFinishDisabled(true);
		this.resgateService.setFinishShow(false);
	}

	configureBreadCrumb() {
		this.$scope.breadcrumb.reset();
		this.$scope.breadcrumb.addMenuItem('Home', '/caponline/secured#!/home', true, false);
		this.$scope.breadcrumb.addMenuItem('Solicitação', '/caponline/secured#!/solicitacoes/lista', true, false);
		this.$scope.breadcrumb.addMenuItem('Nova Documentação de Emissão', '#', false, true);
	}

}

module.exports = documentacaoPropostaNewController;
