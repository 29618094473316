'use strict'

class sorteioController {
	// @ngInject
	constructor($scope, visualForceApi, $window, $state, $timeout, sorteioFormControlService, sorteioApi, dadosBancariosHelper, annexHelper, breadcrumbHelper, feedbackMessages, utilsHelper) {
		console.log('entrou no sorteioController construtor()')
		this.$scope = $scope;
		this.$window = $window;
		this.$state = $state;
		this.$timeout = $timeout;
		this.visualForceApi = visualForceApi;

		this.api = sorteioApi;
		this.formField = sorteioFormControlService;
		this.dadosBancariosHelper = dadosBancariosHelper;
		this.annexHelper = annexHelper;
		this.$scope.breadcrumb = breadcrumbHelper;
		this.feedbackMessages = feedbackMessages;
		this.utilsHelper = utilsHelper;

		this.formField.information.active = true;

		this.dataCheckNewFields();
		this.listenSaveSorteio();
		this.listenUpdateEditCaseData();
		console.log('fim construtor da controller principal')
	}

	dataCheckNewFields() {
		console.log('entrou no dataCheckNewFields()');

		// Novas chaves no Json são criadas no FormField do legado
		// Para não acontecer erros de undefined na estrutura
		let dataTemp = null; // para trabalhar com dados temporarios
		//--- 2018-07-11
		// .formField.bank.fields.
		if (!this.formField.bank.fields.agenciaDV) this.formField.bank.fields.agenciaDV = this.sorteioFormControlService.pristineFormObj();
		if (!this.formField.bank.fields.contaDV) this.formField.bank.fields.contaDV = this.sorteioFormControlService.pristineFormObj();
		// CAP-3696 Quebrar no layout e adaptar no modelo para termos campos de DV para agência e conta
		if ((this.formField.bank.fields.agencia.value) && (this.formField.bank.fields.agencia.value.includes("-"))) {
			dataTemp = this.formField.bank.fields.agencia.value.split("-", 2);
			this.formField.bank.fields.agencia.value = dataTemp[0];
			this.formField.bank.fields.agenciaDV.value = dataTemp[1];
		}
		if ((this.formField.bank.fields.conta.value) && (this.formField.bank.fields.conta.value.includes("-"))) {
			dataTemp = this.formField.bank.fields.conta.value.split("-", 2);
			this.formField.bank.fields.conta.value = dataTemp[0];
			this.formField.bank.fields.contaDV.value = dataTemp[1];
		}
	}

	isFormValid(page, setFieldErrors) {
		console.log('entrou no isFormValid(page, setFieldErrors)', page, setFieldErrors);
		return this.formField.hasRequiredFields(page, setFieldErrors) && this.formField.isValidFields(page);
	}

	hasRequiredFields() {
		console.log('entrou no hasRequiredFields()');

		if (this.formField.information.active === true) {
			return this.formField.hasRequiredFields('information', false);
		} else if (this.formField.bank.active === true) {
			return this.formField.hasRequiredFields('bank', false);
		}
	}

	doNext() {
		console.log('entrou no doNext()');

		if (this.formField.information.active === true) {
			if (this.isFormValid('information', true)) {
				this.formField.activateTab('bank');
				this.formField.setStepButtons('bank');
				this.$window.scrollTo(0, 0);
				this.$state.go('case.sorteio.bank');
			}
		} else if (this.formField.bank.active === true) {

			if (this.isFormValid('bank', true)) {
				this.validaContaBancaria();
			}
		}
	}

	doPrevious() {
		console.log('entrou no doPrevious()');

		if (this.formField.bank.active === true) {
			this.formField.activateTab('information');
			this.formField.setStepButtons('information');
			this.$window.scrollTo(0, 0);
			this.$state.go('case.sorteio.new');
		} else if (this.formField.annex.active === true) {
			this.formField.activateTab('bank');
			this.formField.setStepButtons('bank');
			this.$window.scrollTo(0, 0);
			this.$state.go('case.sorteio.bank');
		}
	}

	goTo(state) {
		console.log('entrou no goTo()');

		// Se estou na tela BANK, e tento ir para aba de anexos, estando com o form preenchido (valido), valido a conta!
		if ((this.formField.bank.active) && (state === "annex") && this.isFormValid('bank', false)) {
			this.validaContaBancaria();
		} else if (state == 'information') {
			if (this.formField.information.active === false) {
				this.formField.activateTab('information');
				this.formField.setStepButtons('information');
				this.$window.scrollTo(0, 0);
				this.$state.go('case.sorteio.new');
			}
		} else if (state == 'bank') {
			if (this.formField.bank.active === false && this.navStepStatus('case.sorteio.bank') != '') {
				if (this.isFormValid('information', true)) {
					this.formField.activateTab('bank');
					this.formField.setStepButtons('bank');
					this.$window.scrollTo(0, 0);
					this.$state.go('case.sorteio.bank');
				}
			}
		} else if (state == 'annex') {
			if (this.formField.annex.active === false && this.navStepStatus('case.sorteio.annex') != '') {
				if (this.isFormValid('information', true) && this.isFormValid('bank', true)) {
					this.formField.activateTab('annex');
					this.formField.setStepButtons('annex');
					this.$window.scrollTo(0, 0);
					this.$state.go('case.sorteio.annex');
				}
			}
		}
	}

	validaContaBancaria() {
		console.log('entrou no validaContaBancaria()');

		this.isContaBancariaValida("dados-sorteio",
			this.formField.bank.fields.tipoConta.value,
			this.formField.bank.fields.cpfCnpj.value,
			this.formField.bank.fields.banco.value,
			this.formField.bank.fields.agencia.value,
			this.formField.bank.fields.agenciaDV.value,
			this.formField.bank.fields.conta.value,
			this.formField.bank.fields.contaDV.value);
	}

	isContaBancariaValida(secao, tipoConta, cpfCnpj, banco, agencia, agenciaDV, conta, contaDV) {
		console.log('entrou no isContaBancariaValida()');

		const me = this;
		me.$scope.$emit('changeMainShowLoading', true);
		me.dadosBancariosHelper.consultaDadosBancariosSeValidos(tipoConta, cpfCnpj, banco, agencia, agenciaDV, conta, contaDV, (response) => {
			me.$scope.$broadcast('applyErrorBankFields', response, secao);
			if ((response.isSuccessful) && (response.isValid)) {
				setTimeout(() => {

					// No caso de sucesso avança para a próxima aba
					me.formField.activateTab('annex');
					me.formField.setStepButtons('annex')
					me.$window.scrollTo(0, 0);
					me.$state.go('case.sorteio.annex');

					me.$scope.$emit('changeMainShowLoading', false);
				}, 500);
			} else {
				me.$scope.$emit('changeMainShowLoading', false);
			}
		});
	}

	navStepCompleteness(step) {
		console.log('entrou no navStepCompleteness(step):', step);

		const warningClass = 'fa-exclamation-triangle warning';
		const successClass = 'fa-check-circle success';
		let cssClass = '';

		const form = step

		const isStepValid = this.isFormValid(form, false);

		if (this.formField[step].status !== 'Não Iniciado') {
			if (!isStepValid) {
				cssClass = warningClass;
			} else {
				cssClass = successClass;
			}
		}

		return cssClass;
	}

	isDetailPage() {
		console.log('entrou no isDetailPage()');
		return this.$state.current.name === 'case.sorteio.detail';
	}

	cancelCurrentSolicitacao() {
		console.log('entrou no cancelCurrentSolicitacao()');

		if (this.formField.id !== null) {
			this.$scope.$emit('changeMainShowLoading', true);
			this.api.cancelCase(this.formField.id).then((response) => {
				this.$scope.$emit('changeMainShowLoading', false);
				if (response.isSuccessful === false) {
					this.api.setErrorMessage(response.errorMessages[0]);
				} else {
					this.$state.go('case.list');
				}
			});
		} else {
			this.$state.go('case.list');
		}
	}

	navStepStatus(step) {
		console.log('entrou no navStepStatus()');

		if (step == 'information') {
			if (this.formField.information.active === true) {
				return 'act';
			} else if (this.formField.information.fields.mainAccount.value !== null) {
				return 'edt';
			} else {
				return '';
			}
		} else if (step == 'bank') {
			if (this.formField.bank.active === true) {
				return 'act';
			} else if (this.formField.bank.status == 'Em Andamento') {
				return 'edt';
			} else {
				return '';
			}
		} else if (step == 'annex') {
			if (this.formField.annex.active === true) {
				return 'act'
			} else if (this.formField.annex.status == 'Em Andamento') {
				return 'edt';
			} else {
				return ''
			}
		}
	}

	//@todo gambiarra. refatorar pra deixar dinâmico em vez de marretar os anexos
	//Esse método serve pra remover os anexos que deram erro na hora de salvar o editCaseData
	removeFilesWithError(editCaseDataFiles) {
		console.log('entrou no removeFilesWithError()');

		const files = this.getCurrentAnnexesToValidate(editCaseDataFiles);

		for (let i = files.length - 1; i >= 0; i--) {
			for (let j = files[i].length - 1; j >= 0; j--) {
				if (!files[i][j].result) {
					files[i].splice(j, 1);
				}
			}
		}
	}

	updateEditCaseData() {
		console.log('entrou no updateEditCaseData()');

		const editCaseData = JSON.parse(JSON.stringify(this.formField.extractEditCaseData())); //@todo gambiarra. Angular.copy() não funciona bem com a classe File
		this.removeFilesWithError(editCaseData.annex);
		const request = {
			caseId: this.formField.id,
			editCaseData: JSON.stringify(editCaseData)
		}

		console.log('Request updateEditCaseData', request);

		return this.visualForceApi.invoke(
			'CapCommunityCaseControllerExt.updateEditCaseData', request);

	}

	listenUpdateEditCaseData() {
		console.log('entrou no listenUpdateEditCaseData()');

		this.$scope.$on('updateEditCaseData', (event) => {
			event.stopPropagation();

			this.updateEditCaseData();
		})
	}

	listenSaveSorteio() {
		console.log('entrou no listenSaveSorteio()');

		this.$scope.$on('saveSorteio', (event) => {
			// console.log('listened saveCaseInstance');
			event.stopPropagation();
			this.saveCaseSorteio().then((response) => {
				if (response != 'error') {
					this.$scope.$broadcast('saveSorteioDone');
				}
			});
		});
	}

	saveCaseSorteio() {
		console.log('entrou no saveCaseSorteio()');

		this.feedbackMessages.showErrorMessage(this.$scope, '');
		if (this.formField.information.fields.mainAccount.value === null) {
			return;
		}
		const form = angular.copy(this.formField);
		this.$scope.$emit('changeMainShowLoading', true);
		return this.api.saveCaseSorteio(form).then((response) => {
			this.$scope.$emit('changeMainShowLoading', false);
			if (!response.isSuccessful) {
				this.feedbackMessages.showErrorMessage(this.$scope, response.errorMessages[0]);
				return 'error';
			}
			this.formField.id = response.caseId;
			this.feedbackMessages.showSuccessMessage(this.$scope, 'Solicitação salva com sucesso.');
			this.$timeout(() => {
				this.feedbackMessages.cleanMessages(this.$scope);
			}, 5000);
		});
	}

	sendCaseSorteio() {
		console.log('entrou no sendCaseSorteio()');

		this.isValidUploads() && this.saveCaseBeforeUpload();
		console.log('Uploads Validos = ', this.isValidUploads());
	}

	saveCaseBeforeUpload() {
		console.log('entrou no saveCaseBeforeUpload()');

		this.feedbackMessages.showErrorMessage(this.$scope, '');

		if (this.formField.information.fields.mainAccount.value === null) {

			return;
		}
		const form = angular.copy(this.formField);

		this.$scope.$emit('changeMainShowLoading', true);

		this.api.saveCaseSorteio(form).then((response) => {
			this.$scope.$emit('changeMainShowLoading', false);
			if (response.isSuccessful) {
				this.formField.id = response.caseId;
				this.checkUpload();
			} else {
				this.feedbackMessages.showErrorMessage(this.$scope, response.errorMessages[0]);
			}
		});
	}

	changeFileArray(fileList) {
		for (const i in fileList) {
			fileList[i].files = fileList[i].gambiFiles;
		}
	}

	isValidUploads() {
		console.log('entrou no isValidUploads()');
		let valid = true;
		const annexes = this.getCurrentAnnexesToValidate();
		this.changeFileArray(annexes);

		for (let i = annexes.length - 1; i >= 0; i--) {

			if (annexes[i].required !== false && ((!annexes[i].files) || (annexes[i].files.length === 0))) {

				valid = false;
				break;
			}
		}
		// console.log('isValidUploads valid', valid);

		return valid;
	}

	checkUpload() {
		console.log('entrou no checkUpload()');

		if (this.isValidUploads()) {
			console.log('Todos os anexos validados (checkupload)', this.isValidUploads());
			const annexes = this.getCurrentAnnexes();
			this.changeFileArray(annexes);
			const caseId = this.formField.id;
			const gedAttachmentType = 'outros';
			const caseType = 'pagamento de sorteio';

			this.$scope.$emit('changeMainShowLoading', true);

			this.annexHelper.uploadAnnexes(annexes, caseId, gedAttachmentType, caseType)
				.then(() => {

					this.feedbackMessages.showErrorMessage(this.$scope, '');
					this.sendCompleteInstance();
				})
				.catch((err) => {
					this.updateEditCaseData();
					console.log('Erro Anexo=====', err);
					const genericErrorMessage = 'Um ou mais arquivos não foram anexados. Os arquivos precisam ter até 8MB, não podem possuir o mesmo nome e devem ter as extensões permitidas.';
					this.feedbackMessages.showErrorMessage(this.$scope, genericErrorMessage);

					this.$scope.$emit('changeMainShowLoading', false);
				});
		} else {

			this.feedbackMessages.showErrorMessage(this.$scope, 'Por favor anexe todos os documentos.');
		}
	}

	sendCompleteInstance() {
		console.log('entrou no sendCompleteInstance()');

		this.$scope.$emit('changeMainShowLoading', true);

		this.formField.status = 'Enviado';

		const form = angular.copy(this.formField);
		this.updateEditCaseData();

		this.api.sendCaseSorteio(form).then((response) => {

			this.$scope.$emit('changeMainShowLoading', false);

			if (!response.isSuccessful) {

				this.feedbackMessages.showErrorMessage(this.$scope, response.errorMessages[0]);
				return;
			}

			this.$state.go('case.sorteio.detail', { id: this.formField.id });
		});
	}

	getCurrentAnnexes() {
		console.log('entrou no getCurrentAnnexes()');

		const annexes = [
			this.formField.annex.form,
			this.formField.annex.auth,
			this.formField.annex.subscritor.identityRenter,
			this.formField.annex.subscritor.cpf,
			this.formField.annex.subscritor.adressCheck,
			this.formField.annex.subscritor.otherPf,
			this.formField.annex.subscritor.cnpj,
			this.formField.annex.subscritor.constitutionIdentity,
			this.formField.annex.subscritor.otherPj
		];

		for (let i = annexes.length - 1; i >= 0; i--) {

			if (!annexes[i].show) {

				angular.extend(annexes[i], this.formField.pristineAnnexObj());
				annexes.splice(i, 1);
			}
		}

		// console.log('getCurrentAnnexes', annexes);
		// console.log(this.formField.annex.form, this.formField.annex.legalRepresentative.document, this.formField.annex.legalRepresentative.identityRenter, this.formField.annex.legalRepresentative.other);

		return annexes;
	}

	getCurrentAnnexesToValidate() {
		console.log('entrou no getCurrentAnnexesToValidate()');

		const annexes = [

			this.formField.annex.form,
			this.formField.annex.auth,
			this.formField.annex.subscritor.identityRenter,
			this.formField.annex.subscritor.cpf,
			this.formField.annex.subscritor.adressCheck,
			this.formField.annex.subscritor.otherPf,
			this.formField.annex.subscritor.cnpj,
			this.formField.annex.subscritor.constitutionIdentity,
			this.formField.annex.subscritor.otherPj
		];

		for (let i = annexes.length - 1; i >= 0; i--) {

			if (!annexes[i].show) {

				angular.extend(annexes[i], this.formField.pristineAnnexObj());
				annexes.splice(i, 1);
			}
		}

		// console.log('getCurrentAnnexes', annexes);
		// console.log(this.formField.annex.form, this.formField.annex.legalRepresentative.document, this.formField.annex.legalRepresentative.identityRenter, this.formField.annex.legalRepresentative.other);

		return annexes;
	}
}

module.exports = sorteioController;
