"use strict";

const defaultParam = true;

class VisualForceApi {
	/* @ngInject */
	constructor($q) {
		this.$q = $q;
	}

	invoke(remoteAction, ...parameters) {
		console.log("remote action call: ", remoteAction);

		const deferred = this.$q.defer();

		// @todo Gambiarra, ao que parece Visualforce não aceita não ter parametros
		parameters = parameters.length > 0 ? parameters : [{ defaultParam }];
		parameters = angular.fromJson(angular.toJson(parameters));

		console.log("parameters: ", parameters);

		Visualforce.remoting.Manager.invokeAction(
			remoteAction,
			...parameters,
			(result, event) => {
				if (event.status) {
					deferred.resolve(result);
				} else {
					deferred.reject(event);
				}
			}, { buffer: false, escape: false, timeout: 90000 }
		);

		return deferred.promise;
	}
}

module.exports = VisualForceApi;
