'use strict';

/* @ngInject */
function routesConfiguration($stateProvider, sfConfig) {

	//Rota Principal
	$stateProvider.state('case.bloq-reaplicacao', {

		abstract: true,
		url: '/bloqueioreaplicacao',
		views: {

			'case': {

				templateUrl: `${sfConfig.resource}/views/case/bloq-reaplicacao/base.html`,
				controller: 'bloqReaplicacaoController',
				controllerAs: 'caseInstance'
			}
		}
	});

	//Rota para Criação
	$stateProvider.state('case.bloq-reaplicacao.new', {

		url: '/nova',
		views: {

			'case.bloq-reaplicacao': {

				templateUrl: `${sfConfig.resource}/views/case/bloq-reaplicacao/bloq-reaplicacao-new/bloq-reaplicacao-new.html`,
				controller: 'bloqReaplicacaoNewController',
				controllerAs: 'bloqReaplicacaoNew'
			}
		}
	});

	//Rota para Amexos
	/*$stateProvider.state('case.bloqReaplicacao.annex', {

		url: '/anexos',
		views: {

			'case.bloqReaplicacao': {

				templateUrl: `${sfConfig.resource}/views/case/bloqReaplicacao/annex/annex.html`,
				controller: 'bloqReaplicacaoAnnexController',
				controllerAs: 'bloqReaplicacaoAnnex'
			}
		}
	});*/

	$stateProvider.state('case.bloq-reaplicacao.detail', {

		url: '/detalhes?:id',
		views: {

			'case.bloq-reaplicacao': {

				templateUrl: `${sfConfig.resource}/views/case/bloq-reaplicacao/bloq-reaplicacao-detail/bloq-reaplicacao-detail.html`,
				controller: 'bloqReaplicacaoDetailController',
				controllerAs: 'bloqReaplicacaoDetail'
			}
		}
	});
};

module.exports = routesConfiguration;
