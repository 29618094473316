'use strict';

const listController = require('./list/list.controller');
const listApiService = require('./list/api.service');

const detailController = require('./detail/detail.controller');
const detailApiService = require('./detail/api.service');

const newController = require('./new/new.controller');
const newApiService = require('./new/api.service');

const editController = require('./edit/edit.controller');
const editApiService = require('./edit/api.service');

const routesConfiguration = require('./component.routes');

const componentModule = angular.module('cap.secured.administrar-usuario', [
		'ui.router'
	])
	.controller('administrarUsuarioListController', listController)
	.service('administrarUsuarioListApi', listApiService)

	.controller('administrarUsuarioDetailController', detailController)
	.service('administrarUsuarioDetailApi', detailApiService)

	.controller('administrarUsuarioNewController', newController)
	.service('administrarUsuarioNewApi', newApiService)

	.controller('administrarUsuarioEditController', editController)
	.service('administrarUsuarioEditApi', editApiService)

	.config(routesConfiguration);

module.exports = componentModule;
