'use strict';

class liberacaoDaCaucaoAnnexController {

	// @ngInject
	constructor($scope, $window, $q, feedbackMessages, liberacaoDaCaucaoFormControlService, liberacaoDaCaucaoAnnexApi) {
		// console.log('liberacaoDaCaucaoNewController');

		this.$window = $window;
		this.$scope = $scope;
		this.$q = $q;

		this.messages = feedbackMessages;
		this.formField = liberacaoDaCaucaoFormControlService;
		this.api = liberacaoDaCaucaoAnnexApi;

		this.formField.formStep = 'case.liberacao-da-caucao.annex';

		this.listenSaveCaseInstance();
		this.checkStatusOnLoad();

		console.log('Form Field:', this.formField);
	}

	hasFormFiles() {

		return true;
	}

	listenSaveCaseInstance() {
		// console.log('listenSaveCaseInstance');

		this.$scope.$on('caseInstanceSaved', (event) => {
			// console.log('listened saveCaseInstance');

			this.formFilesWaitingSave && this.getFormFiles();
		});
	}

	hideTrashByStatus() {
		const statusToHideTrash = ['Reprovado', 'Pagamento Criticado'];

		return (statusToHideTrash.indexOf(this.formField.status) >= 0);
	}

	getFormFiles() {
		// console.log('getFormFiles');

		const formId = this.formField.id;

		this.formFilesWaitingSave = false;

		if (!formId) {
			// console.log('getFormFiles no ID');

			this.formFilesWaitingSave = true;
			this.$scope.$emit('saveCaseInstance');
			return;
		}
		// console.log('getFormFiles with ID');

		this.formField.lastFormAction = 'BaixarFormulario';

		window.open(`apex/CapOnlineCaseLiberacaoSemPgtoPDFPage?id=${formId}`, '_blank');
	}

	isLegalRepresentative() {
		// console.log('isLegalRepresentative', this.formField.information.legalRepresentative);
		this.formField.annex.legalRepresentative.identityRenter.show = true;
		let legalRepresentative = false;

		if (this.formField.information.legalRepresentative.value === true) {

			legalRepresentative = true;
		}

		this.formField.annex.legalRepresentative.document.show = legalRepresentative;
		//this.formField.annex.legalRepresentative.identityRenter.show = !legalRepresentative;

		return legalRepresentative;
	}

	checkStatusOnLoad() {
		// console.log('checkStatusOnLoad', this.formField);

		if (this.formField.annex.status !== 'Concluido') {

			this.formField.annex.status = 'Incompleto';

			console.log('checkStepStatus:', this.formField.annex.status);
		}

		this.formField.annex.form.show = true;
		this.formField.annex.legalRepresentative.show = true;
		this.formField.annex.legalRepresentative.other.show = true;

		this.isLegalRepresentative();
		this.cleanHiddenAnnexes();
		if (this.formField.information.mainAccount.value.isPersonAccount === true) {
			this.exibeAnexoSubscritorPF();
		} else this.exibeAnexoSubscritorPJ();

		this.$scope.$emit('validateUploads');
	}

	getAnnexByType(type) {

		let annex;

		if (type === 'form') {

			annex = this.formField.annex.form;
		} else if (type === 'legal-document') {

			annex = this.formField.annex.legalRepresentative.document;
		} else if (type === 'legal-identity-renter') {

			annex = this.formField.annex.legalRepresentative.identityRenter;
		} else if (type === 'legal-other') {

			annex = this.formField.annex.legalRepresentative.other;
		} else if (type === 'subscritor-identity') {

			annex = this.formField.annex.subscritor.identity;
		} else if (type === 'subscritor-identity-admin') {

			annex = this.formField.annex.subscritor.identityAdmin;
		} else if (type === 'subscritor-cnpj') {

			annex = this.formField.annex.subscritor.cnpj;
		} else if (type === 'subscritor-constituition') {

			annex = this.formField.annex.subscritor.constituitionDocumentation;
		} else if (type === 'subscritor-other') {

			annex = this.formField.annex.subscritor.other;
		}

		return annex;
	}

	cleanHiddenAnnexes() {

		const annexes = [

			this.formField.annex.form,
			this.formField.annex.legalRepresentative.document,
			this.formField.annex.legalRepresentative.identityRenter,
			this.formField.annex.legalRepresentative.other,
			this.formField.annex.subscritor.identity,
			this.formField.annex.subscritor.other,
			this.formField.annex.subscritor.identityAdmin,
			this.formField.annex.subscritor.cnpj,
			this.formField.annex.subscritor.constituitionDocumentation

		];

		for (let i = annexes.length - 1; i >= 0; i--) {

			if (!annexes[i].show) {

				angular.extend(annexes[i], this.formField.pristineAnnexObj());
				annexes.splice(i, 1);
			}
		}

		// console.log('cleanHiddenAnnexes', annexes);
		// console.log(this.formField.annex.form, this.formField.annex.legalRepresentative.document, this.formField.annex.legalRepresentative.identityRenter, this.formField.annex.legalRepresentative.other);
	}

	removeFile(itemIndex, type) {
		console.log('removeFiles', itemIndex, type);

		const annex = this.getAnnexByType(type);

		if (!annex.files[itemIndex].result) {

			annex.files.splice(itemIndex, 1);

			return;
		}

		this.$scope.$emit('changeMainShowLoading', true);

		this.api.removeFile(annex.files[itemIndex].salesForceId).then((response) => {

			this.$scope.$emit('changeMainShowLoading', false);

			if (!response.isSuccessful) {

				this.$window.scrollTo(0, 0);

				const errorMessage = angular.toJson(response.errorMessages);

				this.messages.showErrorMessage(this.$scope, errorMessage);

				return;
			}

			annex.files.splice(itemIndex, 1);

			this.$scope.$emit('validateUploads');
		});
	}

	uploadFiles(files, errFiles, type) {
		// console.log('uploadFiles', files, errFiles, type);

		const annex = this.getAnnexByType(type);

		if (!annex.files) annex.files = [];

		annex.files = annex.files.concat(files);

		this.removeDuplicatedFiles(annex);

		annex.filesParsed = this.parseFiles(annex.files);
		console.log('Files:', annex.files);
		console.log('FilesParserd:', annex.filesParsed);

		this.$scope.$emit('validateUploads');
	}

	removeDuplicatedFiles(annex) {
		console.log('removeDuplicatedFiles', annex);

		const fileNames = [];

		for (let i = 0; i < annex.files.length; i++) {

			const fileName = annex.files[i].name;

			if (this.isDuplicatedFileName(fileNames, fileName)) {

				annex.files.splice(i, 1);
			}

			fileNames.push(fileName);
		}
	}

	isDuplicatedFileName(fileNames, fileName) {

		return fileNames.indexOf(fileName) !== -1;
	}

	parseFiles(files) {
		console.log('parseFiles(): ', files);
		//Manobra desenvolvida para evitar o sumiço dos dados do arquino ao parsear o JSON de envio
		//https://github.com/danialfarid/ng-file-upload/issues/1605

		const array = [];

		for (const file of files) {
			// console.log("files: ", file);
			array.push({
				name: file.name,
				size: file.size,
				type: file.type,
				salesForceId: file.salesForceId
			});
		}

		return array;
	}

	checkFileUpload(salesforceId) {
		return (typeof salesforceId == 'undefined')
	}

	exibeAnexoSubscritorPF() {
		//this.formField.file.subscritor.show = true;
		this.formField.annex.subscritor.identity.show = true;
		this.formField.annex.subscritor.other.show = true;
	}
	exibeAnexoSubscritorPJ() {
		this.formField.annex.subscritor.identityAdmin.show = true;
		this.formField.annex.subscritor.cnpj.show = true;
		this.formField.annex.subscritor.constituitionDocumentation.show = true;
		this.formField.annex.subscritor.other.show = true;
	}

	get tooltipForm() {

		return '<p>Documento utilizado apenas para liberação da caução do endereço do imóvel locado.</p>\
						<p>Observações:</p>\
						<p>Este formulário não caracteriza a solicitação de pagamento do resgate. O direito ao pagamento do resgate ou uma nova carta caução poderão ser solicitados posteriormente pelo Titular.</p>';
	}

	get tooltipOthers() {

		return '<p>Documentos identificados como necessários para solicitação, além dos listados acima. </p>\
						<p>Exemplos: Procurações de representatividade, Alvará ou escritura pública, dentre outros. </p>';
	}

	get tooltipLocatorRepresentation() {

		return '<p>Se administrador/representante for Pessoa Jurídica: </p>\
						<p>- Documento de Representação do Locador: Contrato de administração ou Procuração, ou Declaração de administração do imóvel junto ao Contrato de locação. </p>\
						<p>- Cartão do CNPJ do Representante Legal: Comprovante de inscrição retirado na página da Receita Federal e emitido no prazo de até 180 dias. </p>\
						<p>- Documento de constituição do Representante Legal: Contrato Social ou Alteração Contratual ou Estatuto junto à Ata de eleição. </p>\
						<p>- Documento de identificação do Sócio Administrador ou Procurador, ou Representante legal: Documento de identidade válido em todo território nacional, podendo ser: RG, Carteira de Registro no Conselho da Profissão, Carteira de Trabalho, Carteira Nacional de Habilitação ou Registro Nacional de Estrangeiro. </p>\
						<br>\
						<p>Observações: </p>\
						<p>1) Só serão aceitos documentos dentro do prazo de validade, exceto: </p>\
						<p>- Carteira Nacional de Habilitação, ou </p>\
						<p>- Registro Nacional de Estrangeiro junto ao protocolo de renovação emitido pelo Órgão responsável e cópia do documento vencido. </p>\
						<p>2) O Passaporte só será aceito com a identificação do país de expedição. </p>\
						<br>\
						<p>Se administrador/representante for Pessoa Física: </p>\
						<p>- Documento de identificação do Representante Legal: idem a explicação para Documento de identificação do Sócio Administrador ou Procurador, ou Representante legal.</p>';
	}

	get tooltipLocatorIdentity() {

		return '<p>Documento de identidade válido em todo território nacional, podendo ser: RG, Carteira de Registro no Conselho da Profissão, Carteira de Trabalho, Carteira Nacional de Habilitação ou Registro Nacional de Estrangeiro. </p>\
						<br>\
						<p>Observações: </p>\
						<p>1) Só serão aceitos documentos dentro do prazo de validade, exceto: </p>\
						<p>- Carteira Nacional de Habilitação, ou </p>\
						<p>- Registro Nacional de Estrangeiro junto ao protocolo de renovação emitido pelo Órgão responsável e cópia do documento vencido. </p>\
						<p>2) O Passaporte só será aceito com a identificação do país de expedição.</p>';
	}
}

module.exports = liberacaoDaCaucaoAnnexController;
