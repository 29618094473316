class alteracaoDadosApi {

	/* @ngInject */
	constructor($q, visualForceApi, alteracaoDadosFormControlService) {

		this.$q = $q;
		this.visualForceApi = visualForceApi;
		this.formField = alteracaoDadosFormControlService;

		this.setPristine();
	}

	setPristine() {
		return true;
	}

	pristineAnnexObj() {
		return {
			files: [],
			hasError: false,
			loading: false,
			show: false,
			uploaded: false,
			uploadHasFailure: false
		};
	}

	//@todo gambiarra. refatorar pra deixar dinâmico em vez de marretar os anexos
	//Esse método serve pra remover os anexos que deram erro na hora de salvar o editCaseData
	removeFilesWithError(editCaseDataFiles) {
		const files = this.getAnnexes(editCaseDataFiles);

		for (let i = files.length - 1; i >= 0; i--) {
			for (let j = files[i].length - 1; j >= 0; j--) {
				if (!files[i][j].result) {
					files[i].splice(j, 1);
				}
			}
		}
	}

	getAnnexes(editCaseDataFiles) {
		const annexes = [
			editCaseDataFiles.documents.files
		];
		return annexes;
	}

	resetAnnex(form) {
		const annexes = [
			form.annex.form,
			form.annex.others,
			form.annex.legalRepresentative.document,
			form.annex.legalRepresentative.identityRenter,
			form.annex.legalRepresentative.other,
			form.annex.locationContract.document,
			form.annex.subscritor.identityRenter,
			//form.annex.subscritor.cpf, CAP-3543
			//form.annex.subscritor.adressCheck, CAP-3543
			form.annex.subscritor.otherPf,
			form.annex.subscritor.cnpj,
			form.annex.subscritor.constitutionIdentity,
			form.annex.subscritor.adminIdentity,
			form.annex.subscritor.otherPj
		];

		for (let i = 0; i <= annexes.length - 1; i++) {
			annexes[i].files = [];
			annexes[i].filesParsed = [];
		}

		return form;
	}

	// Parametro isSend para identificar qual a ação Salvar ou Enviar
	saveCaseAlteracaoDados(isSend) {
		let editCaseData = angular.copy(this.formField.extractEditCaseData());

		editCaseData.annex.form.files = editCaseData.annex.form.filesParsed;
		editCaseData.annex.others.files = editCaseData.annex.others.filesParsed;
		editCaseData.annex.legalRepresentative.document.files = editCaseData.annex.legalRepresentative.document.filesParsed;
		editCaseData.annex.legalRepresentative.identityRenter.files = editCaseData.annex.legalRepresentative.identityRenter.filesParsed;
		editCaseData.annex.legalRepresentative.other.files = editCaseData.annex.legalRepresentative.other.filesParsed;
		editCaseData.annex.locationContract.document.files = editCaseData.annex.locationContract.document.filesParsed;
		editCaseData.annex.subscritor.identityRenter.files = editCaseData.annex.subscritor.identityRenter.filesParsed;
		//editCaseData.annex.subscritor.cpf.files = editCaseData.annex.subscritor.cpf.filesParsed; CAP-3543
		//editCaseData.annex.subscritor.adressCheck.files = editCaseData.annex.subscritor.adressCheck.filesParsed; CAP-3543
		editCaseData.annex.subscritor.otherPf.files = editCaseData.annex.subscritor.otherPf.filesParsed;
		editCaseData.annex.subscritor.cnpj.files = editCaseData.annex.subscritor.cnpj.filesParsed;
		editCaseData.annex.subscritor.constitutionIdentity.files = editCaseData.annex.subscritor.constitutionIdentity.filesParsed;
		editCaseData.annex.subscritor.adminIdentity.files = editCaseData.annex.subscritor.adminIdentity.filesParsed;
		editCaseData.annex.subscritor.otherPj.files = editCaseData.annex.subscritor.otherPj.filesParsed;

		// Ao salvar - limpa os anexos
		if (!isSend) editCaseData = this.resetAnnex(editCaseData);

		editCaseData = JSON.stringify(editCaseData);
		console.log('EditCaseData: ====> ', editCaseData);
		//this.cleanUpFileArray(editCaseData.file);

		const tipoAlteracao = this.formField.information.fields.alteracaoType.value;

		console.log('Tipo Alteração', tipoAlteracao);

		const cap_access_token = localStorage.getItem("cap_access_token");

		const payload = this.createSaveRequest(editCaseData);
		payload.accessToken = cap_access_token;

		if (tipoAlteracao == 'Mudança de imóvel/ locador') {
			return this.visualForceApi.invoke(
				'CapCommunityCaseControllerExt.saveCaseAltDadosImovel', payload);
		} else if (tipoAlteracao == 'Mudança de Locador') {
			return this.visualForceApi.invoke(
				'CapCommunityCaseControllerExt.saveCaseAltDadosLocador', payload);
		} else if (tipoAlteracao == 'Alteração de Dados do Cliente') {
			return this.visualForceApi.invoke(
				'CapCommunityCaseControllerExt.saveCaseAltDadosCliente', payload);
		} else {
			return this.visualForceApi.invoke(
				'CapCommunityCaseControllerExt.saveCaseAltDadosCaucao', payload);
		}
	}

	sendCaseAlteracaoDados() {
		let editCaseData = angular.copy(this.formField.extractEditCaseData());
		console.log('Extract EditCaseData ===>', editCaseData);
		editCaseData.annex.form.files = editCaseData.annex.form.filesParsed;
		editCaseData.annex.others.files = editCaseData.annex.others.filesParsed;
		editCaseData.annex.legalRepresentative.document.files = editCaseData.annex.legalRepresentative.document.filesParsed;
		editCaseData.annex.legalRepresentative.identityRenter.files = editCaseData.annex.legalRepresentative.identityRenter.filesParsed;
		editCaseData.annex.legalRepresentative.other.files = editCaseData.annex.legalRepresentative.other.filesParsed;
		editCaseData.annex.locationContract.document.files = editCaseData.annex.locationContract.document.filesParsed;
		editCaseData.annex.subscritor.identityRenter.files = editCaseData.annex.subscritor.identityRenter.filesParsed;
		//editCaseData.annex.subscritor.cpf.files = editCaseData.annex.subscritor.cpf.filesParsed; CAP-3543
		//editCaseData.annex.subscritor.adressCheck.files = editCaseData.annex.subscritor.adressCheck.filesParsed; CAP-3543
		editCaseData.annex.subscritor.otherPf.files = editCaseData.annex.subscritor.otherPf.filesParsed;
		editCaseData.annex.subscritor.cnpj.files = editCaseData.annex.subscritor.cnpj.filesParsed;
		editCaseData.annex.subscritor.constitutionIdentity.files = editCaseData.annex.subscritor.constitutionIdentity.filesParsed;
		editCaseData.annex.subscritor.adminIdentity.files = editCaseData.annex.subscritor.adminIdentity.filesParsed;
		editCaseData.annex.subscritor.otherPj.files = editCaseData.annex.subscritor.otherPj.filesParsed;

		editCaseData = JSON.stringify(editCaseData);
		//const editCaseData = JSON.parse(JSON.stringify(this.formField.extractEditCaseData()));
		console.log('Enviar CASE editcasedata==>: ', editCaseData);

		const tipoAlteracao = this.formField.information.fields.alteracaoType.value;

		console.log('Tipo Alteração', tipoAlteracao);

		const cap_access_token = localStorage.getItem("cap_access_token");

		const payload = this.createSaveRequest(editCaseData);
		payload.accessToken = cap_access_token;

		if (tipoAlteracao == 'Mudança de imóvel/ locador') {
			return this.visualForceApi.invoke(
				'CapCommunityCaseControllerExt.sendCaseAltDadosImovel', payload);
		} else if (tipoAlteracao == 'Mudança de Locador') {
			return this.visualForceApi.invoke(
				'CapCommunityCaseControllerExt.sendCaseAltDadosLocador', payload);
		} else if (tipoAlteracao == 'Alteração de Dados do Cliente') {
			return this.visualForceApi.invoke(
				'CapCommunityCaseControllerExt.sendCaseAltDadosCliente', payload);
		} else {
			return this.visualForceApi.invoke(
				'CapCommunityCaseControllerExt.sendCaseAltDadosCaucao', payload);
		}
	}

	cancelCase(id) {

		const cap_access_token = localStorage.getItem("cap_access_token");

		return this.visualForceApi.invoke(
			'CapCommunityCaseControllerExt.cancelResgate', id, 'alteracao-dados', cap_access_token);
	}

	getFormattedDate(date) {
		const month = `0${date.getMonth() + 1}`.slice(0, 2);
		const day = `0${date.getDate() + 1}`.slice(0, 2);
		const year = date.getFullYear();
		return `${month}/${day}/${year}`;
	}

	createSaveRequest(pEditCaseData) {
		File.prototype.toJSON = function () {
			return {
				// "index": this.index,
				// "$$hashKey": this.$$hashKey,
				"name": this.name,
				"result": this.result,
				"salesForceId": this.salesForceId
			};
		};
		const request = { // TEM UM MONTE DE TERNÁRIO SEM SENTIDO
			solicitacao: {
				id: this.formField.id,
				cpfCnpj: this.formField.information.fields.mainAccount.value.cpf,
				type: (this.formField.information.fields.alteracaoType.value != null) ? this.formField.information.fields.alteracaoType.value : null,
				accountId: (this.formField.information.fields.mainAccount.value.Id != null) ? this.formField.information.fields.mainAccount.value.Id : null,
				propostaId: (this.formField.information.fields.proposta.value.objectId != null) ? this.formField.information.fields.proposta.value.objectId : null,
				status: this.formField.information.status,
				isLegalRepresentative: (this.formField.information.fields.legalRepresentative.value != null) ? this.formField.information.fields.legalRepresentative.value : false,
				isPersonAccount: this.formField.information.fields.mainAccount.value.isPersonAccount,
				titulos: [],
				//editCaseData: this.formField.information.fields,
				alteracaoImovel: {
					cep: (this.formField.data.alteracaoImovel.fields.cep.value != null) ? this.formField.data.alteracaoImovel.fields.cep.value : null,
					logradouro: (this.formField.data.alteracaoImovel.fields.logradouro.value != null) ? this.formField.data.alteracaoImovel.fields.logradouro.value : null,
					numero: (this.formField.data.alteracaoImovel.fields.numero.value != null) ? this.formField.data.alteracaoImovel.fields.numero.value : null,
					complemento: (this.formField.data.alteracaoImovel.fields.complemento.value != null) ? this.formField.data.alteracaoImovel.fields.complemento.value : null,
					bairro: (this.formField.data.alteracaoImovel.fields.bairro.value != null) ? this.formField.data.alteracaoImovel.fields.bairro.value : null,
					cidade: (this.formField.data.alteracaoImovel.fields.cidade.value != null) ? this.formField.data.alteracaoImovel.fields.cidade.value : null,
					uf: (this.formField.data.alteracaoImovel.fields.uf.value != null) ? this.formField.data.alteracaoImovel.fields.uf.value : null,
					tipoImovel: (this.formField.data.alteracaoImovel.fields.tipoImovel.value != null) ? this.formField.data.alteracaoImovel.fields.tipoImovel.value : null,
					finalidadeImovel: (this.formField.data.alteracaoImovel.fields.finalidade.value != null) ? this.formField.data.alteracaoImovel.fields.finalidade.value : null,
					newLocador: (this.formField.data.alteracaoImovel.fields.newLocador.value != null) ? this.formField.data.alteracaoImovel.fields.newLocador.value.Id : null,
					observacoes: (this.formField.data.alteracaoImovel.fields.observacoes.value != null) ? this.formField.data.alteracaoImovel.fields.observacoes.value : null
				},
				mudancaLocador: {
					newLocador: (this.formField.data.mudancaLocador.fields.newLocador.value != null) ? this.formField.data.mudancaLocador.fields.newLocador.value.Id : null,
					observacoes: (this.formField.data.mudancaLocador.fields.observacoes.value != null) ? this.formField.data.mudancaLocador.fields.observacoes.value : null
				},
				alteracaoCliente: {
					alteracaoClientePf: {
						cpf: (this.formField.data.alteracaoDadosCliente.currentData != null) ? this.formField.data.alteracaoDadosCliente.currentData.cpf : null,
						nome: (this.formField.data.alteracaoDadosCliente.fields.pf.nome.value != null) ? this.formField.data.alteracaoDadosCliente.fields.pf.nome.value : null,
						sexo: (this.formField.data.alteracaoDadosCliente.fields.pf.sexo.value != null) ? this.formField.data.alteracaoDadosCliente.fields.pf.sexo.value : null,
						dataNascimento: (this.formField.data.alteracaoDadosCliente.fields.pf.dataNascimento.value != null) ? this.getFormattedDate(this.formField.data.alteracaoDadosCliente.fields.pf.dataNascimento.value) : null,
						identidade: (this.formField.data.alteracaoDadosCliente.fields.pf.identidade.value != null) ? this.formField.data.alteracaoDadosCliente.fields.pf.identidade.value : null,
						orgaoEmissor: (this.formField.data.alteracaoDadosCliente.fields.pf.orgaoEmissor.value != null) ? this.formField.data.alteracaoDadosCliente.fields.pf.orgaoEmissor.value : null,
						uf: (this.formField.data.alteracaoDadosCliente.fields.pf.uf.value != null) ? this.formField.data.alteracaoDadosCliente.fields.pf.uf.value : null,
						dataExpedicao: (this.formField.data.alteracaoDadosCliente.fields.pf.dataExpedicao.value != null) ? this.getFormattedDate(this.formField.data.alteracaoDadosCliente.fields.pf.dataExpedicao.value) : null,
						celular: (this.formField.data.alteracaoDadosCliente.fields.pf.celular.value != null) ? this.formField.data.alteracaoDadosCliente.fields.pf.celular.value : null,
						telefoneFixo: (this.formField.data.alteracaoDadosCliente.fields.pf.telefoneFixo.value != null) ? this.formField.data.alteracaoDadosCliente.fields.pf.telefoneFixo.value : null,
						email: (this.formField.data.alteracaoDadosCliente.fields.pf.email.value != null) ? this.formField.data.alteracaoDadosCliente.fields.pf.email.value : null,
						observacoes: (this.formField.data.alteracaoDadosCliente.fields.pf.observacoes.value != null) ? this.formField.data.alteracaoDadosCliente.fields.pf.observacoes.value : null
					},
					alteracaoClientePj: {
						cnpj: (this.formField.data.alteracaoDadosCliente.currentData != null) ? this.formField.data.alteracaoDadosCliente.currentData.cnpj : null,
						razaoSocial: (this.formField.data.alteracaoDadosCliente.fields.pj.razaoSocial.value != null) ? this.formField.data.alteracaoDadosCliente.fields.pj.razaoSocial.value : null,
						nomeFantasia: (this.formField.data.alteracaoDadosCliente.fields.pj.nomeFantasia.value != null) ? this.formField.data.alteracaoDadosCliente.fields.pj.nomeFantasia.value : null,
						isentoInscricaoEstadual: (this.formField.data.alteracaoDadosCliente.fields.pj.isentoInscricaoEstadual.value != null) ? this.formField.data.alteracaoDadosCliente.fields.pj.isentoInscricaoEstadual.value : null,
						inscricaoEstadual: (this.formField.data.alteracaoDadosCliente.fields.pj.inscricaoEstadual.value != null) ? this.formField.data.alteracaoDadosCliente.fields.pj.inscricaoEstadual.value : null,
						telefoneComercial: (this.formField.data.alteracaoDadosCliente.fields.pj.telefoneComercial.value != null) ? this.formField.data.alteracaoDadosCliente.fields.pj.telefoneComercial.value : null,
						ramal: (this.formField.data.alteracaoDadosCliente.fields.pj.ramal.value != null) ? this.formField.data.alteracaoDadosCliente.fields.pj.ramal.value : null,
						telefoneComercialDois: (this.formField.data.alteracaoDadosCliente.fields.pj.telefoneComercialDois.value != null) ? this.formField.data.alteracaoDadosCliente.fields.pj.telefoneComercialDois.value : null,
						ramalDois: (this.formField.data.alteracaoDadosCliente.fields.pj.ramalDois.value != null) ? this.formField.data.alteracaoDadosCliente.fields.pj.ramalDois.value : null,
						observacoes: (this.formField.data.alteracaoDadosCliente.fields.pj.observacoes.value != null) ? this.formField.data.alteracaoDadosCliente.fields.pj.observacoes.value : null
					}
				},
				alteracaoCaucao: {
					cep: (this.formField.data.inclusaoCaucao.fields.cep.value != null) ? this.formField.data.inclusaoCaucao.fields.cep.value : null,
					logradouro: (this.formField.data.inclusaoCaucao.fields.logradouro.value != null) ? this.formField.data.inclusaoCaucao.fields.logradouro.value : null,
					numero: (this.formField.data.inclusaoCaucao.fields.numero.value != null) ? this.formField.data.inclusaoCaucao.fields.numero.value : null,
					complemento: (this.formField.data.inclusaoCaucao.fields.complemento.value != null) ? this.formField.data.inclusaoCaucao.fields.complemento.value : null,
					bairro: (this.formField.data.inclusaoCaucao.fields.bairro.value != null) ? this.formField.data.inclusaoCaucao.fields.bairro.value : null,
					cidade: (this.formField.data.inclusaoCaucao.fields.cidade.value != null) ? this.formField.data.inclusaoCaucao.fields.cidade.value : null,
					uf: (this.formField.data.inclusaoCaucao.fields.uf.value != null) ? this.formField.data.inclusaoCaucao.fields.uf.value : null,
					tipoImovel: (this.formField.data.inclusaoCaucao.fields.tipoImovel.value != null) ? this.formField.data.inclusaoCaucao.fields.tipoImovel.value : null,
					finalidade: (this.formField.data.inclusaoCaucao.fields.finalidade.value != null) ? this.formField.data.inclusaoCaucao.fields.finalidade.value : null,
					newLocador: (this.formField.data.inclusaoCaucao.fields.newLocador.value != null) ? this.formField.data.inclusaoCaucao.fields.newLocador.value.Id : null,
					administradorDiferenteLocador: (this.formField.data.inclusaoCaucao.fields.administradorDiferenteLocador.value != null) ? this.formField.data.inclusaoCaucao.fields.administradorDiferenteLocador.value : false,
					administradorImovel: (this.formField.data.inclusaoCaucao.fields.administradorImovel.value != null) ? this.formField.data.inclusaoCaucao.fields.administradorImovel.value.Id : null,
					locatarioDiferenteTitular: (this.formField.data.inclusaoCaucao.fields.locatarioDiferenteTitular.value != null) ? this.formField.data.inclusaoCaucao.fields.locatarioDiferenteTitular.value : null,
					cpfLocatario: (this.formField.data.inclusaoCaucao.fields.cpfLocatario.value != null) ? this.formField.data.inclusaoCaucao.fields.cpfLocatario.value : null,
					nomeLocatario: (this.formField.data.inclusaoCaucao.fields.nomeLocatario.value != null) ? this.formField.data.inclusaoCaucao.fields.nomeLocatario.value : null,
					percentualDireito: (this.formField.data.inclusaoCaucao.fields.percentualDireito.value != null) ? this.formField.data.inclusaoCaucao.fields.percentualDireito.value : null,
					observacoes: (this.formField.data.inclusaoCaucao.fields.observacoes.value != null) ? this.formField.data.inclusaoCaucao.fields.observacoes.value : null
				},
				editCaseData: pEditCaseData

			}
		}

		if (this.formField.information.fields.garantias.value !== null) {

			for (let i = 0; i < this.formField.information.fields.garantias.value.length; i++) {

				if (this.formField.information.fields.garantias.value[i].selected === true) {

					request.solicitacao.titulos.push({
						id: this.formField.information.fields.garantias.value[i].objectId,
						value: this.formField.information.fields.garantias.value[i].value,
						antecipatedValue: this.formField.information.fields.garantias.value[i].antecipatedValue
					});
				}
			}
		}

		console.log('REQUEST: ', request);
		return request;
	}

	cleanUpFileArray(object) {
		const properties = Object.keys(object);

		for (let i = properties.length - 1; i >= 0; i--) {
			const propertyName = properties[i];

			if (propertyName === 'files') {
				object[propertyName] = [];
			} else if (angular.isObject(object[propertyName])) {
				this.cleanUpFileArray(object[propertyName]);
			}
		}
	}
}

module.exports = alteracaoDadosApi;
