"use strict";

class propostaDetailService {
	/* @ngInject */
	constructor(visualForceApi) {
		this.visualForceApi = visualForceApi;
	}

	getPropostaDetails(accountId) {
		const cap_access_token = localStorage.getItem("cap_access_token");

		return this.visualForceApi.invoke(
			"CapCommunityPropostaControllerExt.getPropostaDetails",
			accountId,
			cap_access_token
		);
	}

	cloneProposta(propostaId) {
		const cap_access_token = localStorage.getItem("cap_access_token");

		return this.visualForceApi.invoke(
			"CapCommunityPropostaControllerExt.cloneProposta",
			propostaId,
			cap_access_token
		);
	}
}

module.exports = propostaDetailService;
