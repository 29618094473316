"use strict";

class comissoesPagasListApi {
	/* @ngInject */
	constructor($q, visualForceApi) {
		this.visualForceApi = visualForceApi;
		this.$q = $q;
	}

	getList(query) {
		return this.visualForceApi.invoke(
			"CapCommunityComissaoPagaControllerExt.getComissaoPagaList",
			query
		);
	}

	getReport(query) {
		return this.visualForceApi.invoke(
			"CapCommunityComissaoPagaControllerExt.getComissaoPagaReport",
			query
		);
	}

	getProductsList() {

		const cap_access_token = localStorage.getItem("cap_access_token");

		return this.visualForceApi.invoke(
			"CapCommunityAccountControllerExt.getAccountProducts", {
				accessToken: cap_access_token
			}
		);
	}

	searchSaleStructure(searchKeyWord) {
		return this.search(searchKeyWord, "ESTRUTURA_DE_PRODUCAO__c");
	}

	searchPropostaNumber(searchKeyWord) {
		return this.search(searchKeyWord, "PROPOSTA__c");
	}

	search(searchKeyWord, searchTypeObject) {
		const deferred = this.$q.defer();

		const searchModel = {
			dataFetch: {
				searchKeyWord,
				searchTypeObject
			}
		};

		const cap_access_token = localStorage.getItem("cap_access_token");
		searchModel.accessToken = cap_access_token;

		this.visualForceApi
			.invoke("CapCommunityPropostaControllerExt.solFetchRecords", searchModel)
			.then((response) => {
				deferred.resolve(response);
			});

		return deferred.promise;
	}
}

module.exports = comissoesPagasListApi;
