'use strict';

class ResgateFileService {

	/* @ngInject */
	constructor(visualForceApi, $sce, $q) {
		this.visualForceApi = visualForceApi;
		this.$q = $q;
	}

	uploadFiles(Id, fileName, attachment, attachmentType, part, isLastPart, contentType, operationId) {
		console.log('uploadFilesAPI', { Id, fileName, attachment, attachmentType, part, isLastPart });

		const request = { Id, fileName, attachment, attachmentType, part, isLastPart, contentType, operationId };

		const cap_access_token = localStorage.getItem("cap_access_token");
		request.accessToken = cap_access_token;

		return this.visualForceApi.invoke(
			'CapCommunityCaseControllerExt.attachCase', request);
	}

	mountFiles(caseId, attachmentType, attachmentId, userId, fileName, gedAttachmentType, caseType) {
		//const request = { caseId, attachmentType, attachmentId, userId, fileName };

		return this.visualForceApi.invoke('CapCommunityCaseControllerExt.mountAttachmentFile', caseId, attachmentType, fileName, userId, attachmentId, gedAttachmentType, caseType);
	}

	deleteAttachmentFile(attachmentId) {

		const cap_access_token = localStorage.getItem("cap_access_token");

		return this.visualForceApi.invoke('CapCommunityCaseControllerExt.deleteAttachment', attachmentId, cap_access_token);
	}
}

module.exports = ResgateFileService;
