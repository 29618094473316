'use strict';

class accountListService {

	/* @ngInject */
	constructor(visualForceApi) {
		this.visualForceApi = visualForceApi;
	}

	getAccountList(listView) {

		const cap_access_token = localStorage.getItem("cap_access_token");
		listView.accessToken = cap_access_token;

		return this.visualForceApi.invoke(
			'CapCommunityAccountControllerExt.getAccountList', listView);
	}

}

module.exports = accountListService;
