'use strict';

class revisaoComissaoAnnexController {

	// @ngInject
	constructor($scope, $window, feedbackMessages, revisaoComissaoFormService, revisaoComissaoService, revisaoComissaoAnnexService) {
		console.log('revisaoComissaoNewController');

		this.$window = $window;
		this.$scope = $scope;

		this.messages = feedbackMessages;
		this.revisaoComissaoFormService = revisaoComissaoFormService;
		this.revisaoComissaoService = revisaoComissaoService;
		this.api = revisaoComissaoAnnexService;

		this.formField = this.revisaoComissaoFormService.getFormField();

		console.log('Form Field:', this.formField);
	}

	hideTrashByStatus() {
		const statusToHideTrash = ['Reprovado', 'Pagamento Criticado'];

		return (statusToHideTrash.indexOf(this.formField.status) >= 0);
	}

	removeFile(itemIndex, type) {
		console.log('removeFile', itemIndex, type);
		const annex = this.getAnnexByType(type);

		if (!annex.files[itemIndex].result) {
			annex.files.splice(itemIndex, 1);
			return;
		}

		this.$scope.$emit('changeMainShowLoading', true);
		this.api.removeFile(annex.files[itemIndex].salesForceId).then((response) => {

			if (response.isSuccessful) {
				annex.files.splice(itemIndex, 1);
				this.revisaoComissaoFormService.setFormField(this.formField);
				this.revisaoComissaoService.updateEditCaseData();
			} else {
				this.$window.scrollTo(0, 0);
				const errorMessage = angular.toJson(response.errorMessages);
				this.messages.showErrorMessage(this.$scope, errorMessage);
				return;
			}

			this.$scope.$emit('changeMainShowLoading', false);
		});
	}

	uploadFiles(files, errFiles, type) {
		console.log('uploadFiles', files, errFiles, type);
		console.log(this.formField);

		if (!this.validateFilesSizes(files)) {
			//this.resgateService.setErrorMessage('Um dos arquivos selecionados excedeu o limite de 8MB');
			console.log('Um dos arquivos selecionados excedeu o limite de 8MB');
			return;
		}

		// A variavel annex é uma referencia ao anexo do formfield
		// Então quando manipulamos annex abaixo, estamos atribuindo ao formfield
		const annex = this.getAnnexByType(type);

		if (!annex.files) annex.files = [];

		annex.files = annex.files.concat(files);

		this.removeDuplicatedFiles(annex);

		annex.filesParsed = this.parseFiles(annex.files);
		console.log(annex.files);
		console.log(annex.filesParsed);
	}

	validateFilesSizes(annex) {
		let isValid = true;
		const sizeLimit = 8388608 //8MB

		for (let i = annex.length - 1; i >= 0; i--) {
			if (annex[i].size > sizeLimit) {
				isValid = false;
				break;
			}
		}

		return isValid;
	}

	getAnnexByType(type) {
		let annex;

		if (type === 'revisao') {
			annex = this.formField.file.documents;
		}

		return annex;
	}

	removeDuplicatedFiles(annex) {
		console.log('removeDuplicatedFiles', annex);
		const fileNames = [];

		for (let i = 0; i < annex.files.length; i++) {
			const fileName = annex.files[i].name;
			if (this.isDuplicatedFileName(fileNames, fileName)) {
				annex.files.splice(i, 1);
			}
			fileNames.push(fileName);
		}
	}

	isDuplicatedFileName(fileNames, fileName) {
		return fileNames.indexOf(fileName) !== -1;
	}

	parseFiles(files) {
		console.log('parseFiles(): ', files);
		//Manobra desenvolvida para evitar o sumiço dos dados do arquino ao parsear o JSON de envio
		//https://github.com/danialfarid/ng-file-upload/issues/1605

		const array = [];

		for (const file of files) {
			// console.log("files: ", file);
			array.push({
				name: file.name,
				size: file.size,
				type: file.type,
				salesForceId: file.salesForceId
			});
		}

		return array;
	}

	checkFileUpload(salesforceId) {
		return (typeof salesforceId == 'undefined')
	}
}

module.exports = revisaoComissaoAnnexController;
