'use strict';

/* @ngInject */
function routesConfiguration($stateProvider, sfConfig) {

	$stateProvider.state('assistencia-locaticia', {

		url: '/assistencia-locaticia/lista',
		views: {

			'content': {

				templateUrl: `${sfConfig.resource}/views/assistencia-locaticia/assistencia-locaticia.html`,
				controller: 'assistenciaLocaticiaController',
				controllerAs: 'assistencia'
			}
		}
	});
};

module.exports = routesConfiguration;
