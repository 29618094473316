'use strict'

class CapInputFilterDirective {
	// @ngInject
	constructor() {
		this.restrict = 'A';
		this.require = 'ngModel';
		this.scope = {
			capInputFilter: '@',
			capTransform: '@'
		};
	}

	link(scope, element, attrs, ngModelController) {

		ngModelController.$parsers.push(
			(modelValue) => {
				const options = {
					'no-special-chars': /^[0-9A-Za-z\s]*$/,
					'no-special-chars-phone': /^[0-9A-Za-z\s\(\)\-]*$/
				};

				let result = modelValue;
				//@todo melhorar
				if (scope.capTransform === 'uppercase') {
					result = result.toUpperCase();
					ngModelController.$setViewValue(result);
					ngModelController.$render();
				}
				if (!!options[scope.capInputFilter] && !options[scope.capInputFilter].test(result)) {
					result = result.slice(0, -1);
					ngModelController.$setViewValue(result);
					ngModelController.$render();
				}
				return result;
			}
		)
		//console.log('diretiva funcionando!')
	}

}

module.exports = CapInputFilterDirective;
