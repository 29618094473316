'use strict';

class documentsService {

	/* @ngInject */
	constructor(visualForceApi) {
		this.visualForceApi = visualForceApi;
	}

	getDocumentsList() {
		return this.visualForceApi.invoke(
			'CapCommunityDocumentControllerExt.getDocuments', 'CAPONLINE_MODELOS_CARTA');
	}
}

module.exports = documentsService;
