'use strict';

/* @ngInject */
function routesConfiguration($stateProvider, sfConfig) {

	$stateProvider.state('remuneracoes-pagas', {

		abstract: true,
		url: '/remuneracoes-pagas',
		views: {

			'content': {

				templateUrl: `${sfConfig.resource}/views/remuneracoes-pagas/remuneracoes-pagas.html`
			}
		}
	});

	$stateProvider.state('remuneracoes-pagas.list', {

		url: '/lista',
		views: {

			'remuneracoes-pagas': {

				templateUrl: `${sfConfig.resource}/views/remuneracoes-pagas/list/list.html`,
				controller: 'remuneracoesPagasListController',
				controllerAs: 'remuneracoesPagasList'
			}
		}
	});

	$stateProvider.state('remuneracoes-pagas.detail', {

		url: '/detalhes/:id',
		views: {

			'remuneracoes-pagas': {

				templateUrl: `${sfConfig.resource}/views/remuneracoes-pagas/detail/detail.html`,
				controller: 'remuneracoesPagasDetailController',
				controllerAs: 'remuneracoesPagasDetail'
			}
		}
	});
};

module.exports = routesConfiguration;
