'use strict';

class caseCacheService {

	// @ngInject
	constructor(reaplicacaoTituloFormService, bloqReaplicacaoFormService, liberacaoDaCaucaoFormControlService, resgateFormService, resgateService, documentacaoPropostaFormControlService, revisaoComissaoFormService, alteracaoDadosFormControlService, alteracaoDadosSetupAccountService, sorteioFormControlService) {
		// console.log('caseCacheService');
		this.documentacaoPropostaFormControlService = documentacaoPropostaFormControlService;
		this.reaplicacaoTituloFormService = reaplicacaoTituloFormService;
		this.bloqReaplicacaoFormService = bloqReaplicacaoFormService;
		this.liberacaoDaCaucaoFormControl = liberacaoDaCaucaoFormControlService;
		this.resgateFormService = resgateFormService;
		this.resgateService = resgateService;
		this.revisaoComissaoFormService = revisaoComissaoFormService;
		this.alteracaoDadosFormControlService = alteracaoDadosFormControlService;
		this.alteracaoDadosSetupAccountService = alteracaoDadosSetupAccountService;
		this.sorteioFormControlService = sorteioFormControlService;
	}

	clean() {
		// console.log('clean', this.liberacaoDaCaucaoFormControl);
		this.documentacaoPropostaFormControlService.setPristine();
		this.bloqReaplicacaoFormService.setPristine();
		this.reaplicacaoTituloFormService.setPristine();
		this.liberacaoDaCaucaoFormControl.reset();
		this.resgateFormService.resetForm();
		this.resgateService.reset();
		this.revisaoComissaoFormService.resetForm();
		this.alteracaoDadosFormControlService.setPristine();
		this.alteracaoDadosSetupAccountService.setPristine();
		this.sorteioFormControlService.setPristine();
		// console.log('cleaned', this.liberacaoDaCaucaoFormControl);
	}

}

module.exports = caseCacheService;
